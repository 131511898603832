/* eslint-disable no-underscore-dangle */
import { WidgetType } from '@app/core/widgets/react/types';

/**
 * Checks if the widget is a conditional one.
 */
export function isConditionalWidget(widget: WidgetType) {
  switch (widget.__typename) {
    case 'SsoWidget':
      // The widget is conditional if it is set to hide if ineligible.
      return widget.hideIfIneligible;

    default:
      return false;
  }
}
