/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.
export type Maybe<T> = T | null;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  Any: any;
  /** The `Byte` scalar type represents non-fractional whole numeric values. Byte can represent values between 0 and 255. */
  Byte: any;
  /** The `Date` scalar represents an ISO-8601 compliant date type. */
  Date: any;
  /** The `DateTime` scalar represents an ISO-8601 compliant date time type. */
  DateTime: any;
  /** The built-in `Decimal` scalar type. */
  Decimal: any;
  /** The `Long` scalar type represents non-fractional signed whole 64-bit numeric values. Long can represent values between -(2^63) and 2^63 - 1. */
  Long: any;
  /** The `TimeSpan` scalar represents an ISO-8601 compliant duration type. */
  TimeSpan: any;
  /** The `Upload` scalar type represents a file upload. */
  Upload: any;
};




export enum AccessType {
  UNKNOWN = 'UNKNOWN',
  PRIMARY = 'PRIMARY',
  JOINT = 'JOINT',
  PREFERRED = 'PREFERRED',
  SUBUSER = 'SUBUSER'
}

export type AccessibleUser = {
  __typename?: 'AccessibleUser';
  key: Scalars['ID'];
  name: Scalars['String'];
  isDualApprover: Scalars['Boolean'];
};

/** Account alert representation. */
export type AccountAlert = {
  __typename?: 'AccountAlert';
  id: Scalars['Int'];
  alertType: AlertType;
  description: Scalars['String'];
  toggleState: Scalars['Boolean'];
  alertBankAccounts: Maybe<Array<Maybe<AlertBankAccountInfo>>>;
};

/** Account alerts group representation. */
export type AccountAlertGroup = BaseAlertGroup & {
  __typename?: 'AccountAlertGroup';
  /** Alert group id. */
  id: Scalars['String'];
  /** Alert group title. */
  title: Scalars['String'];
  /** Alerts group's delivery options. */
  deliveryOptions: DeliveryOptions;
  /** Account alerts in the group. */
  alertTypes: Maybe<Array<AccountAlertTypeGroup>>;
};

/** Account alert by type group representation. */
export type AccountAlertTypeGroup = {
  __typename?: 'AccountAlertTypeGroup';
  formId: Scalars['String'];
  title: Scalars['String'];
  description: Scalars['String'];
  alertType: AlertType;
  alertSettings: Array<AccountAlert>;
};

export type AccountSelector = {
  __typename?: 'AccountSelector';
  /** Id of the workflow to run. */
  workflowId: Scalars['String'];
};

export type AchGroup = {
  __typename?: 'AchGroup';
  /** The identifier of ACH group. */
  id: Maybe<Scalars['Long']>;
  /** The name of ACH group. */
  name: Maybe<Scalars['String']>;
  /** The settlement account's bank account identifier. */
  bankAccountId: Maybe<Scalars['String']>;
  /** The commercial banking category of ACH group. */
  commercialBankingCategory: CommercialBankingCategory;
  /** The standard entry class code of ACH group. */
  standardEntryClassCode: StandardEntryClassCode;
  /** The settlement account of ACH group. */
  settlementAccount: Maybe<Scalars['String']>;
  /** The error message of ACH group. */
  errorMessage: Maybe<Scalars['String']>;
  /** The total amount of ACH group. */
  totalAmount: Scalars['Decimal'];
  /** The recipients of ACH group. */
  recipients: Maybe<Array<Recipient>>;
};

export type ActivateCardInput = {
  cardKey: Scalars['ID'];
  cardNumber: Maybe<Scalars['String']>;
  pin: Maybe<Scalars['String']>;
  ssn: Maybe<Scalars['String']>;
  cvv2: Maybe<Scalars['String']>;
};

export type ActivateCardResult = {
  __typename?: 'ActivateCardResult';
  card: Maybe<Card>;
  successful: Scalars['Boolean'];
  errors: Maybe<Array<ValidationItem>>;
  warnings: Maybe<Array<ValidationItem>>;
  infos: Maybe<Array<ValidationItem>>;
};

export type ActivateFeature = {
  __typename?: 'ActivateFeature';
  isEnabled: Scalars['Boolean'];
  pin: PinOnActivation;
  validation: Array<ValidationType>;
};

/** The AdService widget type contains base url for IFrame. */
export type AdServiceWidget = {
  __typename?: 'AdServiceWidget';
  /** Id of the Item */
  id: Maybe<Scalars['ID']>;
  /** The content item version id */
  contentItemVersionId: Maybe<Scalars['String']>;
  /** Type of content */
  contentType: Maybe<Scalars['String']>;
  /** The display text of the content item */
  displayText: Maybe<Scalars['String']>;
  /** Is the published version */
  published: Scalars['Boolean'];
  /** Is the latest version */
  latest: Scalars['Boolean'];
  /** The date and time of modification */
  modifiedUtc: Maybe<Scalars['DateTime']>;
  /** The date and time of publication */
  publishedUtc: Maybe<Scalars['DateTime']>;
  /** The date and time of creation */
  createdUtc: Maybe<Scalars['DateTime']>;
  /** The owner of the content item */
  owner: Maybe<Scalars['String']>;
  /** The author of the content item */
  author: Maybe<Scalars['String']>;
  /** Widget Flow Layout Meta Information from WYSIWYG Editor */
  flowMeta: Maybe<FlowMetadata>;
  /** AdService provider identifier. */
  providerId: Scalars['String'];
};

export type AddCardInternationalLocationControlInput = {
  cardKey: Scalars['String'];
  countryCode: Scalars['String'];
};

export type AddCardInternationalLocationControlResult = {
  __typename?: 'AddCardInternationalLocationControlResult';
  countries: Array<InternationalLocationPreference>;
  successful: Scalars['Boolean'];
  errors: Maybe<Array<ValidationItem>>;
  warnings: Maybe<Array<ValidationItem>>;
  infos: Maybe<Array<ValidationItem>>;
};

export type AddCardMyRegionLocationAlertInput = {
  cardKey: Scalars['String'];
  name: Scalars['String'];
  latitude: Scalars['Float'];
  longitude: Scalars['Float'];
  radiusInMeters: Scalars['Decimal'];
};

export type AddCardMyRegionLocationAlertResult = {
  __typename?: 'AddCardMyRegionLocationAlertResult';
  myRegions: Array<MyRegionPreference>;
  successful: Scalars['Boolean'];
  errors: Maybe<Array<ValidationItem>>;
  warnings: Maybe<Array<ValidationItem>>;
  infos: Maybe<Array<ValidationItem>>;
};

export type AddCardMyRegionLocationControlInput = {
  cardKey: Scalars['String'];
  name: Scalars['String'];
  latitude: Scalars['Float'];
  longitude: Scalars['Float'];
  radiusInMeters: Scalars['Decimal'];
};

export type AddCardMyRegionLocationControlResult = {
  __typename?: 'AddCardMyRegionLocationControlResult';
  myRegions: Array<MyRegionPreference>;
  successful: Scalars['Boolean'];
  errors: Maybe<Array<ValidationItem>>;
  warnings: Maybe<Array<ValidationItem>>;
  infos: Maybe<Array<ValidationItem>>;
};

export type AddExternalCardCommandInput = {
  /** Id of the card in external gateway. */
  externalId: Scalars['String'];
  /** Obfuscated card number. */
  cardNumber: Scalars['String'];
  /** Name of cardholder. */
  cardholder: Scalars['String'];
  /** Expiration date of the card. */
  expirationDate: Scalars['String'];
  /** Some description provided by user. */
  description: Maybe<Scalars['String']>;
};

export type AddExternalCardResult = {
  __typename?: 'AddExternalCardResult';
  /** Id of added external card. */
  externalCardId: Maybe<Scalars['ID']>;
  /** Validation result of operation. */
  validationResult: Maybe<ValidationResultContainer>;
};

export type AddFavoriteInput = {
  /** Name of favorite */
  name: Scalars['String'];
  /** Id of transfer that should be saved as favorite */
  transferId: Scalars['String'];
};

export type AddFavoriteResult = {
  __typename?: 'AddFavoriteResult';
  /** Created favorite id. */
  favoriteId: Maybe<Scalars['ID']>;
  /** Contains summary about operation execution. */
  validationResult: Maybe<ValidationResultContainer>;
};

export type AddInternalPaymentInput = {
  /** Amount of payment */
  amount: Scalars['Decimal'];
  /** Source account identifier of payment */
  sourceAccountId: Scalars['String'];
  /** Destination account identifier of payment */
  destinationAccountId: Scalars['String'];
  /** Memo of payment */
  memo: Maybe<Scalars['String']>;
  /** Payment option identifier of payment */
  paymentOptionId: Maybe<Scalars['String']>;
  /** Scheduled date of payment */
  scheduledDate: Maybe<Scalars['Date']>;
  /** Recurring settings if payment can be recurred */
  recurringOptionSettings: Maybe<RecurringOptionSettingsInput>;
};

export type AddInternalPaymentResult = {
  __typename?: 'AddInternalPaymentResult';
  /** Transaction identifier. This identifier is unique for payment */
  transactionId: Maybe<Scalars['ID']>;
  /** Transfer status. */
  transactionStatus: Scalars['String'];
  /** Validation result of operation. */
  validationResult: Maybe<ValidationResultContainer>;
};

export type AddInvestmentPaymentInput = {
  /** Source account identifier of payment */
  sourceAccountId: Scalars['String'];
  /** Destination account identifier of payment */
  destinationAccountId: Scalars['String'];
  /** Payment option identifier of payment */
  paymentOptionId: Maybe<Scalars['String']>;
  /** Amount of payment */
  amount: Scalars['Decimal'];
  /** Memo of payment */
  memo: Maybe<Scalars['String']>;
};

export type AddInvestmentPaymentResult = {
  __typename?: 'AddInvestmentPaymentResult';
  /** Transaction identifier. This identifier is unique for payment. */
  transactionId: Maybe<Scalars['ID']>;
  /** Validation result of operation. */
  validationResult: Maybe<ValidationResultContainer>;
};

export type AddM2MPaymentInput = {
  /** Source account identifier of m2m payment */
  sourceAccountId: Scalars['String'];
  /** Amount of m2m payment */
  amount: Scalars['Decimal'];
  /** Destination account identifier of m2m payment */
  destinationAccountId: Scalars['String'];
  /** Memo of m2m payment */
  memo: Maybe<Scalars['String']>;
  /** Recurring settings for m2m if payment can be recurred */
  recurringOptionSettings: Maybe<RecurringOptionSettingsInput>;
  /** Scheduled date of m2m payment */
  scheduledDate: Maybe<Scalars['Date']>;
};

export type AddM2MPaymentResult = {
  __typename?: 'AddM2MPaymentResult';
  /** Transaction identifier. This identifier is unique for payment */
  transactionId: Maybe<Scalars['ID']>;
  /** Transfer status. */
  transactionStatus: Scalars['String'];
  /** Validation result of operation. */
  validationResult: Maybe<ValidationResultContainer>;
};

export type AddPayByCardPaymentInput = {
  /** Id of external card selected by user. */
  sourceExternalCardId: Scalars['String'];
  /** Unique id of destination account selected by user. */
  destinationAccountId: Scalars['String'];
  /** Unique id of payment option selected by user if supported. */
  paymentOptionId: Maybe<Scalars['String']>;
  /** Payment amount. */
  amount: Scalars['Decimal'];
  /** Memo of payment */
  memo: Maybe<Scalars['String']>;
};

export type AddPayByCardPaymentResult = {
  __typename?: 'AddPayByCardPaymentResult';
  /** Transaction identifier. This identifier is unique for payment */
  transactionId: Maybe<Scalars['ID']>;
  /** Transfer status. */
  transactionStatus: Scalars['String'];
  /** Validation result of operation. */
  validationResult: Maybe<ValidationResultContainer>;
};

export type AddPayeeInput = {
  /** Recipient nickname. */
  nickname: Scalars['String'];
  /** Recipient member number. */
  memberNumber: Scalars['String'];
  /** Recipient last name. */
  verificationName: Scalars['String'];
  /** Recipient account number. */
  accountNumber: Scalars['String'];
  /** Recipient's account type identifier. */
  accountType: Maybe<Scalars['String']>;
};

export type AddPayeeResult = {
  __typename?: 'AddPayeeResult';
  /** Created recipient id. */
  payeeId: Maybe<Scalars['ID']>;
  /** Contains summary about operation execution. */
  validationResult: Maybe<ValidationResultContainer>;
};

export type AddUserCustomDataResult = {
  __typename?: 'AddUserCustomDataResult';
  successful: Scalars['Boolean'];
  errors: Maybe<Array<ValidationItem>>;
  warnings: Maybe<Array<ValidationItem>>;
  infos: Maybe<Array<ValidationItem>>;
};

export type AddWireRecipientResult = {
  __typename?: 'AddWireRecipientResult';
  /** Id of added recipient */
  recipientId: Scalars['ID'];
  /** Indicates if the action is successful */
  successful: Scalars['Boolean'];
  /** The list of errors */
  errors: Maybe<Array<ValidationItem>>;
  /** The list of warnings */
  warnings: Maybe<Array<ValidationItem>>;
  /** The list of infos */
  infos: Maybe<Array<ValidationItem>>;
};

/** AdService advertisement. */
export type AdvertisementResult = {
  __typename?: 'AdvertisementResult';
  /** HTML */
  html: Maybe<Scalars['String']>;
  /** Advertisement identifier. */
  id: Maybe<Scalars['String']>;
};

export enum AdvertisementStatus {
  UNKNOWN = 'UNKNOWN',
  PRESENTED = 'PRESENTED',
  DECLINED = 'DECLINED',
  REFERRED = 'REFERRED',
  POSTPONED = 'POSTPONED',
  ACCEPTED = 'ACCEPTED',
  FULFILLED = 'FULFILLED'
}

/** The agreement field info type. */
export type AgreementFieldInfo = UiElementInfo & FieldInfoBase & {
  __typename?: 'AgreementFieldInfo';
  /** Field Value. */
  value: Scalars['Boolean'];
  /** Agreement text. */
  agreementText: Maybe<Scalars['String']>;
  /** Agreement title. */
  agreementTitle: Maybe<Scalars['String']>;
  /** The field Id. */
  id: Scalars['ID'];
  /** Field type. */
  type: FieldType;
  /** Field Name, Can be used as Label in AT and/or for validation and error messages. */
  name: Maybe<Scalars['String']>;
  /** Field Description Can be used as tooltip or watermark on a presentation level */
  description: Maybe<Scalars['String']>;
  /** Gets value which indicates whether this field is enabled in the current configuration. */
  isEnabled: Scalars['Boolean'];
  /** Label for front-end UI */
  label: Maybe<Scalars['String']>;
  /** Indicates whether the field with the field value needs to be submitted in SubmitWizardStepFieldValues API calls. */
  isSubmittable: Scalars['Boolean'];
  /**
   * client-based property bindings.
   *                     Bindings are exposed to the web app to form a kind of name-value collection of data items
   *                     required to property display a configurable field (that is called DataContext).
   */
  bindings: Maybe<Array<Maybe<Binding>>>;
  /** Field Behavior e.g Required, Readonly */
  fieldBehaviorMode: FieldBehaviorMode;
  /** This property stores the field input hint. Intended to be displayed by native apps on mobile devices. */
  placeholder: Maybe<Scalars['String']>;
};

/** Bank account representation for alert domain model. */
export type AlertBankAccountInfo = {
  __typename?: 'AlertBankAccountInfo';
  /** Bank account unique identifier. */
  alertId: Maybe<Scalars['Long']>;
  /** Bank account unique identifier. */
  bankAccountId: Scalars['String'];
  /** Bank account alert-related description. */
  description: Scalars['String'];
  /** Flag whether bank account is associated with alert. */
  isSelected: Scalars['Boolean'];
};

export type AlertChannelPreferenceInput = {
  isOn: Scalars['Boolean'];
};

export type AlertChannelSettings = {
  __typename?: 'AlertChannelSettings';
  isEnabled: Scalars['Boolean'];
  isOn: Scalars['Boolean'];
};

/** Account alert representation. */
export type AlertDetails = {
  __typename?: 'AlertDetails';
  id: Scalars['Int'];
  description: Scalars['String'];
  fields: Array<FieldsUnion>;
  accounts: Array<AlertBankAccountInfo>;
};

/** Alerts group representation. */
export type AlertGroup = BaseAlertGroup & {
  __typename?: 'AlertGroup';
  /** Alert group id. */
  id: Scalars['String'];
  /** Alert group title. */
  title: Scalars['String'];
  /** Alerts group's delivery options. */
  deliveryOptions: DeliveryOptions;
  /** Alerts in the group. */
  alerts: Maybe<Array<AlertSetting>>;
};

/** Alert setting representation. */
export type AlertSetting = {
  __typename?: 'AlertSetting';
  /** Alert human-readable name. */
  name: Scalars['String'];
  /** Alert description. */
  description: Scalars['String'];
  /** Alert technical name. */
  typeName: Scalars['String'];
  /** Alert on/off state. */
  toggleState: Scalars['Boolean'];
};

/** Alert settings representation. */
export type AlertSettings = {
  __typename?: 'AlertSettings';
  /** Some id. TBD. */
  id: Maybe<Scalars['Int']>;
  /** Alert description. */
  description: Maybe<Scalars['String']>;
  /** Alert on/off state. */
  toggleState: Scalars['Boolean'];
  /** Alert setting ids. */
  alertIds: Array<Scalars['Long']>;
  /** Associated accounts descriptions. */
  accountDescriptions: Maybe<Array<Scalars['String']>>;
};

export enum AlertType {
  NEWSECUREMESSAGE = 'NEWSECUREMESSAGE',
  USERCONTACTSCHANGED = 'USERCONTACTSCHANGED',
  USERCREDENTIALSCHANGED = 'USERCREDENTIALSCHANGED',
  TRANSACTIONTYPE = 'TRANSACTIONTYPE',
  BALANCESUMMARY = 'BALANCESUMMARY',
  BALANCEAMOUNT = 'BALANCEAMOUNT',
  TRANSACTIONSIZE = 'TRANSACTIONSIZE',
  LOGINALERT = 'LOGINALERT',
  PASSWORDRESETREQUESTALERT = 'PASSWORDRESETREQUESTALERT',
  LOANPAYMENTDUEALERT = 'LOANPAYMENTDUEALERT',
  RECURRINGTRANSFEREXPIRING = 'RECURRINGTRANSFEREXPIRING',
  PROFILECHANGEDALERT = 'PROFILECHANGEDALERT',
  CERTIFICATEMATURITYDATEALERT = 'CERTIFICATEMATURITYDATEALERT',
  NEWCARDALERT = 'NEWCARDALERT',
  FAILEDLOGINALERT = 'FAILEDLOGINALERT'
}

export type AlertsChannelsPreferencesInput = {
  email: AlertChannelPreferenceInput;
  pushNotification: AlertChannelPreferenceInput;
  sms: AlertChannelPreferenceInput;
};

export type AlertsChannelsSettings = {
  __typename?: 'AlertsChannelsSettings';
  email: AlertChannelSettings;
  pushNotification: AlertChannelSettings;
  sms: AlertChannelSettings;
};


export enum ApplyPolicy {
  BEFORE_RESOLVER = 'BEFORE_RESOLVER',
  AFTER_RESOLVER = 'AFTER_RESOLVER'
}

export type ApproveSubuserTransferInput = {
  /** User password for approve transaction */
  password: Scalars['String'];
  /** Token of transaction from email */
  token: Scalars['String'];
};

export type ApproveSubuserTransferResult = {
  __typename?: 'ApproveSubuserTransferResult';
  /** Allow to retry approve transaction */
  isRetryAllowed: Scalars['Boolean'];
  /** Validation result of operation. */
  validationResult: Maybe<ValidationResultContainer>;
};

export type AttachStatementTransactionImageResult = {
  __typename?: 'AttachStatementTransactionImageResult';
  attachedId: Maybe<Scalars['String']>;
  attachedName: Maybe<Scalars['String']>;
  successful: Scalars['Boolean'];
  errors: Maybe<Array<ValidationItem>>;
  warnings: Maybe<Array<ValidationItem>>;
  infos: Maybe<Array<ValidationItem>>;
};

/** Represents attachable statement transaction. */
export type AttachableTransaction = {
  __typename?: 'AttachableTransaction';
  /** Transaction identifier. */
  id: Scalars['ID'];
  /** Whether transaction is pending. */
  isPending: Scalars['Boolean'];
  /** Transaction status. */
  status: TransactionStatus;
  /** Account identifier. */
  accountId: Scalars['ID'];
  /** Account name. */
  accountName: Scalars['String'];
  /** Masked account number. */
  maskedAccountNumber: Scalars['String'];
  /** Transaction name. */
  name: Maybe<Scalars['String']>;
  /** Transaction note. */
  note: Maybe<Scalars['String']>;
  /** Transaction description. */
  description: Maybe<Scalars['String']>;
  /** Transaction amount. */
  amount: Scalars['Decimal'];
  /** Transaction post date. */
  postDate: Scalars['DateTime'];
  /** Transaction process date. */
  processDate: Maybe<Scalars['DateTime']>;
  /** Transaction effective date. */
  effectiveDate: Maybe<Scalars['DateTime']>;
};

/** Attachable statement transactions search filters. */
export type AttachableTransactionsFilters = {
  /** Bank account identifier. */
  accountIds: Array<Scalars['ID']>;
  /** Transaction amount. */
  amount: Maybe<Scalars['Decimal']>;
  /** Start date of the search interval. */
  startDate: Maybe<Scalars['Date']>;
  /** End date of the search interval. */
  endDate: Maybe<Scalars['Date']>;
  /** Search text. */
  searchText: Scalars['String'];
  /** Page number. */
  pageNumber: Scalars['Int'];
  /** Page size. */
  pageSize: Scalars['Int'];
};

/** Attachment information. */
export type Attachment = {
  __typename?: 'Attachment';
  /** Attachment type. */
  type: Scalars['String'];
  /** Attachment name. */
  displayName: Scalars['String'];
  /** Attachment entity ID. */
  entityId: Scalars['String'];
};

export type AttachmentDownloadResponse = {
  __typename?: 'AttachmentDownloadResponse';
  /** Name of the file. */
  fileName: Scalars['String'];
  /** MIME type of the content. */
  mimeType: Scalars['String'];
  /** Base64-encoded file binary data. */
  data: Scalars['String'];
  /** Indicates if the action is successful. */
  successful: Scalars['Boolean'];
  /** The list of errors. */
  errors: Maybe<Array<ValidationItem>>;
};

/** Attachment information. */
export type AttachmentInput = {
  /** Attachment type. */
  type: Scalars['String'];
  /** Attachment name. */
  displayName: Scalars['String'];
  /** Attachment entity ID. */
  entityId: Scalars['String'];
};

export type AttachmentUploadResponse = {
  __typename?: 'AttachmentUploadResponse';
  /** Secure message attachment file identifier. */
  fileId: Maybe<Scalars['String']>;
  /** Secure message attachment file name. */
  fileName: Maybe<Scalars['String']>;
  /** Indicates if the action is successful. */
  successful: Scalars['Boolean'];
  /** The list of errors. */
  errors: Maybe<Array<ValidationItem>>;
};

export type AuthResult = {
  __typename?: 'AuthResult';
  /** Flag shows if Wizard finished */
  wizardFinished: Scalars['Boolean'];
  /** User Authenticated Token */
  userAuthToken: Maybe<Scalars['String']>;
  /** Represent what type of user information need to reset. E.g Password reset */
  userInfoResetRequest: Maybe<UserInfoResetUnion>;
  userUidHash: Maybe<Scalars['String']>;
  successSummary: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type AuthenticatorAssertionResponse = {
  /** ClientDataJSON. */
  clientDataJSON: Scalars['String'];
  /** AuthenticatorData. */
  authenticatorData: Scalars['String'];
  /** Signature. */
  signature: Scalars['String'];
  /** UserHandle. */
  userHandle: Scalars['String'];
};

export type AuthenticatorAttestationResponse = {
  /** ClientDataJSON. */
  clientDataJSON: Scalars['String'];
  /** AuthenticatorData. */
  authenticatorData: Scalars['String'];
  /** Transports. */
  transports: Array<Scalars['String']>;
  /** PublicKey. */
  publicKey: Scalars['String'];
  /** PublicKeyAlgorithm. */
  publicKeyAlgorithm: Scalars['Long'];
  /** AttestationObject. */
  attestationObject: Scalars['String'];
};

export type AuthenticatorSelectionCriteria = {
  __typename?: 'AuthenticatorSelectionCriteria';
  /** AuthenticatorAttachment. */
  authenticatorAttachment: Scalars['String'];
  /** ResidentKey. */
  residentKey: Scalars['String'];
  /** RequireResidentKey. */
  requireResidentKey: Scalars['Boolean'];
  /** UserVerification. */
  userVerification: Scalars['String'];
};

export type AuthorizedContact = {
  __typename?: 'AuthorizedContact';
  firstName: Maybe<Scalars['String']>;
  lastName: Maybe<Scalars['String']>;
  phone: Maybe<Scalars['String']>;
  relationToCardHolder: Maybe<Scalars['String']>;
  contactReason: Maybe<Scalars['String']>;
  contactReasonText: Maybe<Scalars['String']>;
};

export type AuthorizedContactInput = {
  firstName: Maybe<Scalars['String']>;
  lastName: Maybe<Scalars['String']>;
  phone: Maybe<Scalars['String']>;
  relationToCardHolder: Maybe<Scalars['String']>;
  contactReason: Maybe<Scalars['String']>;
  contactReasonText: Maybe<Scalars['String']>;
};

/** The backend component arguments dictionary entry type. */
export type BackendComponentArgument = {
  /** Name of the argument. */
  name: Maybe<Scalars['String']>;
  /** Value of the argument. */
  value: Maybe<Scalars['Any']>;
};

export enum BalanceChangeTimeframe {
  LASTMONTH = 'LASTMONTH',
  LASTWEEK = 'LASTWEEK',
  LASTLOGIN = 'LASTLOGIN'
}

export type BankAccount = {
  __typename?: 'BankAccount';
  /** Bank account identifier. This identifier is unique among all accounts */
  id: Scalars['ID'];
  /** Indicate whether transaction history can be loaded for this instance */
  isPaymentOptionsAvailable: Scalars['Boolean'];
  /** Indicates whether one time scheduled payment is allowed for account. */
  isScheduledPaymentAllowed: Scalars['Boolean'];
  /** Indicates whether recurring payments is allowed for account. */
  isRecurringPaymentsAllowed: Scalars['Boolean'];
  /** Indicates whether Automatic recurring payments are allowed for account. */
  isAutoPaymentsAllowed: Scalars['Boolean'];
  /** List of supported recurring options. */
  forcedRecurringOptions: Maybe<Array<RecurringOption>>;
  /** User notice. */
  userNotice: Maybe<Scalars['String']>;
  /** Indicate whether available balance should be shown for this account. */
  showAvailableBalance: Scalars['Boolean'];
  /** Indicate whether available balance should be shown for this account. */
  showCurrentBalance: Scalars['Boolean'];
};

export type BankAccountBalanceChanges = {
  __typename?: 'BankAccountBalanceChanges';
  fromLastWeek: Scalars['Decimal'];
  fromLastMonth: Scalars['Decimal'];
  sinceLastLogin: Scalars['Decimal'];
  defaultBalanceChangeTimeframe: BalanceChangeTimeframe;
};

export type BankAccountBalancesForecastGraphItem = {
  __typename?: 'BankAccountBalancesForecastGraphItem';
  date: Scalars['Date'];
  balance: Scalars['Decimal'];
};

export type BankAccountBalancesGraphItem = {
  __typename?: 'BankAccountBalancesGraphItem';
  date: Scalars['Date'];
  ledgerBalance: Maybe<Scalars['Decimal']>;
  transactionCount: Scalars['Int'];
};

export type BankAccountDisplayDetailsItem = {
  __typename?: 'BankAccountDisplayDetailsItem';
  code: Scalars['String'];
  label: Scalars['String'];
  isEditable: Scalars['Boolean'];
  values: Array<Scalars['String']>;
  extendedValues: Array<Scalars['String']>;
};

export type BankAccountDisplayOptions = {
  __typename?: 'BankAccountDisplayOptions';
  bankAccountId: Scalars['ID'];
  title: Scalars['String'];
  isVisible: Scalars['Boolean'];
};

export type BankAccountGroup = {
  __typename?: 'BankAccountGroup';
  id: Maybe<Scalars['ID']>;
  name: Maybe<Scalars['String']>;
  total: Scalars['Decimal'];
  accounts: Array<BankAccountRef>;
};

export type BankAccountInfo = {
  __typename?: 'BankAccountInfo';
  id: Scalars['ID'];
  accountNumber: Maybe<Scalars['String']>;
  type: BankAccountType;
  accessType: AccessType;
  isVisible: Scalars['Boolean'];
  isClosed: Scalars['Boolean'];
  name: Maybe<Scalars['String']>;
  description: Maybe<Scalars['String']>;
  maskedMemberNumber: Maybe<Scalars['String']>;
  productId: Maybe<Scalars['String']>;
  ledgerBalance: Maybe<Scalars['Decimal']>;
  userFriendlyLedgerBalance: Maybe<Scalars['Decimal']>;
  availableBalance: Maybe<Scalars['Decimal']>;
  isTransactionsHistorySupported: Scalars['Boolean'];
  isTransferDestinationAllowed: Scalars['Boolean'];
  isTransferSourceAllowed: Scalars['Boolean'];
  isForCrossAccountTransferOnly: Scalars['Boolean'];
  isBillPayEligible: Scalars['Boolean'];
  ownerMembership: Maybe<BankAccountMembershipInfo>;
  accessMembership: Maybe<BankAccountMembershipInfo>;
  savingDetails: Maybe<BankAccountSavingDetails>;
  loanDetails: Maybe<BankAccountLoanDetails>;
  investmentDetails: Maybe<BankAccountInvestmentDetails>;
};

export type BankAccountInvestmentDetails = {
  __typename?: 'BankAccountInvestmentDetails';
  todayPercent: Maybe<Scalars['Decimal']>;
  todayAmount: Maybe<Scalars['Decimal']>;
};

export type BankAccountLoanDetails = {
  __typename?: 'BankAccountLoanDetails';
  loanType: LoanType;
  nextPaymentDate: Maybe<Scalars['Date']>;
  nextPaymentAmount: Maybe<Scalars['Decimal']>;
  minimumPaymentAmount: Maybe<Scalars['Decimal']>;
  overduePaymentAmount: Maybe<Scalars['Decimal']>;
  lastStatementDate: Maybe<Scalars['Date']>;
  lastStatementBalance: Maybe<Scalars['Decimal']>;
  payoffAmount: Maybe<Scalars['Decimal']>;
  paymentAmount: Maybe<Scalars['Decimal']>;
  isOverdueInPaymentAmount: Maybe<Scalars['Boolean']>;
};

export type BankAccountMembershipInfo = {
  __typename?: 'BankAccountMembershipInfo';
  number: Scalars['String'];
  displayName: Scalars['String'];
};

export type BankAccountPermission = {
  __typename?: 'BankAccountPermission';
  displayOption: BankAccountPermissionDisplayOption;
  accessValue: Scalars['Boolean'];
};

export enum BankAccountPermissionDisplayOption {
  SHOW = 'SHOW',
  DISABLED = 'DISABLED',
  HIDE = 'HIDE'
}

export type BankAccountPermissionInput = {
  displayOption: BankAccountPermissionDisplayOption;
  accessValue: Scalars['Boolean'];
};

export type BankAccountPermissions = {
  __typename?: 'BankAccountPermissions';
  accountId: Scalars['ID'];
  viewAccount: BankAccountPermission;
  viewTransactions: BankAccountPermission;
  transferOut: BankAccountPermission;
  transferIn: BankAccountPermission;
};

export type BankAccountPermissionsInput = {
  accountId: Scalars['ID'];
  viewAccount: BankAccountPermissionInput;
  viewTransactions: BankAccountPermissionInput;
  transferOut: BankAccountPermissionInput;
  transferIn: BankAccountPermissionInput;
};

export type BankAccountRef = {
  __typename?: 'BankAccountRef';
  /** Bank account identifier. This identifier is unique among all accounts */
  accountId: Scalars['ID'];
};

export type BankAccountSavingDetails = {
  __typename?: 'BankAccountSavingDetails';
  regDCount: Maybe<Scalars['Int']>;
  irsMaxContributionCty: Maybe<Scalars['Decimal']>;
  irsCurrentContributionCty: Maybe<Scalars['Decimal']>;
  savingType: SavingType;
};

export type BankAccountSummary = {
  __typename?: 'BankAccountSummary';
  id: Scalars['ID'];
  nameRaw: Maybe<Scalars['String']>;
  transferActions: Maybe<Array<TransferActionItem>>;
  loanDetails: Maybe<BankAccountSummaryLoanDetails>;
  isBalanceHistoryAvailable: Scalars['Boolean'];
  isPreferredAccessAccount: Scalars['Boolean'];
};

export type BankAccountSummaryLoanDetails = {
  __typename?: 'BankAccountSummaryLoanDetails';
  nextPaymentDate: Maybe<Scalars['Date']>;
  paymentAmount: Maybe<Scalars['Decimal']>;
  overduePaymentAmount: Maybe<Scalars['Decimal']>;
  transferAccountActions: Maybe<Array<TransferActionItem>>;
};

export enum BankAccountType {
  UNKNOWN = 'UNKNOWN',
  CHECKING = 'CHECKING',
  SAVINGS = 'SAVINGS',
  LOAN = 'LOAN',
  CREDITLINE = 'CREDITLINE',
  INVESTMENT = 'INVESTMENT'
}

export type BankInfo = {
  __typename?: 'BankInfo';
  /** Bank Id */
  id: Maybe<Scalars['ID']>;
  /** Bank routing number */
  routingNumber: Maybe<Scalars['String']>;
  /** Routing number type of usage details. */
  routingNumberType: RoutingNumberType;
  /** Bank short name */
  shortName: Maybe<Scalars['String']>;
  /** Bank long name */
  fullName: Maybe<Scalars['String']>;
};

/** Base alerts group representation. */
export type BaseAlertGroup = {
  /** Alert group id. */
  id: Scalars['String'];
  /** Alert group title. */
  title: Scalars['String'];
  /** Alerts group's delivery options. */
  deliveryOptions: DeliveryOptions;
};

export type BillingAccount = {
  __typename?: 'BillingAccount';
  /** Bank account identifier. This identifier is unique among all accounts. */
  id: Scalars['ID'];
  /** Indicate whether available balance should be shown for this account. */
  showAvailableBalance: Scalars['Boolean'];
  /** Indicate whether available balance should be shown for this account. */
  showCurrentBalance: Scalars['Boolean'];
};

export type BillingAddress = {
  __typename?: 'BillingAddress';
  line1: Maybe<Scalars['String']>;
  line2: Maybe<Scalars['String']>;
  city: Maybe<Scalars['String']>;
  state: Maybe<Scalars['String']>;
  country: Maybe<Scalars['String']>;
  phone: Maybe<Scalars['String']>;
  zip: Maybe<Scalars['String']>;
};

export type BillingAddressFeature = {
  __typename?: 'BillingAddressFeature';
  isEnabled: Scalars['Boolean'];
  isEditable: Scalars['Boolean'];
  address: Maybe<BillingAddress>;
};

export type BillingAddressInput = {
  line1: Maybe<Scalars['String']>;
  line2: Maybe<Scalars['String']>;
  city: Maybe<Scalars['String']>;
  state: Maybe<Scalars['String']>;
  country: Maybe<Scalars['String']>;
  phone: Maybe<Scalars['String']>;
  zip: Maybe<Scalars['String']>;
};

export type Binding = {
  __typename?: 'Binding';
  /** Source of the property binding */
  sourcePath: Maybe<Scalars['String']>;
  /** Instructs the web app which Configurable Field property to use to get the data for the corresponding SourcePath key. */
  destinationPath: Maybe<Scalars['String']>;
  cloneForExternalApi: Maybe<Binding>;
};

export type BiometricAuthenticationNotification = {
  __typename?: 'BiometricAuthenticationNotification';
  /** Password storage key which should be submitted back to the Quick Login step of the Login wizard */
  passwordKey: Maybe<Scalars['String']>;
  /** Result of the of the biometrics scan */
  biometricAuthenticationResult: BiometricsScanResultType;
};

export enum BiometricsScanResultType {
  SUCCEEDED = 'SUCCEEDED',
  CANCELED = 'CANCELED',
  FAILED = 'FAILED',
  EXPIRED = 'EXPIRED'
}

/** The boolean field info type. */
export type BooleanFieldInfo = UiElementInfo & FieldInfoBase & {
  __typename?: 'BooleanFieldInfo';
  /** Field Value */
  value: Scalars['Boolean'];
  /** The field Id. */
  id: Scalars['ID'];
  /** Field type. */
  type: FieldType;
  /** Field Name, Can be used as Label in AT and/or for validation and error messages. */
  name: Maybe<Scalars['String']>;
  /** Field Description Can be used as tooltip or watermark on a presentation level */
  description: Maybe<Scalars['String']>;
  /** Gets value which indicates whether this field is enabled in the current configuration. */
  isEnabled: Scalars['Boolean'];
  /** Label for front-end UI */
  label: Maybe<Scalars['String']>;
  /** Indicates whether the field with the field value needs to be submitted in SubmitWizardStepFieldValues API calls. */
  isSubmittable: Scalars['Boolean'];
  /**
   * client-based property bindings.
   *                     Bindings are exposed to the web app to form a kind of name-value collection of data items
   *                     required to property display a configurable field (that is called DataContext).
   */
  bindings: Maybe<Array<Maybe<Binding>>>;
  /** Field Behavior e.g Required, Readonly */
  fieldBehaviorMode: FieldBehaviorMode;
  /** This property stores the field input hint. Intended to be displayed by native apps on mobile devices. */
  placeholder: Maybe<Scalars['String']>;
};

/** Branding representation. */
export type Branding = {
  __typename?: 'Branding';
  /** Light theme variables. */
  lightThemeVariables: Array<CssVariable>;
  /** Dark theme variables. */
  darkThemeVariables: Array<CssVariable>;
};

export type BusinessAchHistoryAnyTransactionsForYearInfo = {
  __typename?: 'BusinessAchHistoryAnyTransactionsForYearInfo';
  /** Specified year, for which the information about existence of any available transactions is provided. */
  year: Scalars['Int'];
  /** Flag indicating, if there are any available transactions for the specified year. */
  anyTransactions: Scalars['Boolean'];
};

export type BusinessBankingHistoryExportResult = {
  __typename?: 'BusinessBankingHistoryExportResult';
  /** Indicates if the action is successful */
  successful: Scalars['Boolean'];
  /** The list of errors */
  errors: Maybe<Array<ValidationItem>>;
  /** The list of warnings */
  warnings: Maybe<Array<ValidationItem>>;
  /** The list of infos */
  infos: Maybe<Array<ValidationItem>>;
  /** Name of the file. */
  fileName: Maybe<Scalars['String']>;
  /** MIME type of the content. */
  mimeType: Maybe<Scalars['String']>;
  /** Base64-encoded file binary data. */
  data: Maybe<Scalars['String']>;
};

export enum BusinessGroupsAccessLevel {
  NOACCESS = 'NOACCESS',
  READONLY = 'READONLY',
  MODIFY = 'MODIFY'
}

export type BusinessTransferAchGroupInput = {
  /** The internal group identifier. */
  id: Maybe<Scalars['Long']>;
  /** The settlement account's bank account identifier. */
  bankAccountId: Maybe<Scalars['String']>;
  /** The commercial banking category of ACH group. */
  commercialBankingCategory: CommercialBankingCategory;
  /** The standard entry class code of ACH group. */
  standardEntryClassCode: StandardEntryClassCode;
  /** The recipients of ACH group. */
  recipients: Maybe<Array<BusinessTransferRecipientInput>>;
};

export type BusinessTransferRecipientInput = {
  /** Recipient's identifier. */
  id: Scalars['Long'];
  /** Recipient's name. */
  name: Scalars['String'];
  /** Recipient's routing number. */
  routingNumber: Scalars['String'];
  /** Recipient's account type. */
  recipientAccountType: RecipientAccountType;
  /** Recipient's type. */
  recipientType: RecipientType;
  /** Recipient's account number. */
  accountNumber: Scalars['String'];
  /** Recipient's amount. */
  defaultAmount: Maybe<Scalars['Decimal']>;
  /** Recipient's note. */
  note: Maybe<Scalars['String']>;
  /** Recipient's reference. */
  referenceId: Maybe<Scalars['String']>;
};

export type BusinessTransferSettings = {
  __typename?: 'BusinessTransferSettings';
  /** Scheduled ACH Payments availability. */
  isScheduledAchPaymentsAllowed: Scalars['Boolean'];
  /** Scheduled ACH Payrolls availability. */
  isScheduledAchPayrollsAllowed: Scalars['Boolean'];
  /** Scheduled ACH Receipts availability. */
  isScheduledAchReceiptsAllowed: Scalars['Boolean'];
  /** Temporary configuration to allow Send Now for ACH transfers. Send Now feature is planned to be replaced with Same Day ACH feature in the future. */
  isSendNowAllowed: Scalars['Boolean'];
  /** Forbidden days for scheduled payments. */
  excludedPaymentDates: ExcludedPaymentDays;
  /** Whether scheduling is allowed on non-business dates. */
  isAllowedSchedulingOnNonBusinessDays: Scalars['Boolean'];
  /** Returns the error message to show when scheduled date is not allowed. */
  notAvailableScheduledDateMessage: Maybe<Scalars['String']>;
  /** Returns the notice to show when the user selects scheduled payment option. */
  scheduledPaymentNotice: Maybe<Scalars['String']>;
  /** List of supported recurring options. */
  recurringOptions: Array<Maybe<RecurringOption>>;
  /** Returns the notice to show when the user selects recurring payment option. */
  recurringPaymentNotice: Maybe<Scalars['String']>;
  /** Whether the end after number of transfers ending option for recurring transfers is enabled. */
  allowEndAfterCountOption: Scalars['Boolean'];
  /** Whether the end after specific date ending option for recurring transfers is enabled. */
  allowEndAfterDateOption: Scalars['Boolean'];
  /** Whether an optional Transfer Memo field is enabled when a user is setting up a transfer. */
  isTransferMemoEnabled: Scalars['Boolean'];
  /** List of supported recurring options for Business ACH Payroll. */
  businessAchPayrollRecurringOptions: Array<Maybe<RecurringOption>>;
  /** List of supported recurring options for Business ACH Payment. */
  businessAchPaymentsRecurringOptions: Array<Maybe<RecurringOption>>;
  /** List of supported recurring options for Business ACH Receipt. */
  businessAchReceiptsRecurringOptions: Array<Maybe<RecurringOption>>;
  /** Indicates whether the same day ACH transfer option is available for outgoing Business ACH payments. */
  isSameDayAchPaymentsAllowed: Scalars['Boolean'];
  /** Indicates whether the same day ACH transfer option is available for outgoing Business ACH payrolls. */
  isSameDayAchPayrollsAllowed: Scalars['Boolean'];
  /** Indicates whether the same day ACH transfer option is available for incoming Business ACH receipts. */
  isSameDayAchReceiptsAllowed: Scalars['Boolean'];
};


export type CancelTransferInput = {
  /** Identifier of transfer. */
  transferId: Scalars['String'];
};

export type CancelTransferResult = {
  __typename?: 'CancelTransferResult';
  /** Validation result of operation. */
  validationResult: Maybe<ValidationResultContainer>;
};

export type Card = {
  __typename?: 'Card';
  key: Scalars['ID'];
  cardType: CardType;
  state: CardState;
  cardTypeName: Maybe<Scalars['String']>;
  number: Scalars['String'];
  cardholder: Maybe<Scalars['String']>;
  expirationDate: Maybe<Scalars['String']>;
  features: CardFeatures;
};

export type CardAlerts = {
  __typename?: 'CardAlerts';
  isOn: Scalars['Boolean'];
  allTransactionsAlerts: Scalars['Boolean'];
  merchant: MerchantPreferences;
  transaction: TransactionPreferences;
  spend: SpendLimitPreferences;
  location: LocationPreferences;
  deliveryChannels: AlertsChannelsSettings;
};

export type CardBalance = {
  __typename?: 'CardBalance';
  currentBalance: Scalars['Decimal'];
};

export type CardControls = {
  __typename?: 'CardControls';
  isOn: Scalars['Boolean'];
  merchant: MerchantPreferences;
  transaction: TransactionPreferences;
  spend: SpendLimitPreferences;
  location: LocationPreferences;
};

export type CardFeature = {
  __typename?: 'CardFeature';
  isEnabled: Scalars['Boolean'];
};

export type CardFeatures = {
  __typename?: 'CardFeatures';
  status: CardStatusFeature;
  activate: ActivateFeature;
  billingAddress: BillingAddressFeature;
  dailyLimit: DailyLimitFeature;
  travels: CardFeature;
  changePin: PinChangingFeature;
  markLost: CardFeature;
  reorderSame: CardFeature;
  reorderNew: ReorderNewFeature;
  alerts: CardFeature;
  controls: CardFeature;
  viewCardDetails: CardFeature;
  balanceTransfers: CardFeature;
};

export type CardPaymentDetails = {
  __typename?: 'CardPaymentDetails';
  key: Scalars['ID'];
  cardType: CardType;
  cardTypeName: Maybe<Scalars['String']>;
  cardNumber: Scalars['String'];
  cardholder: Maybe<Scalars['String']>;
  expirationDate: Scalars['String'];
  cvv2: Scalars['String'];
  isMasked: Scalars['Boolean'];
};

export enum CardState {
  UNKNOWN = 'UNKNOWN',
  REQUESTED = 'REQUESTED',
  ISSUED = 'ISSUED'
}

export type CardStatusFeature = {
  __typename?: 'CardStatusFeature';
  isCardEnabled: Scalars['Boolean'];
  isEditable: Scalars['Boolean'];
};

export enum CardType {
  UNKNOWN = 'UNKNOWN',
  VISA = 'VISA',
  MASTERCARD = 'MASTERCARD',
  AMERICANEXPRESS = 'AMERICANEXPRESS',
  DISCOVER = 'DISCOVER'
}

export type CardsSettings = {
  __typename?: 'CardsSettings';
  isEnabled: Scalars['Boolean'];
  cardCanNotBeEnabledMessage: Maybe<Scalars['String']>;
  changePinPhoneNumber: Maybe<Scalars['String']>;
};

export type ChangeAccountGroupNameInput = {
  groupId: Scalars['String'];
  name: Scalars['String'];
};

export enum CharacterRequirement {
  /** Characters of given type are allowed and can be unlimited. */
  ALLOWED = 'ALLOWED',
  /** Characters of given type are not allowed. */
  NOTALLOWED = 'NOTALLOWED',
  /** At least one character of given type is required. */
  ONEREQUIRED = 'ONEREQUIRED'
}

export type Choice = {
  __typename?: 'Choice';
  /** Field Id */
  id: Scalars['ID'];
  /** Data mask */
  dataMask: Maybe<Scalars['String']>;
  /** Data pattern */
  dataPattern: Maybe<Scalars['String']>;
  /** Enabled flag */
  enabled: Scalars['Boolean'];
  /** The Max Length */
  maxLength: Maybe<Scalars['Int']>;
  /** The Min Length */
  minLength: Maybe<Scalars['Int']>;
  /** The Validation pattern */
  validationPattern: Maybe<Scalars['String']>;
  /** Is option hidden flag */
  isHidden: Scalars['Boolean'];
  /** User facing text */
  text: Maybe<Scalars['String']>;
};

/** The choice collection field info base type. */
export type ChoiceCollectionFieldInfoBase = UiElementInfo & FieldInfoBase & {
  __typename?: 'ChoiceCollectionFieldInfoBase';
  /** The field Id. */
  id: Scalars['ID'];
  /** Field type. */
  type: FieldType;
  /** Field Name, Can be used as Label in AT and/or for validation and error messages. */
  name: Maybe<Scalars['String']>;
  /** Field Description Can be used as tooltip or watermark on a presentation level */
  description: Maybe<Scalars['String']>;
  /** Gets value which indicates whether this field is enabled in the current configuration. */
  isEnabled: Scalars['Boolean'];
  /** Label for front-end UI */
  label: Maybe<Scalars['String']>;
  /** Indicates whether the field with the field value needs to be submitted in SubmitWizardStepFieldValues API calls. */
  isSubmittable: Scalars['Boolean'];
  /**
   * client-based property bindings.
   *                     Bindings are exposed to the web app to form a kind of name-value collection of data items
   *                     required to property display a configurable field (that is called DataContext).
   */
  bindings: Maybe<Array<Maybe<Binding>>>;
  /** Field Behavior e.g Required, Readonly */
  fieldBehaviorMode: FieldBehaviorMode;
  /** This property stores the field input hint. Intended to be displayed by native apps on mobile devices. */
  placeholder: Maybe<Scalars['String']>;
  /** Choose list. */
  choiceList: Maybe<Array<Maybe<Choice>>>;
};

export enum CommercialBankingCategory {
  UNKNOWN = 'UNKNOWN',
  PAYROLL = 'PAYROLL',
  RECEIPTS = 'RECEIPTS',
  PAYMENTS = 'PAYMENTS'
}

/** Sender or recipient representation. */
export type CommunicationParty = {
  __typename?: 'CommunicationParty';
  /** Sender address. */
  address: Scalars['String'];
  /** Sender display name. */
  displayName: Scalars['String'];
};

/** Sender or recipient representation. */
export type CommunicationPartyInput = {
  /** Sender address. */
  address: Scalars['String'];
  /** Sender display name. */
  displayName: Scalars['String'];
};

export enum CompromisedCredentialsStatus {
  /** Credentials never have been checked. */
  NOTCHECKED = 'NOTCHECKED',
  /** Credentials have been checked and they are compromised. */
  COMPROMISED = 'COMPROMISED',
  /** Credentials have been checked are they aren't compromised. */
  NOTCOMPROMISED = 'NOTCOMPROMISED',
  /** Credentials check is still running. */
  INPROGRESS = 'INPROGRESS'
}

export type ConfigurationChangedNotification = {
  __typename?: 'ConfigurationChangedNotification';
  /** Unique datasource instance ID to use it in the system notifications */
  instanceId: Scalars['String'];
  /** System notification type */
  type: SystemNotificationType;
};

/** The input type for Confirm email request. */
export type ConfirmEmailInput = {
  /** Valid UserToken from email. */
  tempEmailToken: Scalars['String'];
};

/** The result type for confirm email request. */
export type ConfirmEmailResult = {
  __typename?: 'ConfirmEmailResult';
  /** Indicates whether the operation succeed. */
  successful: Scalars['Boolean'];
};

export type ConfirmExternalAccountInput = {
  bankAccountId: Scalars['ID'];
  confirmationAmount1: Scalars['String'];
  confirmationAmount2: Scalars['String'];
};

export type ConfirmExternalAccountResult = {
  __typename?: 'ConfirmExternalAccountResult';
  /** Validation result of operation. */
  validationResult: Maybe<ValidationResultContainer>;
};

export type ConsolidateBalanceInput = {
  cardKey: Scalars['String'];
  lender: Maybe<LenderInput>;
  amount: Scalars['Decimal'];
};

export type ConsolidateBalanceResult = {
  __typename?: 'ConsolidateBalanceResult';
  transactionId: Scalars['String'];
  successful: Scalars['Boolean'];
  errors: Maybe<Array<ValidationItem>>;
  warnings: Maybe<Array<ValidationItem>>;
  infos: Maybe<Array<ValidationItem>>;
};

/** The ConstrainedText field info type. */
export type ConstrainedTextFieldInfo = UiElementInfo & FieldInfoBase & {
  __typename?: 'ConstrainedTextFieldInfo';
  /** The regular expression that is used for the validation of this field value. Regular expression for validation must be compatible C#.NET, Java and JavaScript regular expression engines. */
  validationRegExp: Maybe<Scalars['String']>;
  /** The message displayed if validation of the field fails. */
  validationMessage: Maybe<Scalars['String']>;
  /** The field Id. */
  id: Scalars['ID'];
  /** Field type. */
  type: FieldType;
  /** Field Name, Can be used as Label in AT and/or for validation and error messages. */
  name: Maybe<Scalars['String']>;
  /** Field Description Can be used as tooltip or watermark on a presentation level */
  description: Maybe<Scalars['String']>;
  /** Gets value which indicates whether this field is enabled in the current configuration. */
  isEnabled: Scalars['Boolean'];
  /** Label for front-end UI */
  label: Maybe<Scalars['String']>;
  /** Indicates whether the field with the field value needs to be submitted in SubmitWizardStepFieldValues API calls. */
  isSubmittable: Scalars['Boolean'];
  /**
   * client-based property bindings.
   *                     Bindings are exposed to the web app to form a kind of name-value collection of data items
   *                     required to property display a configurable field (that is called DataContext).
   */
  bindings: Maybe<Array<Maybe<Binding>>>;
  /** Field Behavior e.g Required, Readonly */
  fieldBehaviorMode: FieldBehaviorMode;
  /** This property stores the field input hint. Intended to be displayed by native apps on mobile devices. */
  placeholder: Maybe<Scalars['String']>;
  /** Max length of the field. */
  maxLength: Scalars['Int'];
  /** Min length of the field. */
  minLength: Scalars['Int'];
  /** The value of the field. */
  value: Maybe<Scalars['String']>;
};

export type ConsumerBankAccountsDisplayOptions = {
  __typename?: 'ConsumerBankAccountsDisplayOptions';
  consumerId: Scalars['ID'];
  title: Scalars['String'];
  isVisible: Scalars['Boolean'];
  nickname: Maybe<Scalars['String']>;
  bankAccountsDisplayOptions: Array<BankAccountDisplayOptions>;
};

export enum ContactType {
  EMAIL = 'EMAIL',
  HOMEPHONE = 'HOMEPHONE',
  WORKPHONE = 'WORKPHONE',
  MOBILEPHONE = 'MOBILEPHONE'
}

/** Contacts and Settings. */
export type ContactsAndSettingsPermissions = {
  __typename?: 'ContactsAndSettingsPermissions';
  /** Contacts and settings array with ReadonlyGroups permissions */
  readonlyGroups: Array<Maybe<Scalars['String']>>;
  /** Contacts and settings array with MultipleFields permissions */
  multipleFields: Array<Maybe<Scalars['String']>>;
  /** Contacts and settings array with EnabledGroups permissions */
  enabledGroups: Array<Maybe<Scalars['String']>>;
};

export type ContentItem = {
  __typename?: 'ContentItem';
  /** Widgets Inside the Header Zone */
  header: Array<Maybe<WidgetUnion>>;
  /** Widgets Inside the Middle Zone */
  middle: Array<Maybe<WidgetUnion>>;
  /** Widgets Inside the Footer Zone */
  footer: Array<Maybe<WidgetUnion>>;
  /** Widgets Inside the RightSide Zone */
  rightside: Array<Maybe<WidgetUnion>>;
};

export type CreateNewWireRecipientQueryInput = {
  /** Name of new wire recipient. */
  contactName: Scalars['String'];
  /** Street and house number of recipient. */
  contactAddressLine1: Scalars['String'];
  /** Floor and room of recipient. */
  contactAddressLine2: Maybe<Scalars['String']>;
  /** City for contact address. */
  contactCity: Scalars['String'];
  /** USA state for contact address. */
  contactUsaState: Scalars['String'];
  /** Zip code for contact address. */
  contactZipCode: Scalars['String'];
  /** Instructions about this contact. */
  instructions: Scalars['String'];
  /** Routing number of contact bank. */
  contactBankRoutingNumber: Scalars['String'];
  /** Account number of contact bank. */
  contactBankAccountNumber: Scalars['String'];
  /** Street and house number for contact bank. */
  contactBankAddressLine1: Scalars['String'];
  /** Floor and room (or office) for contact bank. */
  contactBankAddressLine2: Maybe<Scalars['String']>;
  /** City for contact bank address. */
  contactBankCity: Scalars['String'];
  /** Usa state for contact bank address. */
  contactBankUsaState: Scalars['String'];
  /** Zip code for contact bank address. */
  contactBankZipCode: Scalars['String'];
  /** Routing number of intermediate bank. */
  intermediateBankRoutingNumber: Maybe<Scalars['String']>;
  /** Street and house number for intermediate bank. */
  intermediateBankAddressLine1: Maybe<Scalars['String']>;
  /** Floor and room (or office) for intermediate bank. */
  intermediateBankAddressLine2: Maybe<Scalars['String']>;
  /** City for intermediate bank address. */
  intermediateBankCity: Maybe<Scalars['String']>;
  /** Usa state for intermediate bank address. */
  intermediateBankUsaState: Maybe<Scalars['String']>;
  /** Zip code for intermediate bank address. */
  intermediateBankZipCode: Maybe<Scalars['String']>;
};

export type CreateRecipientResult = {
  __typename?: 'CreateRecipientResult';
  /** Indicates if the action is successful */
  successful: Scalars['Boolean'];
  /** The list of errors */
  errors: Maybe<Array<ValidationItem>>;
  /** The list of warnings */
  warnings: Maybe<Array<ValidationItem>>;
  /** The list of infos */
  infos: Maybe<Array<ValidationItem>>;
  /** Id of the new recipient */
  recipientId: Scalars['Int'];
};

export type CreateTemplateResult = {
  __typename?: 'CreateTemplateResult';
  /** Indicates if the action is successful */
  successful: Scalars['Boolean'];
  /** The list of errors */
  errors: Maybe<Array<ValidationItem>>;
  /** The list of warnings */
  warnings: Maybe<Array<ValidationItem>>;
  /** The list of infos */
  infos: Maybe<Array<ValidationItem>>;
  /** Id of newly created template */
  templateId: Scalars['Long'];
};

export type CreditScoreMemberAccount = {
  __typename?: 'CreditScoreMemberAccount';
  number: Scalars['String'];
  displayName: Scalars['String'];
};

export type CreditScoreOptInSetting = {
  __typename?: 'CreditScoreOptInSetting';
  memberNumber: Scalars['String'];
  displayName: Scalars['String'];
  isOptIn: Scalars['Boolean'];
};

export type CreditScoreOptInSettingInput = {
  memberNumber: Scalars['String'];
  isOptIn: Scalars['Boolean'];
};

/** CSS variable. */
export type CssVariable = {
  __typename?: 'CssVariable';
  /** CSS variable name. */
  name: Scalars['String'];
  /** CSS variable value. */
  value: Scalars['String'];
};

/** The currency field info type. */
export type CurrencyFieldInfo = UiElementInfo & FieldInfoBase & {
  __typename?: 'CurrencyFieldInfo';
  /** Field Value. */
  value: Maybe<Scalars['Decimal']>;
  /** Min field Value. */
  minValue: Maybe<Scalars['Decimal']>;
  /** Max field Value. */
  maxValue: Maybe<Scalars['Decimal']>;
  /** The field Id. */
  id: Scalars['ID'];
  /** Field type. */
  type: FieldType;
  /** Field Name, Can be used as Label in AT and/or for validation and error messages. */
  name: Maybe<Scalars['String']>;
  /** Field Description Can be used as tooltip or watermark on a presentation level */
  description: Maybe<Scalars['String']>;
  /** Gets value which indicates whether this field is enabled in the current configuration. */
  isEnabled: Scalars['Boolean'];
  /** Label for front-end UI */
  label: Maybe<Scalars['String']>;
  /** Indicates whether the field with the field value needs to be submitted in SubmitWizardStepFieldValues API calls. */
  isSubmittable: Scalars['Boolean'];
  /**
   * client-based property bindings.
   *                     Bindings are exposed to the web app to form a kind of name-value collection of data items
   *                     required to property display a configurable field (that is called DataContext).
   */
  bindings: Maybe<Array<Maybe<Binding>>>;
  /** Field Behavior e.g Required, Readonly */
  fieldBehaviorMode: FieldBehaviorMode;
  /** This property stores the field input hint. Intended to be displayed by native apps on mobile devices. */
  placeholder: Maybe<Scalars['String']>;
};

/** The current wizard step type. */
export type CurrentWizardStep = {
  __typename?: 'CurrentWizardStep';
  /** User friendly Wizard Step Name */
  name: Maybe<Scalars['String']>;
  /** Wizard Step identifier (should be url compatible name) */
  id: Scalars['String'];
  /** Indicates whether the current step is final for the wizard. */
  finalStep: Scalars['Boolean'];
  /** Wizard Step short description */
  description: Maybe<Scalars['String']>;
  /** Indicates whether the "Back" redirect is available for the current step. */
  allowBackRedirect: Scalars['Boolean'];
  /** Indicates whether the step is a terminal step. */
  isTermination: Scalars['Boolean'];
  /** Current Wizard Step Fields */
  fields: Array<Maybe<FieldsUnion>>;
};

/** The Custom App widget type contains the iframe source url, width and height. */
export type CustomAppWidget = {
  __typename?: 'CustomAppWidget';
  /** Id of the Item */
  id: Maybe<Scalars['ID']>;
  /** The content item version id */
  contentItemVersionId: Maybe<Scalars['String']>;
  /** Type of content */
  contentType: Maybe<Scalars['String']>;
  /** The display text of the content item */
  displayText: Maybe<Scalars['String']>;
  /** Is the published version */
  published: Scalars['Boolean'];
  /** Is the latest version */
  latest: Scalars['Boolean'];
  /** The date and time of modification */
  modifiedUtc: Maybe<Scalars['DateTime']>;
  /** The date and time of publication */
  publishedUtc: Maybe<Scalars['DateTime']>;
  /** The date and time of creation */
  createdUtc: Maybe<Scalars['DateTime']>;
  /** The owner of the content item */
  owner: Maybe<Scalars['String']>;
  /** The author of the content item */
  author: Maybe<Scalars['String']>;
  /** Widget Flow Layout Meta Information from WYSIWYG Editor */
  flowMeta: Maybe<FlowMetadata>;
  url: Maybe<Scalars['String']>;
  width: Maybe<Scalars['String']>;
  height: Maybe<Scalars['String']>;
};

export type DailyLimitFeature = {
  __typename?: 'DailyLimitFeature';
  isEnabled: Scalars['Boolean'];
  isEditable: Scalars['Boolean'];
  current: Scalars['Decimal'];
  maximum: Scalars['Decimal'];
};


/** The date field info type. */
export type DateFieldInfo = UiElementInfo & FieldInfoBase & {
  __typename?: 'DateFieldInfo';
  /** Field Value. */
  value: Maybe<Scalars['DateTime']>;
  /** Min field Value. */
  minValue: Maybe<Scalars['DateTime']>;
  /** Max field Value. */
  maxValue: Maybe<Scalars['DateTime']>;
  /** The field Id. */
  id: Scalars['ID'];
  /** Field type. */
  type: FieldType;
  /** Field Name, Can be used as Label in AT and/or for validation and error messages. */
  name: Maybe<Scalars['String']>;
  /** Field Description Can be used as tooltip or watermark on a presentation level */
  description: Maybe<Scalars['String']>;
  /** Gets value which indicates whether this field is enabled in the current configuration. */
  isEnabled: Scalars['Boolean'];
  /** Label for front-end UI */
  label: Maybe<Scalars['String']>;
  /** Indicates whether the field with the field value needs to be submitted in SubmitWizardStepFieldValues API calls. */
  isSubmittable: Scalars['Boolean'];
  /**
   * client-based property bindings.
   *                     Bindings are exposed to the web app to form a kind of name-value collection of data items
   *                     required to property display a configurable field (that is called DataContext).
   */
  bindings: Maybe<Array<Maybe<Binding>>>;
  /** Field Behavior e.g Required, Readonly */
  fieldBehaviorMode: FieldBehaviorMode;
  /** This property stores the field input hint. Intended to be displayed by native apps on mobile devices. */
  placeholder: Maybe<Scalars['String']>;
};


export enum DayOfWeek {
  SUNDAY = 'SUNDAY',
  MONDAY = 'MONDAY',
  TUESDAY = 'TUESDAY',
  WEDNESDAY = 'WEDNESDAY',
  THURSDAY = 'THURSDAY',
  FRIDAY = 'FRIDAY',
  SATURDAY = 'SATURDAY'
}


/** The decimal field info type. */
export type DecimalFieldInfo = UiElementInfo & FieldInfoBase & {
  __typename?: 'DecimalFieldInfo';
  /** Field Value. */
  value: Maybe<Scalars['Decimal']>;
  /** Min field Value. */
  minValue: Maybe<Scalars['Decimal']>;
  /** Max field Value. */
  maxValue: Maybe<Scalars['Decimal']>;
  /** The field Id. */
  id: Scalars['ID'];
  /** Field type. */
  type: FieldType;
  /** Field Name, Can be used as Label in AT and/or for validation and error messages. */
  name: Maybe<Scalars['String']>;
  /** Field Description Can be used as tooltip or watermark on a presentation level */
  description: Maybe<Scalars['String']>;
  /** Gets value which indicates whether this field is enabled in the current configuration. */
  isEnabled: Scalars['Boolean'];
  /** Label for front-end UI */
  label: Maybe<Scalars['String']>;
  /** Indicates whether the field with the field value needs to be submitted in SubmitWizardStepFieldValues API calls. */
  isSubmittable: Scalars['Boolean'];
  /**
   * client-based property bindings.
   *                     Bindings are exposed to the web app to form a kind of name-value collection of data items
   *                     required to property display a configurable field (that is called DataContext).
   */
  bindings: Maybe<Array<Maybe<Binding>>>;
  /** Field Behavior e.g Required, Readonly */
  fieldBehaviorMode: FieldBehaviorMode;
  /** This property stores the field input hint. Intended to be displayed by native apps on mobile devices. */
  placeholder: Maybe<Scalars['String']>;
};

export type DeepLinkMappingPair = {
  __typename?: 'DeepLinkMappingPair';
  /** Legacy V3 Deep Link value. */
  key: Maybe<Scalars['String']>;
  /** V5 route. */
  value: Maybe<Scalars['String']>;
};

/** The DeepTarget widget type contains base url, width and height for IFrame. */
export type DeepTargetWidget = {
  __typename?: 'DeepTargetWidget';
  /** Id of the Item */
  id: Maybe<Scalars['ID']>;
  /** The content item version id */
  contentItemVersionId: Maybe<Scalars['String']>;
  /** Type of content */
  contentType: Maybe<Scalars['String']>;
  /** The display text of the content item */
  displayText: Maybe<Scalars['String']>;
  /** Is the published version */
  published: Scalars['Boolean'];
  /** Is the latest version */
  latest: Scalars['Boolean'];
  /** The date and time of modification */
  modifiedUtc: Maybe<Scalars['DateTime']>;
  /** The date and time of publication */
  publishedUtc: Maybe<Scalars['DateTime']>;
  /** The date and time of creation */
  createdUtc: Maybe<Scalars['DateTime']>;
  /** The owner of the content item */
  owner: Maybe<Scalars['String']>;
  /** The author of the content item */
  author: Maybe<Scalars['String']>;
  /** Widget Flow Layout Meta Information from WYSIWYG Editor */
  flowMeta: Maybe<FlowMetadata>;
  width: Maybe<Scalars['String']>;
  height: Maybe<Scalars['String']>;
  mobileWidth: Maybe<Scalars['String']>;
  mobileHeight: Maybe<Scalars['String']>;
  is3DStoryWidget: Maybe<Scalars['Boolean']>;
  url: Maybe<Scalars['String']>;
};

export type DeleteCardInternationalLocationControlInput = {
  cardKey: Scalars['String'];
  countryId: Scalars['String'];
  countryCode: Scalars['String'];
};

export type DeleteCardInternationalLocationControlResult = {
  __typename?: 'DeleteCardInternationalLocationControlResult';
  successful: Scalars['Boolean'];
  errors: Maybe<Array<ValidationItem>>;
  warnings: Maybe<Array<ValidationItem>>;
  infos: Maybe<Array<ValidationItem>>;
};

export type DeleteCardMyRegionLocationAlertInput = {
  cardKey: Scalars['String'];
  regionId: Scalars['String'];
  name: Scalars['String'];
  latitude: Scalars['Float'];
  longitude: Scalars['Float'];
  radiusInMeters: Scalars['Decimal'];
};

export type DeleteCardMyRegionLocationAlertResult = {
  __typename?: 'DeleteCardMyRegionLocationAlertResult';
  successful: Scalars['Boolean'];
  errors: Maybe<Array<ValidationItem>>;
  warnings: Maybe<Array<ValidationItem>>;
  infos: Maybe<Array<ValidationItem>>;
};

export type DeleteCardMyRegionLocationControlInput = {
  cardKey: Scalars['String'];
  regionId: Scalars['String'];
  name: Scalars['String'];
  latitude: Scalars['Float'];
  longitude: Scalars['Float'];
  radiusInMeters: Scalars['Decimal'];
};

export type DeleteCardMyRegionLocationControlResult = {
  __typename?: 'DeleteCardMyRegionLocationControlResult';
  successful: Scalars['Boolean'];
  errors: Maybe<Array<ValidationItem>>;
  warnings: Maybe<Array<ValidationItem>>;
  infos: Maybe<Array<ValidationItem>>;
};

export type DeleteExternalAccountInput = {
  bankAccountId: Scalars['ID'];
};

export type DeleteExternalAccountResult = {
  __typename?: 'DeleteExternalAccountResult';
  /** Validation result of operation. */
  validationResult: Maybe<ValidationResultContainer>;
};

export type DeletePayeeInput = {
  /** Identifier of payee. */
  payeeAccountId: Scalars['String'];
};

export type DeletePayeeResult = {
  __typename?: 'DeletePayeeResult';
  /** Validation result of operation. */
  validationResult: Maybe<ValidationResultContainer>;
};

export type DeleteRecipientResult = {
  __typename?: 'DeleteRecipientResult';
  /** Indicates if the action is successful */
  successful: Scalars['Boolean'];
  /** The list of errors */
  errors: Maybe<Array<ValidationItem>>;
  /** The list of warnings */
  warnings: Maybe<Array<ValidationItem>>;
  /** The list of infos */
  infos: Maybe<Array<ValidationItem>>;
};

export type DeleteTemplateResult = {
  __typename?: 'DeleteTemplateResult';
  /** Indicates if the action is successful */
  successful: Scalars['Boolean'];
  /** The list of errors */
  errors: Maybe<Array<ValidationItem>>;
  /** The list of warnings */
  warnings: Maybe<Array<ValidationItem>>;
  /** The list of infos */
  infos: Maybe<Array<ValidationItem>>;
};

export type DeleteTravelResult = {
  __typename?: 'DeleteTravelResult';
  travels: Array<Travel>;
  successful: Scalars['Boolean'];
  errors: Maybe<Array<ValidationItem>>;
  warnings: Maybe<Array<ValidationItem>>;
  infos: Maybe<Array<ValidationItem>>;
};

export type DeleteWireRecipientInput = {
  recipientId: Scalars['ID'];
};

export type DeleteWireRecipientResult = {
  __typename?: 'DeleteWireRecipientResult';
  /** Indicates if the action is successful */
  successful: Scalars['Boolean'];
  /** The list of errors */
  errors: Maybe<Array<ValidationItem>>;
  /** The list of warnings */
  warnings: Maybe<Array<ValidationItem>>;
  /** The list of infos */
  infos: Maybe<Array<ValidationItem>>;
};

export enum DeliveryCardMethod {
  DEFAULT = 'DEFAULT',
  BYMAIL = 'BYMAIL',
  INBRANCH = 'INBRANCH',
  DIGITALONLY = 'DIGITALONLY'
}

/** Delivery channel representation. */
export type DeliveryChannel = {
  __typename?: 'DeliveryChannel';
  /** Delivery channel technical name. */
  transportType: Scalars['String'];
  /** Delivery channel title. */
  title: Scalars['String'];
  /** Delivery channel help text. */
  helpText: Maybe<Scalars['String']>;
  /** Delivery channel allowed flag. */
  allowed: Scalars['Boolean'];
  /** Delivery channel enabled flag. */
  enabled: Scalars['Boolean'];
};

/** Delivery options representation. */
export type DeliveryOptions = {
  __typename?: 'DeliveryOptions';
  /** Delivery options form id. */
  formId: Scalars['String'];
  /** Delivery options form title. */
  title: Scalars['String'];
  /** Delivery options form description. */
  description: Scalars['String'];
  /** Delivery channels. */
  deliveryChannels: Array<DeliveryChannel>;
};

export type Destination = {
  __typename?: 'Destination';
  country: Maybe<Scalars['String']>;
  state: Maybe<Scalars['String']>;
};

export type DestinationInput = {
  country: Maybe<Scalars['String']>;
  state: Maybe<Scalars['String']>;
};

export type DetermineAnyTransactionsForPeriodResult = {
  __typename?: 'DetermineAnyTransactionsForPeriodResult';
  /** List of periods, each indicating if there are any available transactions for the specified year. */
  periods: Array<BusinessAchHistoryAnyTransactionsForYearInfo>;
};

export type Dictionaries = {
  __typename?: 'Dictionaries';
  countries: Array<SimpleDictionary>;
  states: Array<SimpleDictionary>;
  contactReasons: Array<SimpleDictionary>;
  purposes: Array<SimpleDictionary>;
  authorizedContacts: Array<AuthorizedContact>;
  preferredContact: Maybe<PreferredContact>;
};

export type Document = {
  __typename?: 'Document';
  key: Scalars['String'];
  name: Scalars['String'];
  date: Scalars['DateTime'];
};

export enum DocumentType {
  STATEMENTS = 'STATEMENTS',
  TAXFORMS = 'TAXFORMS'
}

export type DownloadDocumentResult = {
  __typename?: 'DownloadDocumentResult';
  fileName: Scalars['String'];
  data: Scalars['String'];
  mimeType: Scalars['String'];
};

export type EditPayeeInput = {
  /** Recipient id. */
  payeeId: Scalars['String'];
  /** Recipient nickname. */
  nickname: Scalars['String'];
};

export type EditPayeeResult = {
  __typename?: 'EditPayeeResult';
  /** Contains summary about operation execution. */
  validationResult: Maybe<ValidationResultContainer>;
};

export type EligibilityCheckTag = {
  __typename?: 'EligibilityCheckTag';
  /** Eligibility service type. */
  serviceType: Maybe<ServiceType>;
  /** Eligibility service name. */
  serviceName: Maybe<Scalars['String']>;
};

/** The Email field info type. */
export type EmailFieldInfo = UiElementInfo & FieldInfoBase & {
  __typename?: 'EmailFieldInfo';
  /** The regular expression that is used for the validation of this field value. Regular expression for validation must be compatible C#.NET, Java and JavaScript regular expression engines */
  validationRegExp: Maybe<Scalars['String']>;
  /** Indicates whether a user must verify an entered email address twice */
  isConfirmationRequired: Scalars['Boolean'];
  /** The field Id. */
  id: Scalars['ID'];
  /** Field type. */
  type: FieldType;
  /** Field Name, Can be used as Label in AT and/or for validation and error messages. */
  name: Maybe<Scalars['String']>;
  /** Field Description Can be used as tooltip or watermark on a presentation level */
  description: Maybe<Scalars['String']>;
  /** Gets value which indicates whether this field is enabled in the current configuration. */
  isEnabled: Scalars['Boolean'];
  /** Label for front-end UI */
  label: Maybe<Scalars['String']>;
  /** Indicates whether the field with the field value needs to be submitted in SubmitWizardStepFieldValues API calls. */
  isSubmittable: Scalars['Boolean'];
  /**
   * client-based property bindings.
   *                     Bindings are exposed to the web app to form a kind of name-value collection of data items
   *                     required to property display a configurable field (that is called DataContext).
   */
  bindings: Maybe<Array<Maybe<Binding>>>;
  /** Field Behavior e.g Required, Readonly */
  fieldBehaviorMode: FieldBehaviorMode;
  /** This property stores the field input hint. Intended to be displayed by native apps on mobile devices. */
  placeholder: Maybe<Scalars['String']>;
  /** Max length of the field. */
  maxLength: Scalars['Int'];
  /** Min length of the field. */
  minLength: Scalars['Int'];
  /** The value of the field. */
  value: Maybe<Scalars['String']>;
};

/** Embedded resource representation. */
export type EmbeddedResource = {
  __typename?: 'EmbeddedResource';
  /** Embedded resource HTML. */
  html: Scalars['String'];
};

/** Embedded resource container. */
export type EmbeddedResourceContainer = {
  __typename?: 'EmbeddedResourceContainer';
  /** Embedded resource render location. */
  renderLocation: Scalars['String'];
  /** Embedded resources. */
  embeddedResources: Array<EmbeddedResource>;
};

export type EndLinkInstitutionInput = {
  publicToken: Scalars['String'];
  institutionName: Maybe<Scalars['String']>;
};

export type EndLinkInstitutionResult = {
  __typename?: 'EndLinkInstitutionResult';
  successful: Scalars['Boolean'];
  errors: Maybe<Array<ValidationItem>>;
  warnings: Maybe<Array<ValidationItem>>;
  infos: Maybe<Array<ValidationItem>>;
};

export type EnrollmentWizardResult = {
  __typename?: 'EnrollmentWizardResult';
  /** Flag shows if Wizard finished */
  wizardFinished: Scalars['Boolean'];
  /** Indicates whether the user is already enrolled. */
  alreadyEnrolled: Scalars['Boolean'];
  /** Represent The UserUid. */
  userUid: Scalars['Int'];
  /** Represent the list of success summary strings. */
  successSummary: Array<Maybe<Scalars['String']>>;
};

/** The user enrollment result. */
export type EnrollmentWizardResultUnion = NextStep | EnrollmentWizardResult;

export enum ErrorCodes {
  /** User lockout code. */
  USER_LOCKOUT = 'USER_LOCKOUT',
  /** Wizard incorrect configuration code. */
  WIZARD_INCORRECT_CONFIGURATION = 'WIZARD_INCORRECT_CONFIGURATION',
  /** Wizard steps inconsistency code. */
  WIZARD_STEPS_INCONSISTENCY = 'WIZARD_STEPS_INCONSISTENCY',
  /** Triggered event required code. */
  TRIGGERED_EVENT_REQUIRED = 'TRIGGERED_EVENT_REQUIRED',
  /** reCaptcha error code. */
  RECAPTCHA = 'RECAPTCHA',
  /** SSO related error code. */
  SSO_ERROR = 'SSO_ERROR',
  /** Force user info reset code. */
  FORCE_USER_INFO_RESET = 'FORCE_USER_INFO_RESET',
  /** Maintenance mode error code */
  MAINTENANCE_MODE = 'MAINTENANCE_MODE'
}

/** The error message field info type. */
export type ErrorMessageFieldInfo = UiElementInfo & FieldInfoBase & {
  __typename?: 'ErrorMessageFieldInfo';
  /** Error Message */
  errorMessageValue: Maybe<Scalars['String']>;
  /** The field Id. */
  id: Scalars['ID'];
  /** Field type. */
  type: FieldType;
  /** Field Name, Can be used as Label in AT and/or for validation and error messages. */
  name: Maybe<Scalars['String']>;
  /** Field Description Can be used as tooltip or watermark on a presentation level */
  description: Maybe<Scalars['String']>;
  /** Gets value which indicates whether this field is enabled in the current configuration. */
  isEnabled: Scalars['Boolean'];
  /** Label for front-end UI */
  label: Maybe<Scalars['String']>;
  /** Indicates whether the field with the field value needs to be submitted in SubmitWizardStepFieldValues API calls. */
  isSubmittable: Scalars['Boolean'];
  /**
   * client-based property bindings.
   *                     Bindings are exposed to the web app to form a kind of name-value collection of data items
   *                     required to property display a configurable field (that is called DataContext).
   */
  bindings: Maybe<Array<Maybe<Binding>>>;
  /** Field Behavior e.g Required, Readonly */
  fieldBehaviorMode: FieldBehaviorMode;
  /** This property stores the field input hint. Intended to be displayed by native apps on mobile devices. */
  placeholder: Maybe<Scalars['String']>;
};

export enum ErrorTypes {
  /** Unhandled application error (e.g. received from DS). User can do nothing. */
  CRITICAL = 'CRITICAL',
  /** Regular error with user-facing text (e.g. lockout). */
  ERROR = 'ERROR',
  /** Business error (e.g. Triggered event). */
  EVENT = 'EVENT'
}

export type EventLogItem = {
  __typename?: 'EventLogItem';
  /** Event log identifier. Can't be null */
  id: Scalars['ID'];
  /** The event type name. */
  eventTypeName: Maybe<Scalars['String']>;
  /** Event log description */
  description: Maybe<Scalars['String']>;
  /** The event type identifier */
  eventTypeId: Maybe<Scalars['String']>;
  /** User identifier */
  userId: Maybe<Scalars['Int']>;
  /** User name */
  userFullName: Maybe<Scalars['String']>;
  /** Ip address */
  ipAddress: Maybe<Scalars['String']>;
  /** Event log operation status type */
  operationStatus: EventLogItemOperationStatus;
  /** Event log record occurred date */
  timestamp: Scalars['DateTime'];
};

export enum EventLogItemOperationStatus {
  INFORMATION = 'INFORMATION',
  SUCCESS = 'SUCCESS',
  ERROR = 'ERROR',
  CRITICAL = 'CRITICAL',
  WARNING = 'WARNING'
}

export type ExchangeUserTokenResult = {
  __typename?: 'ExchangeUserTokenResult';
  /** User Authenticated Token */
  UserToken: Maybe<Scalars['String']>;
  /** API Key which used for CSRF Protection */
  ApiKey: Maybe<Scalars['String']>;
};

export type ExcludedPaymentDays = {
  __typename?: 'ExcludedPaymentDays';
  /** List of days of week which should not be available for scheduling payments. */
  excludedDaysOfWeek: Array<Maybe<DayOfWeek>>;
  /** List of exact dates which should not be available for scheduling payments. */
  excludedDates: Array<Maybe<Scalars['Date']>>;
};

export type ExecuteTransferInput = {
  /** Source account identifier for the transfer. */
  sourceAccountId: Scalars['ID'];
  /** Template identifier for the transfer. */
  templateId: Scalars['Long'];
  /** Per recipient parameters for the transfer. */
  perRecipientParameters: Maybe<Array<PerRecipientTransferParametersInput>>;
  /** Scheduled date of the transfer. */
  scheduledDate: Maybe<Scalars['Date']>;
  /** Setting for recurring option selected by the user. */
  recurringOptionSettings: Maybe<RecurringOptionSettingsInput>;
  /** Whether to use the same day ACH payment option. */
  isSameDayAchPayment: Scalars['Boolean'];
};

export type ExecuteTransferResult = {
  __typename?: 'ExecuteTransferResult';
  /** Indicates if the action is successful */
  successful: Scalars['Boolean'];
  /** The list of errors */
  errors: Maybe<Array<ValidationItem>>;
  /** The list of warnings */
  warnings: Maybe<Array<ValidationItem>>;
  /** The list of infos */
  infos: Maybe<Array<ValidationItem>>;
  /** Transfer status. */
  transactionStatus: Scalars['String'];
};

export type ExportTransactionsHistoryResult = {
  __typename?: 'ExportTransactionsHistoryResult';
  fileName: Scalars['String'];
  data: Scalars['String'];
  mimeType: Scalars['String'];
};

export type ExternalAccount = {
  __typename?: 'ExternalAccount';
  /** External account Id. */
  id: Scalars['ID'];
  /** Routing number of bank where account was opened. */
  routingNumber: Scalars['String'];
  /** Account number. */
  accountNumber: Scalars['String'];
  /** External account Id. */
  institutionName: Maybe<Scalars['String']>;
  /** Masked member number. */
  maskedAccountNumber: Maybe<Scalars['String']>;
  /** External account owner name. */
  primaryAccountName: Scalars['String'];
  /** True for accounts which linked via Plaid https://plaid.com/docs/ */
  isPlaidLinked: Scalars['Boolean'];
  /** External account type. */
  accountType: ExternalBankAccountType;
};

export type ExternalAccountGroup = {
  __typename?: 'ExternalAccountGroup';
  /** The group name */
  name: Maybe<Scalars['String']>;
  /** External accounts list. */
  accounts: Array<ExternalAccountView>;
};

export type ExternalAccountView = {
  __typename?: 'ExternalAccountView';
  /** The account identifier */
  id: Scalars['ID'];
  /** The short institution name */
  institutionName: Scalars['String'];
  /** The masked account number */
  maskedAccountNumber: Scalars['String'];
  /** Account owner */
  accountOwner: Scalars['String'];
  /** True for accounts which linked via Plaid https://plaid.com/docs/ */
  isPlaidLinked: Scalars['Boolean'];
  /** True if accounts allow to use RTP via Plaid https://plaid.com/docs/api/products/transfer/account-linking/#transfercapabilitiesget */
  isPlaidRtpEnabled: Scalars['Boolean'];
  /** Type of account */
  accountType: ExternalBankAccountType;
};

export type ExternalApiIntegrationArgumentInput = {
  name: Scalars['String'];
  value: Scalars['Any'];
};

export enum ExternalBankAccountType {
  UNKNOWN = 'UNKNOWN',
  CHECKING = 'CHECKING',
  SAVINGS = 'SAVINGS'
}

/** The external link field info type. */
export type ExternalLinkFieldInfo = UiElementInfo & {
  __typename?: 'ExternalLinkFieldInfo';
  /** Wizard Code */
  wizardCode: Maybe<Scalars['String']>;
  /** The field Id. */
  id: Scalars['ID'];
  /** Field type. */
  type: FieldType;
  /** Field Name, Can be used as Label in AT and/or for validation and error messages. */
  name: Maybe<Scalars['String']>;
  /** Field Description Can be used as tooltip or watermark on a presentation level */
  description: Maybe<Scalars['String']>;
  /** Gets value which indicates whether this field is enabled in the current configuration. */
  isEnabled: Scalars['Boolean'];
  /** Label for front-end UI */
  label: Maybe<Scalars['String']>;
  /** Indicates whether the field with the field value needs to be submitted in SubmitWizardStepFieldValues API calls. */
  isSubmittable: Scalars['Boolean'];
  /**
   * client-based property bindings.
   *                     Bindings are exposed to the web app to form a kind of name-value collection of data items
   *                     required to property display a configurable field (that is called DataContext).
   */
  bindings: Maybe<Array<Maybe<Binding>>>;
};

export type ExternalTransferInput = {
  /** Unique id of source account selected by user. */
  sourceAccountId: Scalars['ID'];
  /** Unique id of destination account selected by user. */
  destinationAccountId: Scalars['ID'];
  /** Specifies direction of external transfer. For external transfer service external account must be source or destination of the transfer. */
  isExternalAccountSource: Scalars['Boolean'];
  /** Unique id of payment option selected by user if supported. */
  paymentOptionId: Maybe<Scalars['ID']>;
  /** Same Day payment option. */
  isSameDay: Scalars['Boolean'];
  /** Payment amount. */
  amount: Scalars['Decimal'];
  /** Memo entered by user. */
  memo: Maybe<Scalars['String']>;
  /** Date selected by user for one time scheduling option. */
  scheduledDate: Maybe<Scalars['Date']>;
  /** Setting for recurring option selected by user. */
  recurringOptionSettings: Maybe<RecurringOptionSettingsInput>;
};

export type ExternalTransferSettings = {
  __typename?: 'ExternalTransferSettings';
  /** Are scheduled and recurring payments allowed. */
  isScheduledPaymentAllowed: Scalars['Boolean'];
  /** List of days which should not be available for scheduling payments. */
  excludedPaymentDates: ExcludedPaymentDays;
  /** Get or sets value that specifies whether scheduling allowed on non-business dates. */
  isAllowedSchedulingOnNonBusinessDays: Scalars['Boolean'];
  /** Returns error message if scheduled date is not allowed. */
  notAvailableScheduledDateMessage: Scalars['String'];
  /** Is Transfer Activity page available */
  isTransferActivityPageAvailable: Scalars['Boolean'];
  /** Return notice that should be shown when user select scheduled payment option. */
  scheduledPaymentNotice: Scalars['String'];
  /** List of supported recurring options. */
  recurringOptions: Maybe<Array<RecurringOption>>;
  /** Return notice that should be shown when user select recurring payment option. */
  recurringPaymentNotice: Scalars['String'];
  /** Indicates whether the same day ACH transfer option is available for outgoing ACH. */
  sameDayACHIsAllowed: Scalars['Boolean'];
  /** Indicates whether 'Send now' option is entirely removed for outgoing and incoming ACH transfers. */
  aCHSendNowIsAllowed: Scalars['Boolean'];
  /** ACH daily cutoff time in the FI timezone. 'Same Day ACH' option won't be available in the day after the set time. Any 'Send Now' External Account transfer will be provided with the next business day as an effective entry date. Possible format: hh:mm:ss AM/PM. */
  aCHDailyCutOffTime: Scalars['String'];
  /** End after number of transfers ending option for recurring transfers enabled (True) or disabled (False) */
  allowEndAfterCountOption: Scalars['Boolean'];
  /** End after date of transfers ending option for recurring transfers enabled (True) or disabled (False) */
  allowEndAfterDateOption: Scalars['Boolean'];
  /** Indicates whether an optional Transfer Memo field is enabled when a user is setting up a transfer. */
  isTransferMemoEnabled: Scalars['Boolean'];
  /** Indicate that Plaid integration is enabled. */
  isPlaidEnabled: Scalars['Boolean'];
  /** Indicate that real time paymrnt via Plaid is enabled. */
  isPlaidRtpEnabled: Scalars['Boolean'];
  /** Indicate that Alacriti integration is enabled. */
  isAlacritiEnabled: Scalars['Boolean'];
  /** Indicate that real time paymrnt via Alacriti is enabled. */
  isAlacritiRtpEnabled: Scalars['Boolean'];
};

export type Favorite = {
  __typename?: 'Favorite';
  /** id of saved favorite */
  id: Scalars['ID'];
  /** Name for favorite */
  name: Scalars['String'];
  /** Type of operation for favorite */
  operationType: Scalars['String'];
  /** Amount of saved favorite transfer */
  amount: Scalars['Decimal'];
  /** Text notes of saved favorite transfer */
  memo: Scalars['String'];
};

export type FavoriteTransferData = {
  __typename?: 'FavoriteTransferData';
  /** Id of favorite payment */
  id: Scalars['String'];
  /** Name of favorite payment */
  name: Scalars['String'];
  /** Amount of favorite payment */
  amount: Scalars['Decimal'];
  /** Source account identifier of favorite payment */
  sourceAccountId: Scalars['String'];
  /** Destination account identifier of favorite payment */
  destinationAccountId: Scalars['String'];
  /** Memo of favorite payment */
  memo: Maybe<Scalars['String']>;
  /** Payment option identifier of favorite payment */
  paymentOptionId: Maybe<Scalars['String']>;
};

export type FiSettings = {
  __typename?: 'FiSettings';
  contactInfoUrl: Scalars['String'];
  customerHelpCenterUrl: Scalars['String'];
  faviconAdminUrl: Scalars['String'];
  faviconUrl: Scalars['String'];
  fullName: Scalars['String'];
  mfmDownloadUrl: Scalars['String'];
  phoneNumber: Scalars['String'];
  mobileInterrupterDisplayPerDevice: Scalars['Int'];
  redirectToFiSiteAfterLogoff: Scalars['Boolean'];
  shortName: Scalars['String'];
  siteHostUrl: Scalars['String'];
  fiSupportedCultures: FiSupportedCultures;
};

export type FiSupportedCultures = {
  __typename?: 'FiSupportedCultures';
  /** Represent the list of localization cultures supported by FI. */
  list: Array<Maybe<LocalizationCulture>>;
};

export enum FieldBehaviorMode {
  REQUIRED = 'REQUIRED',
  READONLY = 'READONLY',
  OPTIONAL = 'OPTIONAL'
}

/** The field info base interface. */
export type FieldInfoBase = {
  /** The field Id that must be implemented. */
  id: Scalars['ID'];
  /** Field type that must be implemented. */
  type: FieldType;
  /** Field Name that must be implemented, Can be used as Label in AT and/or for validation and error messages. */
  name: Maybe<Scalars['String']>;
  /** Field Description that must be implemented. Can be used as tooltip or watermark on a presentation level. */
  description: Maybe<Scalars['String']>;
  /** The value which indicates whether this field is enabled in the current configuration. Must be implemented. */
  isEnabled: Scalars['Boolean'];
  /** Label for front-end UI. Must be implemented. */
  label: Maybe<Scalars['String']>;
  /** Indicates whether the field with the field value needs to be submitted in SubmitWizardStepFieldValues API calls. Must be implemented. */
  isSubmittable: Scalars['Boolean'];
  /**
   * Client-based property bindings.
   *                     Bindings are exposed to the web app to form a kind of name-value collection of data items
   *                     required to property display a configurable field (that is called DataContext). Must be implemented.
   */
  bindings: Maybe<Array<Maybe<Binding>>>;
  /** Field Behavior e.g Required, Readonly. Must be implemented. */
  fieldBehaviorMode: FieldBehaviorMode;
  /** This property stores the field input hint. Intended to be displayed by native apps on mobile devices. Must be implemented. */
  placeholder: Maybe<Scalars['String']>;
};

/** The field info group type. */
export type FieldInfoGroup = UiElementInfo & {
  __typename?: 'FieldInfoGroup';
  /** Field within a group */
  subFields: Array<Maybe<FieldsUnion>>;
  /** The field Id. */
  id: Scalars['ID'];
  /** Field type. */
  type: FieldType;
  /** Field Name, Can be used as Label in AT and/or for validation and error messages. */
  name: Maybe<Scalars['String']>;
  /** Field Description Can be used as tooltip or watermark on a presentation level */
  description: Maybe<Scalars['String']>;
  /** Gets value which indicates whether this field is enabled in the current configuration. */
  isEnabled: Scalars['Boolean'];
  /** Label for front-end UI */
  label: Maybe<Scalars['String']>;
  /** Indicates whether the field with the field value needs to be submitted in SubmitWizardStepFieldValues API calls. */
  isSubmittable: Scalars['Boolean'];
  /**
   * client-based property bindings.
   *                     Bindings are exposed to the web app to form a kind of name-value collection of data items
   *                     required to property display a configurable field (that is called DataContext).
   */
  bindings: Maybe<Array<Maybe<Binding>>>;
};

export enum FieldType {
  BOOLEANFIELDINFO = 'BOOLEANFIELDINFO',
  CHALLENGEQUESTIONSFIELDINFO = 'CHALLENGEQUESTIONSFIELDINFO',
  CONSTRAINEDTEXTFIELDINFO = 'CONSTRAINEDTEXTFIELDINFO',
  CURRENCYFIELDINFO = 'CURRENCYFIELDINFO',
  DATEFIELDINFO = 'DATEFIELDINFO',
  DECIMALFIELDINFO = 'DECIMALFIELDINFO',
  EMAILFIELDINFO = 'EMAILFIELDINFO',
  FIELDINFOGROUP = 'FIELDINFOGROUP',
  FUZZYVALUEFIELDINFO = 'FUZZYVALUEFIELDINFO',
  INTEGERFIELDINFO = 'INTEGERFIELDINFO',
  MASKEDTEXTFIELDINFO = 'MASKEDTEXTFIELDINFO',
  MULTIPLECHOICEFIELDINFO = 'MULTIPLECHOICEFIELDINFO',
  PASSWORDFIELDINFO = 'PASSWORDFIELDINFO',
  ADMINPASSWORDFIELDINFO = 'ADMINPASSWORDFIELDINFO',
  PERSONALSECURITYIMAGEFIELDINFO = 'PERSONALSECURITYIMAGEFIELDINFO',
  PERSONALSECURITYPHRASEFIELDINFO = 'PERSONALSECURITYPHRASEFIELDINFO',
  PHONEFIELDINFO = 'PHONEFIELDINFO',
  RANDOMCHALLENGEQUESTIONFIELDINFO = 'RANDOMCHALLENGEQUESTIONFIELDINFO',
  READONLYPERSONALSECURITYIMAGEFIELDINFO = 'READONLYPERSONALSECURITYIMAGEFIELDINFO',
  SINGLECHOICEFIELDINFO = 'SINGLECHOICEFIELDINFO',
  SSNFIELDINFO = 'SSNFIELDINFO',
  TEXTFIELDINFO = 'TEXTFIELDINFO',
  TEXTFIELDINFOWITHREQUIREDFLAG = 'TEXTFIELDINFOWITHREQUIREDFLAG',
  USERNAMEFIELDINFO = 'USERNAMEFIELDINFO',
  ADMINNAMEFIELDINFO = 'ADMINNAMEFIELDINFO',
  ZIPCODEFIELDINFO = 'ZIPCODEFIELDINFO',
  NOTIFICATIONFIELDINFO = 'NOTIFICATIONFIELDINFO',
  LABELFIELDINFO = 'LABELFIELDINFO',
  AGREEMENTFIELDINFO = 'AGREEMENTFIELDINFO',
  HTMLTEXTFIELDINFO = 'HTMLTEXTFIELDINFO',
  SELECTABLEGROUPFIELDINFO = 'SELECTABLEGROUPFIELDINFO',
  MANAGEALERTSFIELDINFO = 'MANAGEALERTSFIELDINFO',
  OPTIONALGROUPFIELDINFO = 'OPTIONALGROUPFIELDINFO',
  ERRORMESSAGEFIELDINFO = 'ERRORMESSAGEFIELDINFO',
  HIDDENFIELDINFO = 'HIDDENFIELDINFO',
  CUSTOMIZABLESINGLECHOICEFIELDINFO = 'CUSTOMIZABLESINGLECHOICEFIELDINFO',
  TIMEFIELDINFO = 'TIMEFIELDINFO',
  EXTERNALLINK = 'EXTERNALLINK'
}

/** The field dictionary entry type. */
export type FieldValue = {
  /** Scalar Value. */
  value: Maybe<Scalars['Any']>;
  /** Scalar Values. */
  values: Maybe<Array<Maybe<Scalars['Any']>>>;
  /** Field Id. */
  fieldId: Scalars['ID'];
  /** Represent optional value-type for a value/values being posted. */
  valueType: Maybe<Scalars['String']>;
};

export type FieldsUnion = EmailFieldInfo | ConstrainedTextFieldInfo | PasswordFieldInfo | SsnFieldInfo | UsernameFieldInfo | MaskedTextFieldInfo | TextFieldInfo | ZipCodeFieldInfo | HtmlTextFieldInfo | SingleChoiceFieldInfo | MultipleChoiceFieldInfo | ChoiceCollectionFieldInfoBase | FuzzyValueFieldInfo | CurrencyFieldInfo | AgreementFieldInfo | BooleanFieldInfo | DateFieldInfo | DecimalFieldInfo | IntegerFieldInfo | TimeFieldInfo | ErrorMessageFieldInfo | QuestionPoolsFieldInfoGroup | FieldInfoGroup | LabelFieldInfo | HiddenFieldInfo | NotificationFieldInfo | ExternalLinkFieldInfo | SelectableGroupFieldInfo;

export type FinalRedirect = {
  __typename?: 'FinalRedirect';
  /** Flag shows if POST or GET HTTP methods to use for redirect. */
  isPost: Scalars['Boolean'];
  /** URL to redirect. */
  url: Maybe<Scalars['String']>;
  /** List of parameters to be used during POST to redirect URL. */
  postParameters: Maybe<Scalars['Any']>;
  /** List of HTTP headers to be used during redirect. */
  httpHeaders: Maybe<Scalars['Any']>;
  /** A way to open SSO in the user's browser. */
  ssoOpenStrategy: Maybe<Scalars['Any']>;
  /** Native SDK information used by SSO to launch native mobile functionality. */
  nativeSdk: Maybe<SsoNativeSdkInformation>;
};

export enum FlowAlignment {
  LEFT = 'LEFT',
  CENTER = 'CENTER',
  RIGHT = 'RIGHT',
  JUSTIFY = 'JUSTIFY'
}

export type FlowMetadata = {
  __typename?: 'FlowMetadata';
  /** The Alignment of the Widget: Left, Center, Right, Justify */
  alignment: FlowAlignment;
  /** The Percentage of the Alignment */
  size: Scalars['Int'];
  has: Scalars['Boolean'];
  contentItem: Maybe<Page>;
};


export type FlowMetadataHasArgs = {
  name: Maybe<Scalars['String']>;
};

export type ForceUserInfoResetRequest = {
  __typename?: 'ForceUserInfoResetRequest';
  /** The type of User Data to reset */
  resetDataType: UserInfoResetType;
};

export type ForceUserLogoutNotification = {
  __typename?: 'ForceUserLogoutNotification';
  /** User friendly logout reason. */
  reasonText: Scalars['String'];
};

export enum FuzzyValueDifferenceType {
  VALUE = 'VALUE',
  PERCENTS = 'PERCENTS'
}

/** The fuzzy value field info type. */
export type FuzzyValueFieldInfo = UiElementInfo & FieldInfoBase & {
  __typename?: 'FuzzyValueFieldInfo';
  /** Field Value. */
  value: Maybe<Scalars['Decimal']>;
  /** Min field Value. */
  minValue: Maybe<Scalars['Decimal']>;
  /** Max field Value. */
  maxValue: Maybe<Scalars['Decimal']>;
  /** The acceptable difference value. */
  acceptableDifferenceValue: Scalars['Decimal'];
  /** The acceptable difference value. */
  differenceType: Maybe<FuzzyValueDifferenceType>;
  /** The field Id. */
  id: Scalars['ID'];
  /** Field type. */
  type: FieldType;
  /** Field Name, Can be used as Label in AT and/or for validation and error messages. */
  name: Maybe<Scalars['String']>;
  /** Field Description Can be used as tooltip or watermark on a presentation level */
  description: Maybe<Scalars['String']>;
  /** Gets value which indicates whether this field is enabled in the current configuration. */
  isEnabled: Scalars['Boolean'];
  /** Label for front-end UI */
  label: Maybe<Scalars['String']>;
  /** Indicates whether the field with the field value needs to be submitted in SubmitWizardStepFieldValues API calls. */
  isSubmittable: Scalars['Boolean'];
  /**
   * client-based property bindings.
   *                     Bindings are exposed to the web app to form a kind of name-value collection of data items
   *                     required to property display a configurable field (that is called DataContext).
   */
  bindings: Maybe<Array<Maybe<Binding>>>;
  /** Field Behavior e.g Required, Readonly */
  fieldBehaviorMode: FieldBehaviorMode;
  /** This property stores the field input hint. Intended to be displayed by native apps on mobile devices. */
  placeholder: Maybe<Scalars['String']>;
};

export type GenerateAuthOptions = {
  __typename?: 'GenerateAuthOptions';
  /** RpId. */
  rpId: Scalars['String'];
  /** User verification. */
  userVerification: Scalars['String'];
  /** Challenge. */
  challenge: Scalars['String'];
  /** Timeout. */
  timeout: Scalars['Long'];
  /** AllowCredentials. */
  allowCredentials: Array<PublicKeyCredentialDescriptor>;
  /** Hints. */
  hints: Array<Scalars['String']>;
};

export type GetAccessiblePermissionsResult = {
  __typename?: 'GetAccessiblePermissionsResult';
  accountsPermissions: Array<BankAccountPermissions>;
  transfersPermissions: Array<TransferPermissions>;
  businessGroupsAccessLevel: Maybe<BusinessGroupsAccessLevel>;
  ssoPermissions: Array<SsoPermissions>;
  isDualApprover: Maybe<Scalars['Boolean']>;
  allowViewAchHistory: Maybe<Scalars['Boolean']>;
};

export type GetAccessibleUsersResult = {
  __typename?: 'GetAccessibleUsersResult';
  accessibleUsers: Array<AccessibleUser>;
};

/** List of identifiers of actions user can perform by the link from advanced login takeover alert. */
export type GetAccountSecurityActionsResult = {
  __typename?: 'GetAccountSecurityActionsResult';
  /** Identifiers of actions user can perform by the link from advanced login takeover alert. */
  accountSecurityActionIds: Array<Scalars['String']>;
};

export type GetAccountsPermissionsResult = {
  __typename?: 'GetAccountsPermissionsResult';
  permissions: Array<BankAccountPermissions>;
  restrictedBankAccountTypesMessage: Maybe<Scalars['String']>;
};

export type GetAdditionalPermissionsResult = {
  __typename?: 'GetAdditionalPermissionsResult';
  businessGroupsAccessLevel: Maybe<BusinessGroupsAccessLevel>;
  ssoPermissions: Array<SsoPermissions>;
  allowViewAchHistory: Maybe<Scalars['Boolean']>;
};

/** Represents secure message to be created. */
export type GetAttachableTransactionsResponse = {
  __typename?: 'GetAttachableTransactionsResponse';
  /** Attachable statement transactions. */
  data: Array<AttachableTransaction>;
  /** Whether more data is available. */
  isAvailableMoreData: Scalars['Boolean'];
};

/** Alert groups container. */
export type GetAvailableAlertsResponse = {
  __typename?: 'GetAvailableAlertsResponse';
  alertGroups: Maybe<Array<AlertGroup>>;
  infoText: Maybe<Scalars['String']>;
};

export type GetBankAccountBalancesForecastGraphResult = {
  __typename?: 'GetBankAccountBalancesForecastGraphResult';
  items: Array<BankAccountBalancesForecastGraphItem>;
};

export type GetBankAccountBalancesGraphResult = {
  __typename?: 'GetBankAccountBalancesGraphResult';
  items: Array<BankAccountBalancesGraphItem>;
};

export type GetBankAccountDetailsResult = {
  __typename?: 'GetBankAccountDetailsResult';
  items: Array<BankAccountDisplayDetailsItem>;
};

export type GetBankAccountRewardPointsResult = {
  __typename?: 'GetBankAccountRewardPointsResult';
  rewardPointsAvailable: Maybe<Scalars['Int']>;
};

export type GetBankInfoByRoutingNumberResult = {
  __typename?: 'GetBankInfoByRoutingNumberResult';
  /** Bank info details. */
  bankInfo: Maybe<BankInfo>;
  /** Validation result of operation. */
  validationResult: Maybe<ValidationResultContainer>;
};

export type GetCardAlertsResult = {
  __typename?: 'GetCardAlertsResult';
  alerts: CardAlerts;
  successful: Scalars['Boolean'];
  errors: Maybe<Array<ValidationItem>>;
  warnings: Maybe<Array<ValidationItem>>;
  infos: Maybe<Array<ValidationItem>>;
};

export type GetCardBalanceResult = {
  __typename?: 'GetCardBalanceResult';
  balance: CardBalance;
  successful: Scalars['Boolean'];
  errors: Maybe<Array<ValidationItem>>;
  warnings: Maybe<Array<ValidationItem>>;
  infos: Maybe<Array<ValidationItem>>;
};

export type GetCardControlsResult = {
  __typename?: 'GetCardControlsResult';
  controls: CardControls;
  successful: Scalars['Boolean'];
  errors: Maybe<Array<ValidationItem>>;
  warnings: Maybe<Array<ValidationItem>>;
  infos: Maybe<Array<ValidationItem>>;
};

export type GetCardPaymentDetailsResult = {
  __typename?: 'GetCardPaymentDetailsResult';
  paymentDetails: CardPaymentDetails;
  successful: Scalars['Boolean'];
  errors: Maybe<Array<ValidationItem>>;
  warnings: Maybe<Array<ValidationItem>>;
  infos: Maybe<Array<ValidationItem>>;
};

export type GetCardsResult = {
  __typename?: 'GetCardsResult';
  settings: Settings;
  cards: Array<Card>;
  successful: Scalars['Boolean'];
  errors: Maybe<Array<ValidationItem>>;
  warnings: Maybe<Array<ValidationItem>>;
  infos: Maybe<Array<ValidationItem>>;
};

export type GetCompromisedCredentialsInput = {
  /** Password to check for compromise. */
  password: Maybe<Scalars['String']>;
};

export type GetConfigResult = {
  __typename?: 'GetConfigResult';
  /** Whether business banking transfers with zero amounts are allowed. */
  allowZeroAmountsBatches: Scalars['Boolean'];
  /** Whether business banking functionality is available for current user. */
  businessBankingAvailable: Scalars['Boolean'];
  /** Whether cash management functionality is available for current user. */
  cashManagementAvailable: Scalars['Boolean'];
  /** List of parent users. Note that this list might also include the current user. */
  parentUsers: Array<ParentUserInfo>;
  /** Id of the user in the ParentUsers list which should be shown as selected in the UI. */
  defaultParentUserId: Scalars['Int'];
  /** Id of the currently logged in user. */
  currentUserId: Scalars['Int'];
  /** Is ACH history export available */
  isHistoryExportAvailable: Scalars['Boolean'];
};

/** Contacts and Settings Configuration. */
export type GetContactsAndSettingsConfigurationResult = {
  __typename?: 'GetContactsAndSettingsConfigurationResult';
  /** Check primary email status on login */
  checkPrimaryEmailStatusOnLogin: Scalars['Boolean'];
  /** Is allowed alert channels SMS alert enabled */
  isAllowedAlertChannelsSmsAlertEnabled: Scalars['Boolean'];
  /** Is alerts by SMS enabled for all users */
  isAlertsBySmsEnabledForAllUsers: Scalars['Boolean'];
  /**
   * Is backend address sync enabled
   * @deprecated Artifact. To be removed in 5.6
   */
  isBackendAddressSyncEnabled: Scalars['Boolean'];
  /** Is delete primary work phone enabled */
  isDeletePrimaryWorkPhoneEnabled: Scalars['Boolean'];
  /** Is foreign address enabled */
  isForeignAddressEnabled: Scalars['Boolean'];
  /** Is international phone enabled */
  isInternationalPhoneEnabled: Scalars['Boolean'];
  /** Is using same number for work home mobile phone enabled */
  isUsingSameNumberForWorkHomeMobilePhoneEnabled: Scalars['Boolean'];
  /** Max automatic log off after min */
  maxAutomaticLogOffAfterMin: Scalars['Int'];
  /** Is changing user time zone enabled */
  isChangeTimeZoneEnabled: Scalars['Boolean'];
};

/** Contacts and Settings. */
export type GetContactsAndSettingsResult = {
  __typename?: 'GetContactsAndSettingsResult';
  /** Contacts and settings fields */
  config: Maybe<ContactsAndSettingsPermissions>;
  /** Contacts and settings fields */
  fields: Array<Maybe<FieldsUnion>>;
};

/** List of empty fields used for creating addresses and contacts. */
export type GetContactsEmptyFieldsResult = {
  __typename?: 'GetContactsEmptyFieldsResult';
  /** Empty address fields */
  address: FieldsUnion;
  /** Empty email fields */
  email: FieldsUnion;
  /** Empty mobile phone fields */
  mobilePhone: FieldsUnion;
  /** Empty home phone fields */
  homePhone: FieldsUnion;
  /** Empty work phone fields */
  workPhone: FieldsUnion;
};

export type GetCreditScoreMemberAccountsResult = {
  __typename?: 'GetCreditScoreMemberAccountsResult';
  isOptInEnabled: Scalars['Boolean'];
  members: Array<CreditScoreMemberAccount>;
};

export type GetCreditScoreOptInSettingsResult = {
  __typename?: 'GetCreditScoreOptInSettingsResult';
  settings: Array<CreditScoreOptInSetting>;
  fiName: Scalars['String'];
};

export type GetCreditScoreResult = {
  __typename?: 'GetCreditScoreResult';
  isOptIn: Scalars['Boolean'];
  isInvalidScore: Scalars['Boolean'];
  score: Scalars['Int'];
  accountHolder: Maybe<Scalars['String']>;
  itemCodes: Array<Scalars['String']>;
  fiName: Scalars['String'];
  lastUpdate: Scalars['Date'];
};

/** Current User Account Info. */
export type GetCurrentUserAccountInfoResult = {
  __typename?: 'GetCurrentUserAccountInfoResult';
  /** First Name */
  firstName: Scalars['String'];
  /** Last Name */
  lastName: Scalars['String'];
  /** Middle Name */
  middleName: Maybe<Scalars['String']>;
  /** Preferred Name */
  preferredName: Maybe<Scalars['String']>;
  /** User Login */
  userLogin: Scalars['String'];
  /** User Unique Identifier */
  userUid: Scalars['String'];
  /** Determines whether User is FI Member */
  isFIMember: Scalars['Boolean'];
  /** DeepTarget Id */
  deepTargetId: Maybe<Scalars['String']>;
  /** Adobe Analytics Id */
  adobeAnalyticsId: Maybe<Scalars['String']>;
  /** Preferred Culture */
  preferredCulture: Maybe<Scalars['String']>;
};

export type GetDocumentsResult = {
  __typename?: 'GetDocumentsResult';
  documents: Array<Document>;
};

/** Empty configurable alert fields and available bank accounts. */
export type GetEmptyFieldsResponse = {
  __typename?: 'GetEmptyFieldsResponse';
  /** Empty configurable alert fields. */
  fields: Array<FieldsUnion>;
  /** Available bank accounts. */
  accounts: Array<AlertBankAccountInfo>;
};

export type GetEventLogsResult = {
  __typename?: 'GetEventLogsResult';
  /** User event logs data */
  data: Array<EventLogItem>;
  /** Flag for additional data on the server side in the next request. */
  isAvailableMoreData: Scalars['Boolean'];
};

export type GetExternalAccountLinkingSettingsResult = {
  __typename?: 'GetExternalAccountLinkingSettingsResult';
  /** Indicates whether external account linking available. */
  isExternalAccountLinkingAvailable: Maybe<Scalars['Boolean']>;
  /** Indicates whether primary account name editing available on external account linking. */
  isPrimaryAccountNameEditingAvailable: Maybe<Scalars['Boolean']>;
  /** User full name. */
  userFullname: Maybe<Scalars['String']>;
};

export type GetFavoritesResult = {
  __typename?: 'GetFavoritesResult';
  /** List of saved transfer favorites */
  favorites: Maybe<Array<Favorite>>;
};

export type GetHistoryExportConfigResult = {
  __typename?: 'GetHistoryExportConfigResult';
  /** Id of the user in the ContextUsers list which should be shown as selected in the UI by default. */
  defaultContextUserId: Scalars['Int'];
  /** List of context users. Note that this list might also include the current user. */
  contextUsers: Array<ParentUserInfo>;
};

export type GetInvestmentAccountsResult = {
  __typename?: 'GetInvestmentAccountsResult';
  /** List of eligible internal accounts. */
  internalAccountIds: Array<Scalars['String']>;
  /** List of eligible investment accounts. */
  investmentAccountIds: Array<Scalars['String']>;
};

export type GetLinkInternalAccountSettingsResult = {
  __typename?: 'GetLinkInternalAccountSettingsResult';
  isMembershipNicknameRequired: Scalars['Boolean'];
  isMembershipNicknameAllowed: Scalars['Boolean'];
  isAccountLinkingAllowed: Scalars['Boolean'];
};

export type GetLinkedInstitutionsResult = {
  __typename?: 'GetLinkedInstitutionsResult';
  linkInstitutions: Array<LinkInstitution>;
};

export type GetM2MPayeesPayload = {
  __typename?: 'GetM2MPayeesPayload';
  /** Member to member payee accounts. */
  payeeAccounts: Array<PayeeAccount>;
  /** Member to member manage payees settings. */
  payeesSettings: M2mPayeesSettings;
};

export type GetMemberAccountsResult = {
  __typename?: 'GetMemberAccountsResult';
  members: Array<MemberAccount>;
  documentTypes: Array<DocumentType>;
  years: Array<Scalars['String']>;
  allowOptInOptOutPreferences: Scalars['Boolean'];
};

/** Container for GetMessages response */
export type GetMessagesResponse = {
  __typename?: 'GetMessagesResponse';
  /** List of secure messages or alerts. */
  data: Array<Message>;
  /** Flag for additional data on the server side in the next request. */
  isAvailableMoreData: Scalars['Boolean'];
};

export type GetNewExternalTransferDataResult = {
  __typename?: 'GetNewExternalTransferDataResult';
  /** Pending external accounts count. */
  pendingExternalAccountCount: Maybe<Scalars['Int']>;
  /** Account group with external accounts that is available as transfer source. */
  externalSourceGroup: Maybe<ExternalAccountGroup>;
  /** Account group with internal accounts that is available as transfer source. */
  internalSourceGroup: Maybe<InternalAccountGroup>;
  /** Account group with external accounts that is available as transfer destination. */
  externalDestinationGroup: Maybe<ExternalAccountGroup>;
  /** Account group with external accounts that is available as transfer destination. */
  internalDestinationGroup: Maybe<InternalAccountGroup>;
  /** External payments settings. */
  externalTransferSettings: Maybe<ExternalTransferSettings>;
  /** Result of sync with Plaid for every envolved FIs. */
  syncWithPlaidDetails: Array<PlaidSyncResult>;
};

export type GetNewTransferDataPayload = {
  __typename?: 'GetNewTransferDataPayload';
  /** List of Internal accounts that can be chosen as source for Investment transfer. */
  sourceInternalAccounts: InvestmentBankAccountGroup;
  /** List of Investment accounts that can be chosen as source for Investment transfer. */
  sourceInvestmentAccounts: InvestmentBankAccountGroup;
  /** List of Internal accounts that can be chosen as destination for Investment transfer. */
  destinationInternalAccounts: InvestmentBankAccountGroup;
  /** List of Investment accounts that can be chosen as destination for Investment transfer. */
  destinationInvestmentAccounts: InvestmentBankAccountGroup;
};

export type GetOneOffInvoiceConfigResult = {
  __typename?: 'GetOneOffInvoiceConfigResult';
  /** Id of the user in the ContextUsers list which should be shown as selected in the UI by default. */
  defaultContextUserId: Scalars['Long'];
  /** List of context users. Note that this list might or might not include current user as well. */
  contextUsers: Array<ParentUserInfo>;
};

export type GetOneOffInvoiceRecipientListResult = {
  __typename?: 'GetOneOffInvoiceRecipientListResult';
  /** ID of the recipient in the Recipients list which should be shown as selected in the UI by default */
  defaultRecipientId: Scalars['Long'];
  /** List of one-off invoice recipients. */
  recipients: Array<OneOffInvoiceRecipientInfo>;
};

export type GetOpenNewSharesBankAccountTypesResult = {
  __typename?: 'GetOpenNewSharesBankAccountTypesResult';
  groups: Array<OpenNewSharesBankAccountTypeGroup>;
};

export type GetOpenNewSharesFundingSourcesResult = {
  __typename?: 'GetOpenNewSharesFundingSourcesResult';
  accounts: Array<OpenNewSharesFundingSourceRef>;
  externalAccounts: Array<OpenNewSharesFundingSourceRef>;
  isExternalAccountsAllowed: Scalars['Boolean'];
};

export type GetOpenNewSharesMemberAccountsResult = {
  __typename?: 'GetOpenNewSharesMemberAccountsResult';
  isHiddenAccountsEnabled: Scalars['Boolean'];
  members: Array<OpenNewSharesMemberAccount>;
};

export type GetOptInSettingsResult = {
  __typename?: 'GetOptInSettingsResult';
  settings: Array<OptInSetting>;
  isPaperAndEmailStatementsEnabledMode: Scalars['Boolean'];
  isEStatementsOptOutDisabled: Scalars['Boolean'];
  optOutDisabledNotificationLabel: Maybe<Scalars['String']>;
};

export type GetOverdraftProtectionOptInSettingsResult = {
  __typename?: 'GetOverdraftProtectionOptInSettingsResult';
  settings: Array<OverdraftProtectionOptInSetting>;
  isTermsAndConditionsEnabled: Scalars['Boolean'];
};

export type GetPaymentOptionsResult = {
  __typename?: 'GetPaymentOptionsResult';
  /** List of payment options. */
  paymentOptions: Array<InvestmentPaymentOption>;
  /** Payment operation type. */
  paymentType: Scalars['String'];
};

export type GetPlaidAccountsResult = {
  __typename?: 'GetPlaidAccountsResult';
  institutions: Array<PlaidInstitution>;
};

export type GetPlaidTransactionsResult = {
  __typename?: 'GetPlaidTransactionsResult';
  data: Array<PlaidTransaction>;
  status: GetPlaidTransactionsStatus;
};

export enum GetPlaidTransactionsStatus {
  UNKNOWN = 'UNKNOWN',
  NOTREADY = 'NOTREADY',
  SUCCESS = 'SUCCESS'
}

export type GetProPayAuthTokenResult = {
  __typename?: 'GetProPayAuthTokenResult';
  /** ProPay temp token for SPI service. */
  token: Maybe<Scalars['String']>;
  /** ProPay temp token for SPI service. */
  tokenLifetimeInSeconds: Scalars['String'];
  /** ProPay temp token credential ID for SPI service. */
  credentialId: Maybe<Scalars['String']>;
  /** Unique identifier of the payer in ProPay system. */
  payerId: Maybe<Scalars['String']>;
  /** ProPay payment settings. */
  settings: Maybe<ProPayPaymentSettings>;
  /** Validation result of operation. */
  validationResult: Maybe<ValidationResultContainer>;
};

/** Container for GetMessageRecipients response */
export type GetRecipientsResponse = {
  __typename?: 'GetRecipientsResponse';
  /** List of recipient. */
  data: Array<MessageRecipient>;
};

export type GetRegisteredPasskeys = {
  __typename?: 'GetRegisteredPasskeys';
  /** Passkeys. */
  passkeys: Array<Passkey>;
};

export type GetRetrievalWizardStateParams = {
  /** Selected retrieval type. */
  retrievalType: RetrievalType;
  /** Selected user kind. */
  userKind: UserKind;
  /** The current step Id. */
  stepId: Scalars['String'];
};

/** Parameters necessary to finally redirect user's browser to the desired vendor page. */
export type GetSsoScenarioResultUnion = AccountSelector | FinalRedirect | OpenExternalApp;

export type GetStopCheckOptionsResult = {
  __typename?: 'GetStopCheckOptionsResult';
  /** Available bank accounts for stop check payment. */
  bankAccounts: Array<Maybe<StopCheckBankAccount>>;
  /** Check stop payment fee note. */
  checkStopPaymentFeeNote: Maybe<Scalars['String']>;
  /** Check stop payment fee. */
  checkStopPaymentFee: Maybe<Scalars['Decimal']>;
  isOnlySingleCheckSupported: Scalars['Boolean'];
};

export type GetSubuserEnrollmentInvitationByTokenInput = {
  /** Valid Invite token from email. */
  inviteToken: Scalars['String'];
};

export type GetSubuserEnrollmentInvitationByTokenResult = {
  __typename?: 'GetSubuserEnrollmentInvitationByTokenResult';
  /** Success Summary Message. */
  successSummary: Array<Maybe<Scalars['String']>>;
  /** List of errors. */
  errors: Array<Maybe<ValidationUnion>>;
  /** List of warnings. */
  warnings: Array<Maybe<ValidationUnion>>;
  /** Indicates whether the operation succeed. */
  successful: Scalars['Boolean'];
  /** The invitation. */
  invitation: Maybe<Invitation>;
};

export type GetSubuserInfoResult = {
  __typename?: 'GetSubuserInfoResult';
  isFullAccessAllowed: Scalars['Boolean'];
  isNonMemberSubusersAllowed: Scalars['Boolean'];
  isPhoneNumberAllowed: Scalars['Boolean'];
  isInternationalPhoneNumberAllowed: Scalars['Boolean'];
  isDisclosureAllowed: Scalars['Boolean'];
  isGrantingAchHistoryAllowed: Scalars['Boolean'];
  info: Maybe<SubuserInfo>;
};

export type GetSubuserTransferDetailsResult = {
  __typename?: 'GetSubuserTransferDetailsResult';
  /** Subuser transfer details */
  subuserTransferDetails: Maybe<SubuserTransferDetails>;
  /** Validation result of operation. */
  validationResult: Maybe<ValidationResultContainer>;
};

export type GetSubusersResult = {
  __typename?: 'GetSubusersResult';
  subusers: Array<Subuser>;
};

/** Alert groups container. */
export type GetSupportedAlertsResponse = {
  __typename?: 'GetSupportedAlertsResponse';
  accountAlerts: Maybe<AccountAlertGroup>;
  securityAlerts: Maybe<SecurityAlertGroup>;
  infoText: Maybe<Scalars['String']>;
};

/** User time zone info. */
export type GetTimeZoneResult = {
  __typename?: 'GetTimeZoneResult';
  /** User time zone. */
  userTimeZone: UserTimeZone;
  /** Time zones list. */
  timeZones: Array<TimeZoneDto>;
};

export type GetTransactionAttachedImageResult = {
  __typename?: 'GetTransactionAttachedImageResult';
  mimeType: Scalars['String'];
  data: Scalars['String'];
  fileName: Scalars['String'];
};

export type GetTransactionsGraphResult = {
  __typename?: 'GetTransactionsGraphResult';
  items: Array<StatementTransactionGraphItem>;
};

export type GetTransactionsHistoryExportersResult = {
  __typename?: 'GetTransactionsHistoryExportersResult';
  items: Array<StatementTransactionExporter>;
};

export type GetTransactionsHistoryResult = {
  __typename?: 'GetTransactionsHistoryResult';
  isTransactionsHistoryFound: Scalars['Boolean'];
  isExportEnabled: Scalars['Boolean'];
  isTransactionEmojiCategorizationFeatureEnabled: Scalars['Boolean'];
  isTransactionDisputeEnabled: Scalars['Boolean'];
  data: Array<StatementTransactionHistoryItem>;
  attachmentImagesValidation: StatementTransactionAttachmentImagesValidation;
};

export type GetTransferActivitiesResult = {
  __typename?: 'GetTransferActivitiesResult';
  /** List of transfer activities. */
  transferActivities: Maybe<Array<Maybe<TransferActivity>>>;
};

export type GetTransferActivityDetailsInput = {
  /** Identifier of transfer. */
  transferId: Scalars['String'];
  /** Optional parameter for take payment task with particular payment operation. */
  operationId: Maybe<Scalars['String']>;
};

export type GetTransferActivityDetailsResult = {
  __typename?: 'GetTransferActivityDetailsResult';
  /** Transfer activity details. */
  transferActivity: TransferActivityDetails;
  /** Transfer activity settings. */
  settings: TransferActivitySettings;
};

export type GetTransferConfigResult = {
  __typename?: 'GetTransferConfigResult';
  /** List of context users. Note that this list might also include the current user. */
  contextUsers: Array<ParentUserInfo>;
  /** Id of the user in the ContextUsers list which should be shown as selected in the UI. */
  defaultContextUserId: Scalars['Int'];
  /** Id of the currently logged in user. */
  currentUserId: Scalars['Int'];
  /** Whether business banking transfers with zero amounts are allowed. */
  allowZeroAmountsBatches: Scalars['Boolean'];
};

export type GetTransferDataResult = {
  __typename?: 'GetTransferDataResult';
  /** Account group with internal accounts that is available as transfer source. */
  internalSourceGroup: InternalBusinessBankingAccountGroup;
  /** Account group with internal accounts that is available as transfer destination. */
  internalDestinationGroup: InternalBusinessBankingAccountGroup;
  /** Template group that is available as transfer source. */
  templatesSourceGroup: Maybe<TemplatesGroup>;
  /** Template group that is available as transfer destination. */
  templatesDestinationGroup: Maybe<TemplatesGroup>;
  /** Business Banking transfer settings. */
  transferSettings: Maybe<BusinessTransferSettings>;
};

export type GetTransfersPermissionsResult = {
  __typename?: 'GetTransfersPermissionsResult';
  permissions: Array<TransferPermissions>;
  isDualApprover: Maybe<Scalars['Boolean']>;
};

export type GetTravelsResult = {
  __typename?: 'GetTravelsResult';
  travels: Array<Travel>;
  successful: Scalars['Boolean'];
  errors: Maybe<Array<ValidationItem>>;
  warnings: Maybe<Array<ValidationItem>>;
  infos: Maybe<Array<ValidationItem>>;
};

export type GetTriggeredEventParams = {
  /** The triggered event challenge Id. */
  challengeId: Scalars['String'];
  /** The current step Id. */
  stepId: Scalars['String'];
};

export type GetUserAccountGroupsResult = {
  __typename?: 'GetUserAccountGroupsResult';
  items: Array<UserAccountGroup>;
};

export type GetUserBankAccountBalanceChangesResult = {
  __typename?: 'GetUserBankAccountBalanceChangesResult';
  balanceChanges: BankAccountBalanceChanges;
};

export type GetUserBankAccountDisplayOptionsResult = {
  __typename?: 'GetUserBankAccountDisplayOptionsResult';
  consumerDisplayOptions: Array<ConsumerBankAccountsDisplayOptions>;
  canEditConsumerNickname: Scalars['Boolean'];
};

export type GetUserBankAccountSummaryResult = {
  __typename?: 'GetUserBankAccountSummaryResult';
  summary: BankAccountSummary;
  canEditAccountName: Scalars['Boolean'];
  accountNameMaxLength: Scalars['Int'];
  accountNameMinLength: Scalars['Int'];
  isBalanceGraphEnabled: Scalars['Boolean'];
  isTransactionsGraphEnabled: Scalars['Boolean'];
  isRewardsPointsEnabled: Scalars['Boolean'];
  rewardsPointsSsoProviderId: Scalars['String'];
};

export type GetUserBankAccountsResult = {
  __typename?: 'GetUserBankAccountsResult';
  isHiddenAccountsEnabled: Scalars['Boolean'];
  isAccountIconEnabled: Scalars['Boolean'];
  isOpeningAccountEnabled: Scalars['Boolean'];
  isLinkInternalAccountEnabled: Scalars['Boolean'];
  isGroupingEnabled: Scalars['Boolean'];
  isAccountGroupTotalsHidden: Scalars['Boolean'];
  isPlaidAccountsEnabled: Scalars['Boolean'];
  groups: Array<BankAccountGroup>;
  accounts: Array<BankAccountInfo>;
};

export type GetUserCustomDataResult = {
  __typename?: 'GetUserCustomDataResult';
  data: Maybe<Scalars['String']>;
  successful: Scalars['Boolean'];
  errors: Maybe<Array<ValidationItem>>;
  warnings: Maybe<Array<ValidationItem>>;
  infos: Maybe<Array<ValidationItem>>;
};

export type GetWireTransferDataResult = {
  __typename?: 'GetWireTransferDataResult';
  /** Wire transfer payments settings. */
  wireTransferSettings: WireTransferSettings;
  /** List of wire recipients available for current user. */
  wireRecipients: Array<WireRecipient>;
  /** List of shared accounts which available for wire transfer. */
  wireAccounts: Array<WireAccountView>;
};

export type GetWizardStateParams = {
  /** The current step Id. */
  stepId: Scalars['String'];
};

export type GetYearEndInfoMemberAccountsResult = {
  __typename?: 'GetYearEndInfoMemberAccountsResult';
  members: Array<YearEndInfoMemberAccount>;
};

export type GetYearEndInfoResult = {
  __typename?: 'GetYearEndInfoResult';
  contributions: Array<YearEndInfo>;
  loanInfos: Array<YearEndLoanInfo>;
  shareInfos: Array<YearEndShareInfo>;
};

export enum GrantedCashManagementAccessLevel {
  READONLY = 'READONLY',
  MODIFY = 'MODIFY',
  NOACCESS = 'NOACCESS'
}

export type HeartbeatResult = {
  __typename?: 'HeartbeatResult';
  /** Result of the heartbeat operation. True - heartbeat was successful, DS session was prolonged. False - otherwise. */
  successful: Scalars['Boolean'];
};

/** The hidden text field info type. */
export type HiddenFieldInfo = UiElementInfo & {
  __typename?: 'HiddenFieldInfo';
  /** Hidden Text Field Value. */
  value: Maybe<Scalars['String']>;
  /** The field Id. */
  id: Scalars['ID'];
  /** Field type. */
  type: FieldType;
  /** Field Name, Can be used as Label in AT and/or for validation and error messages. */
  name: Maybe<Scalars['String']>;
  /** Field Description Can be used as tooltip or watermark on a presentation level */
  description: Maybe<Scalars['String']>;
  /** Gets value which indicates whether this field is enabled in the current configuration. */
  isEnabled: Scalars['Boolean'];
  /** Label for front-end UI */
  label: Maybe<Scalars['String']>;
  /** Indicates whether the field with the field value needs to be submitted in SubmitWizardStepFieldValues API calls. */
  isSubmittable: Scalars['Boolean'];
  /**
   * client-based property bindings.
   *                     Bindings are exposed to the web app to form a kind of name-value collection of data items
   *                     required to property display a configurable field (that is called DataContext).
   */
  bindings: Maybe<Array<Maybe<Binding>>>;
};

export type HistoryExportParametersInput = {
  /** Start of history export period */
  startDateTime: Scalars['DateTime'];
  /** End of history export period */
  endDateTime: Scalars['DateTime'];
};

/** The HTML text field info type. */
export type HtmlTextFieldInfo = UiElementInfo & FieldInfoBase & {
  __typename?: 'HtmlTextFieldInfo';
  /** Field Value. */
  htmlTextValue: Maybe<Scalars['String']>;
  /** The field Id. */
  id: Scalars['ID'];
  /** Field type. */
  type: FieldType;
  /** Field Name, Can be used as Label in AT and/or for validation and error messages. */
  name: Maybe<Scalars['String']>;
  /** Field Description Can be used as tooltip or watermark on a presentation level */
  description: Maybe<Scalars['String']>;
  /** Gets value which indicates whether this field is enabled in the current configuration. */
  isEnabled: Scalars['Boolean'];
  /** Label for front-end UI */
  label: Maybe<Scalars['String']>;
  /** Indicates whether the field with the field value needs to be submitted in SubmitWizardStepFieldValues API calls. */
  isSubmittable: Scalars['Boolean'];
  /**
   * client-based property bindings.
   *                     Bindings are exposed to the web app to form a kind of name-value collection of data items
   *                     required to property display a configurable field (that is called DataContext).
   */
  bindings: Maybe<Array<Maybe<Binding>>>;
  /** Field Behavior e.g Required, Readonly */
  fieldBehaviorMode: FieldBehaviorMode;
  /** This property stores the field input hint. Intended to be displayed by native apps on mobile devices. */
  placeholder: Maybe<Scalars['String']>;
};

/** Represent validation unit interface. Depending from implementation it might contain additional information like for range validation, minLength, maxLength */
export type IValidationItem = {
  /** Gets the Path, Usually Field ID. In case of validation for Array of Entities, it will be another IValidationItem in a collection but with different key 'users\person[0]\firstName', 'users\person[1]\firstName' */
  path: Maybe<Scalars['String']>;
};

/** The Image widget contains Path to the Image */
export type ImageWidget = {
  __typename?: 'ImageWidget';
  /** Id of the Item */
  id: Maybe<Scalars['ID']>;
  /** The content item version id */
  contentItemVersionId: Maybe<Scalars['String']>;
  /** Type of content */
  contentType: Maybe<Scalars['String']>;
  /** The display text of the content item */
  displayText: Maybe<Scalars['String']>;
  /** Is the published version */
  published: Scalars['Boolean'];
  /** Is the latest version */
  latest: Scalars['Boolean'];
  /** The date and time of modification */
  modifiedUtc: Maybe<Scalars['DateTime']>;
  /** The date and time of publication */
  publishedUtc: Maybe<Scalars['DateTime']>;
  /** The date and time of creation */
  createdUtc: Maybe<Scalars['DateTime']>;
  /** The owner of the content item */
  owner: Maybe<Scalars['String']>;
  /** The author of the content item */
  author: Maybe<Scalars['String']>;
  /** Widget Flow Layout Meta Information from WYSIWYG Editor */
  flowMeta: Maybe<FlowMetadata>;
  path: Maybe<Scalars['String']>;
};

/** The integer field info type. */
export type IntegerFieldInfo = UiElementInfo & FieldInfoBase & {
  __typename?: 'IntegerFieldInfo';
  /** Field Value. */
  value: Maybe<Scalars['Int']>;
  /** Min field Value. */
  minValue: Maybe<Scalars['Int']>;
  /** Max field Value. */
  maxValue: Maybe<Scalars['Int']>;
  /** The field Id. */
  id: Scalars['ID'];
  /** Field type. */
  type: FieldType;
  /** Field Name, Can be used as Label in AT and/or for validation and error messages. */
  name: Maybe<Scalars['String']>;
  /** Field Description Can be used as tooltip or watermark on a presentation level */
  description: Maybe<Scalars['String']>;
  /** Gets value which indicates whether this field is enabled in the current configuration. */
  isEnabled: Scalars['Boolean'];
  /** Label for front-end UI */
  label: Maybe<Scalars['String']>;
  /** Indicates whether the field with the field value needs to be submitted in SubmitWizardStepFieldValues API calls. */
  isSubmittable: Scalars['Boolean'];
  /**
   * client-based property bindings.
   *                     Bindings are exposed to the web app to form a kind of name-value collection of data items
   *                     required to property display a configurable field (that is called DataContext).
   */
  bindings: Maybe<Array<Maybe<Binding>>>;
  /** Field Behavior e.g Required, Readonly */
  fieldBehaviorMode: FieldBehaviorMode;
  /** This property stores the field input hint. Intended to be displayed by native apps on mobile devices. */
  placeholder: Maybe<Scalars['String']>;
};

export type InternalAccountGroup = {
  __typename?: 'InternalAccountGroup';
  /** The group name */
  name: Maybe<Scalars['String']>;
  /** Internal accounts list. */
  accounts: Array<InternalAccountView>;
};

export type InternalAccountView = {
  __typename?: 'InternalAccountView';
  /** The account identifier */
  id: Scalars['ID'];
  /** Indicates whether credit card or loan payment options has to be shown on transfer attempt. */
  isPaymentOptionsAvailable: Maybe<Scalars['Boolean']>;
  /**  Indicates whether one time scheduled payment is allowed for account. */
  isRecurringPaymentsAllowed: Maybe<Scalars['Boolean']>;
  /** Indicates whether recurring payments is allowed for accounts. */
  isScheduledPaymentAllowed: Maybe<Scalars['Boolean']>;
  /** Indicates whether current balance available for source or destination bank account according to configuration settings. */
  isCurrentBalanceVisible: Maybe<Scalars['Boolean']>;
  /** Indicates whether available balance available for source or destination bank account according to configuration settings. */
  isAvailableBalanceVisible: Maybe<Scalars['Boolean']>;
  /** Optional. Can contain DS customizable user notice message with interests when Credit Line is selected as source. */
  userNotice: Maybe<Scalars['String']>;
};

export type InternalBusinessBankingAccountGroup = {
  __typename?: 'InternalBusinessBankingAccountGroup';
  /** The group name */
  name: Maybe<Scalars['String']>;
  /** Internal accounts list. */
  accounts: Array<InternalBusinessBankingAccountView>;
};

export type InternalBusinessBankingAccountView = {
  __typename?: 'InternalBusinessBankingAccountView';
  /** The account identifier */
  id: Scalars['ID'];
  /** Indicates whether credit card or loan payment options has to be shown on transfer attempt. */
  isPaymentOptionsAvailable: Scalars['Boolean'];
  /**  Indicates whether one time scheduled payment is allowed for account. */
  isRecurringPaymentsAllowed: Scalars['Boolean'];
  /** Indicates whether recurring payments is allowed for accounts. */
  isScheduledPaymentAllowed: Scalars['Boolean'];
  /** Indicates whether current balance available for source or destination bank account according to configuration settings. */
  isCurrentBalanceVisible: Scalars['Boolean'];
  /** Indicates whether available balance available for source or destination bank account according to configuration settings. */
  isAvailableBalanceVisible: Scalars['Boolean'];
  /** Optional. Can contain DS customizable user notice message with interests when Credit Line is selected as source. */
  userNotice: Maybe<Scalars['String']>;
  /** Indicates eligible commercial banking category of this account. */
  eligibleBankingCategories: Array<CommercialBankingCategory>;
};

export type InternationalLocationPreference = {
  __typename?: 'InternationalLocationPreference';
  isOn: Scalars['Boolean'];
  id: Maybe<Scalars['String']>;
  countryCode: Maybe<Scalars['String']>;
};

export type InternationalLocationPreferences = {
  __typename?: 'InternationalLocationPreferences';
  isEnabled: Scalars['Boolean'];
  isOn: Scalars['Boolean'];
  countries: Array<InternationalLocationPreference>;
};

export type InvalidFormat = IValidationItem & {
  __typename?: 'InvalidFormat';
  /** Path E.g FiedlId/Name being validated */
  path: Maybe<Scalars['String']>;
  /** Field Format Expected Format */
  expectedFormat: Maybe<Scalars['String']>;
};

export type Invitation = {
  __typename?: 'Invitation';
  /** The first name of the sender. */
  senderFirstName: Scalars['String'];
  /** The last name of the sender. */
  senderLastName: Scalars['String'];
  /** The relationship. */
  relationship: Maybe<Scalars['String']>;
  /** The receiver name. */
  receiverName: Scalars['String'];
  /** The note. */
  note: Maybe<Scalars['String']>;
  /** The status of the invitation. */
  status: InviteStatus;
  /** The email of receiver. */
  email: Scalars['String'];
  /** The expiration time. */
  expired: Maybe<Scalars['DateTime']>;
  /** The flag to indicate if the receiver can enroll only as non-FI member. */
  canEnrollOnlyAsNonFIMember: Scalars['Boolean'];
};

export enum InviteStatus {
  /** Invitation has been just created. */
  NEW = 'NEW',
  /** Invitation has been sent to receiver. */
  SENT = 'SENT',
  /** Invitation has been accepted. */
  ACCEPTED = 'ACCEPTED',
  /** Invitation has been rejected. */
  REJECTED = 'REJECTED',
  /** Invitation has been expired. */
  EXPIRED = 'EXPIRED',
  /** Invitation has been removed. */
  REMOVED = 'REMOVED'
}

export type InvokeBackendComponentResult = {
  __typename?: 'InvokeBackendComponentResult';
  /** List of items returned by backend component invocation. */
  result: Maybe<Scalars['Any']>;
  /** Indicates if the action is successful */
  successful: Scalars['Boolean'];
  /** The list of errors */
  errors: Maybe<Array<ValidationItem>>;
  /** The list of warnings */
  warnings: Maybe<Array<ValidationItem>>;
  /** The list of infos */
  infos: Maybe<Array<ValidationItem>>;
};

export type InvokeExternalApiIntegrationResult = {
  __typename?: 'InvokeExternalApiIntegrationResult';
  result: Maybe<Scalars['Any']>;
  /** Indicates if the action is successful */
  successful: Scalars['Boolean'];
  /** The list of errors */
  errors: Maybe<Array<ValidationItem>>;
  /** The list of warnings */
  warnings: Maybe<Array<ValidationItem>>;
  /** The list of infos */
  infos: Maybe<Array<ValidationItem>>;
};

export type IsEligibleServiceResult = {
  __typename?: 'IsEligibleServiceResult';
  /** Is user eligible for the service or not. */
  isEligible: Maybe<Scalars['Boolean']>;
};

/** The label field info type. */
export type LabelFieldInfo = UiElementInfo & {
  __typename?: 'LabelFieldInfo';
  /** The field Id. */
  id: Scalars['ID'];
  /** Field type. */
  type: FieldType;
  /** Field Name, Can be used as Label in AT and/or for validation and error messages. */
  name: Maybe<Scalars['String']>;
  /** Field Description Can be used as tooltip or watermark on a presentation level */
  description: Maybe<Scalars['String']>;
  /** Gets value which indicates whether this field is enabled in the current configuration. */
  isEnabled: Scalars['Boolean'];
  /** Label for front-end UI */
  label: Maybe<Scalars['String']>;
  /** Indicates whether the field with the field value needs to be submitted in SubmitWizardStepFieldValues API calls. */
  isSubmittable: Scalars['Boolean'];
  /**
   * client-based property bindings.
   *                     Bindings are exposed to the web app to form a kind of name-value collection of data items
   *                     required to property display a configurable field (that is called DataContext).
   */
  bindings: Maybe<Array<Maybe<Binding>>>;
};

export type Lender = {
  __typename?: 'Lender';
  id: Maybe<Scalars['String']>;
  name: Scalars['String'];
  type: Maybe<Scalars['String']>;
  address1: Scalars['String'];
  address2: Maybe<Scalars['String']>;
  city: Scalars['String'];
  state: Scalars['String'];
  zip: Scalars['String'];
  accountNumber: Maybe<Scalars['String']>;
};

export type LenderInput = {
  id: Maybe<Scalars['String']>;
  name: Scalars['String'];
  type: Maybe<Scalars['String']>;
  address1: Scalars['String'];
  address2: Maybe<Scalars['String']>;
  city: Scalars['String'];
  state: Scalars['String'];
  zip: Scalars['String'];
  accountNumber: Maybe<Scalars['String']>;
};

export type LinkExternalAccountInput = {
  /** Account number. */
  accountNumber: Maybe<Scalars['String']>;
  /** External account owner name */
  accountOwnerName: Maybe<Scalars['String']>;
  /** External account type. */
  accountType: Maybe<Scalars['String']>;
  /** Name of bank where account was opened. */
  institutionName: Maybe<Scalars['String']>;
  /** Routing number of bank where account was opened. */
  routingNumber: Maybe<Scalars['String']>;
};

export type LinkExternalAccountResult = {
  __typename?: 'LinkExternalAccountResult';
  /** Validation result of operation. */
  validationResult: Maybe<ValidationResultContainer>;
};

export type LinkInstitution = {
  __typename?: 'LinkInstitution';
  linkId: Scalars['String'];
  institutionName: Scalars['String'];
};

export type LinkMenuItem = {
  __typename?: 'LinkMenuItem';
  /** Id of the Item */
  id: Maybe<Scalars['ID']>;
  /** The content item version id */
  contentItemVersionId: Maybe<Scalars['String']>;
  /** Type of content */
  contentType: Maybe<Scalars['String']>;
  /** The display text of the content item */
  displayText: Maybe<Scalars['String']>;
  /** Is the published version */
  published: Scalars['Boolean'];
  /** Is the latest version */
  latest: Scalars['Boolean'];
  /** The date and time of modification */
  modifiedUtc: Maybe<Scalars['DateTime']>;
  /** The date and time of publication */
  publishedUtc: Maybe<Scalars['DateTime']>;
  /** The date and time of creation */
  createdUtc: Maybe<Scalars['DateTime']>;
  /** The owner of the content item */
  owner: Maybe<Scalars['String']>;
  /** The author of the content item */
  author: Maybe<Scalars['String']>;
  /** Path to an icon */
  icon: Maybe<Scalars['String']>;
  /** Sub Menu Items */
  menuItems: Maybe<Array<Maybe<LinkMenuItem>>>;
  /** Link Name to Display */
  linkName: Maybe<Scalars['String']>;
  /** Link Name to Display */
  url: Maybe<Scalars['String']>;
};

export enum LoanType {
  UNKNOWN = 'UNKNOWN',
  CLOSEDEND = 'CLOSEDEND',
  OPENEND = 'OPENEND',
  LINEOFCREDIT = 'LINEOFCREDIT',
  CREDITCARD = 'CREDITCARD',
  LEASE = 'LEASE'
}

export type LocalizationCulture = {
  __typename?: 'LocalizationCulture';
  /** Culture code. */
  code: Scalars['String'];
  /** Culture name. */
  name: Scalars['String'];
};

export type LocationPreferences = {
  __typename?: 'LocationPreferences';
  myRegion: MyRegionPreferences;
  international: InternationalLocationPreferences;
};

export type LoginSettingsResult = {
  __typename?: 'LoginSettingsResult';
  /** The quick login settings. */
  quickLoginSettings: QuickLoginSettings;
  /** The password visibility control settings. */
  passwordVisibilityControlSettings: PasswordVisibilityControlSettings;
  /** The passkey login settings. */
  passkeyLoginSettings: PasskeyLoginSettings;
};

/** The user authenticated result. */
export type LoginWizardResultUnion = NextStep | AuthResult;

export type LogoutResult = {
  __typename?: 'LogoutResult';
  /** Url needed of redirection after logout */
  redirectUrl: Maybe<Scalars['String']>;
};


export type LookupLenderInput = {
  cardKey: Scalars['String'];
  lenderAccountNumber: Scalars['String'];
  lenderZip: Scalars['String'];
};

export type LookupLenderResult = {
  __typename?: 'LookupLenderResult';
  lender: Maybe<Lender>;
  successful: Scalars['Boolean'];
  errors: Maybe<Array<ValidationItem>>;
  warnings: Maybe<Array<ValidationItem>>;
  infos: Maybe<Array<ValidationItem>>;
};

export type MakeBusinessTransferInput = {
  /** Settlement date of transfer. */
  settlementDate: Scalars['DateTime'];
  /** ACH groups to make transfer. */
  achGroups: Array<BusinessTransferAchGroupInput>;
};

export type MakeBusinessTransferResult = {
  __typename?: 'MakeBusinessTransferResult';
  /** Indicates if the action is successful */
  successful: Scalars['Boolean'];
  /** The list of errors */
  errors: Maybe<Array<ValidationItem>>;
  /** The list of warnings */
  warnings: Maybe<Array<ValidationItem>>;
  /** The list of infos */
  infos: Maybe<Array<ValidationItem>>;
};

export type MakeExternalTransferResult = {
  __typename?: 'MakeExternalTransferResult';
  /** Unique identifier of the transfer registered in the core. */
  transactionId: Maybe<Scalars['ID']>;
  /** Validation result of operation. */
  validationResult: Maybe<ValidationResultContainer>;
};

export type MakeWireTransferInput = {
  /** Unique id of source bank bank account selected by user. */
  sourceBankAccountId: Scalars['ID'];
  /** Unique id of destination wire recipient account selected by user. */
  destinationWireRecipientId: Scalars['ID'];
  /** Unique id of payment option selected by user if supported. */
  paymentOptionId: Maybe<Scalars['ID']>;
  /** Payment amount. */
  amount: Scalars['Decimal'];
  /** Memo entered by user. */
  memo: Maybe<Scalars['String']>;
  /** Date selected by user for one time scheduling option. */
  scheduledDate: Maybe<Scalars['Date']>;
  /** Setting for recurring option selected by user. */
  recurringOptionSettings: Maybe<RecurringOptionSettingsInput>;
};

export type MakeWireTransferResult = {
  __typename?: 'MakeWireTransferResult';
  /** Indicates if the action is successful */
  successful: Scalars['Boolean'];
  /** The list of errors */
  errors: Maybe<Array<ValidationItem>>;
  /** The list of warnings */
  warnings: Maybe<Array<ValidationItem>>;
  /** The list of infos */
  infos: Maybe<Array<ValidationItem>>;
  /** Unique identifier of the transfer registered in the core. */
  transactionId: Maybe<Scalars['ID']>;
  /** Transaction status. See enum values for details. */
  transactionStatus: TransactionStatusType;
};

export type MapsSettings = {
  __typename?: 'MapsSettings';
  googleMapsApiKey: Maybe<Scalars['String']>;
};

export type MarkAsLostResult = {
  __typename?: 'MarkAsLostResult';
  successful: Scalars['Boolean'];
  errors: Maybe<Array<ValidationItem>>;
  warnings: Maybe<Array<ValidationItem>>;
  infos: Maybe<Array<ValidationItem>>;
};

/** The masked text field info type. */
export type MaskedTextFieldInfo = UiElementInfo & FieldInfoBase & {
  __typename?: 'MaskedTextFieldInfo';
  /** MaskFormat should conform following notation: http://docs.telerik.com/kendo-ui/api/web/maskedtextbox#configuration-mask */
  maskFormat: Scalars['String'];
  /** The field Id. */
  id: Scalars['ID'];
  /** Field type. */
  type: FieldType;
  /** Field Name, Can be used as Label in AT and/or for validation and error messages. */
  name: Maybe<Scalars['String']>;
  /** Field Description Can be used as tooltip or watermark on a presentation level */
  description: Maybe<Scalars['String']>;
  /** Gets value which indicates whether this field is enabled in the current configuration. */
  isEnabled: Scalars['Boolean'];
  /** Label for front-end UI */
  label: Maybe<Scalars['String']>;
  /** Indicates whether the field with the field value needs to be submitted in SubmitWizardStepFieldValues API calls. */
  isSubmittable: Scalars['Boolean'];
  /**
   * client-based property bindings.
   *                     Bindings are exposed to the web app to form a kind of name-value collection of data items
   *                     required to property display a configurable field (that is called DataContext).
   */
  bindings: Maybe<Array<Maybe<Binding>>>;
  /** Field Behavior e.g Required, Readonly */
  fieldBehaviorMode: FieldBehaviorMode;
  /** This property stores the field input hint. Intended to be displayed by native apps on mobile devices. */
  placeholder: Maybe<Scalars['String']>;
  /** Max length of the field. */
  maxLength: Scalars['Int'];
  /** Min length of the field. */
  minLength: Scalars['Int'];
  /** The value of the field. */
  value: Maybe<Scalars['String']>;
};

export type MemberAccount = {
  __typename?: 'MemberAccount';
  number: Scalars['String'];
  displayName: Scalars['String'];
};

export type Menu = {
  __typename?: 'Menu';
  /** Content item id */
  contentItemId: Maybe<Scalars['ID']>;
  /** Id of the Item */
  id: Maybe<Scalars['ID']>;
  /** The content item version id */
  contentItemVersionId: Maybe<Scalars['String']>;
  /** Type of content */
  contentType: Maybe<Scalars['String']>;
  /** The display text of the content item */
  displayText: Maybe<Scalars['String']>;
  /** Is the published version */
  published: Scalars['Boolean'];
  /** Is the latest version */
  latest: Scalars['Boolean'];
  /** The date and time of modification */
  modifiedUtc: Maybe<Scalars['DateTime']>;
  /** The date and time of publication */
  publishedUtc: Maybe<Scalars['DateTime']>;
  /** The date and time of creation */
  createdUtc: Maybe<Scalars['DateTime']>;
  /** The owner of the content item */
  owner: Maybe<Scalars['String']>;
  /** The author of the content item */
  author: Maybe<Scalars['String']>;
  menuItems: Maybe<Array<Maybe<LinkMenuItem>>>;
};

export type MenuItem = {
  __typename?: 'MenuItem';
  /** Menu item ID. */
  id: Maybe<Scalars['String']>;
  /** Link Name to Display. */
  linkName: Maybe<Scalars['String']>;
  /** Menu icon. */
  icon: Maybe<Scalars['String']>;
  /** Menu link URL. */
  url: Maybe<Scalars['String']>;
  /** Eligibility check required before using this menu item. */
  isEligibilityCheckRequired: Maybe<Scalars['Boolean']>;
  eligibilityCheckTag: Maybe<EligibilityCheckTag>;
  /** Sub menu items if any. */
  menuItems: Maybe<Array<Maybe<MenuItem>>>;
  visibilityCheckTag: Maybe<VisibilityCheckTag>;
};

export type MenuItemEligibilityStatus = {
  __typename?: 'MenuItemEligibilityStatus';
  /** Service type. The value that is returned by "EligibilityCheckTag.ServiceType". */
  serviceType: Maybe<ServiceType>;
  /** Service name. The value that is returned by "EligibilityCheckTag.ServiceName". */
  serviceName: Maybe<Scalars['String']>;
  /** Is user eligible for the service or not. */
  isEligible: Maybe<Scalars['Boolean']>;
};

export type MenuRoot = {
  __typename?: 'MenuRoot';
  /** Menu root ID. */
  id: Maybe<Scalars['String']>;
  /** Sub menu items if any. */
  menuItems: Maybe<Array<Maybe<MenuItem>>>;
};

export type MerchantPreferenceType = {
  __typename?: 'MerchantPreferenceType';
  isOn: Scalars['Boolean'];
  code: Scalars['String'];
};

export type MerchantPreferenceTypeInput = {
  isOn: Scalars['Boolean'];
  code: Scalars['String'];
};

export type MerchantPreferences = {
  __typename?: 'MerchantPreferences';
  isEnabled: Scalars['Boolean'];
  types: Array<MerchantPreferenceType>;
};

/** Secure message or alert representation. */
export type Message = {
  __typename?: 'Message';
  /** Unique secure message or alert identifier. */
  id: Scalars['String'];
  /** SecureMessage or Alert. */
  type: Scalars['String'];
  /** New, Read or Replied. */
  status: Scalars['String'];
  /** Unique folder identifier. */
  folderId: Scalars['String'];
  /** Date when message or alert was created. */
  sentDate: Scalars['DateTime'];
  /** Date as string when message or alert was created. */
  sentDateText: Scalars['String'];
  /** None, Reply, Delete or All. */
  permissions: Scalars['String'];
  /** Secure message or alert sender. */
  from: CommunicationParty;
  /** Secure message or alert subject. */
  subject: Scalars['String'];
  /** Secure message or alert text. */
  text: Scalars['String'];
  /** Flag whether secure message is in HTML format. */
  isHtml: Scalars['Boolean'];
  /** Unique secure message identifier (in case this is a reply message). */
  inReplyToMessageId: Maybe<Scalars['String']>;
  /** List of attachment. */
  attachments: Maybe<Array<Attachment>>;
};

export type MessageConfigurationResponse = {
  __typename?: 'MessageConfigurationResponse';
  allowedExtensions: Array<Scalars['String']>;
  allowedMimeTypes: Array<Scalars['String']>;
  initialMessageLoad: Scalars['Int'];
  initialAttachmentsLoad: Scalars['Int'];
  secureMessageMaximumSizeInMb: Scalars['Int'];
  areBinaryAttachmentsEnabled: Scalars['Boolean'];
  areAccountSummaryAttachmentsEnabled: Scalars['Boolean'];
  areEventLogAttachmentsEnabled: Scalars['Boolean'];
  areTransactionRecordAttachmentsEnabled: Scalars['Boolean'];
};

/** Message folder representation. */
export type MessageFolder = {
  __typename?: 'MessageFolder';
  /** Unique message folder identifier. */
  id: Scalars['String'];
  /** Message folder name. */
  name: Scalars['String'];
  /** Message folder alias (technical name to be used on FE to select icons). */
  alias: Scalars['String'];
  /** Unread messages and/or alerts count. */
  unreadCount: Scalars['Int'];
  /** In or Out. */
  kind: Scalars['String'];
  /** Sub-folders (if any). */
  subFolders: Maybe<Array<MessageFolder>>;
};

/** The recipient for sending a new message. */
export type MessageRecipient = {
  __typename?: 'MessageRecipient';
  /** Secure message recipient. */
  recipient: CommunicationParty;
  /** List of templates by department. */
  templates: Maybe<Array<MessageTemplate>>;
};

/** Container for department template */
export type MessageTemplate = {
  __typename?: 'MessageTemplate';
  /** Subject of message template. */
  subject: Scalars['String'];
  /** Body of message template. */
  body: Maybe<Scalars['String']>;
};

/** The move previous command result. */
export type MovePreviousResult = {
  __typename?: 'MovePreviousResult';
  /** The current wizard step Id. */
  currentStepId: Scalars['String'];
  /** Indicates whether the wizard was moved. */
  isMoved: Scalars['Boolean'];
  /** Success Summary Message. */
  successSummary: Array<Maybe<Scalars['String']>>;
  /** List of errors. */
  errors: Array<Maybe<ValidationUnion>>;
  /** List of warnings. */
  warnings: Array<Maybe<ValidationUnion>>;
};

export type MovePreviousTriggeredEventStepParams = {
  /** The triggered event challenge Id. */
  challengeId: Scalars['String'];
};

/** The multi choice field info base type. */
export type MultipleChoiceFieldInfo = UiElementInfo & FieldInfoBase & {
  __typename?: 'MultipleChoiceFieldInfo';
  /** Default Selected Option Text. */
  selectedValues: Maybe<Array<Maybe<MultipleChoiceSelection>>>;
  /** The field Id. */
  id: Scalars['ID'];
  /** Field type. */
  type: FieldType;
  /** Field Name, Can be used as Label in AT and/or for validation and error messages. */
  name: Maybe<Scalars['String']>;
  /** Field Description Can be used as tooltip or watermark on a presentation level */
  description: Maybe<Scalars['String']>;
  /** Gets value which indicates whether this field is enabled in the current configuration. */
  isEnabled: Scalars['Boolean'];
  /** Label for front-end UI */
  label: Maybe<Scalars['String']>;
  /** Indicates whether the field with the field value needs to be submitted in SubmitWizardStepFieldValues API calls. */
  isSubmittable: Scalars['Boolean'];
  /**
   * client-based property bindings.
   *                     Bindings are exposed to the web app to form a kind of name-value collection of data items
   *                     required to property display a configurable field (that is called DataContext).
   */
  bindings: Maybe<Array<Maybe<Binding>>>;
  /** Field Behavior e.g Required, Readonly */
  fieldBehaviorMode: FieldBehaviorMode;
  /** This property stores the field input hint. Intended to be displayed by native apps on mobile devices. */
  placeholder: Maybe<Scalars['String']>;
  /** Choose list. */
  choiceList: Maybe<Array<Maybe<Choice>>>;
};

export type MultipleChoiceSelection = {
  __typename?: 'MultipleChoiceSelection';
  /** Selected Index */
  indexId: Maybe<Scalars['Int']>;
  /** Selected Value */
  value: Maybe<Scalars['String']>;
};

export type Mutation = {
  __typename?: 'Mutation';
  /** Set advertisement status. */
  setAdvertisementStatus: OperationResult;
  /** Attach image to statement transaction */
  attachImage: AttachStatementTransactionImageResult;
  /** Remove attached image from statement transaction */
  removeAttachedImage: ValidatingOperationResult;
  /** Set statement transaction note field */
  setTransactionNote: ValidatingOperationResult;
  /** Create user account group */
  createUserGroup: ValidatingOperationResult;
  /** Delete user account group by ID */
  deleteUserGroup: ValidatingOperationResult;
  /** Change name for user account group */
  changeUserGroupName: ValidatingOperationResult;
  /** Change user groups order */
  changeUserGroupsOrder: GetUserAccountGroupsResult;
  /** Move bank account to a group and change its order position */
  moveBankAccount: Scalars['Byte'];
  /** Change bank account name */
  setBankAccountName: SetBankAccountNameResult;
  /** Change consumer nickname */
  setConsumerNickname: Scalars['Byte'];
  setBankAccountVisibility: Scalars['Byte'];
  setConsumerVisibility: Scalars['Byte'];
  /** Link internal account request */
  linkInternalAccount: ValidatingOperationResult;
  /** Set ODP opt-in or opt-out */
  setOverdraftProtectionSettings: SetOverdraftProtectionOptInSettingsResult;
  /** Start linking institution via Plaid */
  startLinkInstitution: StartLinkInstitutionResult;
  /** End linking institution via Plaid */
  endLinkInstitution: EndLinkInstitutionResult;
  /** unlinking institution via Plaid */
  unlinkInstitution: UnlinkInstitutionResult;
  /** Relinking institution via Plaid */
  relinkInstitution: RelinkInstitutionResult;
  /** Delete business banking recipient */
  deleteBusinessBankingRecipient: DeleteRecipientResult;
  /** Create business banking recipient */
  createBusinessBankingRecipient: CreateRecipientResult;
  /** Upload csv/NACHA file for processing */
  uploadTransferFile: UploadTransferFileResult;
  /** Update business banking recipient details */
  updateBusinessBankingRecipientDetails: UpdateRecipientDetailsResult;
  /** Update business banking recipient data */
  updateBusinessBankingRecipient: UpdateRecipientResult;
  /** Update business banking template data */
  updateBusinessBankingTemplate: UpdateTemplateResult;
  /** Creates a business banking template */
  createBusinessBankingTemplate: CreateTemplateResult;
  /** Delete business banking template */
  deleteBusinessBankingTemplate: DeleteTemplateResult;
  /** Make business transfer */
  makeBusinessTransfer: MakeBusinessTransferResult;
  /** Validate business banking transfer operation */
  validateBusinessBankingTransfer: ValidateTransferResult;
  /** Execute business banking ACH transfer operation */
  executeBusinessBankingTransfer: ExecuteTransferResult;
  /** Send One-Off Invoice */
  sendOneOffInvoice: SendOneOffInvoiceResult;
  /** Set card controls status */
  setCardControlsStatus: SetCardControlsStatusResult;
  /** Set card merchant controls status */
  setCardMerchantControlsStatus: SetCardMerchantControlsStatusResult;
  /** Set card transaction controls status */
  setCardTransactionControlsStatus: SetCardTransactionControlsStatusResult;
  /** Set card spend limit controls status */
  setCardSpendLimitControlsStatus: SetCardSpendLimitControlsStatusResult;
  /** Set card alerts status */
  setCardAlertsStatus: SetCardAlertsStatusResult;
  /** Set all card transactions alerts status */
  setAllCardTransactionsAlerts: SetAllCardTransactionsAlertsResult;
  /** Set card transaction alerts status */
  setCardTransactionAlertsStatus: SetCardTransactionAlertsStatusResult;
  /** Set card merchant alerts status */
  setCardMerchantAlertsStatus: SetCardMerchantAlertsStatusResult;
  /** Set card spend limit alerts status */
  setCardSpendLimitAlertsStatus: SetCardSpendLimitAlertsStatusResult;
  /** Set card internation transactions alerts status */
  setCardInternationalLocationAlerts: SetCardInternationalLocationAlertsResult;
  /** Add card my-region location alert status */
  addCardMyRegionLocationAlert: AddCardMyRegionLocationAlertResult;
  /** Update card my-region location alert status */
  updateCardMyRegionLocationAlert: UpdateCardMyRegionLocationAlertResult;
  /** Delete card my-region location alert status */
  deleteCardMyRegionLocationAlert: DeleteCardMyRegionLocationAlertResult;
  /** Set card internation transactions controls status */
  setCardInternationalLocationControls: SetCardInternationalLocationControlsResult;
  /** Add card international location control */
  addCardInternationalLocationControl: AddCardInternationalLocationControlResult;
  /** Delete card international location control */
  deleteCardInternationalLocationControl: DeleteCardInternationalLocationControlResult;
  /** Add card my-region location control status */
  addCardMyRegionLocationControl: AddCardMyRegionLocationControlResult;
  /** Update card my-region location control status */
  updateCardMyRegionLocationControl: UpdateCardMyRegionLocationControlResult;
  /** Delete card my-region location control status */
  deleteCardMyRegionLocationControl: DeleteCardMyRegionLocationControlResult;
  /** Activate card */
  activateCard: ActivateCardResult;
  /** Set card limits */
  setCardLimits: SetCardLimitsResult;
  /** Mark card as lost (Mark Lost and Don't Reorder) */
  markAsLost: MarkAsLostResult;
  /** Reorder new card (Mark Lost and Reorder) */
  reorderNewCard: ReorderNewCardResult;
  /** Reorder same card (Request Replacement Card by Mail) */
  reorderSameCard: ReorderSameCardResult;
  /** Set card pin */
  setCardPin: SetCardPinResult;
  /** Set card status (enable/disable) */
  setCardStatus: SetCardStatusResult;
  /** Update billing address) */
  updateBillingAddress: UpdateBillingAddressResult;
  /** Delete travel */
  deleteTravel: DeleteTravelResult;
  /** Save travel */
  saveTravel: SaveTravelResult;
  /** Set card alerts delivery channels */
  setCardAlertsDeliveryChannels: SetCardAlertsDeliveryChannelsResult;
  /** Consolidate balance from one card to another */
  consolidateBalance: ConsolidateBalanceResult;
  /** Toggles security alert on/off */
  toggleAlertType: Maybe<ValidatingOperationResult>;
  /** Toggles bank-account-related alert on/off */
  toggleAlert: ValidatingOperationResult;
  /** Deletes bank-account-related alert */
  deleteAlert: ValidatingOperationResult;
  /** Creates or updates alert. */
  saveAlert: Maybe<SaveAlertResponse>;
  /** Saves delivery options for given alerts group. */
  saveDeliveryOptions: Maybe<SaveDeliveryOptionsResponse>;
  /** Uploads attachment for secure message */
  uploadAttachment: AttachmentUploadResponse;
  /** Uploads attachment for secure message */
  deleteAttachment: ValidatingOperationResult;
  /** Creates and sends new secure message. */
  setMessageStatus: Maybe<ValidatingOperationResult>;
  /** Creates and sends new secure message. */
  sendMessage: Maybe<ValidatingOperationResult>;
  /** Removes user's address. */
  removeUserAddress: Maybe<ValidatingOperationResult>;
  /** Removes user's email or phone. */
  removeUserContact: Maybe<ValidatingOperationResult>;
  /** Creates or updates user's address or email or phone. */
  submitUserContactFields: Maybe<SubmitUserContactFieldsResult>;
  /** Changes the user password. */
  setUserPassword: Maybe<SetUserPasswordResult>;
  /** Send request to sms enroll user's mobile phone. */
  sendSmsEnrollment: Maybe<SmsEnrollmentResult>;
  /** Confirm user's phone for used in SMS. */
  confirmSmsEnrollment: Maybe<SmsEnrollmentResult>;
  /** Remove user's phone for used in SMS. */
  removeSmsEnrollment: Maybe<SmsEnrollmentResult>;
  /** Updates user time zone. */
  timeZone: SetTimeZoneResult;
  /** Set Credit score opt-in or opt-out */
  setCreditScoreSettings: Scalars['Byte'];
  /** Add user custom data. */
  addUserCustomData: AddUserCustomDataResult;
  /** Update user custom data. */
  updateUserCustomData: UpdateUserCustomDataResult;
  /** Set optIn/optOut Estatement setting */
  setEstatementSettingOptIn: SetOptInSettingResult;
  /** Save sub-user permissions */
  saveSubuserPermissions: Scalars['Byte'];
  /** Resend sub-user invite */
  resendSubuserInvite: Scalars['Byte'];
  /** Delete sub-user or invite */
  deleteSubuserInfo: Scalars['Byte'];
  /** Updates localization culture preferred by user. */
  setPreferredCulture: SetPreferredCultureResult;
  /** Submit Login Fields */
  loginStep: Maybe<LoginWizardResultUnion>;
  /** Logs out user and returns redirect url. */
  logout: Maybe<LogoutResult>;
  /** Heartbeat needed to keep alive DS session. */
  heartbeat: Maybe<HeartbeatResult>;
  /** Move previous step. */
  movePreviousLoginStep: MovePreviousResult;
  /** Issue Active user's Front-Back token  for Mobile App/MFM MW */
  tokenExchange: Maybe<ExchangeUserTokenResult>;
  /** VerifyRegistrationMutation */
  verifyRegistration: VerifyRegistrationResult;
  /** VerifyAuthenticationMutation */
  verifyAuthentication: VerifyAuthenticationResult;
  /** Open bank account */
  openBankAccount: OpenBankAccountResult;
  /** Submit retrieval step Fields */
  retrievalStep: Maybe<RetrievalWizardResultUnion>;
  /** Create payment between selected accounts */
  validateInternalPayment: ValidateInternalPaymentResult;
  /** Create payment between selected accounts */
  addInternalPayment: AddInternalPaymentResult;
  /** Confirm external account */
  confirmExternalAccount: ConfirmExternalAccountResult;
  /** Delete external account */
  deleteExternalAccount: DeleteExternalAccountResult;
  /** Link external account */
  linkExternalAccount: LinkExternalAccountResult;
  /** Make External Transfer */
  makeExternalTransfer: MakeExternalTransferResult;
  /** Validate skip regular loan/credit card payment. */
  validateSkipPay: ValidateSkipPayResult;
  /** Skip regular loan/credit card payment. */
  skipPay: SkipPayResult;
  /** Create payment between selected accounts */
  validateM2MPayment: ValidateM2MPaymentResult;
  /** Create payment between selected accounts */
  addM2MPayment: AddM2MPaymentResult;
  /** Add new member to member payee. */
  addPayee: AddPayeeResult;
  /** Edit member to member payee. */
  editPayee: EditPayeeResult;
  /** Delete payee. */
  deletePayee: DeletePayeeResult;
  /** Add complete transfer to favorites */
  addFavorite: AddFavoriteResult;
  /** Remove existing favorite transfer */
  removeFavorite: RemoveFavoriteResult;
  /** Validate payment between selected accounts */
  validateInvestmentPayment: ValidateInvestmentPaymentResult;
  /** Create payment between selected accounts */
  addInvestmentPayment: AddInvestmentPaymentResult;
  /** Stops check by check number */
  stopCheck: StopCheckResult;
  /** Returns id of added recipient */
  newWireRecipient: AddWireRecipientResult;
  /** Delete wire recipient */
  deleteWireRecipient: DeleteWireRecipientResult;
  /** Make Wire Transfer */
  makeWireTransfer: MakeWireTransferResult;
  /** Returns id of added external card */
  addExternalCard: AddExternalCardResult;
  /** Validate pay by card payment between selected accounts */
  validatePayByCardPayment: ValidatePayByCardPaymentResult;
  addPayByCardPayment: AddPayByCardPaymentResult;
  /** Submit selected account and/or additional fields */
  accountSelectorStep: Maybe<SsoUniversalAccountSelectorWizardResultUnion>;
  /** Register the invite token for subuser enrollment. */
  registerSubuserEnrollmentInviteToken: Maybe<RegisterSubuserEnrollmentInviteTokenResult>;
  /** Reject the invite token for subuser enrollment. */
  rejectSubuserEnrollmentInviteToken: Maybe<RejectSubuserEnrollmentInviteTokenResult>;
  /** Submit SubUser Enrollment step Fields */
  subuserEnrollmentStep: Maybe<SubuserEnrollmentWizardResultUnion>;
  /** Move previous step. */
  movePreviousSubuserEnrollmentStep: Maybe<MovePreviousResult>;
  /** Approve subuser transfer. */
  approveSubuserTransfer: Maybe<ApproveSubuserTransferResult>;
  /** Reject subuser transfer. */
  rejectSubuserTransfer: Maybe<RejectSubuserTransferResult>;
  /** Update transfer. */
  updateTransfer: UpdateTransferResult;
  /** Cancel transfer. */
  cancelTransfer: CancelTransferResult;
  /** Submit User Enrollment step Fields */
  userEnrollmentStep: Maybe<EnrollmentWizardResultUnion>;
  /** Move previous step. */
  movePreviousUserEnrollmentStep: Maybe<MovePreviousResult>;
  /** Wizard heartbeat needed to keep alive wizard session. */
  wizardHeartbeat: Maybe<HeartbeatResult>;
  /** Submit user kind step Fields */
  userKindStep: Maybe<UserKindWizardResultUnion>;
  /** Confirm user's email address. */
  confirmEmail: Maybe<ConfirmEmailResult>;
  /** Sets user self-locked status. */
  selfLock: ValidatingOperationResult;
  /** Submit triggered event step Fields */
  triggeredEventStep: Maybe<TriggeredEventResultUnion>;
  /** Move previous step. */
  movePreviousTriggeredEventStep: MovePreviousResult;
};


export type MutationSetAdvertisementStatusArgs = {
  providerId: Scalars['String'];
  adId: Scalars['String'];
  status: AdvertisementStatus;
};


export type MutationAttachImageArgs = {
  transactionId: Scalars['ID'];
  data: Maybe<Scalars['Upload']>;
  fileName: Scalars['String'];
  mimeType: Scalars['String'];
};


export type MutationRemoveAttachedImageArgs = {
  imageKey: Scalars['ID'];
};


export type MutationSetTransactionNoteArgs = {
  data: SetStatementTransactionNoteInput;
};


export type MutationCreateUserGroupArgs = {
  groupName: Scalars['String'];
};


export type MutationDeleteUserGroupArgs = {
  groupId: Scalars['String'];
};


export type MutationChangeUserGroupNameArgs = {
  data: ChangeAccountGroupNameInput;
};


export type MutationChangeUserGroupsOrderArgs = {
  data: Array<Scalars['String']>;
};


export type MutationMoveBankAccountArgs = {
  bankAccountId: Scalars['String'];
  oldGroupId: Maybe<Scalars['String']>;
  newGroupId: Maybe<Scalars['String']>;
  orderedBankAccountIds: Array<Scalars['String']>;
};


export type MutationSetBankAccountNameArgs = {
  data: SetBankAccountNameInput;
};


export type MutationSetConsumerNicknameArgs = {
  data: SetConsumerNicknameInput;
};


export type MutationSetBankAccountVisibilityArgs = {
  data: SetBankAccountVisibilityInput;
};


export type MutationSetConsumerVisibilityArgs = {
  data: SetConsumerVisibilityInput;
};


export type MutationLinkInternalAccountArgs = {
  memberNumber: Scalars['String'];
  membershipNickname: Maybe<Scalars['String']>;
};


export type MutationSetOverdraftProtectionSettingsArgs = {
  data: SetOverdraftProtectionOptInSettingsInput;
};


export type MutationEndLinkInstitutionArgs = {
  data: EndLinkInstitutionInput;
};


export type MutationUnlinkInstitutionArgs = {
  linkId: Scalars['String'];
};


export type MutationRelinkInstitutionArgs = {
  linkId: Scalars['String'];
};


export type MutationDeleteBusinessBankingRecipientArgs = {
  id: Scalars['Int'];
  contextUserId: Scalars['Long'];
};


export type MutationCreateBusinessBankingRecipientArgs = {
  recipient: RecipientInput;
  contextUserId: Scalars['Long'];
};


export type MutationUploadTransferFileArgs = {
  data: Maybe<Scalars['Upload']>;
  fileName: Scalars['String'];
};


export type MutationUpdateBusinessBankingRecipientDetailsArgs = {
  recipientId: Scalars['Long'];
  recipientDetails: RecipientDetailsInput;
};


export type MutationUpdateBusinessBankingRecipientArgs = {
  recipientId: Scalars['Long'];
  recipient: RecipientInput;
  contextUserId: Scalars['Long'];
};


export type MutationUpdateBusinessBankingTemplateArgs = {
  templateId: Scalars['Long'];
  template: TemplateInput;
  contextUserId: Scalars['Long'];
};


export type MutationCreateBusinessBankingTemplateArgs = {
  template: TemplateInput;
  contextUserId: Scalars['Long'];
};


export type MutationDeleteBusinessBankingTemplateArgs = {
  id: Scalars['Long'];
  contextUserId: Scalars['Long'];
};


export type MutationMakeBusinessTransferArgs = {
  data: MakeBusinessTransferInput;
};


export type MutationValidateBusinessBankingTransferArgs = {
  data: ValidateTransferInput;
  contextUserId: Scalars['Long'];
};


export type MutationExecuteBusinessBankingTransferArgs = {
  contextUserId: Scalars['Long'];
  data: ExecuteTransferInput;
};


export type MutationSendOneOffInvoiceArgs = {
  contextUserId: Scalars['Long'];
  data: SendOneOffInvoiceInput;
};


export type MutationSetCardControlsStatusArgs = {
  data: SetCardControlsStatusInput;
};


export type MutationSetCardMerchantControlsStatusArgs = {
  data: SetCardMerchantControlsStatusInput;
};


export type MutationSetCardTransactionControlsStatusArgs = {
  data: SetCardTransactionControlsStatusInput;
};


export type MutationSetCardSpendLimitControlsStatusArgs = {
  data: SetCardSpendLimitControlsStatusInput;
};


export type MutationSetCardAlertsStatusArgs = {
  data: SetCardAlertsStatusInput;
};


export type MutationSetAllCardTransactionsAlertsArgs = {
  data: SetAllCardTransactionsAlertsInput;
};


export type MutationSetCardTransactionAlertsStatusArgs = {
  data: SetCardTransactionAlertsStatusInput;
};


export type MutationSetCardMerchantAlertsStatusArgs = {
  data: SetCardMerchantAlertsStatusInput;
};


export type MutationSetCardSpendLimitAlertsStatusArgs = {
  data: SetCardSpendLimitAlertsStatusInput;
};


export type MutationSetCardInternationalLocationAlertsArgs = {
  data: SetCardInternationalLocationAlertsInput;
};


export type MutationAddCardMyRegionLocationAlertArgs = {
  data: AddCardMyRegionLocationAlertInput;
};


export type MutationUpdateCardMyRegionLocationAlertArgs = {
  data: UpdateCardMyRegionLocationAlertInput;
};


export type MutationDeleteCardMyRegionLocationAlertArgs = {
  data: DeleteCardMyRegionLocationAlertInput;
};


export type MutationSetCardInternationalLocationControlsArgs = {
  data: SetCardInternationalLocationControlsInput;
};


export type MutationAddCardInternationalLocationControlArgs = {
  data: AddCardInternationalLocationControlInput;
};


export type MutationDeleteCardInternationalLocationControlArgs = {
  data: DeleteCardInternationalLocationControlInput;
};


export type MutationAddCardMyRegionLocationControlArgs = {
  data: AddCardMyRegionLocationControlInput;
};


export type MutationUpdateCardMyRegionLocationControlArgs = {
  data: UpdateCardMyRegionLocationControlInput;
};


export type MutationDeleteCardMyRegionLocationControlArgs = {
  data: DeleteCardMyRegionLocationControlInput;
};


export type MutationActivateCardArgs = {
  data: ActivateCardInput;
};


export type MutationSetCardLimitsArgs = {
  data: SetCardLimitsInput;
};


export type MutationMarkAsLostArgs = {
  cardKey: Scalars['ID'];
};


export type MutationReorderNewCardArgs = {
  cardKey: Scalars['ID'];
  deliveryMethod: Maybe<DeliveryCardMethod>;
};


export type MutationReorderSameCardArgs = {
  cardKey: Scalars['ID'];
};


export type MutationSetCardPinArgs = {
  data: SetCardPinInput;
};


export type MutationSetCardStatusArgs = {
  data: SetCardStatusInput;
};


export type MutationUpdateBillingAddressArgs = {
  data: UpdateBillingAddressInput;
};


export type MutationDeleteTravelArgs = {
  travelKey: Scalars['ID'];
};


export type MutationSaveTravelArgs = {
  data: SaveTravelInput;
};


export type MutationSetCardAlertsDeliveryChannelsArgs = {
  data: SetCardAlertsDeliveryChannelsInput;
};


export type MutationConsolidateBalanceArgs = {
  data: ConsolidateBalanceInput;
};


export type MutationToggleAlertTypeArgs = {
  toggleState: Scalars['Boolean'];
  alertType: Scalars['String'];
};


export type MutationToggleAlertArgs = {
  toggleState: Scalars['Boolean'];
  alertSettingsIds: Array<Scalars['String']>;
};


export type MutationDeleteAlertArgs = {
  alertSettingsIds: Array<Scalars['String']>;
};


export type MutationSaveAlertArgs = {
  data: SaveAlertInput;
};


export type MutationSaveDeliveryOptionsArgs = {
  data: SaveDeliveryOptionsInput;
};


export type MutationUploadAttachmentArgs = {
  data: Maybe<Scalars['Upload']>;
  fileName: Scalars['String'];
  mimeType: Scalars['String'];
};


export type MutationDeleteAttachmentArgs = {
  fileId: Scalars['ID'];
};


export type MutationSetMessageStatusArgs = {
  data: SetMessageStatusInput;
};


export type MutationSendMessageArgs = {
  data: NewMessage;
};


export type MutationRemoveUserAddressArgs = {
  addressId: Scalars['ID'];
};


export type MutationRemoveUserContactArgs = {
  contactId: Scalars['ID'];
  contactType: ContactType;
};


export type MutationSubmitUserContactFieldsArgs = {
  data: SubmitContactFieldsInput;
};


export type MutationSetUserPasswordArgs = {
  setUserPasswordInput: SetUserPasswordInput;
};


export type MutationSendSmsEnrollmentArgs = {
  mobilePhoneId: Scalars['ID'];
};


export type MutationConfirmSmsEnrollmentArgs = {
  mobilePhoneId: Scalars['ID'];
  setOneTimePasswordInput: Scalars['String'];
};


export type MutationRemoveSmsEnrollmentArgs = {
  mobilePhoneId: Scalars['ID'];
};


export type MutationTimeZoneArgs = {
  data: UserTimeZoneInput;
};


export type MutationSetCreditScoreSettingsArgs = {
  data: SetCreditScoreOptInSettingsInput;
};


export type MutationAddUserCustomDataArgs = {
  scope: Scalars['String'];
  data: Scalars['String'];
};


export type MutationUpdateUserCustomDataArgs = {
  scope: Scalars['String'];
  data: Scalars['String'];
};


export type MutationSetEstatementSettingOptInArgs = {
  data: SetOptInSettingInput;
};


export type MutationSaveSubuserPermissionsArgs = {
  subuserInfo: SubuserInfoInput;
  accountsPermissions: Array<BankAccountPermissionsInput>;
  transfersPermissions: Array<TransferPermissionsInput>;
  ssoPermissions: Array<SsoPermissionsInput>;
  businessGroupsAccessLevel: Maybe<BusinessGroupsAccessLevel>;
  isDualApprover: Maybe<Scalars['Boolean']>;
  allowViewAchHistory: Maybe<Scalars['Boolean']>;
};


export type MutationResendSubuserInviteArgs = {
  subuserKey: Scalars['ID'];
};


export type MutationDeleteSubuserInfoArgs = {
  subuserKey: Scalars['ID'];
};


export type MutationSetPreferredCultureArgs = {
  preferredCulture: Scalars['String'];
};


export type MutationLoginStepArgs = {
  submitWizardStepParams: SubmitWizardStepParams;
};


export type MutationTokenExchangeArgs = {
  userSessionData: TokenExchangeInput;
};


export type MutationVerifyRegistrationArgs = {
  verifyRegistrationParams: VerifyRegistrationParams;
};


export type MutationVerifyAuthenticationArgs = {
  verifyAuthenticationParams: VerifyAuthenticationParams;
};


export type MutationOpenBankAccountArgs = {
  data: OpenBankAccountInput;
};


export type MutationRetrievalStepArgs = {
  submitWizardStepParams: SubmitRetrievalWizardStepParams;
};


export type MutationValidateInternalPaymentArgs = {
  data: ValidateInternalPaymentInput;
};


export type MutationAddInternalPaymentArgs = {
  data: AddInternalPaymentInput;
};


export type MutationConfirmExternalAccountArgs = {
  data: ConfirmExternalAccountInput;
};


export type MutationDeleteExternalAccountArgs = {
  data: DeleteExternalAccountInput;
};


export type MutationLinkExternalAccountArgs = {
  data: LinkExternalAccountInput;
};


export type MutationMakeExternalTransferArgs = {
  data: ExternalTransferInput;
};


export type MutationValidateSkipPayArgs = {
  data: ValidateSkipPayInput;
};


export type MutationSkipPayArgs = {
  data: SkipPayInput;
};


export type MutationValidateM2MPaymentArgs = {
  data: ValidateM2MPaymentInput;
};


export type MutationAddM2MPaymentArgs = {
  data: AddM2MPaymentInput;
};


export type MutationAddPayeeArgs = {
  data: AddPayeeInput;
};


export type MutationEditPayeeArgs = {
  data: EditPayeeInput;
};


export type MutationDeletePayeeArgs = {
  data: DeletePayeeInput;
};


export type MutationAddFavoriteArgs = {
  data: AddFavoriteInput;
};


export type MutationRemoveFavoriteArgs = {
  data: RemoveFavoriteInput;
};


export type MutationValidateInvestmentPaymentArgs = {
  data: ValidateInvestmentPaymentInput;
};


export type MutationAddInvestmentPaymentArgs = {
  data: AddInvestmentPaymentInput;
};


export type MutationStopCheckArgs = {
  data: StopCheckInput;
};


export type MutationNewWireRecipientArgs = {
  data: CreateNewWireRecipientQueryInput;
};


export type MutationDeleteWireRecipientArgs = {
  data: DeleteWireRecipientInput;
};


export type MutationMakeWireTransferArgs = {
  data: MakeWireTransferInput;
};


export type MutationAddExternalCardArgs = {
  data: AddExternalCardCommandInput;
};


export type MutationValidatePayByCardPaymentArgs = {
  data: ValidatePayByCardPaymentInput;
};


export type MutationAddPayByCardPaymentArgs = {
  data: AddPayByCardPaymentInput;
};


export type MutationAccountSelectorStepArgs = {
  submitWizardStepParams: SubmitWizardStepParams;
};


export type MutationRegisterSubuserEnrollmentInviteTokenArgs = {
  registerSubuserEnrollmentInviteTokenParams: RegisterSubuserEnrollmentInviteTokenInput;
};


export type MutationRejectSubuserEnrollmentInviteTokenArgs = {
  rejectSubuserEnrollmentInviteTokenParams: RejectSubuserEnrollmentInviteTokenInput;
};


export type MutationSubuserEnrollmentStepArgs = {
  submitWizardStepParams: SubmitWizardStepParams;
};


export type MutationApproveSubuserTransferArgs = {
  data: ApproveSubuserTransferInput;
};


export type MutationRejectSubuserTransferArgs = {
  data: RejectSubuserTransferInput;
};


export type MutationUpdateTransferArgs = {
  data: UpdateTransferInput;
};


export type MutationCancelTransferArgs = {
  data: CancelTransferInput;
};


export type MutationUserEnrollmentStepArgs = {
  submitWizardStepParams: SubmitWizardStepParams;
};


export type MutationUserKindStepArgs = {
  submitWizardStepParams: SubmitUserKindWizardStepParams;
};


export type MutationConfirmEmailArgs = {
  confirmEmailParams: ConfirmEmailInput;
};


export type MutationSelfLockArgs = {
  securityToken: Scalars['String'];
};


export type MutationTriggeredEventStepArgs = {
  submitTriggeredEventParams: SubmitTriggeredEventParams;
};


export type MutationMovePreviousTriggeredEventStepArgs = {
  movePreviousTriggeredEventStepParams: MovePreviousTriggeredEventStepParams;
};

export type MyRegionPreference = {
  __typename?: 'MyRegionPreference';
  isOn: Scalars['Boolean'];
  id: Maybe<Scalars['String']>;
  name: Maybe<Scalars['String']>;
  latitude: Scalars['Float'];
  longitude: Scalars['Float'];
  radiusInMeters: Scalars['Decimal'];
};

export type MyRegionPreferences = {
  __typename?: 'MyRegionPreferences';
  isEnabled: Scalars['Boolean'];
  isOn: Scalars['Boolean'];
  regions: Array<MyRegionPreference>;
};

export type NewInternalTransferPayload = {
  __typename?: 'NewInternalTransferPayload';
  /** List of accounts that can be chosen as a source for internal transfer. */
  sourceAccounts: Array<BankAccount>;
  /** List of accounts that can be chosen as a destination for internal transfer. */
  destinationAccounts: Array<BankAccount>;
  /** Internal payments settings. */
  transferSettings: TransferSettings;
  /** Favorite payment data. */
  favoriteTransferData: Maybe<FavoriteTransferData>;
};

export type NewM2MTransferPayload = {
  __typename?: 'NewM2MTransferPayload';
  /** List of accounts that can be chosen as a source for M2M transfer. */
  sourceAccounts: Array<M2mBankAccount>;
  /** List of accounts that can be chosen as a destination for M2M transfer. */
  destinationAccounts: Array<PayeeAccount>;
  /** Member to member payment settings. */
  transferSettings: M2mTransferSettings;
  /** Member to member manage payees settings. */
  payeesSettings: M2mPayeesSettings;
};

/** Represents secure message to be created */
export type NewMessage = {
  /** Message recipients. */
  to: Array<CommunicationPartyInput>;
  /** New message subject. */
  subject: Scalars['String'];
  /** New message text. */
  text: Scalars['String'];
  /** Existing message unique id (in case this is a reply message). */
  inReplyToMessageId: Maybe<Scalars['String']>;
  /** Attachments metadata. */
  attachments: Maybe<Array<AttachmentInput>>;
};

/** Unread user messages */
export type NewUserMessageNotification = {
  __typename?: 'NewUserMessageNotification';
  /** Total secure message count of the current user 2. */
  totalCount: Scalars['Int'];
  /** Alert messages count of the current user. */
  alertsCount: Scalars['Int'];
  /** Secure messages count of the current user. */
  messagesCount: Scalars['Int'];
};

export type NextStep = {
  __typename?: 'NextStep';
  /** Flag shows if Wizard finished. */
  wizardFinished: Scalars['Boolean'];
  /** Wizard's next step. */
  nextStepId: Scalars['String'];
  /** Success Summary Message. */
  successSummary: Array<Maybe<Scalars['String']>>;
  /** List of errors. */
  errors: Array<Maybe<ValidationUnion>>;
};

/** The notification graphql field type. */
export type NotificationFieldInfo = UiElementInfo & {
  __typename?: 'NotificationFieldInfo';
  notificationLevel: NotificationLevel;
  /** The field Id. */
  id: Scalars['ID'];
  /** Field type. */
  type: FieldType;
  /** Field Name, Can be used as Label in AT and/or for validation and error messages. */
  name: Maybe<Scalars['String']>;
  /** Field Description Can be used as tooltip or watermark on a presentation level */
  description: Maybe<Scalars['String']>;
  /** Gets value which indicates whether this field is enabled in the current configuration. */
  isEnabled: Scalars['Boolean'];
  /** Label for front-end UI */
  label: Maybe<Scalars['String']>;
  /** Indicates whether the field with the field value needs to be submitted in SubmitWizardStepFieldValues API calls. */
  isSubmittable: Scalars['Boolean'];
  /**
   * client-based property bindings.
   *                     Bindings are exposed to the web app to form a kind of name-value collection of data items
   *                     required to property display a configurable field (that is called DataContext).
   */
  bindings: Maybe<Array<Maybe<Binding>>>;
};

export enum NotificationLevel {
  NOTICE = 'NOTICE',
  WARNING = 'WARNING',
  CRITICAL = 'CRITICAL'
}

export type OneOffInvoiceItemInput = {
  /** Description of the invoice item */
  description: Scalars['String'];
  /** Quantity of the invoice item */
  quantity: Scalars['Decimal'];
  /** Price of the invoice item */
  price: Scalars['Decimal'];
};

export type OneOffInvoiceRecipientInfo = {
  __typename?: 'OneOffInvoiceRecipientInfo';
  /** Identifier of the recipient */
  id: Scalars['Long'];
  /** Email address of the recipient. */
  email: Scalars['String'];
  /** Display name for the recipient */
  displayName: Scalars['String'];
  /** Recipient's type. */
  recipientType: RecipientType;
};

export type OpenBankAccountInput = {
  memberNumber: Scalars['String'];
  bankAccountTypeId: Scalars['String'];
  virtualSignature: Scalars['String'];
  fundingSource: Maybe<OpenNewSharesFundingSourceInput>;
};

export type OpenBankAccountResult = {
  __typename?: 'OpenBankAccountResult';
  isFundingRequired: Scalars['Boolean'];
  accountId: Scalars['String'];
  successful: Scalars['Boolean'];
  errors: Maybe<Array<ValidationItem>>;
  warnings: Maybe<Array<ValidationItem>>;
  infos: Maybe<Array<ValidationItem>>;
};

export type OpenExternalApp = {
  __typename?: 'OpenExternalApp';
  /** Universal link to open external app in iOS devices. */
  iosUrl: Maybe<Scalars['String']>;
  /** Application "home" page at the Apple App store. App store must be opened if neither target native app is install nor the "FallbackUrl" parameter is supplied. */
  iosAppStoreUrl: Maybe<Scalars['String']>;
  /** Android package name used to open external app on Android devices. */
  androidPackage: Maybe<Scalars['String']>;
  /** URL to use when the external app is not installed on user device. */
  fallbackUrl: Maybe<Scalars['String']>;
  /** List of parameters to use for launching external app or to redirect to fallback URL if external app is not installed on user device. */
  parameters: Maybe<Scalars['Any']>;
};

export type OpenNewSharesBankAccountType = {
  __typename?: 'OpenNewSharesBankAccountType';
  id: Scalars['String'];
  name: Scalars['String'];
  rates: Array<OpenNewSharesInterestRate>;
  minimumBalance: Maybe<Scalars['Decimal']>;
  checkFee: Maybe<Scalars['Decimal']>;
  features: Array<Scalars['String']>;
  minimumFundingAmount: Maybe<Scalars['Decimal']>;
  miscellaneousInfo: Maybe<Scalars['String']>;
  termsAndConditions: Maybe<Scalars['String']>;
};

export type OpenNewSharesBankAccountTypeGroup = {
  __typename?: 'OpenNewSharesBankAccountTypeGroup';
  groupName: Scalars['String'];
  imageUrl: Scalars['String'];
  types: Array<OpenNewSharesBankAccountType>;
};

export type OpenNewSharesFundingSourceInput = {
  amount: Scalars['Decimal'];
  accountId: Scalars['String'];
  isExternalAccount: Scalars['Boolean'];
};

export type OpenNewSharesFundingSourceRef = {
  __typename?: 'OpenNewSharesFundingSourceRef';
  accountId: Scalars['String'];
};

export type OpenNewSharesInterestRate = {
  __typename?: 'OpenNewSharesInterestRate';
  balance: Maybe<Scalars['String']>;
  rate: Scalars['Decimal'];
};

export type OpenNewSharesMemberAccount = {
  __typename?: 'OpenNewSharesMemberAccount';
  number: Scalars['String'];
  displayName: Scalars['String'];
  isVisible: Scalars['Boolean'];
};

/** Operation Result. */
export type OperationResult = {
  __typename?: 'OperationResult';
  /** Success Status. */
  success: Scalars['Boolean'];
  /** List of errors. */
  errors: Array<Maybe<ValidationUnion>>;
};

export type OptInSetting = {
  __typename?: 'OptInSetting';
  memberNumber: Scalars['String'];
  displayName: Scalars['String'];
  isOptIn: Scalars['Boolean'];
};

export type OverdraftProtectionOptInSetting = {
  __typename?: 'OverdraftProtectionOptInSetting';
  memberNumber: Scalars['String'];
  displayName: Scalars['String'];
  isOptIn: Scalars['Boolean'];
};

export type OverdraftProtectionOptInSettingInput = {
  memberNumber: Scalars['String'];
  isOptIn: Scalars['Boolean'];
};

/** ContentItem represent individual Page which hosts other Widgets */
export type Page = {
  __typename?: 'Page';
  /** Id of the Item */
  id: Maybe<Scalars['ID']>;
  /** The content item version id */
  contentItemVersionId: Maybe<Scalars['String']>;
  /** Type of content */
  contentType: Maybe<Scalars['String']>;
  /** The display text of the content item */
  displayText: Maybe<Scalars['String']>;
  /** Is the published version */
  published: Scalars['Boolean'];
  /** Is the latest version */
  latest: Scalars['Boolean'];
  /** The date and time of modification */
  modifiedUtc: Maybe<Scalars['DateTime']>;
  /** The date and time of publication */
  publishedUtc: Maybe<Scalars['DateTime']>;
  /** The date and time of creation */
  createdUtc: Maybe<Scalars['DateTime']>;
  /** The owner of the content item */
  owner: Maybe<Scalars['String']>;
  /** The author of the content item */
  author: Maybe<Scalars['String']>;
  /** Render ContentItem */
  html: Maybe<Scalars['String']>;
  /** Widgets placed in the Page */
  widgets: Maybe<ContentItem>;
};

export type ParentUserInfo = {
  __typename?: 'ParentUserInfo';
  /** Identifier of the parent user */
  id: Scalars['Long'];
  /** Login name of the parent user */
  userLogin: Scalars['String'];
  /** Display name of the parent user, to show in the UI */
  userDisplayName: Scalars['String'];
  /** Whether the user has write access to cash management */
  hasWriteAccessToCashManagement: Scalars['Boolean'];
  /** Which cash management access level parent has granted to the user */
  grantedCashManagementAccess: GrantedCashManagementAccessLevel;
  /** Whether the user has access to sending one-off invoices to recipients */
  hasAccessToSendOneOffInvoice: Scalars['Boolean'];
  /** Parent user's address */
  address: Maybe<ParentUserInfoAddress>;
  /** Parent user's company name */
  companyName: Maybe<Scalars['String']>;
  /** Parent user's primary email address */
  primaryEmail: Maybe<Scalars['String']>;
};

export type ParentUserInfoAddress = {
  __typename?: 'ParentUserInfoAddress';
  /** Country of the parent user's address */
  country: Scalars['String'];
  /** Country code of the parent user's address */
  countryCode: Scalars['String'];
  /** State of the parent user's address */
  state: Scalars['String'];
  /** City of the parent user's address */
  city: Scalars['String'];
  /** Street of the parent user's address */
  street: Scalars['String'];
  /** Second street of the parent user's address */
  street2: Scalars['String'];
  /** Third street of the parent user's address */
  street3: Scalars['String'];
  /** Zip-code of the parent user's address */
  zipCode: Scalars['String'];
};

export type Passkey = {
  __typename?: 'Passkey';
  /** Id. */
  id: Scalars['String'];
  /** Nickname. */
  nickname: Scalars['String'];
};

export type PasskeyLoginSettings = {
  __typename?: 'PasskeyLoginSettings';
  /** Indicates whether the passkey login feature is enabled. */
  isEnabled: Scalars['Boolean'];
};

export type PasskeyRegisterOptions = {
  __typename?: 'PasskeyRegisterOptions';
  /** Rp. */
  rp: PublicKeyCredentialRpEntity;
  /** User. */
  user: PublicKeyCredentialUserEntity;
  /** Challenge. */
  challenge: Scalars['String'];
  /** PubKeyCredParams. */
  pubKeyCredParams: Array<PublicKeyCredentialParameters>;
  /** Timeout. */
  timeout: Scalars['Long'];
  /** ExcludeCredentials. */
  excludeCredentials: Array<PublicKeyCredentialDescriptor>;
  /** AuthenticatorSelection. */
  authenticatorSelection: AuthenticatorSelectionCriteria;
  /** Hints. */
  hints: Array<Scalars['String']>;
  /** Attestation. */
  attestation: Scalars['String'];
  /** AttestationFormats. */
  attestationFormats: Array<Scalars['String']>;
};

/** The password text field info type. */
export type PasswordFieldInfo = UiElementInfo & FieldInfoBase & {
  __typename?: 'PasswordFieldInfo';
  /** Define Password Strength Rules */
  passwordPolicy: Maybe<PasswordStrengthPolicy>;
  /** The field Id. */
  id: Scalars['ID'];
  /** Field type. */
  type: FieldType;
  /** Field Name, Can be used as Label in AT and/or for validation and error messages. */
  name: Maybe<Scalars['String']>;
  /** Field Description Can be used as tooltip or watermark on a presentation level */
  description: Maybe<Scalars['String']>;
  /** Gets value which indicates whether this field is enabled in the current configuration. */
  isEnabled: Scalars['Boolean'];
  /** Label for front-end UI */
  label: Maybe<Scalars['String']>;
  /** Indicates whether the field with the field value needs to be submitted in SubmitWizardStepFieldValues API calls. */
  isSubmittable: Scalars['Boolean'];
  /**
   * client-based property bindings.
   *                     Bindings are exposed to the web app to form a kind of name-value collection of data items
   *                     required to property display a configurable field (that is called DataContext).
   */
  bindings: Maybe<Array<Maybe<Binding>>>;
  /** Field Behavior e.g Required, Readonly */
  fieldBehaviorMode: FieldBehaviorMode;
  /** This property stores the field input hint. Intended to be displayed by native apps on mobile devices. */
  placeholder: Maybe<Scalars['String']>;
  /** Max length of the field. */
  maxLength: Scalars['Int'];
  /** Min length of the field. */
  minLength: Scalars['Int'];
  /** The value of the field. */
  value: Maybe<Scalars['String']>;
};

export type PasswordStrengthPolicy = {
  __typename?: 'PasswordStrengthPolicy';
  /** Flag indicating Minimum Character Length of the password */
  minimumCharacterLength: Scalars['Int'];
  /** Flag indicating whether is minimum character length enabled */
  isMinimumCharacterLengthEnabled: Scalars['Boolean'];
  /** Flag indicating Maximum Character Length of the password */
  maximumCharacterLength: Scalars['Int'];
  /** Flag indicating whether is Maximum Character Length enabled */
  isMaximumCharacterLengthEnabled: Scalars['Boolean'];
  /** Flag indicating whether Password must not contain UserName */
  mustNotContainUsername: Scalars['Boolean'];
  /** Flag indicating whether first character must be alphabetic */
  firstCharacterMustBeAlphabetic: Scalars['Boolean'];
  /** Flag indicating at least one character must be alphabetic */
  anyCharacterMustBeAlphabetic: Scalars['Boolean'];
  /** Flag for Lower Case Requirement */
  lowerCaseRequirement: Maybe<CharacterRequirement>;
  /** Flag for Upper Case Requirement */
  upperCaseRequirement: Maybe<CharacterRequirement>;
  /** Flag for Number Requirement */
  numberRequirement: Maybe<CharacterRequirement>;
  /** Flag for Special Character Requirement */
  specialCharacterRequirement: Maybe<CharacterRequirement>;
  /** Flag indicating whether display Password Strength Meter */
  passwordStrengthMeterEnabled: Scalars['Boolean'];
  /** Flag indicating whether Password must not contain member Birth Date */
  mustNotContainBirthdate: Scalars['Boolean'];
  /** Flag indicating whether Password must not contain Member Number */
  mustNotContainMemberNumber: Scalars['Boolean'];
  /** Flag indicating whether Password must not contain Member Social Security Number */
  mustNotContainSsn: Scalars['Boolean'];
  /** Value indicating the count of days the Password must not be reused. */
  doNotReUseLastNDays: Scalars['Int'];
  /** Value indicating the count of last N passwords the Password must not be equal. */
  doNotReUseLastNPasswords: Scalars['Int'];
  /** Value containing the words the Password must not contain. */
  doNotUseWords: Maybe<Scalars['String']>;
};

export type PasswordVisibilityControlSettings = {
  __typename?: 'PasswordVisibilityControlSettings';
  /** Indicates whether the password visibility control feature is enabled. */
  isEnabled: Scalars['Boolean'];
};

export type PayeeAccount = {
  __typename?: 'PayeeAccount';
  /** Payee account identifier. This identifier is unique among all accounts */
  id: Scalars['ID'];
  /** Payee account title */
  title: Scalars['String'];
  /** Payee account masked member number */
  maskedMemberNumber: Scalars['String'];
  /** Payee account sub Id. This use for child payee account */
  subAccountId: Maybe<Scalars['String']>;
  /** Payee account member number */
  memberNumber: Scalars['String'];
  /** Payee last name */
  lastName: Scalars['String'];
  /** Payee nickname */
  nickName: Maybe<Scalars['String']>;
};

export type PaymentOption = {
  __typename?: 'PaymentOption';
  /** Unique option identifier. */
  id: Scalars['ID'];
  /** User friendly label. */
  displayName: Maybe<Scalars['String']>;
  /** Available amount. */
  amount: Maybe<Scalars['Decimal']>;
  /** Max amount. */
  maxAmount: Maybe<Scalars['Decimal']>;
  /** Warning for max amount option. */
  maxAmountWarning: Maybe<Scalars['String']>;
  /** Indicates whether amount can be modified by a user. */
  canModifyAmount: Scalars['Boolean'];
  /** Indicates whether dynamic amount will be applied for an option in case of scheduled transfer. */
  isDynamicAmount: Scalars['Boolean'];
  /** Description for dynamic amount option. */
  dynamicAmountDescription: Maybe<Scalars['String']>;
  /** Indicates whether one time scheduled payment is allowed for account. */
  isScheduledPaymentAllowed: Scalars['Boolean'];
  /** Indicates whether recurring payments is allowed for accounts. */
  isRecurringPaymentsAllowed: Scalars['Boolean'];
  /** Optional disclaimer text for this payment option. */
  disclaimer: Maybe<Scalars['String']>;
};

export type PerRecipientTransferParametersInput = {
  /** Identifier of the recipient in template */
  id: Scalars['Long'];
  /** Recipient's overridden amount for given transfer */
  amount: Maybe<Scalars['Decimal']>;
};

export enum PhoneSmsEnrollmentStatus {
  NOTENROLLED = 'NOTENROLLED',
  ENROLLED = 'ENROLLED',
  OTPPENDING = 'OTPPENDING',
  OTPATTEMPTSFAILED = 'OTPATTEMPTSFAILED',
  ENROLLEDANDCONFIRMEDBYANOTHERUSER = 'ENROLLEDANDCONFIRMEDBYANOTHERUSER',
  ENROLLEDANDCONFIRMEDONANOTHERFINANCIALINSTITUTION = 'ENROLLEDANDCONFIRMEDONANOTHERFINANCIALINSTITUTION',
  AGGREGATORBLACKLIST = 'AGGREGATORBLACKLIST'
}

export enum PinChangeOption {
  UNKNOWN = 'UNKNOWN',
  PHONECALL = 'PHONECALL',
  NATIVE = 'NATIVE'
}

export type PinChangingFeature = {
  __typename?: 'PinChangingFeature';
  isEnabled: Scalars['Boolean'];
  mode: PinChangeOption;
  validation: Array<ValidationType>;
};

export enum PinOnActivation {
  HIDE = 'HIDE',
  OPTIONAL = 'OPTIONAL',
  REQUIRED = 'REQUIRED'
}

/** The Orpheus Widget Pointing to the Template */
export type PlacementWidget = {
  __typename?: 'PlacementWidget';
  /** Id of the Item */
  id: Maybe<Scalars['ID']>;
  /** The content item version id */
  contentItemVersionId: Maybe<Scalars['String']>;
  /** Type of content */
  contentType: Maybe<Scalars['String']>;
  /** The display text of the content item */
  displayText: Maybe<Scalars['String']>;
  /** Is the published version */
  published: Scalars['Boolean'];
  /** Is the latest version */
  latest: Scalars['Boolean'];
  /** The date and time of modification */
  modifiedUtc: Maybe<Scalars['DateTime']>;
  /** The date and time of publication */
  publishedUtc: Maybe<Scalars['DateTime']>;
  /** The date and time of creation */
  createdUtc: Maybe<Scalars['DateTime']>;
  /** The owner of the content item */
  owner: Maybe<Scalars['String']>;
  /** The author of the content item */
  author: Maybe<Scalars['String']>;
  /** Widget Flow Layout Meta Information from WYSIWYG Editor */
  flowMeta: Maybe<FlowMetadata>;
  placementType: Maybe<Scalars['String']>;
};

export type PlaidAccount = {
  __typename?: 'PlaidAccount';
  id: Scalars['String'];
  name: Scalars['String'];
  maskedMemberNumber: Maybe<Scalars['String']>;
  ledgerBalance: Maybe<Scalars['Decimal']>;
  availableBalance: Maybe<Scalars['Decimal']>;
};

export type PlaidInstitution = {
  __typename?: 'PlaidInstitution';
  id: Scalars['String'];
  name: Scalars['String'];
  accounts: Array<PlaidAccount>;
};

export type PlaidSyncResult = {
  __typename?: 'PlaidSyncResult';
  /** Is particular FI sync success with Plaid. */
  isSuccess: Scalars['Boolean'];
  /** FI name. */
  institutionName: Scalars['String'];
};

export type PlaidTransaction = {
  __typename?: 'PlaidTransaction';
  id: Scalars['ID'];
  amount: Maybe<Scalars['Decimal']>;
  description: Maybe<Scalars['String']>;
  transactionDate: Scalars['Date'];
  checkNumber: Maybe<Scalars['String']>;
  transactionStatus: PlaidTransactionStatus;
};

export enum PlaidTransactionStatus {
  PROCESSING = 'PROCESSING',
  POSTED = 'POSTED'
}

export type PlaidTransactionsReadyNotification = {
  __typename?: 'PlaidTransactionsReadyNotification';
  accountIds: Array<Scalars['String']>;
};

export type PreferredContact = {
  __typename?: 'PreferredContact';
  phone: Maybe<Scalars['String']>;
  email: Maybe<Scalars['String']>;
  note: Maybe<Scalars['String']>;
};

export type PreferredContactInput = {
  phone: Maybe<Scalars['String']>;
  email: Maybe<Scalars['String']>;
  note: Maybe<Scalars['String']>;
};

export type ProPayPaymentSettings = {
  __typename?: 'ProPayPaymentSettings';
  /** URL to ProPay SPI service. */
  proPaySpiUrl: Maybe<Scalars['String']>;
  /** ProPay merchant profile ID */
  merchantProfileId: Maybe<Scalars['String']>;
  /** List of supported card types. */
  supportedCards: Maybe<Array<Scalars['String']>>;
  /** If an optional card billing address data enabled when a user is adding a new card. */
  isCardBillingAddressEnabled: Scalars['Boolean'];
};

export type PublicKeyCredentialDescriptor = {
  __typename?: 'PublicKeyCredentialDescriptor';
  /** Id. */
  id: Scalars['String'];
  /** Type. */
  type: Scalars['String'];
  /** Transports. */
  transports: Array<Scalars['String']>;
};

export type PublicKeyCredentialParameters = {
  __typename?: 'PublicKeyCredentialParameters';
  /** Type. */
  type: Scalars['String'];
  /** Alg. */
  alg: Scalars['Long'];
};

export type PublicKeyCredentialRpEntity = {
  __typename?: 'PublicKeyCredentialRpEntity';
  /** Id. */
  id: Scalars['String'];
  /** Name. */
  name: Scalars['String'];
};

export type PublicKeyCredentialUserEntity = {
  __typename?: 'PublicKeyCredentialUserEntity';
  /** Id. */
  id: Scalars['String'];
  /** Name. */
  name: Scalars['String'];
  /** DisplayName. */
  displayName: Scalars['String'];
};

export type Query = {
  __typename?: 'Query';
  /** Retrieves embedded resources configured in AT */
  embeddedResources: Array<EmbeddedResourceContainer>;
  /** Get Pages by Ids */
  pages: Maybe<Array<Maybe<Page>>>;
  /** Get Layer Widgets */
  widgets: Maybe<Array<Maybe<ZoneHolder>>>;
  /** Retrieve list of links of all registered SSO providers */
  ssolinks: Array<SsoProviderLink>;
  /** Retrieve link of a registered SSO provider */
  ssolink: Maybe<SsoProviderLink>;
  /** Retrieves advertisement. */
  advertisement: AdvertisementResult;
  /** Invokes backend component and returns the invocation result. */
  invokeBackendComponent: Maybe<InvokeBackendComponentResult>;
  /** Retrieve statement transactions history for a bank account */
  transactionsHistory: GetTransactionsHistoryResult;
  /** Retrieve the list of file formats available for transactions history export */
  transactionsHistoryExporters: GetTransactionsHistoryExportersResult;
  /** Exports transactions history and retrieves the export result as a file data object */
  exportTransactionsHistory: ExportTransactionsHistoryResult;
  /** Retrieve statement transactions items for graph */
  transactionsGraph: GetTransactionsGraphResult;
  /** Retrieve attached image by ID */
  transactionAttachedImage: GetTransactionAttachedImageResult;
  /** Retrieve bank account balances for a balance history graph */
  bankAccountBalancesGraph: GetBankAccountBalancesGraphResult;
  /** Retrieve bank account forecast balances for a balance graph */
  bankAccountBalancesForecastGraph: GetBankAccountBalancesForecastGraphResult;
  /** Retrieve users bank accounts */
  userBankAccounts: GetUserBankAccountsResult;
  /** Retrieve Details for User Bank account */
  bankAccountDetails: GetBankAccountDetailsResult;
  /** Retrieve users bank account summary */
  bankAccountSummary: GetUserBankAccountSummaryResult;
  /** Retrieve user's bank account balance changes */
  bankAccountBalanceChanges: GetUserBankAccountBalanceChangesResult;
  /** Retrieve statement transactions history for a bank account */
  accountsDisplayOptions: GetUserBankAccountDisplayOptionsResult;
  /** Retrieve user account groups */
  userGroups: GetUserAccountGroupsResult;
  /** Retrieve link internal account settings */
  getLinkInternalAccountSettings: GetLinkInternalAccountSettingsResult;
  /** Retrieve ODP settings to optIn or optOut */
  overdraftProtectionSettings: GetOverdraftProtectionOptInSettingsResult;
  /** Retrieve  get year end info member accounts */
  yearEndInfoMemberAccounts: GetYearEndInfoMemberAccountsResult;
  /** Retrieve get year end info */
  yearEndInfo: GetYearEndInfoResult;
  /** Retrieve linked Plaid institutions */
  getLinkedInstitutions: GetLinkedInstitutionsResult;
  /** Retrieve Plaid accounts */
  getPlaidAccounts: GetPlaidAccountsResult;
  /** Retrieve Plaid transactions */
  getPlaidTransactions: GetPlaidTransactionsResult;
  /** Gets reward points by bank account */
  bankAccountRewardPoints: GetBankAccountRewardPointsResult;
  /** Retrieves branding info. */
  branding: Branding;
  /** Retrieves business banking recipient */
  businessBankingRecipient: RecipientDetails;
  /** Retrieves all business banking recipients */
  businessBankingRecipients: Array<RecipientDetails>;
  /** Retrieves all business banking templates */
  businessBankingTemplates: Array<Template>;
  /** Retrieves business banking template by Id for Cash Management */
  businessBankingTemplate: Maybe<Template>;
  /** Retrieves business banking template by Id for transfer */
  businessBankingTemplateForTransfer: Maybe<Template>;
  /** Returns account, template lists and other data to setup new external transfer */
  businessBankingTransferData: GetTransferDataResult;
  /** Returns business banking configuration parameters */
  businessBankingConfig: GetConfigResult;
  /** Returns business banking transfer configuration parameters */
  businessBankingTransferConfig: GetTransferConfigResult;
  /** Exports business banking history for given period */
  businessBankingHistoryExport: BusinessBankingHistoryExportResult;
  /** Retrieves configuration parameters for the Business ACH History Export feature */
  businessBankingHistoryExportConfig: GetHistoryExportConfigResult;
  /** Retrieves information which determines if there are any transactions for the current period from current year to the furthest year with available transactions for the Business ACH History Export feature */
  businessBankingDetermineAnyTransactionsForPeriod: DetermineAnyTransactionsForPeriodResult;
  /** Retrieves configuration parameters for the Business ACH One-Off Invoice feature */
  businessBankingOneOffInvoiceConfig: GetOneOffInvoiceConfigResult;
  /** Retrieves a list of recipients for the Business ACH One-Off Invoice feature */
  businessBankingOneOffInvoiceRecipientList: GetOneOffInvoiceRecipientListResult;
  /** Retrieve card controls */
  cardControls: GetCardControlsResult;
  /** Retrieve card alerts */
  cardAlerts: GetCardAlertsResult;
  /** Retrieve users cards */
  cards: GetCardsResult;
  /** Retrieve users travels */
  travels: GetTravelsResult;
  /** Get card payment details */
  cardPaymentDetails: GetCardPaymentDetailsResult;
  /** Get card payment details with masked Card Number and CVV values */
  cardMaskedPaymentDetails: GetCardPaymentDetailsResult;
  /** Get card balance */
  cardBalance: GetCardBalanceResult;
  /** Lookup lender */
  lookupLender: LookupLenderResult;
  /** Validate zip belongs to state */
  validateStateZip: ValidateStateZipResult;
  /** Retrieves user alert settings */
  alertSettings: GetAvailableAlertsResponse;
  /** Retrieves user alert settings */
  alerts: GetSupportedAlertsResponse;
  /** Retrieves alert details by id. */
  alertDetails: AlertDetails;
  /** Retrieves configurable alert fields */
  alertEmptyFields: GetEmptyFieldsResponse;
  /** Retrieves user secure messages and alerts. */
  messageFolders: Array<MessageFolder>;
  /** Retrieves user secure messages and alerts. */
  messages: GetMessagesResponse;
  /** Retrieves user secure message or alert by id. */
  message: Message;
  /** Retrieves department recipients to set destination address for new message. */
  recipients: GetRecipientsResponse;
  /** Downloads attachment for secure message */
  downloadAttachment: AttachmentDownloadResponse;
  /** Retrieves secure messages configuration. */
  messageConfiguration: MessageConfigurationResponse;
  /** Retrieves transactions attachable to secure message. */
  attachableTransactions: GetAttachableTransactionsResponse;
  /** Retrieves user contacts and settings. */
  contactsAndSettings: GetContactsAndSettingsResult;
  /** Retrieves user contacts and settings. */
  contactsEmptyFields: GetContactsEmptyFieldsResult;
  /** Retrieves configuration for contacts and settings. */
  userContactSettings: GetContactsAndSettingsConfigurationResult;
  /** Retrieves user time zone. */
  timeZone: GetTimeZoneResult;
  /** Retrieve credit score member accounts */
  creditScoreMemberAccounts: GetCreditScoreMemberAccountsResult;
  /** Retrieve Credit score settings to optIn or optOut */
  creditScoreSettings: GetCreditScoreOptInSettingsResult;
  /** Retrieve credit score info */
  creditScore: GetCreditScoreResult;
  /** Retrieve user custom data. */
  customData: GetUserCustomDataResult;
  /** Retrieve member accounts available for eStatements */
  eStatementsMembers: GetMemberAccountsResult;
  /** Retrieve list of e-Documents */
  eStatementsDocuments: GetDocumentsResult;
  /** Download eStatements document */
  eStatementsDownloadDocument: DownloadDocumentResult;
  /** Retrieve settings to optIn or optOut */
  eStatementsSettings: GetOptInSettingsResult;
  /** Retrieve user event logs */
  eventLogs: GetEventLogsResult;
  /** Invokes external API integration and returns the invocation result. */
  invokeExternalApiIntegration: InvokeExternalApiIntegrationResult;
  /** Retrieve accounts permissions result */
  accountsPermissions: GetAccountsPermissionsResult;
  /** Retrieve subuser info */
  subuserInfo: GetSubuserInfoResult;
  /** Retrieve transfers permissions result */
  transfersPermissions: GetTransfersPermissionsResult;
  /** Retrieve additional permissions result */
  additionalPermissions: GetAdditionalPermissionsResult;
  /** Retrieve subusers list */
  subusers: GetSubusersResult;
  /** Retrieve subusers list */
  accessibleUsers: GetAccessibleUsersResult;
  /** Retrieve accessible permissions */
  accessiblePermissions: GetAccessiblePermissionsResult;
  /** Get FI Settings */
  fiSettings: FiSettings;
  /** Get Current User Info */
  currentUserInfo: GetCurrentUserAccountInfoResult;
  /** Get Session Settings */
  sessionSettings: SessionSettings;
  /** Get Compromised Credentials Status */
  credentialsStatus: CompromisedCredentials;
  /** Get Securiry Settings */
  securitySettings: SecuritySettings;
  /** Retrieve Login Fields */
  loginStep: Maybe<CurrentWizardStep>;
  /** Tethered Login Query */
  tetheredLogin: Maybe<TetheredLoginResult>;
  /** Retrieve Login Settings. */
  loginSettings: LoginSettingsResult;
  /** CreatePasskeyRegisterOptions */
  createPasskeyRegisterOptions: PasskeyRegisterOptions;
  /** GenerateAuthOptions */
  generateAuthOptions: GenerateAuthOptions;
  /** GetRegisteredPasskeysQuery */
  getRegisteredPasskeys: GetRegisteredPasskeys;
  /** Get Menus by Ids */
  menus: Maybe<Array<Maybe<Menu>>>;
  /** Checks if user is eligible for the service. */
  isEligibleService: Maybe<IsEligibleServiceResult>;
  /** Get Menus by Ids */
  menu: Maybe<Array<Maybe<MenuRoot>>>;
  /** Checks if user is eligible for the service. */
  menuItemEligibilityStatus: Maybe<MenuItemEligibilityStatus>;
  /** Checks if user is eligible for the service. */
  v3DeepLinkMapping: Maybe<Array<Maybe<DeepLinkMappingPair>>>;
  /** Retrieve member accounts available for Open New Share */
  openNewSharesMembers: GetOpenNewSharesMemberAccountsResult;
  /** Retrieve bank account types for Open New Share */
  openNewSharesOpenableTypes: GetOpenNewSharesBankAccountTypesResult;
  /** Retrieve funding sources available for Open New Share */
  openNewSharesFundingSources: GetOpenNewSharesFundingSourcesResult;
  /** Get Recaptcha Settings */
  recaptchaSettings: RecaptchaSettingsConfiguration;
  /** Retrieve retrieval wizard fields. */
  retrievalStep: Maybe<CurrentWizardStep>;
  /** Get transfers features settings. */
  transfersFeatures: TransfersFeatures;
  /** Get transfers features settings with checks if corresponding features are enabled at the Orchard level. */
  transfersFeaturesV2: TransfersFeatures;
  /** Get all internal transfer form initialization data. */
  newInternalTransferData: NewInternalTransferPayload;
  /** Get available source account Ids for selected destination account. */
  internalSourceAccountIds: Array<BankAccountRef>;
  /** Get available destination account Ids for selected source account. */
  internalDestinationAccountIds: Array<BankAccountRef>;
  /** Get payment options available for selected pair of accounts. */
  internalPaymentOptions: Array<PaymentOption>;
  /** Retrieves bank info by routing number and operation errors */
  bankInfoByRoutingNumber: GetBankInfoByRoutingNumberResult;
  /** Retrieves confirmed external accounts */
  confirmedExternalAccounts: Array<ExternalAccount>;
  /** Retrieves external accounts linking settings */
  externalAccountLinkingSettings: GetExternalAccountLinkingSettingsResult;
  /** Returns account lists and other data to setup new external transfer */
  newExternalTransferData: GetNewExternalTransferDataResult;
  /** Returns payment options available for selected pair of external and internal account. */
  externalTransferPaymentOptions: Array<PaymentOption>;
  /** Retrieves pending external accounts */
  pendingExternalAccounts: Array<ExternalAccount>;
  /** Validate External Transfer parameters */
  validateExternalTransfer: ValidateExternalTransferResult;
  /** Get all initial data for skip pay form. */
  skipPayAllInfo: SkipPayAllInfoPayload;
  /** Member to member transfer form initialization data. */
  newM2MTransferData: NewM2MTransferPayload;
  /** Manage member to member payees form initialization data. */
  m2mPayeesData: GetM2MPayeesPayload;
  /** Get all saved favorites */
  getFavorites: GetFavoritesResult;
  /** Get all initial data for investment payment form. */
  newInvestmentTransferData: GetNewTransferDataPayload;
  /** Get eligible destination accounts for selected source account. */
  investmentDestinationAccounts: GetInvestmentAccountsResult;
  /** Get eligible source accounts for selected destination account. */
  investmentSourceAccounts: GetInvestmentAccountsResult;
  /** Get available payment options for selected source and destination accounts. */
  investmentPaymentOptions: GetPaymentOptionsResult;
  /** Retrieves stop check payment options */
  stopCheckOptions: GetStopCheckOptionsResult;
  /** Returns account list, transfer settings and recipients for wire transfer */
  wireTransferData: GetWireTransferDataResult;
  /** Validate Wire Transfer parameters */
  validateWireTransfer: ValidateWireTransferResult;
  /** Get ProPay authentication token */
  proPayAuthToken: GetProPayAuthTokenResult;
  /** Pay by Card initialization data. */
  newPayByCardTransferData: GetNewPayByCardTransferDataResult;
  /** Get payment options available for selected pair of accounts. */
  payByCardPaymentOptions: Array<PaymentOption>;
  /** Authenticate user via Single Sign-On and get URL and parameters for the final redirect. */
  getSsoScenario: Maybe<GetSsoScenarioResultUnion>;
  /** Retrieve SSO account selector wizard fields */
  accountSelectorStep: Maybe<CurrentWizardStep>;
  /** Retrieve subuser enrollment invitation by token. */
  getSubuserEnrollmentInvitationByToken: Maybe<GetSubuserEnrollmentInvitationByTokenResult>;
  /** Retrieve subuser enrollment wizard fields */
  subuserEnrollmentStep: Maybe<CurrentWizardStep>;
  /** Get transfer payment details of subuser. Can be anonymous */
  getSubuserTransferDetails: GetSubuserTransferDetailsResult;
  /** Get transfer activities. */
  getTransferActivities: GetTransferActivitiesResult;
  /** Get transfer activity details. */
  getTransferActivityDetails: GetTransferActivityDetailsResult;
  /** Retrieve user enrollment wizard fields */
  userEnrollmentStep: Maybe<CurrentWizardStep>;
  /** Retrieve user kind wizard fields. */
  userKindStep: Maybe<CurrentWizardStep>;
  /** Retrieves identifiers of actions user can perform by the link from advanced login takeover alert. */
  getAccountSecurityActions: GetAccountSecurityActionsResult;
  /** Validates user security token. */
  validateSecurityToken: ValidatingOperationResult;
  /** Retrieve triggered event step fields. */
  triggeredEventStep: Maybe<CurrentWizardStep>;
};


export type QueryPagesArgs = {
  ids: Array<Maybe<Scalars['String']>>;
};


export type QuerySsolinkArgs = {
  ssoid: Scalars['String'];
};


export type QueryAdvertisementArgs = {
  providerId: Scalars['String'];
};


export type QueryInvokeBackendComponentArgs = {
  componentName: Scalars['String'];
  arguments: Array<BackendComponentArgument>;
};


export type QueryTransactionsHistoryArgs = {
  pageSize: Maybe<Scalars['Int']>;
  pageNumber: Maybe<Scalars['Int']>;
  accountId: Scalars['ID'];
  isUpcoming: Maybe<Scalars['Boolean']>;
  freeTextFilter: Maybe<Scalars['String']>;
  startDate: Maybe<Scalars['DateTime']>;
  endDate: Maybe<Scalars['DateTime']>;
  isExpense: Scalars['Boolean'];
  isIncome: Scalars['Boolean'];
  minAmount: Maybe<Scalars['Decimal']>;
  maxAmount: Maybe<Scalars['Decimal']>;
  checkNumberFilter: Maybe<Scalars['String']>;
};


export type QueryExportTransactionsHistoryArgs = {
  accountId: Scalars['ID'];
  isUpcoming: Maybe<Scalars['Boolean']>;
  freeTextFilter: Maybe<Scalars['String']>;
  startDate: Maybe<Scalars['DateTime']>;
  endDate: Maybe<Scalars['DateTime']>;
  isExpense: Scalars['Boolean'];
  isIncome: Scalars['Boolean'];
  minAmount: Maybe<Scalars['Decimal']>;
  maxAmount: Maybe<Scalars['Decimal']>;
  checkNumberFilter: Maybe<Scalars['String']>;
  exporterId: Scalars['ID'];
};


export type QueryTransactionsGraphArgs = {
  bankAccountId: Scalars['String'];
  beginDate: Scalars['DateTime'];
  endDate: Scalars['DateTime'];
};


export type QueryTransactionAttachedImageArgs = {
  imageKey: Scalars['ID'];
};


export type QueryBankAccountBalancesGraphArgs = {
  bankAccountId: Scalars['String'];
  beginDate: Scalars['DateTime'];
  endDate: Scalars['DateTime'];
};


export type QueryBankAccountBalancesForecastGraphArgs = {
  bankAccountId: Scalars['String'];
  beginDate: Scalars['DateTime'];
  endDate: Scalars['DateTime'];
};


export type QueryUserBankAccountsArgs = {
  includeHiddenInGroups: Scalars['Boolean'];
  invalidateCache: Scalars['Boolean'];
};


export type QueryBankAccountDetailsArgs = {
  bankAccountId: Scalars['String'];
};


export type QueryBankAccountSummaryArgs = {
  bankAccountId: Scalars['String'];
};


export type QueryBankAccountBalanceChangesArgs = {
  bankAccountId: Scalars['String'];
};


export type QueryYearEndInfoArgs = {
  memberId: Scalars['String'];
};


export type QueryGetPlaidTransactionsArgs = {
  pageSize: Maybe<Scalars['Int']>;
  pageNumber: Maybe<Scalars['Int']>;
  accountId: Scalars['ID'];
  isUpcoming: Maybe<Scalars['Boolean']>;
  freeTextFilter: Maybe<Scalars['String']>;
  startDate: Maybe<Scalars['DateTime']>;
  endDate: Maybe<Scalars['DateTime']>;
  isExpense: Scalars['Boolean'];
  isIncome: Scalars['Boolean'];
  minAmount: Maybe<Scalars['Decimal']>;
  maxAmount: Maybe<Scalars['Decimal']>;
  checkNumberFilter: Maybe<Scalars['String']>;
};


export type QueryBankAccountRewardPointsArgs = {
  bankAccountId: Scalars['ID'];
};


export type QueryBusinessBankingRecipientArgs = {
  id: Scalars['Int'];
  contextUserId: Scalars['Long'];
};


export type QueryBusinessBankingRecipientsArgs = {
  contextUserId: Scalars['Long'];
};


export type QueryBusinessBankingTemplatesArgs = {
  contextUserId: Scalars['Long'];
};


export type QueryBusinessBankingTemplateArgs = {
  id: Scalars['Long'];
  contextUserId: Scalars['Long'];
};


export type QueryBusinessBankingTemplateForTransferArgs = {
  id: Scalars['Long'];
  contextUserId: Scalars['Long'];
};


export type QueryBusinessBankingTransferDataArgs = {
  contextUserId: Scalars['Long'];
};


export type QueryBusinessBankingHistoryExportArgs = {
  exportParameters: HistoryExportParametersInput;
  contextUserId: Scalars['Long'];
};


export type QueryBusinessBankingDetermineAnyTransactionsForPeriodArgs = {
  contextUserId: Scalars['Long'];
};


export type QueryBusinessBankingOneOffInvoiceRecipientListArgs = {
  contextUserId: Scalars['Long'];
};


export type QueryCardControlsArgs = {
  cardKey: Scalars['String'];
};


export type QueryCardAlertsArgs = {
  cardKey: Scalars['String'];
};


export type QueryCardPaymentDetailsArgs = {
  cardKey: Scalars['String'];
};


export type QueryCardMaskedPaymentDetailsArgs = {
  cardKey: Scalars['String'];
};


export type QueryCardBalanceArgs = {
  cardKey: Scalars['String'];
};


export type QueryLookupLenderArgs = {
  data: LookupLenderInput;
};


export type QueryValidateStateZipArgs = {
  data: ValidateStateZipInput;
};


export type QueryAlertDetailsArgs = {
  alertId: Maybe<Scalars['Int']>;
  alertType: Maybe<AlertType>;
};


export type QueryAlertEmptyFieldsArgs = {
  alertType: AlertType;
};


export type QueryMessagesArgs = {
  folderId: Scalars['Int'];
  pageNum: Scalars['Int'];
  searchText: Maybe<Scalars['String']>;
  startDate: Maybe<Scalars['DateTime']>;
  endDate: Maybe<Scalars['DateTime']>;
  hasAttachments: Scalars['Boolean'];
};


export type QueryMessageArgs = {
  id: Scalars['String'];
};


export type QueryDownloadAttachmentArgs = {
  fileId: Scalars['ID'];
};


export type QueryAttachableTransactionsArgs = {
  data: AttachableTransactionsFilters;
};


export type QueryCreditScoreArgs = {
  memberNumber: Scalars['String'];
};


export type QueryCustomDataArgs = {
  scope: Scalars['String'];
};


export type QueryEStatementsDocumentsArgs = {
  memberId: Scalars['String'];
  year: Maybe<Scalars['String']>;
  documentType: Maybe<DocumentType>;
};


export type QueryEStatementsDownloadDocumentArgs = {
  documentKey: Scalars['String'];
};


export type QueryEventLogsArgs = {
  pageSize: Maybe<Scalars['Int']>;
  pageNumber: Maybe<Scalars['Int']>;
  sortDirection: Maybe<Scalars['String']>;
  sortableField: Maybe<Scalars['String']>;
  freeTextFilter: Maybe<Scalars['String']>;
  startDate: Maybe<Scalars['Date']>;
  endDate: Maybe<Scalars['Date']>;
};


export type QueryInvokeExternalApiIntegrationArgs = {
  integrationName: Scalars['String'];
  arguments: Maybe<Array<ExternalApiIntegrationArgumentInput>>;
};


export type QueryAccountsPermissionsArgs = {
  subuserKey: Maybe<Scalars['ID']>;
};


export type QuerySubuserInfoArgs = {
  subuserKey: Maybe<Scalars['ID']>;
};


export type QueryTransfersPermissionsArgs = {
  subuserKey: Maybe<Scalars['ID']>;
};


export type QueryAdditionalPermissionsArgs = {
  subuserKey: Maybe<Scalars['ID']>;
};


export type QueryAccessiblePermissionsArgs = {
  subuserKey: Scalars['ID'];
};


export type QueryCredentialsStatusArgs = {
  getCompromisedCredentialsParams: Maybe<GetCompromisedCredentialsInput>;
};


export type QueryLoginStepArgs = {
  getWizardStateParams: GetWizardStateParams;
};


export type QueryTetheredLoginArgs = {
  tetheredLoginParams: TetheredLoginInput;
};


export type QueryMenusArgs = {
  ids: Array<Maybe<Scalars['String']>>;
};


export type QueryIsEligibleServiceArgs = {
  serviceName: Scalars['String'];
  serviceType: Scalars['String'];
};


export type QueryMenuArgs = {
  ids: Array<Maybe<Scalars['String']>>;
};


export type QueryMenuItemEligibilityStatusArgs = {
  serviceType: Maybe<ServiceType>;
  serviceName: Maybe<Scalars['String']>;
};


export type QueryOpenNewSharesOpenableTypesArgs = {
  memberId: Scalars['String'];
};


export type QueryRetrievalStepArgs = {
  getWizardStateParams: GetRetrievalWizardStateParams;
};


export type QueryNewInternalTransferDataArgs = {
  favoriteId: Maybe<Scalars['String']>;
};


export type QueryInternalSourceAccountIdsArgs = {
  destinationId: Scalars['String'];
};


export type QueryInternalDestinationAccountIdsArgs = {
  sourceId: Scalars['String'];
};


export type QueryInternalPaymentOptionsArgs = {
  sourceId: Scalars['String'];
  destinationId: Scalars['String'];
};


export type QueryBankInfoByRoutingNumberArgs = {
  routingNumber: Scalars['String'];
  routingNumberType: RoutingNumberType;
  allowOwnFi: Maybe<Scalars['Boolean']>;
};


export type QueryExternalTransferPaymentOptionsArgs = {
  sourceAccountId: Scalars['String'];
  destinationAccountId: Scalars['String'];
  isExternalAccountSource: Scalars['Boolean'];
};


export type QueryValidateExternalTransferArgs = {
  data: ExternalTransferInput;
};


export type QueryInvestmentDestinationAccountsArgs = {
  sourceId: Scalars['String'];
};


export type QueryInvestmentSourceAccountsArgs = {
  destinationId: Scalars['String'];
};


export type QueryInvestmentPaymentOptionsArgs = {
  sourceId: Scalars['String'];
  destinationId: Scalars['String'];
};


export type QueryValidateWireTransferArgs = {
  data: ValidateWireTransferInput;
};


export type QueryPayByCardPaymentOptionsArgs = {
  destinationId: Scalars['String'];
};


export type QueryGetSsoScenarioArgs = {
  providerId: Scalars['String'];
  useAccountFromLastChoice: Scalars['Boolean'];
  parameters: Scalars['String'];
};


export type QueryAccountSelectorStepArgs = {
  getWizardStateParams: GetWizardStateParams;
};


export type QueryGetSubuserEnrollmentInvitationByTokenArgs = {
  getSubuserEnrollmentInvitationByTokenParams: GetSubuserEnrollmentInvitationByTokenInput;
};


export type QuerySubuserEnrollmentStepArgs = {
  getWizardStateParams: GetWizardStateParams;
};


export type QueryGetSubuserTransferDetailsArgs = {
  token: Scalars['String'];
};


export type QueryGetTransferActivityDetailsArgs = {
  data: GetTransferActivityDetailsInput;
};


export type QueryUserEnrollmentStepArgs = {
  getWizardStateParams: GetWizardStateParams;
};


export type QueryValidateSecurityTokenArgs = {
  securityToken: Scalars['String'];
  securityTokenType: SecurityTokenType;
};


export type QueryTriggeredEventStepArgs = {
  getTriggeredEventParams: GetTriggeredEventParams;
};

/** The question pool type. */
export type QuestionPool = {
  __typename?: 'QuestionPool';
  /** The question. */
  question: SingleChoiceFieldInfo;
  /** The answer. */
  answer: FieldsUnion;
};

/** The question pools field info group type. */
export type QuestionPoolsFieldInfoGroup = UiElementInfo & FieldInfoBase & {
  __typename?: 'QuestionPoolsFieldInfoGroup';
  /** The answer maximum length. */
  answerMaxLength: Scalars['Int'];
  /** The answer minimum length. */
  answerMinLength: Scalars['Int'];
  /** The list of questions. */
  questionPools: Array<QuestionPool>;
  /** The field Id. */
  id: Scalars['ID'];
  /** Field type. */
  type: FieldType;
  /** Field Name, Can be used as Label in AT and/or for validation and error messages. */
  name: Maybe<Scalars['String']>;
  /** Field Description Can be used as tooltip or watermark on a presentation level */
  description: Maybe<Scalars['String']>;
  /** Gets value which indicates whether this field is enabled in the current configuration. */
  isEnabled: Scalars['Boolean'];
  /** Label for front-end UI */
  label: Maybe<Scalars['String']>;
  /** Indicates whether the field with the field value needs to be submitted in SubmitWizardStepFieldValues API calls. */
  isSubmittable: Scalars['Boolean'];
  /**
   * client-based property bindings.
   *                     Bindings are exposed to the web app to form a kind of name-value collection of data items
   *                     required to property display a configurable field (that is called DataContext).
   */
  bindings: Maybe<Array<Maybe<Binding>>>;
  /** Field Behavior e.g Required, Readonly */
  fieldBehaviorMode: FieldBehaviorMode;
  /** This property stores the field input hint. Intended to be displayed by native apps on mobile devices. */
  placeholder: Maybe<Scalars['String']>;
};

export type QuickLoginSettings = {
  __typename?: 'QuickLoginSettings';
  /** Indicates whether the quick login feature is enabled. */
  isEnabled: Scalars['Boolean'];
};

export enum ReCaptchaVersionCodes {
  /** reCaptcha v2 */
  VERSION_2 = 'VERSION_2',
  /** reCaptcha v3 */
  VERSION_3 = 'VERSION_3'
}

export type RecaptchaSettingsConfiguration = {
  __typename?: 'RecaptchaSettingsConfiguration';
  isEnabled: Scalars['Boolean'];
  apiUrl: Scalars['String'];
  siteKeyV2: Scalars['String'];
  siteKeyV3: Scalars['String'];
  allowListedIPAddresses: Maybe<Array<Maybe<Scalars['String']>>>;
  allowlistedUsernames: Maybe<Array<Maybe<Scalars['String']>>>;
  minScoreForSuccess: Scalars['Decimal'];
  secretKeyV2: Maybe<Scalars['String']>;
  secretKeyV3: Maybe<Scalars['String']>;
  siteVerifyUrl: Maybe<Scalars['String']>;
};

export type Recipient = {
  __typename?: 'Recipient';
  /** Recipient's identifier. */
  id: Scalars['Long'];
  /** Recipient's name. */
  name: Scalars['String'];
  /** Recipient's routing number. */
  routingNumber: Scalars['String'];
  /** Recipient's account type. */
  recipientAccountType: RecipientAccountType;
  /** Recipient's type. */
  recipientType: RecipientType;
  /** Recipient's account number. */
  accountNumber: Scalars['String'];
  /** Recipient's bank name. */
  bankName: Maybe<Scalars['String']>;
  /** Recipient's amount. */
  defaultAmount: Maybe<Scalars['Decimal']>;
  /** Recipient's note. */
  note: Maybe<Scalars['String']>;
  /** Recipient's reference. */
  referenceId: Maybe<Scalars['String']>;
  /** Recipient's email. */
  email: Maybe<Scalars['String']>;
};

export enum RecipientAccountType {
  UNKNOWN = 'UNKNOWN',
  CHECKING = 'CHECKING',
  SAVING = 'SAVING'
}

export type RecipientDetails = {
  __typename?: 'RecipientDetails';
  /** Recipient's identifier. */
  id: Scalars['Long'];
  /** Recipient's name. */
  name: Scalars['String'];
  /** Recipient's routing number. */
  routingNumber: Scalars['String'];
  /** Recipient's account type. */
  recipientAccountType: RecipientAccountType;
  /** Recipient's type. */
  recipientType: RecipientType;
  /** Recipient's account number. */
  accountNumber: Scalars['String'];
  /** Recipient's full bank name. */
  fullBankName: Maybe<Scalars['String']>;
  /** Recipient's short bank name. */
  shortBankName: Maybe<Scalars['String']>;
  /** Recipient's masked account number. */
  maskedAccountNumber: Scalars['String'];
  /** Recipient's email. */
  email: Maybe<Scalars['String']>;
};

export type RecipientDetailsInput = {
  /** Recipient's default amount */
  defaultAmount: Maybe<Scalars['Decimal']>;
  /** Recipient's addenda */
  note: Maybe<Scalars['String']>;
  /** Recipient's reference Id */
  referenceId: Maybe<Scalars['String']>;
};

export type RecipientInTemplate = {
  __typename?: 'RecipientInTemplate';
  /** Identifier of the recipient record for given template. */
  id: Scalars['Long'];
  /** Identifier of the recipient added to the template */
  recipientId: Scalars['Long'];
  /** Name of the recipient. */
  name: Scalars['String'];
  /** Type of the recipient. */
  recipientType: RecipientType;
  /** Masked account number of the recipient. */
  maskedAccountNumber: Scalars['String'];
  /** Recipient's amount. */
  amount: Maybe<Scalars['Decimal']>;
  /** Recipient's note. */
  note: Maybe<Scalars['String']>;
  /** Recipient's reference. */
  referenceId: Maybe<Scalars['String']>;
  /** The recipient's full bank name */
  fullBankName: Maybe<Scalars['String']>;
  /** The recipient's short bank name */
  shortBankName: Maybe<Scalars['String']>;
  /** The recipient's email */
  email: Maybe<Scalars['String']>;
};

export type RecipientInTemplateInput = {
  /** Identifier of the recipient record for given template. Null when adding a new recipient record. */
  id: Maybe<Scalars['Long']>;
  /** Identifier of the recipient added to the template */
  recipientId: Scalars['Long'];
  /** Recipient's amount for given template */
  amount: Maybe<Scalars['Decimal']>;
  /** Recipient's note for given template */
  note: Maybe<Scalars['String']>;
  /** Recipient's reference for given template */
  referenceId: Maybe<Scalars['String']>;
  /** Recipient's email */
  email: Maybe<Scalars['String']>;
};

export type RecipientInput = {
  /** Name of recipient */
  name: Scalars['String'];
  /** Type of recipient. See enum values for details */
  recipientType: RecipientType;
  /** Routing number of recipient's bank */
  routingNumber: Scalars['String'];
  /** Type of recipient account. See enum values for details */
  recipientAccountType: RecipientAccountType;
  /** Number of recipient's account */
  accountNumber: Scalars['String'];
  /** Nullable email of recipient's account */
  email: Maybe<Scalars['String']>;
};

export enum RecipientType {
  UNKNOWN = 'UNKNOWN',
  INDIVIDUAL = 'INDIVIDUAL',
  ORGANIZATION = 'ORGANIZATION'
}

export type RecurringOption = {
  __typename?: 'RecurringOption';
  /** Unique recurring option id. */
  id: Scalars['ID'];
  /** User friendly display name of the recurring option. */
  displayName: Scalars['String'];
  /** Indicates whether user should field two dates (instead of usual one) when this option is selected during transfer creation. */
  isSecondStartDateSupported: Scalars['Boolean'];
  /** Indicates whether Amount field is visible for user when this option is selected */
  canModifyAmount: Scalars['Boolean'];
  /** Indicates whether Start Date field is visible for user when this option is selected */
  isStartDateSupported: Scalars['Boolean'];
  /** Indicates whether End Date field is visible for user when this option is selected */
  isEndDateSupported: Scalars['Boolean'];
  /** Indicates whether End After count field is visible for user when this option is selected */
  isEndAfterCountSupported: Scalars['Boolean'];
  /** Indicates whether payment options are available for user when this option is selected */
  arePaymentOptionsSupported: Scalars['Boolean'];
};

export type RecurringOptionSettings = {
  __typename?: 'RecurringOptionSettings';
  /** Identifier of recurring option */
  recurringOptionId: Scalars['String'];
  /** Recurring start date */
  startDate: Scalars['DateTime'];
  /** Recurring second start date if supported */
  secondStartDate: Maybe<Scalars['DateTime']>;
  /** Recurring end date */
  endDate: Maybe<Scalars['DateTime']>;
  /** Count of transfers to end */
  endAfterTransferCount: Maybe<Scalars['Int']>;
};

export type RecurringOptionSettingsInput = {
  /** Identifier of recurring option */
  recurringOptionId: Scalars['String'];
  /** Recurring start date */
  startDate: Scalars['Date'];
  /** Recurring second start date if supported */
  secondStartDate: Maybe<Scalars['Date']>;
  /** Recurring end date */
  endDate: Maybe<Scalars['Date']>;
  /** Count of transfers to end */
  endAfterTransferCount: Maybe<Scalars['Int']>;
};

export type RegisterSubuserEnrollmentInviteTokenInput = {
  /** Valid Invite token from email. */
  inviteToken: Scalars['String'];
};

export type RegisterSubuserEnrollmentInviteTokenResult = {
  __typename?: 'RegisterSubuserEnrollmentInviteTokenResult';
  /** Success Summary Message. */
  successSummary: Array<Maybe<Scalars['String']>>;
  /** List of errors. */
  errors: Array<Maybe<ValidationUnion>>;
  /** List of warnings. */
  warnings: Array<Maybe<ValidationUnion>>;
  /** Indicates whether the operation succeed. */
  successful: Scalars['Boolean'];
};

export type RejectSubuserEnrollmentInviteTokenInput = {
  /** Valid Invite token from email. */
  inviteToken: Scalars['String'];
};

export type RejectSubuserEnrollmentInviteTokenResult = {
  __typename?: 'RejectSubuserEnrollmentInviteTokenResult';
  /** Success Summary Message. */
  successSummary: Array<Maybe<Scalars['String']>>;
  /** List of errors. */
  errors: Array<Maybe<ValidationUnion>>;
  /** List of warnings. */
  warnings: Array<Maybe<ValidationUnion>>;
  /** Indicates whether the operation succeed. */
  successful: Scalars['Boolean'];
};

export type RejectSubuserTransferInput = {
  /** User password for reject transaction */
  password: Scalars['String'];
  /** Token of transaction from email */
  token: Scalars['String'];
};

export type RejectSubuserTransferResult = {
  __typename?: 'RejectSubuserTransferResult';
  /** Allow to retry reject transaction */
  isRetryAllowed: Scalars['Boolean'];
  /** Validation result of operation. */
  validationResult: Maybe<ValidationResultContainer>;
};

export type RelinkInstitutionResult = {
  __typename?: 'RelinkInstitutionResult';
  linkToken: Scalars['String'];
  successful: Scalars['Boolean'];
  errors: Maybe<Array<ValidationItem>>;
  warnings: Maybe<Array<ValidationItem>>;
  infos: Maybe<Array<ValidationItem>>;
};

export type RemoveFavoriteInput = {
  /** Id of favorite what should remove */
  favoriteId: Scalars['String'];
};

export type RemoveFavoriteResult = {
  __typename?: 'RemoveFavoriteResult';
  /** Contains summary about operation execution. */
  validationResult: Maybe<ValidationResultContainer>;
};

export type ReorderNewCardResult = {
  __typename?: 'ReorderNewCardResult';
  newCard: Maybe<CardPaymentDetails>;
  successful: Scalars['Boolean'];
  errors: Maybe<Array<ValidationItem>>;
  warnings: Maybe<Array<ValidationItem>>;
  infos: Maybe<Array<ValidationItem>>;
};

export type ReorderNewFeature = {
  __typename?: 'ReorderNewFeature';
  isEnabled: Scalars['Boolean'];
  availableDeliveryMethods: Array<DeliveryCardMethod>;
};

export type ReorderSameCardResult = {
  __typename?: 'ReorderSameCardResult';
  successful: Scalars['Boolean'];
  errors: Maybe<Array<ValidationItem>>;
  warnings: Maybe<Array<ValidationItem>>;
  infos: Maybe<Array<ValidationItem>>;
};

export enum RetrievalType {
  /** Represents the username type of retrieval. */
  USERNAME = 'USERNAME',
  /** Represents the password type of retrieval. */
  PASSWORD = 'PASSWORD'
}

export type RetrievalWizardResult = {
  __typename?: 'RetrievalWizardResult';
  /** Flag shows if Wizard finished */
  wizardFinished: Scalars['Boolean'];
  /** Represent the list of success summary strings. */
  successSummary: Array<Maybe<Scalars['String']>>;
};

/** The retrieval wizard result. */
export type RetrievalWizardResultUnion = NextStep | RetrievalWizardResult;

export enum RoutingNumberType {
  UNKNOWN = 'UNKNOWN',
  ACH = 'ACH',
  WIRE = 'WIRE',
  ACHWIRE = 'ACHWIRE'
}

/** Alert to create or edit. */
export type SaveAlertInput = {
  /** Configurable alert fields */
  values: Array<FieldValue>;
};

/** Create or update alert. */
export type SaveAlertResponse = {
  __typename?: 'SaveAlertResponse';
  /** Alert settings group representation. */
  accountAlertTypeGroup: Maybe<AccountAlertTypeGroup>;
  /** Indicates if the action is successful. */
  successful: Scalars['Boolean'];
  /** The list of errors. */
  errors: Maybe<Array<ValidationItem>>;
};

/** Represents container for SaveDeliveryOptions command. */
export type SaveDeliveryOptionsInput = {
  /** Form unique identifier. */
  formId: Scalars['String'];
  /** Email channel ON/OFF status. */
  email: Maybe<Scalars['Boolean']>;
  /** Sms channel ON/OFF status. */
  sms: Maybe<Scalars['Boolean']>;
  /** MobileNotification channel ON/OFF status. */
  mobileNotification: Maybe<Scalars['Boolean']>;
};

/** Save delivery options response container. */
export type SaveDeliveryOptionsResponse = {
  __typename?: 'SaveDeliveryOptionsResponse';
  /** Indicates if the action is successful */
  successful: Scalars['Boolean'];
  /** The list of errors */
  errors: Maybe<Array<ValidationItem>>;
  /** New description for delivery option. */
  description: Scalars['String'];
};

export type SaveTravelInput = {
  travel: TravelInput;
  ssn: Maybe<Scalars['String']>;
  cvv2: Maybe<Scalars['String']>;
};

export type SaveTravelResult = {
  __typename?: 'SaveTravelResult';
  travels: Array<Travel>;
  successful: Scalars['Boolean'];
  errors: Maybe<Array<ValidationItem>>;
  warnings: Maybe<Array<ValidationItem>>;
  infos: Maybe<Array<ValidationItem>>;
};

export enum SavingType {
  UNSPECIFIED = 'UNSPECIFIED',
  IRA = 'IRA',
  MONEYMARKET = 'MONEYMARKET',
  CERTIFICATEOFDEPOSIT = 'CERTIFICATEOFDEPOSIT'
}

/** Security alert representation. */
export type SecurityAlert = {
  __typename?: 'SecurityAlert';
  formId: Scalars['String'];
  title: Scalars['String'];
  description: Scalars['String'];
  infoText: Maybe<Scalars['String']>;
  alertType: AlertType;
  toggleState: Scalars['Boolean'];
  notEditable: Scalars['Boolean'];
};

/** Security alerts group representation. */
export type SecurityAlertGroup = BaseAlertGroup & {
  __typename?: 'SecurityAlertGroup';
  /** Alert group id. */
  id: Scalars['String'];
  /** Alert group title. */
  title: Scalars['String'];
  /** Alerts group's delivery options. */
  deliveryOptions: DeliveryOptions;
  /** Security alerts in the group. */
  alerts: Maybe<Array<SecurityAlert>>;
};

export enum SecurityTokenType {
  UNKNOWN = 'UNKNOWN',
  CHALLENGEQUESTION = 'CHALLENGEQUESTION',
  PASSWORD = 'PASSWORD',
  FILEACCESS = 'FILEACCESS',
  USERACCOUNTLOCKOUTLINK = 'USERACCOUNTLOCKOUTLINK'
}

/** The SelectableGroupFieldInfo field info type. */
export type SelectableGroupFieldInfo = UiElementInfo & {
  __typename?: 'SelectableGroupFieldInfo';
  /** The Id of the selected field group. */
  selectedFieldGroupId: Maybe<Scalars['String']>;
  /** The list of field groups. */
  fieldGroups: Array<Maybe<FieldInfoGroup>>;
  /** The field Id. */
  id: Scalars['ID'];
  /** Field type. */
  type: FieldType;
  /** Field Name, Can be used as Label in AT and/or for validation and error messages. */
  name: Maybe<Scalars['String']>;
  /** Field Description Can be used as tooltip or watermark on a presentation level */
  description: Maybe<Scalars['String']>;
  /** Gets value which indicates whether this field is enabled in the current configuration. */
  isEnabled: Scalars['Boolean'];
  /** Label for front-end UI */
  label: Maybe<Scalars['String']>;
  /** Indicates whether the field with the field value needs to be submitted in SubmitWizardStepFieldValues API calls. */
  isSubmittable: Scalars['Boolean'];
  /**
   * client-based property bindings.
   *                     Bindings are exposed to the web app to form a kind of name-value collection of data items
   *                     required to property display a configurable field (that is called DataContext).
   */
  bindings: Maybe<Array<Maybe<Binding>>>;
};

export type SendOneOffInvoiceInput = {
  /** ID of the recipient of the one-off invoice */
  recipientId: Scalars['Long'];
  /** Invoice number of the one-off invoice */
  invoiceNumber: Scalars['String'];
  /** The invoice due date */
  dueDate: Scalars['Date'];
  /** The tax rate for the invoice */
  taxRate: Scalars['Decimal'];
  /** Payment instructions for the invoice */
  paymentInstructions: Scalars['String'];
  /** Item details of the invoice */
  items: Array<OneOffInvoiceItemInput>;
  /** Email, to which the invoice sending should be replied to */
  replyToEmail: Scalars['String'];
};

export type SendOneOffInvoiceResult = {
  __typename?: 'SendOneOffInvoiceResult';
  /** Indicates if the action is successful */
  successful: Scalars['Boolean'];
  /** The list of errors */
  errors: Maybe<Array<ValidationItem>>;
  /** The list of warnings */
  warnings: Maybe<Array<ValidationItem>>;
  /** The list of infos */
  infos: Maybe<Array<ValidationItem>>;
};

export enum ServiceType {
  UNKNOWN = 'UNKNOWN',
  SSO = 'SSO',
  FAMILYANDFRIENDS = 'FAMILYANDFRIENDS',
  ESTATEMENTS = 'ESTATEMENTS',
  OPENNEWSHARES = 'OPENNEWSHARES',
  OVERDRAFTPROTECTION = 'OVERDRAFTPROTECTION',
  BUSINESSBANKING = 'BUSINESSBANKING',
  CASHMANAGEMENT = 'CASHMANAGEMENT',
  YEARENDINFO = 'YEARENDINFO',
  ACHTRANSACTIONHISTORY = 'ACHTRANSACTIONHISTORY',
  PLAID = 'PLAID',
  CREDITSCORE = 'CREDITSCORE',
  ONEOFFINVOICE = 'ONEOFFINVOICE'
}

export type SessionSettings = {
  __typename?: 'SessionSettings';
  userSessionExpirationInSecs: Scalars['Int'];
};

export type SetAllCardTransactionsAlertsInput = {
  cardKey: Scalars['String'];
  isOn: Scalars['Boolean'];
};

export type SetAllCardTransactionsAlertsResult = {
  __typename?: 'SetAllCardTransactionsAlertsResult';
  successful: Scalars['Boolean'];
  errors: Maybe<Array<ValidationItem>>;
  warnings: Maybe<Array<ValidationItem>>;
  infos: Maybe<Array<ValidationItem>>;
};

export type SetBankAccountNameInput = {
  accountId: Scalars['String'];
  name: Scalars['String'];
};

export type SetBankAccountNameResult = {
  __typename?: 'SetBankAccountNameResult';
  newName: Maybe<Scalars['String']>;
  successful: Scalars['Boolean'];
  errors: Maybe<Array<ValidationItem>>;
  warnings: Maybe<Array<ValidationItem>>;
  infos: Maybe<Array<ValidationItem>>;
};

export type SetBankAccountVisibilityInput = {
  accountId: Scalars['String'];
  isVisible: Scalars['Boolean'];
};

export type SetCardAlertsDeliveryChannelsInput = {
  cardKey: Scalars['String'];
  channels: AlertsChannelsPreferencesInput;
};

export type SetCardAlertsDeliveryChannelsResult = {
  __typename?: 'SetCardAlertsDeliveryChannelsResult';
  successful: Scalars['Boolean'];
  errors: Maybe<Array<ValidationItem>>;
  warnings: Maybe<Array<ValidationItem>>;
  infos: Maybe<Array<ValidationItem>>;
};

export type SetCardAlertsStatusInput = {
  cardKey: Scalars['String'];
  isOn: Scalars['Boolean'];
};

export type SetCardAlertsStatusResult = {
  __typename?: 'SetCardAlertsStatusResult';
  successful: Scalars['Boolean'];
  errors: Maybe<Array<ValidationItem>>;
  warnings: Maybe<Array<ValidationItem>>;
  infos: Maybe<Array<ValidationItem>>;
};

export type SetCardControlsStatusInput = {
  cardKey: Scalars['String'];
  isOn: Scalars['Boolean'];
};

export type SetCardControlsStatusResult = {
  __typename?: 'SetCardControlsStatusResult';
  successful: Scalars['Boolean'];
  errors: Maybe<Array<ValidationItem>>;
  warnings: Maybe<Array<ValidationItem>>;
  infos: Maybe<Array<ValidationItem>>;
};

export type SetCardInternationalLocationAlertsInput = {
  cardKey: Scalars['String'];
  isOn: Scalars['Boolean'];
};

export type SetCardInternationalLocationAlertsResult = {
  __typename?: 'SetCardInternationalLocationAlertsResult';
  successful: Scalars['Boolean'];
  errors: Maybe<Array<ValidationItem>>;
  warnings: Maybe<Array<ValidationItem>>;
  infos: Maybe<Array<ValidationItem>>;
};

export type SetCardInternationalLocationControlsInput = {
  cardKey: Scalars['String'];
  isOn: Scalars['Boolean'];
};

export type SetCardInternationalLocationControlsResult = {
  __typename?: 'SetCardInternationalLocationControlsResult';
  successful: Scalars['Boolean'];
  errors: Maybe<Array<ValidationItem>>;
  warnings: Maybe<Array<ValidationItem>>;
  infos: Maybe<Array<ValidationItem>>;
};

export type SetCardLimitsInput = {
  cardKey: Scalars['ID'];
  limit: Scalars['Decimal'];
};

export type SetCardLimitsResult = {
  __typename?: 'SetCardLimitsResult';
  card: Maybe<Card>;
  successful: Scalars['Boolean'];
  errors: Maybe<Array<ValidationItem>>;
  warnings: Maybe<Array<ValidationItem>>;
  infos: Maybe<Array<ValidationItem>>;
};

export type SetCardMerchantAlertsStatusInput = {
  cardKey: Scalars['String'];
  types: Array<MerchantPreferenceTypeInput>;
};

export type SetCardMerchantAlertsStatusResult = {
  __typename?: 'SetCardMerchantAlertsStatusResult';
  successful: Scalars['Boolean'];
  errors: Maybe<Array<ValidationItem>>;
  warnings: Maybe<Array<ValidationItem>>;
  infos: Maybe<Array<ValidationItem>>;
};

export type SetCardMerchantControlsStatusInput = {
  cardKey: Scalars['String'];
  types: Array<MerchantPreferenceTypeInput>;
};

export type SetCardMerchantControlsStatusResult = {
  __typename?: 'SetCardMerchantControlsStatusResult';
  successful: Scalars['Boolean'];
  errors: Maybe<Array<ValidationItem>>;
  warnings: Maybe<Array<ValidationItem>>;
  infos: Maybe<Array<ValidationItem>>;
};

export type SetCardPinInput = {
  cardKey: Scalars['ID'];
  pin: Scalars['String'];
  ssn: Maybe<Scalars['String']>;
  cvv2: Maybe<Scalars['String']>;
};

export type SetCardPinResult = {
  __typename?: 'SetCardPinResult';
  successful: Scalars['Boolean'];
  errors: Maybe<Array<ValidationItem>>;
  warnings: Maybe<Array<ValidationItem>>;
  infos: Maybe<Array<ValidationItem>>;
};

export type SetCardSpendLimitAlertsStatusInput = {
  cardKey: Scalars['String'];
  limits: Array<SpendLimitPreferenceInput>;
};

export type SetCardSpendLimitAlertsStatusResult = {
  __typename?: 'SetCardSpendLimitAlertsStatusResult';
  successful: Scalars['Boolean'];
  errors: Maybe<Array<ValidationItem>>;
  warnings: Maybe<Array<ValidationItem>>;
  infos: Maybe<Array<ValidationItem>>;
};

export type SetCardSpendLimitControlsStatusInput = {
  cardKey: Scalars['String'];
  limits: Array<SpendLimitPreferenceInput>;
};

export type SetCardSpendLimitControlsStatusResult = {
  __typename?: 'SetCardSpendLimitControlsStatusResult';
  successful: Scalars['Boolean'];
  errors: Maybe<Array<ValidationItem>>;
  warnings: Maybe<Array<ValidationItem>>;
  infos: Maybe<Array<ValidationItem>>;
};

export type SetCardStatusInput = {
  cardKey: Scalars['ID'];
  isCardEnabled: Scalars['Boolean'];
};

export type SetCardStatusResult = {
  __typename?: 'SetCardStatusResult';
  card: Maybe<Card>;
  successful: Scalars['Boolean'];
  errors: Maybe<Array<ValidationItem>>;
  warnings: Maybe<Array<ValidationItem>>;
  infos: Maybe<Array<ValidationItem>>;
};

export type SetCardTransactionAlertsStatusInput = {
  cardKey: Scalars['String'];
  types: Array<TransactionPreferenceTypeInput>;
};

export type SetCardTransactionAlertsStatusResult = {
  __typename?: 'SetCardTransactionAlertsStatusResult';
  successful: Scalars['Boolean'];
  errors: Maybe<Array<ValidationItem>>;
  warnings: Maybe<Array<ValidationItem>>;
  infos: Maybe<Array<ValidationItem>>;
};

export type SetCardTransactionControlsStatusInput = {
  cardKey: Scalars['String'];
  types: Array<TransactionPreferenceTypeInput>;
};

export type SetCardTransactionControlsStatusResult = {
  __typename?: 'SetCardTransactionControlsStatusResult';
  successful: Scalars['Boolean'];
  errors: Maybe<Array<ValidationItem>>;
  warnings: Maybe<Array<ValidationItem>>;
  infos: Maybe<Array<ValidationItem>>;
};

export type SetConsumerNicknameInput = {
  consumerId: Scalars['String'];
  nickname: Scalars['String'];
};

export type SetConsumerVisibilityInput = {
  consumerId: Scalars['String'];
  isVisible: Scalars['Boolean'];
};

export type SetCreditScoreOptInSettingsInput = {
  settings: Array<CreditScoreOptInSettingInput>;
};

/** Represents container for SetMessageStatus command. */
export type SetMessageStatusInput = {
  /** Message recipients. */
  messageIds: Array<Scalars['String']>;
  /** New message subject. */
  status: Scalars['String'];
};

export type SetOptInSettingInput = {
  memberNumber: Scalars['String'];
  isEnabled: Scalars['Boolean'];
};

export type SetOptInSettingResult = {
  __typename?: 'SetOptInSettingResult';
  successful: Scalars['Boolean'];
  errors: Maybe<Array<ValidationItem>>;
  warnings: Maybe<Array<ValidationItem>>;
  infos: Maybe<Array<ValidationItem>>;
};

export type SetOverdraftProtectionOptInSettingsInput = {
  settings: Array<OverdraftProtectionOptInSettingInput>;
};

export type SetOverdraftProtectionOptInSettingsResult = {
  __typename?: 'SetOverdraftProtectionOptInSettingsResult';
  successful: Scalars['Boolean'];
  errors: Maybe<Array<ValidationItem>>;
  warnings: Maybe<Array<ValidationItem>>;
  infos: Maybe<Array<ValidationItem>>;
};

export type SetPreferredCultureResult = {
  __typename?: 'SetPreferredCultureResult';
  /** Indicates if the action is successful */
  successful: Scalars['Boolean'];
  /** The list of errors */
  errors: Maybe<Array<ValidationItem>>;
  /** The list of warnings */
  warnings: Maybe<Array<ValidationItem>>;
  /** The list of infos */
  infos: Maybe<Array<ValidationItem>>;
};

export type SetStatementTransactionNoteInput = {
  transactionId: Scalars['String'];
  note: Scalars['String'];
};

export type SetTimeZoneResult = {
  __typename?: 'SetTimeZoneResult';
  /** Indicates if the action is successful */
  successful: Scalars['Boolean'];
  /** The list of errors */
  errors: Maybe<Array<ValidationItem>>;
  /** The list of warnings */
  warnings: Maybe<Array<ValidationItem>>;
  /** The list of infos */
  infos: Maybe<Array<ValidationItem>>;
  /** User time zone. */
  userTimeZone: UserTimeZone;
};

/** Represents the input params for SetUserPassword mutation. */
export type SetUserPasswordInput = {
  /** The user's old password. */
  oldPassword: Scalars['String'];
  /** The user's new password. */
  newPassword: Scalars['String'];
};

/** The SetUserPassword result. */
export type SetUserPasswordResult = {
  __typename?: 'SetUserPasswordResult';
  /** Success Summary Message. */
  successSummary: Array<Maybe<Scalars['String']>>;
  /** List of errors. */
  errors: Array<Maybe<ValidationUnion>>;
  /** List of warnings. */
  warnings: Array<Maybe<ValidationUnion>>;
  /** Indicates whether the set password operation succeed. */
  successful: Scalars['Boolean'];
};

export type Settings = {
  __typename?: 'Settings';
  cards: CardsSettings;
  travels: TravelsSettings;
  dictionaries: Dictionaries;
  mapsSettings: MapsSettings;
};

export type SimpleDictionary = {
  __typename?: 'SimpleDictionary';
  code: Scalars['String'];
  name: Scalars['String'];
};

/** The single choice field info base type. */
export type SingleChoiceFieldInfo = UiElementInfo & FieldInfoBase & {
  __typename?: 'SingleChoiceFieldInfo';
  /** Default Selected Option Text */
  defaultChoiceText: Maybe<Scalars['String']>;
  /** Selected Value */
  selectedValue: Maybe<Scalars['String']>;
  /** The field Id. */
  id: Scalars['ID'];
  /** Field type. */
  type: FieldType;
  /** Field Name, Can be used as Label in AT and/or for validation and error messages. */
  name: Maybe<Scalars['String']>;
  /** Field Description Can be used as tooltip or watermark on a presentation level */
  description: Maybe<Scalars['String']>;
  /** Gets value which indicates whether this field is enabled in the current configuration. */
  isEnabled: Scalars['Boolean'];
  /** Label for front-end UI */
  label: Maybe<Scalars['String']>;
  /** Indicates whether the field with the field value needs to be submitted in SubmitWizardStepFieldValues API calls. */
  isSubmittable: Scalars['Boolean'];
  /**
   * client-based property bindings.
   *                     Bindings are exposed to the web app to form a kind of name-value collection of data items
   *                     required to property display a configurable field (that is called DataContext).
   */
  bindings: Maybe<Array<Maybe<Binding>>>;
  /** Field Behavior e.g Required, Readonly */
  fieldBehaviorMode: FieldBehaviorMode;
  /** This property stores the field input hint. Intended to be displayed by native apps on mobile devices. */
  placeholder: Maybe<Scalars['String']>;
  /** Choose list. */
  choiceList: Maybe<Array<Maybe<Choice>>>;
};

export type SkipPayAccount = {
  __typename?: 'SkipPayAccount';
  /** Bank account identifier. This identifier is unique among all accounts. */
  id: Scalars['ID'];
  /** Indicate whether available balance should be shown for this account. */
  showAvailableBalance: Scalars['Boolean'];
  /** Indicate whether available balance should be shown for this account. */
  showCurrentBalance: Scalars['Boolean'];
  /** Skip Pay Fee amount. */
  fee: Scalars['Decimal'];
};

export type SkipPayAllInfoPayload = {
  __typename?: 'SkipPayAllInfoPayload';
  /** List of accounts that can be chosen as a billing account for skip pay. */
  billingAccounts: Array<BillingAccount>;
  /** List of accounts eligible for skip pay. */
  skipPayAccounts: Array<SkipPayAccount>;
};

export type SkipPayInput = {
  /** Account id for skip payment. */
  skipPayAccountId: Scalars['String'];
  /** Fee source account id. */
  billingAccountId: Scalars['String'];
};

export type SkipPayResult = {
  __typename?: 'SkipPayResult';
  /** Next payment date. */
  newDueDate: Maybe<Scalars['DateTime']>;
  /** Validation result of operation. */
  validationResult: Maybe<ValidationResultContainer>;
};

/** The SmsEnrollment result. */
export type SmsEnrollmentResult = {
  __typename?: 'SmsEnrollmentResult';
  /** Success Summary Message. */
  successSummary: Array<Maybe<Scalars['String']>>;
  /** List of errors. */
  errors: Array<Maybe<ValidationUnion>>;
  /** List of warnings. */
  warnings: Array<Maybe<ValidationUnion>>;
  /** Indicates whether the confirm one time password operation succeed. */
  successful: Scalars['Boolean'];
  /** Result of sms enrollment status, if the operation is successful. */
  smsEnrollmentStatus: PhoneSmsEnrollmentStatus;
};

export type SpendLimitPreference = {
  __typename?: 'SpendLimitPreference';
  isOn: Scalars['Boolean'];
  code: Scalars['String'];
  current: Scalars['Decimal'];
};

export type SpendLimitPreferenceInput = {
  isOn: Scalars['Boolean'];
  code: Scalars['String'];
  current: Scalars['Decimal'];
};

export type SpendLimitPreferences = {
  __typename?: 'SpendLimitPreferences';
  isEnabled: Scalars['Boolean'];
  limits: Array<SpendLimitPreference>;
};

/** The ssn text field info type. */
export type SsnFieldInfo = UiElementInfo & FieldInfoBase & {
  __typename?: 'SsnFieldInfo';
  /** Data pattern. */
  dataMask: Maybe<Scalars['String']>;
  /** Visible Data Mask. */
  dataPattern: Maybe<Scalars['String']>;
  /** Ssn Validation Pattern. */
  validationPattern: Maybe<Scalars['String']>;
  /** MaskFormat should conform following notation: http://docs.telerik.com/kendo-ui/api/web/maskedtextbox#configuration-mask */
  maskFormat: Scalars['String'];
  /** The field Id. */
  id: Scalars['ID'];
  /** Field type. */
  type: FieldType;
  /** Field Name, Can be used as Label in AT and/or for validation and error messages. */
  name: Maybe<Scalars['String']>;
  /** Field Description Can be used as tooltip or watermark on a presentation level */
  description: Maybe<Scalars['String']>;
  /** Gets value which indicates whether this field is enabled in the current configuration. */
  isEnabled: Scalars['Boolean'];
  /** Label for front-end UI */
  label: Maybe<Scalars['String']>;
  /** Indicates whether the field with the field value needs to be submitted in SubmitWizardStepFieldValues API calls. */
  isSubmittable: Scalars['Boolean'];
  /**
   * client-based property bindings.
   *                     Bindings are exposed to the web app to form a kind of name-value collection of data items
   *                     required to property display a configurable field (that is called DataContext).
   */
  bindings: Maybe<Array<Maybe<Binding>>>;
  /** Field Behavior e.g Required, Readonly */
  fieldBehaviorMode: FieldBehaviorMode;
  /** This property stores the field input hint. Intended to be displayed by native apps on mobile devices. */
  placeholder: Maybe<Scalars['String']>;
  /** Max length of the field. */
  maxLength: Scalars['Int'];
  /** Min length of the field. */
  minLength: Scalars['Int'];
  /** The value of the field. */
  value: Maybe<Scalars['String']>;
};

export type SsoNativeSdkInformation = {
  __typename?: 'SsoNativeSdkInformation';
  /** Name of Native SDK. */
  name: Maybe<Scalars['String']>;
  /** List of parameters to be used during Native SDK launch. */
  parameters: Maybe<Scalars['Any']>;
};

export type SsoPermissions = {
  __typename?: 'SsoPermissions';
  memberId: Scalars['ID'];
  memberName: Scalars['String'];
  providers: Array<SsoProvider>;
};

export type SsoPermissionsInput = {
  memberId: Scalars['ID'];
  memberName: Scalars['String'];
  providers: Array<SsoProviderInput>;
};

export type SsoProvider = {
  __typename?: 'SsoProvider';
  ssoId: Scalars['String'];
  name: Scalars['String'];
  isAllowed: Scalars['Boolean'];
};

export type SsoProviderInput = {
  ssoId: Scalars['String'];
  name: Scalars['String'];
  isAllowed: Scalars['Boolean'];
};

export type SsoProviderLink = {
  __typename?: 'SsoProviderLink';
  /** SSO Provider Id */
  ssoId: Scalars['String'];
  /** Link to SSO Provider */
  link: Maybe<Scalars['String']>;
};

export type SsoUniversalAccountSelectorWizardResult = {
  __typename?: 'SsoUniversalAccountSelectorWizardResult';
  /** Flag shows if Wizard finished */
  wizardFinished: Scalars['Boolean'];
  /** Parameters for the final redirect to desired vendor page in user's browser. */
  finalRedirectScenario: Maybe<FinalRedirect>;
  /** The need to pass next account selector before SSO authentication. */
  nextAccountSelectorScenario: Maybe<AccountSelector>;
  /** Parameters for launching external mobile application. */
  openExternalAppScenario: Maybe<OpenExternalApp>;
  /** Represent the list of success summary strings. */
  successSummary: Array<Maybe<Scalars['String']>>;
};

/** SSO Universal Account Selector Wizard result. */
export type SsoUniversalAccountSelectorWizardResultUnion = NextStep | SsoUniversalAccountSelectorWizardResult;

/** The SSO widget */
export type SsoWidget = {
  __typename?: 'SsoWidget';
  /** Id of the Item */
  id: Maybe<Scalars['ID']>;
  /** The content item version id */
  contentItemVersionId: Maybe<Scalars['String']>;
  /** Type of content */
  contentType: Maybe<Scalars['String']>;
  /** The display text of the content item */
  displayText: Maybe<Scalars['String']>;
  /** Is the published version */
  published: Scalars['Boolean'];
  /** Is the latest version */
  latest: Scalars['Boolean'];
  /** The date and time of modification */
  modifiedUtc: Maybe<Scalars['DateTime']>;
  /** The date and time of publication */
  publishedUtc: Maybe<Scalars['DateTime']>;
  /** The date and time of creation */
  createdUtc: Maybe<Scalars['DateTime']>;
  /** The owner of the content item */
  owner: Maybe<Scalars['String']>;
  /** The author of the content item */
  author: Maybe<Scalars['String']>;
  /** Widget Flow Layout Meta Information from WYSIWYG Editor */
  flowMeta: Maybe<FlowMetadata>;
  providerId: Scalars['String'];
  width: Maybe<Scalars['String']>;
  height: Maybe<Scalars['String']>;
  hideIfIneligible: Maybe<Scalars['Boolean']>;
};

export enum StandardEntryClassCode {
  UNKNOWN = 'UNKNOWN',
  PPD = 'PPD',
  CCD = 'CCD',
  TEL = 'TEL',
  WEB = 'WEB'
}

export type StartLinkInstitutionResult = {
  __typename?: 'StartLinkInstitutionResult';
  linkToken: Scalars['String'];
  successful: Scalars['Boolean'];
  errors: Maybe<Array<ValidationItem>>;
  warnings: Maybe<Array<ValidationItem>>;
  infos: Maybe<Array<ValidationItem>>;
};

export type StatementTransactionAttachedImage = {
  __typename?: 'StatementTransactionAttachedImage';
  key: Scalars['ID'];
  fileName: Scalars['String'];
  contentSize: Scalars['Int'];
};

export type StatementTransactionAttachmentImagesValidation = {
  __typename?: 'StatementTransactionAttachmentImagesValidation';
  allowedContentTypes: Array<Scalars['String']>;
  fileMaxLength: Scalars['Int'];
  imageMinWidth: Scalars['Int'];
  imageMinHeight: Scalars['Int'];
};

export type StatementTransactionCategory = {
  __typename?: 'StatementTransactionCategory';
  id: Scalars['ID'];
  code: Maybe<Scalars['String']>;
  kind: Scalars['Int'];
};

export type StatementTransactionExporter = {
  __typename?: 'StatementTransactionExporter';
  id: Scalars['ID'];
  title: Scalars['String'];
};

export type StatementTransactionGraphItem = {
  __typename?: 'StatementTransactionGraphItem';
  date: Scalars['Date'];
  totalIncome: Scalars['Decimal'];
  totalExpense: Scalars['Decimal'];
  expenseTransactionsCount: Scalars['Int'];
  incomeTransactionsCount: Scalars['Int'];
  isPending: Scalars['Boolean'];
};

export type StatementTransactionHistoryItem = {
  __typename?: 'StatementTransactionHistoryItem';
  id: Scalars['ID'];
  amount: Maybe<Scalars['Decimal']>;
  name: Maybe<Scalars['String']>;
  runningBalance: Maybe<Scalars['Decimal']>;
  description: Maybe<Scalars['String']>;
  transactionDate: Scalars['Date'];
  paymentType: Maybe<Scalars['String']>;
  destinationType: Maybe<Scalars['String']>;
  checkNumber: Maybe<Scalars['String']>;
  note: Maybe<Scalars['String']>;
  transactionStatus: StatementTransactionStatus;
  category: Maybe<StatementTransactionCategory>;
  attachedImages: Array<StatementTransactionAttachedImage>;
  checkImages: Array<StatementTransactionAttachedImage>;
  isImageUploadingEnabled: Scalars['Boolean'];
  hasCheckImages: Scalars['Boolean'];
  isDynamicAmount: Scalars['Boolean'];
  isEditable: Scalars['Boolean'];
  principal: Maybe<Scalars['Decimal']>;
  curtailment: Maybe<Scalars['Decimal']>;
  interest: Maybe<Scalars['Decimal']>;
  taxAndInsurance: Maybe<Scalars['Decimal']>;
  miscellaneousInsurance: Maybe<Scalars['Decimal']>;
  lossDraftFee: Maybe<Scalars['Decimal']>;
  returnedCheckCharge: Maybe<Scalars['Decimal']>;
  foreclosureAndBankFee: Maybe<Scalars['Decimal']>;
  miscellaneousFee: Maybe<Scalars['Decimal']>;
  serviceFee: Maybe<Scalars['Decimal']>;
  unapplied: Maybe<Scalars['Decimal']>;
  lateCharge: Maybe<Scalars['Decimal']>;
  subsidy: Maybe<Scalars['Decimal']>;
  isParentTaskEditable: Scalars['Boolean'];
};

export enum StatementTransactionStatus {
  UNKNOWN = 'UNKNOWN',
  PROCESSING = 'PROCESSING',
  POSTED = 'POSTED',
  SCHEDULED = 'SCHEDULED',
  REJECTED = 'REJECTED'
}

/** AdService status. */
export type StatusResult = {
  __typename?: 'StatusResult';
  /** Successful Status. */
  isSuccessful: Scalars['Boolean'];
};

export type StopCheckInput = {
  bankAccountId: Scalars['String'];
  checkNumber: Scalars['String'];
};

export type StopCheckResult = {
  __typename?: 'StopCheckResult';
  /** Validation result of operation. */
  validationResult: Maybe<ValidationResultContainer>;
};

/** Phone or email or address to add or modify. */
export type SubmitContactFieldsInput = {
  /** Phone or email or address fields */
  values: Array<FieldValue>;
};

export type SubmitRetrievalWizardStepParams = {
  /** Selected retrieval type. */
  retrievalType: RetrievalType;
  /** Selected user kind. */
  userKind: UserKind;
  /** The current step Id. */
  stepId: Scalars['String'];
  /** Indicates whether the current step is a termination step. */
  isTerminationStep: Scalars['Boolean'];
  /** The list of the configurable field values in a wizard step. */
  stepData: Maybe<Array<Maybe<FieldValue>>>;
};

export type SubmitTriggeredEventParams = {
  /** The current triggered event step Id. */
  stepId: Scalars['String'];
  /** Indicates whether the current step is a termination step. */
  isTerminationStep: Scalars['Boolean'];
  /** The triggered event challenge Id. */
  challengeId: Scalars['String'];
  /** The list of the configurable field values in a triggered event step. */
  stepData: Maybe<Array<Maybe<FieldValue>>>;
};

/** Result of create or update operation. */
export type SubmitUserContactFieldsResult = {
  __typename?: 'SubmitUserContactFieldsResult';
  /** Unique identifier of created or updated email, phone or address, if the operation is successful. */
  id: Maybe<Scalars['String']>;
  /** Indicates if the action is successful */
  successful: Scalars['Boolean'];
  /** The list of errors */
  errors: Maybe<Array<ValidationItem>>;
  /** The list of warnings */
  warnings: Maybe<Array<ValidationItem>>;
};

export type SubmitUserKindWizardStepParams = {
  /** The list of the configurable field values in a user kind step */
  stepData: Maybe<Array<Maybe<FieldValue>>>;
};

export type SubmitWizardStepParams = {
  /** The current step Id. */
  stepId: Scalars['String'];
  /** Indicates whether the current step is a termination step. */
  isTerminationStep: Scalars['Boolean'];
  /** The list of the configurable field values in a wizard step. */
  stepData: Maybe<Array<Maybe<FieldValue>>>;
};

export type Subscription = {
  __typename?: 'Subscription';
  /** Plaid transactions ready notifications subscription */
  plaidTransactionsReadyNotification: PlaidTransactionsReadyNotification;
  /** New user message notification subscription */
  newUserMessageNotification: NewUserMessageNotification;
  /** New user message notification subscription */
  biometricAuthenticationNotification: BiometricAuthenticationNotification;
  /** Force user logout notification subscription */
  forceUserLogoutNotification: ForceUserLogoutNotification;
  /** Configuration update notification subscription */
  configUpdateNotification: ConfigurationChangedNotification;
};

export type Subuser = {
  __typename?: 'Subuser';
  key: Scalars['ID'];
  name: Scalars['String'];
  isPendingInvite: Scalars['Boolean'];
  isDualApprover: Scalars['Boolean'];
};

export type SubuserEnrollmentWizardResult = {
  __typename?: 'SubuserEnrollmentWizardResult';
  /** Flag shows if Wizard finished */
  wizardFinished: Scalars['Boolean'];
  /** Indicates whether the user is already enrolled. */
  alreadyEnrolled: Scalars['Boolean'];
  /** Represent The UserId. */
  userId: Scalars['Int'];
  /** Indicates whether the user is the member of the FI. */
  isMember: Scalars['Boolean'];
  /** Represent the list of success summary strings. */
  successSummary: Array<Maybe<Scalars['String']>>;
};

/** The subuser enrollment result. */
export type SubuserEnrollmentWizardResultUnion = NextStep | SubuserEnrollmentWizardResult;

export type SubuserInfo = {
  __typename?: 'SubuserInfo';
  key: Maybe<Scalars['ID']>;
  name: Scalars['String'];
  relationship: Scalars['String'];
  email: Scalars['String'];
  emailNote: Maybe<Scalars['String']>;
  isFullAccess: Scalars['Boolean'];
  isCanResendInvite: Scalars['Boolean'];
  phoneNumber: Maybe<Scalars['String']>;
};

export type SubuserInfoInput = {
  key: Maybe<Scalars['ID']>;
  name: Scalars['String'];
  relationship: Scalars['String'];
  email: Scalars['String'];
  emailNote: Maybe<Scalars['String']>;
  isFullAccess: Scalars['Boolean'];
  isCanResendInvite: Scalars['Boolean'];
  phoneNumber: Maybe<Scalars['String']>;
};

export type SubuserTransferDetails = {
  __typename?: 'SubuserTransferDetails';
  /** Amount of payment */
  amount: Scalars['Decimal'];
  /** Parent first name */
  parentFirstName: Scalars['String'];
  /** Parent last name */
  parentLastName: Scalars['String'];
  /** Sub user full name */
  subuserFullName: Scalars['String'];
  /** Transaction Date */
  transactionDate: Scalars['Date'];
  /** Source account description */
  source: Scalars['String'];
  /** Destination account description */
  destination: Scalars['String'];
  /** Schedule option description */
  schedulingPlanDescription: Maybe<Scalars['String']>;
  /** Ending option description */
  endingOptionDescription: Maybe<Scalars['String']>;
};

export enum SystemNotificationType {
  CONFIGUPDATED = 'CONFIGUPDATED',
  USERCREDENTIALSEXPIRED = 'USERCREDENTIALSEXPIRED',
  TRIGGEREDEVENTSCONFIGUPDATED = 'TRIGGEREDEVENTSCONFIGUPDATED',
  LOCKOUTCONFIGUPDATED = 'LOCKOUTCONFIGUPDATED',
  TEXTCUSTOMIZATIONCACHECLEARED = 'TEXTCUSTOMIZATIONCACHECLEARED'
}

export type Template = {
  __typename?: 'Template';
  /** Template's identifier */
  id: Scalars['Long'];
  /** Template's name */
  name: Scalars['String'];
  /** The commercial banking category of the template */
  commercialBankingCategory: CommercialBankingCategory;
  /** List of recipients included in the template */
  recipients: Maybe<Array<RecipientInTemplate>>;
  /** Total amount across all recipients included in the template */
  totalAmount: Scalars['Decimal'];
  /** Number of recipients included in the template */
  recipientsCount: Scalars['Int'];
};

export type TemplateInput = {
  /** Template's name */
  name: Scalars['String'];
  /** The commercial banking category of the template */
  commercialBankingCategory: CommercialBankingCategory;
  /** List of recipients included in the template */
  recipients: Maybe<Array<RecipientInTemplateInput>>;
};

export type TemplatesGroup = {
  __typename?: 'TemplatesGroup';
  /** The group name */
  name: Maybe<Scalars['String']>;
  /** Internal accounts list. */
  templates: Array<Template>;
};

export type TetheredLoginInput = {
  /** The encoded tethered login token received from the FB. */
  token: Scalars['String'];
};

export type TetheredLoginResult = {
  __typename?: 'TetheredLoginResult';
  /** The decoded username from token. */
  username: Scalars['String'];
};

/** The text field info type. */
export type TextFieldInfo = UiElementInfo & FieldInfoBase & {
  __typename?: 'TextFieldInfo';
  /** The field Id. */
  id: Scalars['ID'];
  /** Field type. */
  type: FieldType;
  /** Field Name, Can be used as Label in AT and/or for validation and error messages. */
  name: Maybe<Scalars['String']>;
  /** Field Description Can be used as tooltip or watermark on a presentation level */
  description: Maybe<Scalars['String']>;
  /** Gets value which indicates whether this field is enabled in the current configuration. */
  isEnabled: Scalars['Boolean'];
  /** Label for front-end UI */
  label: Maybe<Scalars['String']>;
  /** Indicates whether the field with the field value needs to be submitted in SubmitWizardStepFieldValues API calls. */
  isSubmittable: Scalars['Boolean'];
  /**
   * client-based property bindings.
   *                     Bindings are exposed to the web app to form a kind of name-value collection of data items
   *                     required to property display a configurable field (that is called DataContext).
   */
  bindings: Maybe<Array<Maybe<Binding>>>;
  /** Field Behavior e.g Required, Readonly */
  fieldBehaviorMode: FieldBehaviorMode;
  /** This property stores the field input hint. Intended to be displayed by native apps on mobile devices. */
  placeholder: Maybe<Scalars['String']>;
  /** Max length of the field. */
  maxLength: Scalars['Int'];
  /** Min length of the field. */
  minLength: Scalars['Int'];
  /** The value of the field. */
  value: Maybe<Scalars['String']>;
};

/** The Theme-Aware Image widget type contains LightThemePath and DarkThemePath to the corresponding Images. */
export type ThemeAwareImageWidget = {
  __typename?: 'ThemeAwareImageWidget';
  /** Id of the Item */
  id: Maybe<Scalars['ID']>;
  /** The content item version id */
  contentItemVersionId: Maybe<Scalars['String']>;
  /** Type of content */
  contentType: Maybe<Scalars['String']>;
  /** The display text of the content item */
  displayText: Maybe<Scalars['String']>;
  /** Is the published version */
  published: Scalars['Boolean'];
  /** Is the latest version */
  latest: Scalars['Boolean'];
  /** The date and time of modification */
  modifiedUtc: Maybe<Scalars['DateTime']>;
  /** The date and time of publication */
  publishedUtc: Maybe<Scalars['DateTime']>;
  /** The date and time of creation */
  createdUtc: Maybe<Scalars['DateTime']>;
  /** The owner of the content item */
  owner: Maybe<Scalars['String']>;
  /** The author of the content item */
  author: Maybe<Scalars['String']>;
  /** Widget Flow Layout Meta Information from WYSIWYG Editor */
  flowMeta: Maybe<FlowMetadata>;
  lightThemePath: Maybe<Scalars['String']>;
  darkThemePath: Maybe<Scalars['String']>;
};

/** The time field info type. */
export type TimeFieldInfo = UiElementInfo & FieldInfoBase & {
  __typename?: 'TimeFieldInfo';
  /** Field Value. */
  value: Maybe<Scalars['TimeSpan']>;
  /** Min field Value. */
  minValue: Maybe<Scalars['TimeSpan']>;
  /** Max field Value. */
  maxValue: Maybe<Scalars['TimeSpan']>;
  /** The field Id. */
  id: Scalars['ID'];
  /** Field type. */
  type: FieldType;
  /** Field Name, Can be used as Label in AT and/or for validation and error messages. */
  name: Maybe<Scalars['String']>;
  /** Field Description Can be used as tooltip or watermark on a presentation level */
  description: Maybe<Scalars['String']>;
  /** Gets value which indicates whether this field is enabled in the current configuration. */
  isEnabled: Scalars['Boolean'];
  /** Label for front-end UI */
  label: Maybe<Scalars['String']>;
  /** Indicates whether the field with the field value needs to be submitted in SubmitWizardStepFieldValues API calls. */
  isSubmittable: Scalars['Boolean'];
  /**
   * client-based property bindings.
   *                     Bindings are exposed to the web app to form a kind of name-value collection of data items
   *                     required to property display a configurable field (that is called DataContext).
   */
  bindings: Maybe<Array<Maybe<Binding>>>;
  /** Field Behavior e.g Required, Readonly */
  fieldBehaviorMode: FieldBehaviorMode;
  /** This property stores the field input hint. Intended to be displayed by native apps on mobile devices. */
  placeholder: Maybe<Scalars['String']>;
};


/** Time zone. */
export type TimeZoneDto = {
  __typename?: 'TimeZoneDto';
  /** Time zone id. */
  id: Scalars['String'];
  /** Time zone name. */
  name: Scalars['String'];
};

export type TokenExchangeInput = {
  /** Valid UserToken from active user session, for validation purposes */
  userToken: Scalars['String'];
  /** ClientDeviceUid associated with active user session, for validation purposes */
  clientDeviceUid: Scalars['String'];
  /** Active User's DataSource Session Id for validation */
  dataSourceSessionId: Scalars['String'];
  /** The flag indicating that the user token is going to be issued for an SCSO session */
  isScso: Maybe<Scalars['Boolean']>;
};

export type TransactionPreferenceType = {
  __typename?: 'TransactionPreferenceType';
  isOn: Scalars['Boolean'];
  code: Scalars['String'];
};

export type TransactionPreferenceTypeInput = {
  isOn: Scalars['Boolean'];
  code: Scalars['String'];
};

export type TransactionPreferences = {
  __typename?: 'TransactionPreferences';
  isEnabled: Scalars['Boolean'];
  types: Array<TransactionPreferenceType>;
};

export enum TransactionStatus {
  UNKNOWN = 'UNKNOWN',
  POSTED = 'POSTED',
  PENDING = 'PENDING',
  CANCELLED = 'CANCELLED',
  INTENDED = 'INTENDED',
  REJECTED = 'REJECTED'
}

export enum TransactionStatusType {
  UNKNOWN = 'UNKNOWN',
  SCHEDULED = 'SCHEDULED',
  SUCCESS = 'SUCCESS',
  FAIL = 'FAIL',
  CANCELED = 'CANCELED',
  AWAITINGAPPROVAL = 'AWAITINGAPPROVAL'
}

export type TransferActionItem = {
  __typename?: 'TransferActionItem';
  label: Scalars['String'];
  value: Maybe<Scalars['Decimal']>;
  type: TransferActionItemType;
  data: Maybe<TransferActionItemAdditionalData>;
};

export type TransferActionItemAdditionalData = {
  __typename?: 'TransferActionItemAdditionalData';
  ssoBillPay: Maybe<Scalars['String']>;
  ssoMortgage: Maybe<Scalars['String']>;
  ssoOrderCheck: Maybe<Scalars['String']>;
};

export enum TransferActionItemType {
  UNKNOWN = 'UNKNOWN',
  SHAREFROM = 'SHAREFROM',
  SHARETO = 'SHARETO',
  SHARECHECKWITHDRAWAL = 'SHARECHECKWITHDRAWAL',
  LOANREGULAR = 'LOANREGULAR',
  LOANPAYOFF = 'LOANPAYOFF',
  LOANPRINCIPALONLY = 'LOANPRINCIPALONLY',
  LOANSKIP = 'LOANSKIP',
  LOANMINIMUM = 'LOANMINIMUM',
  LOANFROM = 'LOANFROM',
  SSOBILLPAY = 'SSOBILLPAY',
  SSOMORTGAGE = 'SSOMORTGAGE',
  SSOORDERCHECK = 'SSOORDERCHECK',
  STOPCHECK = 'STOPCHECK',
  TRANSACTIONDISPUTE = 'TRANSACTIONDISPUTE'
}

export type TransferActivity = {
  __typename?: 'TransferActivity';
  /** Identifier of transfer. */
  taskId: Scalars['String'];
  /** Identifier of particular operation in transfer. */
  operationId: Scalars['String'];
  /** Amount of transfer. */
  amount: Scalars['Decimal'];
  /** Description for destination account of transfer. */
  destinationDescription: Scalars['String'];
  /** Type of destination account of transfer. */
  destinationType: Scalars['String'];
  /** Description for source account of transfer. */
  sourceDescription: Scalars['String'];
  /** Type of source account of transfer. */
  sourceType: Scalars['String'];
  /** Status of last attempt failed of transfer. */
  isLastAttemptFailed: Maybe<Scalars['Boolean']>;
  /** Last attempt date of transfer. */
  lastAttemptDate: Maybe<Scalars['DateTime']>;
  /** Next attempt date of transfer. */
  nextAttemptDate: Scalars['DateTime'];
  /** Description of scheduling plan for transfer. */
  schedulingPlanDescription: Maybe<Scalars['String']>;
  /** Description of ending date for transfer. */
  endingOptionDescription: Maybe<Scalars['String']>;
  /** Indicates is this record are history item. */
  isHistoryRecord: Scalars['Boolean'];
  /** Indicates whether dynamic amount will be applied for an option in case of transfer. */
  isDynamicAmount: Scalars['Boolean'];
  /** Payment type of transfer. */
  paymentType: Maybe<Scalars['String']>;
  /** User friendly name of payment type for transfer. */
  paymentTypeName: Maybe<Scalars['String']>;
  /** Status of particular operation in history record or staus of last operation in schedule record. */
  transferOperationStatus: Scalars['String'];
};

export type TransferActivityDetails = {
  __typename?: 'TransferActivityDetails';
  /** Identifier of current transfer activity. */
  id: Scalars['String'];
  /** Amount of current transfer activity. */
  amount: Scalars['Decimal'];
  /** Memo of current transfer activity. */
  memo: Maybe<Scalars['String']>;
  /** Description for destination account of current transfer activity. */
  destinationDescription: Scalars['String'];
  /** Type of destination account of current transfer activity. */
  destinationType: Scalars['String'];
  /** Identifier of destination account of current transfer activity. */
  destinationAccountId: Scalars['String'];
  /** Number of recipients for business transfer template. */
  recipientsCount: Maybe<Scalars['Int']>;
  /** Description for source account of current transfer activity. */
  sourceDescription: Scalars['String'];
  /** Type of source account of transfer activity. */
  sourceType: Scalars['String'];
  /** Identifier of source account of current transfer activity. */
  sourceAccountId: Scalars['String'];
  /** Description of current scheduling plan for transfer activity. */
  schedulingPlanDescription: Scalars['String'];
  /** Indicates that current transfer activity can be edited. */
  isEditable: Scalars['Boolean'];
  /** Indicates that current transfer activity can be removed. */
  isRemovable: Scalars['Boolean'];
  /** Scheduled date of current transfer activity. */
  scheduledDate: Maybe<Scalars['DateTime']>;
  /** Indicates that current transfer activity supports start date. */
  isStartDateSupported: Scalars['Boolean'];
  /** Indicates that current record is details history record in false case it is schedule record for transfer activity. */
  isDetailsHistoryRecord: Scalars['Boolean'];
  /** Next attempt date of transfer activity. */
  nextAttemptDate: Scalars['DateTime'];
  /** User notice of transfer activity. */
  userNotice: Maybe<Scalars['String']>;
  /** Description of ending date for transfer activity. */
  endingOptionDescription: Maybe<Scalars['String']>;
  /** Recurring option settings of current transfer activity. */
  recurringOptionSettings: Maybe<RecurringOptionSettings>;
  /** Payment option settings of current transfer activity. */
  paymentOption: Maybe<PaymentOption>;
  /** Represent status for history transaction record, it will be Unknown for schedule detail record. */
  transferOperationStatus: Scalars['String'];
};

export type TransferActivitySettings = {
  __typename?: 'TransferActivitySettings';
  /** Indicate availability scheduling on non business days. */
  isAllowedSchedulingOnNonBusinessDays: Scalars['Boolean'];
  /** Message for not available scheduled date. */
  notAvailableScheduledDateMessage: Maybe<Scalars['String']>;
  /** List of days which should not be available for payments. */
  excludedPaymentDates: ExcludedPaymentDays;
  /** Scheduled payment notice for payments. */
  scheduledPaymentNotice: Maybe<Scalars['String']>;
  /** Recurring payment notice for payments. */
  recurringPaymentNotice: Maybe<Scalars['String']>;
  /** List of supported recurring options. */
  recurringOptions: Array<Maybe<RecurringOption>>;
  /** End after number of transfers ending option for recurring transfers enabled (True) or disabled (False). */
  allowEndAfterCountOption: Scalars['Boolean'];
  /** End after date of transfer ending option for recurring transfers enabled (True) or disabled (False). */
  allowEndAfterDateOption: Scalars['Boolean'];
  /** Indicates whether an optional Transfer Memo field is enabled when a user is setting up a transfer. */
  isTransferMemoEnabled: Scalars['Boolean'];
};

export enum TransferFrequency {
  TRANSFER = 'TRANSFER',
  DAY = 'DAY',
  WEEK = 'WEEK',
  TWOWEEKS = 'TWOWEEKS',
  MONTH = 'MONTH',
  QUARTER = 'QUARTER',
  YEAR = 'YEAR'
}

export type TransferLimit = {
  __typename?: 'TransferLimit';
  frequency: TransferFrequency;
  amountWithApproval: Maybe<Scalars['Decimal']>;
  amountWithoutApproval: Maybe<Scalars['Decimal']>;
};

export type TransferLimitInput = {
  frequency: TransferFrequency;
  amountWithApproval: Maybe<Scalars['Decimal']>;
  amountWithoutApproval: Maybe<Scalars['Decimal']>;
};

export type TransferPermissions = {
  __typename?: 'TransferPermissions';
  channelId: Scalars['ID'];
  channelName: Scalars['String'];
  isAllowed: Scalars['Boolean'];
  limits: Array<TransferLimit>;
};

export type TransferPermissionsInput = {
  channelId: Scalars['ID'];
  channelName: Scalars['String'];
  isAllowed: Scalars['Boolean'];
  limits: Array<TransferLimitInput>;
};

export type TransferSettings = {
  __typename?: 'TransferSettings';
  /** List of days which should not be available for scheduling payments. */
  excludedPaymentDates: ExcludedPaymentDays;
  /** Get or sets value that specifies whether scheduling allowed on non-business dates. */
  isAllowedSchedulingOnNonBusinessDays: Scalars['Boolean'];
  /** Returns error message if scheduled date is not allowed. */
  notAvailableScheduledDateMessage: Maybe<Scalars['String']>;
  /** Return notice that should be shown when user select scheduled payment option. */
  scheduledPaymentNotice: Maybe<Scalars['String']>;
  /** List of supported recurring options. */
  recurringOptions: Array<RecurringOption>;
  /** Return notice that should be shown when user select recurring payment option. */
  recurringPaymentNotice: Maybe<Scalars['String']>;
  /** Get is schedule option available for channel */
  isScheduledPaymentAllowed: Scalars['Boolean'];
  /** End after number of transfers ending option for recurring transfers enabled (True) or disabled (False) */
  allowEndAfterCountOption: Scalars['Boolean'];
  /** End after date of transfer ending option for recurring transfers enabled (True) or disabled (False) */
  allowEndAfterDateOption: Scalars['Boolean'];
  /** Indicates whether an optional Transfer Memo field is enabled when a user is setting up a transfer. */
  isTransferMemoEnabled: Scalars['Boolean'];
  /** Represents current tax year. */
  currentTaxYear: Scalars['Int'];
};

export type TransfersFeatures = {
  __typename?: 'TransfersFeatures';
  /** Indicate whether skip payment feature is available for user. */
  isSkipPaymentAvailable: Scalars['Boolean'];
  /** Indicate whether member-to-member transfers are available for user. */
  isMemberToMemberAvailable: Scalars['Boolean'];
  /** Indicate whether check withdrawal is available for user. */
  isCheckWithdrawalAvailable: Scalars['Boolean'];
  /** Indicate whether investment is available for user. */
  isInvestmentAvailable: Scalars['Boolean'];
  /** Indicate whether external accounts are available for user. */
  isExternalAccountsAvailable: Scalars['Boolean'];
  /** Indicate whether business transfers are available for user. */
  isBusinessTransfersAvailable: Scalars['Boolean'];
  /** Indicate whether transfer from file is available for user. */
  isTransferFromFileAvailable: Scalars['Boolean'];
  /** Indicate whether stop check payment is available for user. */
  isStopCheckPaymentAvailable: Scalars['Boolean'];
  /** Indicate whether favorite transfers are available for user. */
  isFavoritesAvailable: Scalars['Boolean'];
  /** Specifies whether current session is impersonated session without action permissions. */
  isReadOnlyImpersonatedSession: Scalars['Boolean'];
  /** Specifies whether wire transfers are available for user. */
  isWireTransferAvailable: Scalars['Boolean'];
  /** Specifies whether transfers from debit/credit card are available for user. */
  isPayByCardAvailable: Scalars['Boolean'];
  /** Specifies whether one-off invoice payments are available for user. */
  isOneOffInvoiceAvailable: Scalars['Boolean'];
  /** Short name of FI. */
  fIShortName: Scalars['String'];
};

export type Travel = {
  __typename?: 'Travel';
  key: Scalars['ID'];
  beginDate: Scalars['Date'];
  endDate: Scalars['Date'];
  destinations: Array<Destination>;
  cards: Array<Scalars['String']>;
  preferredContact: Maybe<PreferredContact>;
  authorizedContact: Maybe<AuthorizedContact>;
  purpose: Maybe<Scalars['String']>;
};

export type TravelDestinationFeature = {
  __typename?: 'TravelDestinationFeature';
  type: TravelDestinationType;
};

export enum TravelDestinationType {
  INTERNATIONALANDDOMESTIC = 'INTERNATIONALANDDOMESTIC',
  INTERNATIONALORDOMESTIC = 'INTERNATIONALORDOMESTIC'
}

export type TravelFeature = {
  __typename?: 'TravelFeature';
  isEnabled: Scalars['Boolean'];
  isEditable: Scalars['Boolean'];
};

export type TravelFeatureAction = {
  __typename?: 'TravelFeatureAction';
  isEnabled: Scalars['Boolean'];
};

export type TravelFeatures = {
  __typename?: 'TravelFeatures';
  authorizedContact: TravelFeature;
  preferredContact: TravelFeature;
  purpose: TravelFeature;
  add: TravelFeatureAction;
  modify: TravelFeatureAction;
  delete: TravelFeatureAction;
  destination: TravelDestinationFeature;
  overlapping: TravelOverlapping;
};

export type TravelInput = {
  key: Scalars['ID'];
  beginDate: Scalars['Date'];
  endDate: Scalars['Date'];
  destinations: Array<DestinationInput>;
  cards: Array<Scalars['String']>;
  preferredContact: Maybe<PreferredContactInput>;
  authorizedContact: Maybe<AuthorizedContactInput>;
  purpose: Maybe<Scalars['String']>;
};

export type TravelLimits = {
  __typename?: 'TravelLimits';
  maxTravels: Maybe<Scalars['Int']>;
  maxCards: Maybe<Scalars['Int']>;
  maxStates: Maybe<Scalars['Int']>;
  maxCountries: Maybe<Scalars['Int']>;
};

export type TravelOverlapping = {
  __typename?: 'TravelOverlapping';
  isOverlappingAllowed: Scalars['Boolean'];
};

export type TravelsSettings = {
  __typename?: 'TravelsSettings';
  isEnabled: Scalars['Boolean'];
  validation: Array<ValidationType>;
  features: TravelFeatures;
  disclaimer: Maybe<Scalars['String']>;
  limits: Maybe<TravelLimits>;
};

/** Triggered event finished type. */
export type TriggeredEventFinished = {
  __typename?: 'TriggeredEventFinished';
  /** Indicates whether TE succeed. */
  wizardFinished: Scalars['Boolean'];
};

/** The Triggered Event Finished result. */
export type TriggeredEventResultUnion = NextStep | TriggeredEventFinished;

/** The base interface for UiElementInfo. */
export type UiElementInfo = {
  /** The field Id that must be implemented. */
  id: Scalars['ID'];
  /** Field type that must be implemented. */
  type: FieldType;
  /** Field Name that must be implemented, Can be used as Label in AT and/or for validation and error messages. */
  name: Maybe<Scalars['String']>;
  /** Field Description that must be implemented. Can be used as tooltip or watermark on a presentation level. */
  description: Maybe<Scalars['String']>;
  /** The value which indicates whether this field is enabled in the current configuration. Must be implemented. */
  isEnabled: Scalars['Boolean'];
  /** Label for front-end UI. Must be implemented. */
  label: Maybe<Scalars['String']>;
  /** Indicates whether the field with the field value needs to be submitted in SubmitWizardStepFieldValues API calls. Must be implemented. */
  isSubmittable: Scalars['Boolean'];
  /**
   * Client-based property bindings.
   *                     Bindings are exposed to the web app to form a kind of name-value collection of data items
   *                     required to property display a configurable field (that is called DataContext). Must be implemented.
   */
  bindings: Maybe<Array<Maybe<Binding>>>;
};

export type UnlinkInstitutionResult = {
  __typename?: 'UnlinkInstitutionResult';
  successful: Scalars['Boolean'];
  errors: Maybe<Array<ValidationItem>>;
  warnings: Maybe<Array<ValidationItem>>;
  infos: Maybe<Array<ValidationItem>>;
};

export type UpdateBillingAddressInput = {
  cardKey: Scalars['ID'];
  billingAddress: BillingAddressInput;
};

export type UpdateBillingAddressResult = {
  __typename?: 'UpdateBillingAddressResult';
  successful: Scalars['Boolean'];
  errors: Maybe<Array<ValidationItem>>;
  warnings: Maybe<Array<ValidationItem>>;
  infos: Maybe<Array<ValidationItem>>;
};

export type UpdateCardMyRegionLocationAlertInput = {
  cardKey: Scalars['String'];
  regionId: Scalars['String'];
  name: Scalars['String'];
  latitude: Scalars['Float'];
  longitude: Scalars['Float'];
  radiusInMeters: Scalars['Decimal'];
};

export type UpdateCardMyRegionLocationAlertResult = {
  __typename?: 'UpdateCardMyRegionLocationAlertResult';
  successful: Scalars['Boolean'];
  errors: Maybe<Array<ValidationItem>>;
  warnings: Maybe<Array<ValidationItem>>;
  infos: Maybe<Array<ValidationItem>>;
};

export type UpdateCardMyRegionLocationControlInput = {
  cardKey: Scalars['String'];
  regionId: Scalars['String'];
  name: Scalars['String'];
  latitude: Scalars['Float'];
  longitude: Scalars['Float'];
  radiusInMeters: Scalars['Decimal'];
};

export type UpdateCardMyRegionLocationControlResult = {
  __typename?: 'UpdateCardMyRegionLocationControlResult';
  successful: Scalars['Boolean'];
  errors: Maybe<Array<ValidationItem>>;
  warnings: Maybe<Array<ValidationItem>>;
  infos: Maybe<Array<ValidationItem>>;
};

export type UpdateRecipientDetailsResult = {
  __typename?: 'UpdateRecipientDetailsResult';
  /** Indicates if the action is successful */
  successful: Scalars['Boolean'];
  /** The list of errors */
  errors: Maybe<Array<ValidationItem>>;
  /** The list of warnings */
  warnings: Maybe<Array<ValidationItem>>;
  /** The list of infos */
  infos: Maybe<Array<ValidationItem>>;
};

export type UpdateRecipientResult = {
  __typename?: 'UpdateRecipientResult';
  /** Indicates if the action is successful */
  successful: Scalars['Boolean'];
  /** The list of errors */
  errors: Maybe<Array<ValidationItem>>;
  /** The list of warnings */
  warnings: Maybe<Array<ValidationItem>>;
  /** The list of infos */
  infos: Maybe<Array<ValidationItem>>;
};

export type UpdateTemplateResult = {
  __typename?: 'UpdateTemplateResult';
  /** Indicates if the action is successful */
  successful: Scalars['Boolean'];
  /** The list of errors */
  errors: Maybe<Array<ValidationItem>>;
  /** The list of warnings */
  warnings: Maybe<Array<ValidationItem>>;
  /** The list of infos */
  infos: Maybe<Array<ValidationItem>>;
};

export type UpdateTransferInput = {
  /** Identifier of transfer. */
  transferId: Scalars['String'];
  /** Amount of transfer. */
  amount: Scalars['Decimal'];
  /** Memo of transfer. */
  memo: Maybe<Scalars['String']>;
  /** Scheduled date of transfer. */
  scheduledDate: Maybe<Scalars['Date']>;
  /** Recurring option settings of transfer. */
  recurringOptionSettings: Maybe<RecurringOptionSettingsInput>;
};

export type UpdateTransferResult = {
  __typename?: 'UpdateTransferResult';
  /** Transfer status. */
  transactionStatus: Scalars['String'];
  /** Validation result of operation. */
  validationResult: Maybe<ValidationResultContainer>;
};

export type UpdateUserCustomDataResult = {
  __typename?: 'UpdateUserCustomDataResult';
  successful: Scalars['Boolean'];
  errors: Maybe<Array<ValidationItem>>;
  warnings: Maybe<Array<ValidationItem>>;
  infos: Maybe<Array<ValidationItem>>;
};


export type UploadTransferFileResult = {
  __typename?: 'UploadTransferFileResult';
  /** Indicates if the action is successful */
  successful: Scalars['Boolean'];
  /** The list of errors */
  errors: Maybe<Array<ValidationItem>>;
  /** The list of warnings */
  warnings: Maybe<Array<ValidationItem>>;
  /** The list of infos */
  infos: Maybe<Array<ValidationItem>>;
  /** The uploaded file identifier. */
  uploadedTransferFileId: Scalars['Long'];
  /** The uploaded file name. */
  uploadedTransferFileName: Scalars['String'];
  /** The parsed Settlement date. */
  settlementDate: Scalars['DateTime'];
  /** The parsed list of ACH groups. */
  achGroups: Maybe<Array<AchGroup>>;
};

export type UserAccountGroup = {
  __typename?: 'UserAccountGroup';
  id: Scalars['ID'];
  name: Scalars['String'];
};

export type UserFriendly = IValidationItem & {
  __typename?: 'UserFriendly';
  /** Path E.g FiedlId/Name being validated */
  path: Maybe<Scalars['String']>;
  /** User friendly message */
  errorMessage: Maybe<Scalars['String']>;
};

export enum UserInfoResetType {
  PASSWORD = 'PASSWORD',
  CHALLENGEQUESTIONS = 'CHALLENGEQUESTIONS'
}

/** The ForceUserInfoResetRequest type */
export type UserInfoResetUnion = UserPasswordResetAfterAttempts | ForceUserInfoResetRequest;

export enum UserKind {
  /** Represents the business type of user. */
  BUSINESS = 'BUSINESS',
  /** Represents the individual type of user. */
  CONSUMER = 'CONSUMER',
  /** Represents the unselected type of user. */
  UNKNOWN = 'UNKNOWN'
}

export type UserKindWizardResult = {
  __typename?: 'UserKindWizardResult';
  /** Flag shows if Wizard finished */
  wizardFinished: Scalars['Boolean'];
  /** The selected user kind. */
  userKind: Maybe<UserKind>;
  /** Represent the list of success summary strings. */
  successSummary: Array<Maybe<Scalars['String']>>;
};

/** The user kind result. */
export type UserKindWizardResultUnion = NextStep | UserKindWizardResult;

export type UserPasswordResetAfterAttempts = {
  __typename?: 'UserPasswordResetAfterAttempts';
  /** Login attempt count remained */
  loginAttemptRemained: Scalars['Int'];
};

/** User time zone. */
export type UserTimeZone = {
  __typename?: 'UserTimeZone';
  /** Time zone id. */
  id: Scalars['String'];
  /** Time zone name. */
  name: Scalars['String'];
};

/** Represents user time zone. */
export type UserTimeZoneInput = {
  /** Time zone id. */
  id: Scalars['String'];
  /** Time zone name. */
  name: Scalars['String'];
};

/** The user name field info type. */
export type UsernameFieldInfo = UiElementInfo & FieldInfoBase & {
  __typename?: 'UsernameFieldInfo';
  /** Defines User Name Rules */
  userNamePolicy: Maybe<UsernamePolicy>;
  /** The field Id. */
  id: Scalars['ID'];
  /** Field type. */
  type: FieldType;
  /** Field Name, Can be used as Label in AT and/or for validation and error messages. */
  name: Maybe<Scalars['String']>;
  /** Field Description Can be used as tooltip or watermark on a presentation level */
  description: Maybe<Scalars['String']>;
  /** Gets value which indicates whether this field is enabled in the current configuration. */
  isEnabled: Scalars['Boolean'];
  /** Label for front-end UI */
  label: Maybe<Scalars['String']>;
  /** Indicates whether the field with the field value needs to be submitted in SubmitWizardStepFieldValues API calls. */
  isSubmittable: Scalars['Boolean'];
  /**
   * client-based property bindings.
   *                     Bindings are exposed to the web app to form a kind of name-value collection of data items
   *                     required to property display a configurable field (that is called DataContext).
   */
  bindings: Maybe<Array<Maybe<Binding>>>;
  /** Field Behavior e.g Required, Readonly */
  fieldBehaviorMode: FieldBehaviorMode;
  /** This property stores the field input hint. Intended to be displayed by native apps on mobile devices. */
  placeholder: Maybe<Scalars['String']>;
  /** Max length of the field. */
  maxLength: Scalars['Int'];
  /** Min length of the field. */
  minLength: Scalars['Int'];
  /** The value of the field. */
  value: Maybe<Scalars['String']>;
};

export type UsernamePolicy = {
  __typename?: 'UsernamePolicy';
  /** Flag indicating Minimum Character Length of the user name */
  minimumCharacterLength: Scalars['Int'];
  /** Flag indicating whether is minimum character length enabled */
  isMinimumCharacterLengthEnabled: Scalars['Boolean'];
  /** Flag indicating Maximum Character Length of the user name */
  maximumCharacterLength: Scalars['Int'];
  /** Flag indicating whether is Maximum Character Length enabled */
  isMaximumCharacterLengthEnabled: Scalars['Boolean'];
  /** Flag indicating whether UserName must not contain an existing User Names or any of previous User Names */
  mustNotContainUsername: Scalars['Boolean'];
  /** Flag indicating whether UserName must not be a part of existing User Name */
  mustNotBeContainedWithinUsername: Scalars['Boolean'];
  /** Flag indicating whether first character must be alphabetic */
  firstCharacterMustBeAlphabetic: Scalars['Boolean'];
  /** Flag indicating at least one character must be alphabetic */
  anyCharacterMustBeAlphabetic: Scalars['Boolean'];
  /** Flag for Lower Case Requirement */
  lowerCaseRequirement: Maybe<CharacterRequirement>;
  /** Flag for Upper Case Requirement */
  upperCaseRequirement: Maybe<CharacterRequirement>;
  /** Flag for Number Requirement */
  numberRequirement: Maybe<CharacterRequirement>;
  /** Flag for Special Character Requirement */
  specialCharacterRequirement: Maybe<CharacterRequirement>;
  /** Flag indicating whether UserName must not contain member Birth Date */
  mustNotContainBirthdate: Scalars['Boolean'];
  /** Flag indicating whether UserName must not contain Member Number */
  mustNotContainMemberNumber: Scalars['Boolean'];
  /** Flag indicating whether UserName must not contain Member Social Security Number */
  mustNotContainSsn: Scalars['Boolean'];
};

export type ValidateExternalTransferResult = {
  __typename?: 'ValidateExternalTransferResult';
  /** Validation result of operation. */
  validationResult: Maybe<ValidationResultContainer>;
};

export type ValidateInternalPaymentInput = {
  /** Amount of payment */
  amount: Scalars['Decimal'];
  /** Source account identifier of payment */
  sourceAccountId: Scalars['String'];
  /** Destination account identifier of payment */
  destinationAccountId: Scalars['String'];
  /** Payment option identifier of payment */
  paymentOptionId: Maybe<Scalars['String']>;
  /** Scheduled date of payment */
  scheduledDate: Maybe<Scalars['Date']>;
  /** Recurring settings if payment can be recurred */
  recurringOptionSettings: Maybe<RecurringOptionSettingsInput>;
};

export type ValidateInternalPaymentResult = {
  __typename?: 'ValidateInternalPaymentResult';
  /** Validation result of operation. */
  validationResult: Maybe<ValidationResultContainer>;
};

export type ValidateInvestmentPaymentInput = {
  /** Source account identifier of payment */
  sourceAccountId: Scalars['String'];
  /** Destination account identifier of payment */
  destinationAccountId: Scalars['String'];
  /** Payment option identifier of payment */
  paymentOptionId: Maybe<Scalars['String']>;
  /** Amount of payment */
  amount: Scalars['Decimal'];
};

export type ValidateInvestmentPaymentResult = {
  __typename?: 'ValidateInvestmentPaymentResult';
  /** Validation result of operation. */
  validationResult: Maybe<ValidationResultContainer>;
};

export type ValidateM2MPaymentInput = {
  /** Amount of m2m payment */
  amount: Scalars['Decimal'];
  /** Destination account identifier of payment */
  destinationAccountId: Scalars['String'];
  /** Source account identifier of payment */
  sourceAccountId: Scalars['String'];
  /** Scheduled date of m2m payment */
  scheduledDate: Maybe<Scalars['Date']>;
  /** Recurring settings if payment can be recurred */
  recurringOptionSettings: Maybe<RecurringOptionSettingsInput>;
};

export type ValidateM2MPaymentResult = {
  __typename?: 'ValidateM2MPaymentResult';
  /** Validation result of operation. */
  validationResult: Maybe<ValidationResultContainer>;
};

export type ValidatePayByCardPaymentInput = {
  /** Amount of payment */
  amount: Scalars['Decimal'];
  /** Source external card identifier */
  sourceExternalCardId: Scalars['String'];
  /** Destination account identifier */
  destinationAccountId: Scalars['String'];
  /** Payment option identifier */
  paymentOptionId: Maybe<Scalars['String']>;
};

export type ValidatePayByCardPaymentResult = {
  __typename?: 'ValidatePayByCardPaymentResult';
  /** Validation result of operation. */
  validationResult: Maybe<ValidationResultContainer>;
};

export type ValidateSkipPayInput = {
  /** Account id for skip payment. */
  skipPayAccountId: Scalars['String'];
  /** Fee source account id. */
  billingAccountId: Scalars['String'];
};

export type ValidateSkipPayResult = {
  __typename?: 'ValidateSkipPayResult';
  /** Validation result of operation. */
  validationResult: Maybe<ValidationResultContainer>;
};

export type ValidateStateZipInput = {
  cardKey: Scalars['String'];
  state: Scalars['String'];
  zip: Scalars['String'];
};

export type ValidateStateZipResult = {
  __typename?: 'ValidateStateZipResult';
  isValid: Scalars['Boolean'];
  successful: Scalars['Boolean'];
  errors: Maybe<Array<ValidationItem>>;
  warnings: Maybe<Array<ValidationItem>>;
  infos: Maybe<Array<ValidationItem>>;
};

export type ValidateTransferInput = {
  /** Source account identifier for the transfer. */
  sourceAccountId: Scalars['ID'];
  /** Template identifier for the transfer. */
  templateId: Scalars['Long'];
  /** Scheduled date of the transfer. */
  scheduledDate: Maybe<Scalars['Date']>;
  /** Per recipient parameters for the transfer. */
  perRecipientParameters: Maybe<Array<PerRecipientTransferParametersInput>>;
  /** Setting for recurring option selected by the user. */
  recurringOptionSettings: Maybe<RecurringOptionSettingsInput>;
  /** Whether to use the same day ACH payment option. */
  isSameDayAchPayment: Scalars['Boolean'];
};

export type ValidateTransferResult = {
  __typename?: 'ValidateTransferResult';
  /** Indicates if the action is successful */
  successful: Scalars['Boolean'];
  /** The list of errors */
  errors: Maybe<Array<ValidationItem>>;
  /** The list of warnings */
  warnings: Maybe<Array<ValidationItem>>;
  /** The list of infos */
  infos: Maybe<Array<ValidationItem>>;
};

export type ValidateWireTransferInput = {
  /** Unique id of source bank bank account selected by user. */
  sourceBankAccountId: Scalars['ID'];
  /** Unique id of destination wire recipient account selected by user. */
  destinationWireRecipientId: Scalars['ID'];
  /** Unique id of payment option selected by user if supported. */
  paymentOptionId: Maybe<Scalars['ID']>;
  /** Payment amount. */
  amount: Scalars['Decimal'];
  /** Memo entered by user. */
  memo: Maybe<Scalars['String']>;
  /** Date selected by user for one time scheduling option. */
  scheduledDate: Maybe<Scalars['Date']>;
  /** Setting for recurring option selected by user. */
  recurringOptionSettings: Maybe<RecurringOptionSettingsInput>;
};

export type ValidateWireTransferResult = {
  __typename?: 'ValidateWireTransferResult';
  /** Indicates if the action is successful */
  successful: Scalars['Boolean'];
  /** The list of errors */
  errors: Maybe<Array<ValidationItem>>;
  /** The list of warnings */
  warnings: Maybe<Array<ValidationItem>>;
  /** The list of infos */
  infos: Maybe<Array<ValidationItem>>;
};

export type ValidatingOperationResult = {
  __typename?: 'ValidatingOperationResult';
  /** Indicates if the action is successful */
  successful: Scalars['Boolean'];
  /** The list of errors */
  errors: Maybe<Array<ValidationItem>>;
  /** The list of warnings */
  warnings: Maybe<Array<ValidationItem>>;
  /** The list of infos */
  infos: Maybe<Array<ValidationItem>>;
};

export enum ValidationFaultType {
  REQUIRED = 'REQUIRED',
  INVALIDDATA = 'INVALIDDATA',
  INVALIDFORMAT = 'INVALIDFORMAT',
  OTHER = 'OTHER',
  UNSUPPORTEDVERSION = 'UNSUPPORTEDVERSION',
  INVALIDSCOPE = 'INVALIDSCOPE'
}

export type ValidationItem = {
  __typename?: 'ValidationItem';
  /** The context */
  context: Maybe<Scalars['String']>;
  /** The field */
  field: Maybe<Scalars['String']>;
  /** The list of messages */
  messages: Maybe<Array<Maybe<Scalars['String']>>>;
  /** The fault type */
  faultType: Maybe<ValidationFaultType>;
};

export type ValidationResultContainer = {
  __typename?: 'ValidationResultContainer';
  /** Status of operation */
  isSuccess: Scalars['Boolean'];
  /** List of error messages for payment */
  errorMessages: Maybe<Array<Scalars['String']>>;
  /** List of warning messages for payment */
  warningMessages: Maybe<Array<Scalars['String']>>;
  /** List of info messages for payment */
  infoMessages: Maybe<Array<Scalars['String']>>;
};

export enum ValidationType {
  UNKNOWN = 'UNKNOWN',
  SSN = 'SSN',
  CVV = 'CVV',
  NUMBER = 'NUMBER'
}

export type ValidationUnion = UserFriendly | InvalidFormat;

export type VerifyAuthenticationParams = {
  /** Id. */
  id: Scalars['String'];
  /** RawId. */
  rawId: Scalars['String'];
  /** Response. */
  response: AuthenticatorAssertionResponse;
  /** AuthenticatorAttachment. */
  authenticatorAttachment: Scalars['String'];
  /** Type. */
  type: Scalars['String'];
};

export type VerifyAuthenticationResult = {
  __typename?: 'VerifyAuthenticationResult';
  /** Success. */
  success: Scalars['Boolean'];
  /** Message. */
  message: Scalars['String'];
};

export type VerifyRegistrationParams = {
  /** Id. */
  id: Scalars['String'];
  /** RawId. */
  rawId: Scalars['String'];
  /** Response. */
  response: AuthenticatorAttestationResponse;
  /** AuthenticatorAttachment. */
  authenticatorAttachment: Scalars['String'];
  /** Type. */
  type: Scalars['String'];
  /** Nickname. */
  nickName: Scalars['String'];
};

export type VerifyRegistrationResult = {
  __typename?: 'VerifyRegistrationResult';
  /** Success. */
  success: Scalars['Boolean'];
  /** Message. */
  message: Scalars['String'];
};

export type VisibilityCheckTag = {
  __typename?: 'VisibilityCheckTag';
  userGroupIds: Array<Scalars['String']>;
  hideMenuItemFromSelectedVisibilityGroups: Scalars['Boolean'];
};

/** The Basic Widget Type for Html or Liquid Widgets */
export type Widget = {
  __typename?: 'Widget';
  /** Id of the Item */
  id: Maybe<Scalars['ID']>;
  /** The content item version id */
  contentItemVersionId: Maybe<Scalars['String']>;
  /** Type of content */
  contentType: Maybe<Scalars['String']>;
  /** The display text of the content item */
  displayText: Maybe<Scalars['String']>;
  /** Is the published version */
  published: Scalars['Boolean'];
  /** Is the latest version */
  latest: Scalars['Boolean'];
  /** The date and time of modification */
  modifiedUtc: Maybe<Scalars['DateTime']>;
  /** The date and time of publication */
  publishedUtc: Maybe<Scalars['DateTime']>;
  /** The date and time of creation */
  createdUtc: Maybe<Scalars['DateTime']>;
  /** The owner of the content item */
  owner: Maybe<Scalars['String']>;
  /** The author of the content item */
  author: Maybe<Scalars['String']>;
  /** Render ContentItem */
  html: Maybe<Scalars['String']>;
  /** Widget Flow Layout Meta Information from WYSIWYG Editor */
  flowMeta: Maybe<FlowMetadata>;
};

/** The Orpheus Placement widget. */
export type WidgetUnion = Widget | ImageWidget | ThemeAwareImageWidget | PlacementWidget | AdServiceWidget | CustomAppWidget | DeepTargetWidget | SsoWidget | Page | Menu | ContentItem | LinkMenuItem;

export type WireAccountView = {
  __typename?: 'WireAccountView';
  /** Account Id. */
  id: Scalars['ID'];
  /** Indicates whether current balance available for source or destination bank account according to configuration settings. */
  isCurrentBalanceVisible: Scalars['Boolean'];
  /** Indicates whether available balance available for source or destination bank account according to configuration settings. */
  isAvailableBalanceVisible: Scalars['Boolean'];
  /** Optional. User notice. */
  userNotice: Scalars['String'];
};

export type WireAddress = {
  __typename?: 'WireAddress';
  /** Address information line one. */
  addressLine1: Scalars['String'];
  /** Address information line two. */
  addressLine2: Maybe<Scalars['String']>;
  /** State name. */
  usaState: Maybe<Scalars['String']>;
  /** City name. */
  city: Scalars['String'];
  /** Zip code. */
  zipCode: Scalars['String'];
};

export type WireBeneficiaryBank = {
  __typename?: 'WireBeneficiaryBank';
  /** Beneficiary bank name. */
  bankName: Scalars['String'];
  /** Beneficiary bank routing number. */
  routingNumber: Scalars['String'];
  /** Beneficiary bank account number. */
  accountNumber: Scalars['String'];
  /** Beneficiary bank address. */
  address: WireAddress;
};

export type WireRecipient = {
  __typename?: 'WireRecipient';
  /** Recipient Id. */
  id: Scalars['ID'];
  /** Short information about recipient. */
  shortInfo: WireRecipientShortInfo;
  /** Detail information about wire recipient. */
  details: WireRecipientDetailInfo;
  /** Information about beneficiary bank for this recipient. */
  beneficiaryBank: WireBeneficiaryBank;
};

export type WireRecipientDetailInfo = {
  __typename?: 'WireRecipientDetailInfo';
  /** Recipient phone number. */
  phoneNumber: Scalars['String'];
  /** Recipient name. */
  name: Scalars['String'];
  /** Recipient email. */
  email: Scalars['String'];
  /** Recipient address. */
  address: WireAddress;
};

export type WireRecipientShortInfo = {
  __typename?: 'WireRecipientShortInfo';
  /** Name of recipient. */
  name: Scalars['String'];
  /** Short information about recipient. */
  description: Scalars['String'];
};

export type WireTransferSettings = {
  __typename?: 'WireTransferSettings';
  /** List of days which should not be available for scheduling payments. */
  excludedPaymentDates: ExcludedPaymentDays;
  /** Get or sets value that specifies whether scheduling allowed on non-business dates. */
  isAllowedSchedulingOnNonBusinessDays: Scalars['Boolean'];
  /** Returns error message if scheduled date is not allowed. */
  notAvailableScheduledDateMessage: Scalars['String'];
  /** Return notice that should be shown when user select scheduled payment option. */
  scheduledPaymentNotice: Scalars['String'];
  /** List of supported recurring options. */
  recurringOptions: Array<RecurringOption>;
  /** Return notice that should be shown when user select recurring payment option. */
  recurringPaymentNotice: Scalars['String'];
  /** Get value of available scheduling for channel. */
  isScheduledPaymentAllowed: Scalars['Boolean'];
  /** End after number of transfers ending option for recurring transfers enabled (True) or disabled (False). */
  allowEndAfterCountOption: Scalars['Boolean'];
  /** End after number of transfers ending option for recurring transfers enabled (True) or disabled (False). */
  allowEndAfterDateOption: Scalars['Boolean'];
  /** Indicates value whether an optional Transfer Memo field is enabled when a user is setting up a transfer. */
  isTransferMemoEnabled: Scalars['Boolean'];
};

export type YearEndInfo = {
  __typename?: 'YearEndInfo';
  label: Scalars['String'];
  value: Scalars['Decimal'];
};

export type YearEndInfoMemberAccount = {
  __typename?: 'YearEndInfoMemberAccount';
  number: Scalars['String'];
  displayName: Scalars['String'];
};

export type YearEndLoanInfo = {
  __typename?: 'YearEndLoanInfo';
  loanId: Scalars['String'];
  label: Scalars['String'];
  previousYearInterest: YearEndInfo;
  currentYearInterest: YearEndInfo;
};

export type YearEndShareInfo = {
  __typename?: 'YearEndShareInfo';
  shareId: Scalars['String'];
  label: Scalars['String'];
  previousYearDividend: YearEndInfo;
  currentYearDividend: YearEndInfo;
  previousYearWithholding: YearEndInfo;
  currentYearWithholding: YearEndInfo;
  previousYearPenalty: YearEndInfo;
  currentYearPenalty: YearEndInfo;
};

/** The zip code field info type. */
export type ZipCodeFieldInfo = UiElementInfo & FieldInfoBase & {
  __typename?: 'ZipCodeFieldInfo';
  length: ZipCodeLength;
  /** MaskFormat should conform following notation: http://docs.telerik.com/kendo-ui/api/web/maskedtextbox#configuration-mask */
  maskFormat: Scalars['String'];
  /** The field Id. */
  id: Scalars['ID'];
  /** Field type. */
  type: FieldType;
  /** Field Name, Can be used as Label in AT and/or for validation and error messages. */
  name: Maybe<Scalars['String']>;
  /** Field Description Can be used as tooltip or watermark on a presentation level */
  description: Maybe<Scalars['String']>;
  /** Gets value which indicates whether this field is enabled in the current configuration. */
  isEnabled: Scalars['Boolean'];
  /** Label for front-end UI */
  label: Maybe<Scalars['String']>;
  /** Indicates whether the field with the field value needs to be submitted in SubmitWizardStepFieldValues API calls. */
  isSubmittable: Scalars['Boolean'];
  /**
   * client-based property bindings.
   *                     Bindings are exposed to the web app to form a kind of name-value collection of data items
   *                     required to property display a configurable field (that is called DataContext).
   */
  bindings: Maybe<Array<Maybe<Binding>>>;
  /** Field Behavior e.g Required, Readonly */
  fieldBehaviorMode: FieldBehaviorMode;
  /** This property stores the field input hint. Intended to be displayed by native apps on mobile devices. */
  placeholder: Maybe<Scalars['String']>;
  /** Max length of the field. */
  maxLength: Scalars['Int'];
  /** Min length of the field. */
  minLength: Scalars['Int'];
  /** The value of the field. */
  value: Maybe<Scalars['String']>;
};

export enum ZipCodeLength {
  FIVEDIGIT = 'FIVEDIGIT',
  NINEDIGIT = 'NINEDIGIT'
}

export type ZoneHolder = {
  __typename?: 'ZoneHolder';
  /** The Zone Name */
  zoneName: Maybe<Scalars['ID']>;
  /** Widgets Inside the Zone */
  items: Array<Maybe<WidgetUnion>>;
};

export type CompromisedCredentials = {
  __typename?: 'compromisedCredentials';
  /** The status of the compromised credentials check. */
  status: CompromisedCredentialsStatus;
};

export type GetNewPayByCardTransferDataResult = {
  __typename?: 'getNewPayByCardTransferDataResult';
  /** Pay by Card source accounts. */
  sourceAccounts: Array<PayByCardExternalCard>;
  /** Pay by Card destination accounts. */
  destinationAccounts: Array<PayByCardBankAccount>;
  /** Pay by Card transfer settings. */
  transferSettings: PayByCardTransferSettings;
};

export type InvestmentBankAccount = {
  __typename?: 'investmentBankAccount';
  /** Bank account identifier. This identifier is unique among all accounts */
  id: Scalars['ID'];
  /** Indicate whether available balance should be shown for this account. */
  showAvailableBalance: Scalars['Boolean'];
  /** Indicate whether available balance should be shown for this account. */
  showCurrentBalance: Scalars['Boolean'];
};

export type InvestmentBankAccountGroup = {
  __typename?: 'investmentBankAccountGroup';
  /** List of accounts. */
  accounts: Array<InvestmentBankAccount>;
};

export type InvestmentPaymentOption = {
  __typename?: 'investmentPaymentOption';
  /** Unique option identifier. */
  id: Scalars['String'];
  /** User friendly label. */
  label: Scalars['String'];
};

export type M2mBankAccount = {
  __typename?: 'm2mBankAccount';
  /** Indicates whether one time scheduled payment is allowed for account. */
  isScheduledPaymentAllowed: Scalars['Boolean'];
  /** Indicates whether recurring payments is allowed for account. */
  isRecurringPaymentsAllowed: Scalars['Boolean'];
  /** Indicate whether transaction history can be loaded for this instance */
  isPaymentOptionsAvailable: Scalars['Boolean'];
  /** List of supported recurring options. */
  forcedRecurringOptions: Maybe<Array<RecurringOption>>;
  /** Indicate whether available balance should be shown for this account. */
  showCurrentBalance: Scalars['Boolean'];
  /** Bank account identifier. This identifier is unique among all accounts */
  id: Scalars['ID'];
  /** User notice. */
  userNotice: Maybe<Scalars['String']>;
  /** Indicate whether available balance should be shown for this account. */
  showAvailableBalance: Scalars['Boolean'];
};

export type M2mPayeesSettings = {
  __typename?: 'm2mPayeesSettings';
  /** Member to Member recipient share/loan id switch. */
  subAccountEnabled: Scalars['Boolean'];
  /** Member to Member recipient share/loan id format. */
  subAccountFormat: Scalars['String'];
  /** Indicates value whether an destination account type selector is visible on UI. */
  isAccountTypeSelectorEnabled: Scalars['Boolean'];
  /** Comma-separated list of values showing in destination account type selector. */
  accountTypeSelectorValues: Scalars['String'];
};

export type M2mTransferSettings = {
  __typename?: 'm2mTransferSettings';
  /** Get or sets value that specifies whether scheduling allowed on non-business dates. */
  isAllowedSchedulingOnNonBusinessDays: Scalars['Boolean'];
  /** List of days which should not be available for scheduling payments. */
  excludedPaymentDates: ExcludedPaymentDays;
  /** Return notice that should be shown when user select scheduled payment option. */
  scheduledPaymentNotice: Maybe<Scalars['String']>;
  /** Returns error message if scheduled date is not allowed. */
  notAvailableScheduledDateMessage: Maybe<Scalars['String']>;
  /** Return notice that should be shown when user select recurring payment option. */
  recurringPaymentNotice: Maybe<Scalars['String']>;
  /** Get is schedule option available for channel */
  isScheduledPaymentAllowed: Scalars['Boolean'];
  /** List of supported recurring options. */
  recurringOptions: Array<RecurringOption>;
  /** Switcher for message for share/loan ID */
  defaultDepositAccountType: Scalars['Boolean'];
  /** End after number of transfers ending option for recurring transfers enabled (True) or disabled (False) */
  allowEndAfterCountOption: Scalars['Boolean'];
  /** End after date of transfer ending option for recurring transfers enabled (True) or disabled (False) */
  allowEndAfterDateOption: Scalars['Boolean'];
  /** Indicates whether an optional Transfer Memo field is enabled when a user is setting up a transfer. */
  isTransferMemoEnabled: Scalars['Boolean'];
};

export type PayByCardBankAccount = {
  __typename?: 'payByCardBankAccount';
  /** Indicates whether one time scheduled payment is allowed for account. */
  isScheduledPaymentAllowed: Scalars['Boolean'];
  /** Indicates whether recurring payments is allowed for account. */
  isRecurringPaymentsAllowed: Scalars['Boolean'];
  /** Indicate whether transaction history can be loaded for this instance */
  isPaymentOptionsAvailable: Scalars['Boolean'];
  /** List of supported recurring options. */
  forcedRecurringOptions: Maybe<Array<RecurringOption>>;
  /** Indicate whether available balance should be shown for this account. */
  showCurrentBalance: Scalars['Boolean'];
  /** Bank account identifier. This identifier is unique among all accounts */
  id: Scalars['ID'];
  /** User notice. */
  userNotice: Maybe<Scalars['String']>;
  /** Indicate whether available balance should be shown for this account. */
  showAvailableBalance: Scalars['Boolean'];
};

export type PayByCardExternalCard = {
  __typename?: 'payByCardExternalCard';
  /** Id of external card. */
  id: Maybe<Scalars['ID']>;
  /** Id of the user the card belongs to. */
  userId: Maybe<Scalars['ID']>;
  /** Obfuscated card number. */
  cardNumber: Scalars['String'];
  /** Expiration date of the card. */
  expirationDate: Scalars['String'];
  /** Obfuscated card number. */
  description: Scalars['String'];
  /** Name of cardholder. */
  cardholder: Scalars['String'];
};

export type PayByCardTransferSettings = {
  __typename?: 'payByCardTransferSettings';
  /** Get or sets value that specifies whether scheduling allowed on non-business dates. */
  isAllowedSchedulingOnNonBusinessDays: Scalars['Boolean'];
  /** List of days which should not be available for scheduling payments. */
  excludedPaymentDates: ExcludedPaymentDays;
  /** Return notice that should be shown when user select scheduled payment option. */
  scheduledPaymentNotice: Maybe<Scalars['String']>;
  /** Returns error message if scheduled date is not allowed. */
  notAvailableScheduledDateMessage: Maybe<Scalars['String']>;
  /** Return notice that should be shown when user select recurring payment option. */
  recurringPaymentNotice: Maybe<Scalars['String']>;
  /** Get is schedule option available for channel */
  isScheduledPaymentAllowed: Scalars['Boolean'];
  /** List of supported recurring options. */
  recurringOptions: Array<RecurringOption>;
  /** End after number of transfers ending option for recurring transfers enabled (True) or disabled (False) */
  allowEndAfterCountOption: Scalars['Boolean'];
  /** End after date of transfer ending option for recurring transfers enabled (True) or disabled (False) */
  allowEndAfterDateOption: Scalars['Boolean'];
  /** Indicates whether an optional Transfer Memo field is enabled when a user is setting up a transfer. */
  isTransferMemoEnabled: Scalars['Boolean'];
  /** Amount of per transaction fee for pay by card transfers in dollars. */
  perTransactionFee: Scalars['Decimal'];
  /** Percent of transaction fee for pay by card transfers. */
  feePercent: Scalars['Decimal'];
};

export type SecuritySettings = {
  __typename?: 'securitySettings';
  /** Shows whether compromised credentials check is enabled */
  compromisedCredentialsCheckEnabled: Scalars['Boolean'];
};

export type StopCheckBankAccount = {
  __typename?: 'stopCheckBankAccount';
  /** Bank account Id. */
  id: Scalars['ID'];
  /** Indicates whether available balance is visible. */
  isAvailableBalanceVisible: Scalars['Boolean'];
  /** Indicates whether current balance is visible. */
  isCurrentBalanceVisible: Scalars['Boolean'];
};
