import React, {
  FunctionComponent,
  KeyboardEvent,
  ReactNode, useEffect, useRef,
} from 'react';

import classNames from 'classnames';

import { useBreakpoint } from '@ast/magma/breakpoints';

import { Card, CardProps } from '@ast/magma/components/card';
import { ClippedTextBox } from '@ast/magma/components/clippedTextBox';

import styles from './AccountCard.pcss';

export function hasReactNode(value: ReactNode): boolean {
  return value !== undefined && value !== false && value !== null;
}

export interface AccountCardProps extends CardProps {

  readonly isCollapsed?: boolean;
  readonly isSelected?: boolean;
  readonly isFocusable?: boolean;

  readonly title?: ReactNode | string;
  readonly isTitleMultiline?: boolean;

  readonly titleAfter?: ReactNode;
  readonly titleBefore?: ReactNode;

  readonly contentAfter?: ReactNode;
  readonly contentBefore?: ReactNode;

  readonly footerAfter?: ReactNode;
  readonly footerBefore?: ReactNode;
  readonly children?: ReactNode;

  readonly isFooterBeforeMultiline?: boolean;
  readonly onClick?: React.MouseEventHandler<Element>;

  /*
   * Apply custom class
   * @default undefined.
   */
  readonly className?: string;
}

export const AccountCard: FunctionComponent<AccountCardProps> = ({
  isFocusable,
  isSelected,
  isCollapsed,
  isTitleMultiline,
  className,
  title,
  titleAfter,
  titleBefore,
  contentAfter,
  contentBefore,
  footerAfter,
  footerBefore,
  isFooterBeforeMultiline,
  onClick,
  children,
  ...otherProps
}: AccountCardProps) => {
  const hasTitleAfter = hasReactNode(titleAfter);
  const hasTitleBefore = hasReactNode(titleBefore);

  const hasContentAfter = hasReactNode(contentAfter);
  const hasContentBefore = hasReactNode(contentBefore);

  const hasFooterAfter = hasReactNode(footerAfter);
  const hasFooterBefore = hasReactNode(footerBefore);

  const card = useRef<HTMLDivElement>(null);

  const isMobile = useBreakpoint().device === 'mobile';

  /**
   * React keydown handler.
   * @param event react keyboard event
   */
  const keyDownHandler = (event: KeyboardEvent<HTMLDivElement>): void => {
    if (event.key === 'Enter') {
      card?.current?.click();
    }
  };

  const onClickHandler = (event: React.MouseEvent<HTMLDivElement>) => {
    if (!isCollapsed && onClick) {
      onClick(event);
    }
  };

  useEffect(() => {
    if (isSelected && isMobile) {
      card?.current?.scrollIntoView({
        behavior: 'smooth',
        block: 'start',
      });
    }
  }, [isSelected]);

  return (
    <Card
      className={classNames(
        styles.card,
        isCollapsed && styles.isCollapsed,
        isSelected && styles.isSelected,
        className,
      )}
      ref={card}
      tabIndex={isFocusable ? 0 : -1}
      onKeyDown={keyDownHandler}
      onClick={onClickHandler}
      {...otherProps}
    >
      <header className={classNames(styles.header)}>
        <div
          className={classNames(styles.titleBefore)}
          data-stable-name="AccountCardTitleBefore"
        >
          {hasTitleBefore && titleBefore}
        </div>
        <h3
          className={classNames(
            styles.title,
            isTitleMultiline && styles.isTitleMultiline,
          )}
          data-stable-name="AccountCardTitle"
        >
          {title}
        </h3>
        <div
          className={classNames(styles.titleAfter)}
          data-stable-name="AccountCardTitleAfter"
        >
          {hasTitleAfter && (
            <ClippedTextBox className={classNames(styles.titleAfterClipBox)}>
              {titleAfter}
            </ClippedTextBox>
          )}
        </div>
      </header>
      <section className={classNames(styles.content)}>
        {hasContentBefore && (
          <div
            className={classNames(styles.contentBefore)}
            data-stable-name="AccountCardContentBefore"
          >
            {contentBefore}
          </div>
        )}
        {hasContentAfter && (
          <div
            className={classNames(styles.contentAfter)}
            data-stable-name="AccountCardContentAfter"
          >
            {contentAfter}
          </div>
        )}
      </section>
      <footer className={classNames(styles.footer)}>
        {hasFooterBefore && (
          <div
            className={classNames(
              styles.footerBefore,
              isFooterBeforeMultiline && styles.isFooterBeforeMultiline,
            )}
            data-stable-name="AccountCardFooterBefore"
          >
            {footerBefore}
          </div>
        )}
        {hasFooterAfter && (
          <div
            className={classNames(styles.footerAfter)}
            data-stable-name="AccountCardFooterAfter"
          >
            {footerAfter}
          </div>
        )}
      </footer>
      { children }
    </Card>
  );
};

AccountCard.defaultProps = {
  isCollapsed: false,
  isTitleMultiline: true,
  isFooterBeforeMultiline: false,
  // Card default options
  hasShadow: true,
  hasBorder: false,
};
