import { UserError } from '@app/common/errors/UserError';

import { getFixedT } from '@app/core/i18n';

import { PageURL } from './pageURL';

const t = getFixedT(null, 'app');

export type ApplicationLayout = 'anonymous' | 'dashboard';

/**
 * Page settings.
 */
export interface PageSettings {
  /**
   * Page layout id.
   */
  readonly layout: ApplicationLayout;
  /**
   * Page wizard id.
   */
  readonly pageId: string;
}

type PageIndexedArray = {
  [key in PageURL]: Page;
};

interface Page {
  layout: 'anonymous' | 'dashboard'
  subPages?: PageIndexedArray
}

const Pages: PageIndexedArray = {
  [PageURL.USER_LOGIN]: {
    layout: 'anonymous',
  },
  [PageURL.USER_ENROLLMENT]: {
    layout: 'anonymous',
  },
  [PageURL.SUB_USER_ENROLLMENT]: {
    layout: 'anonymous',
  },
  [PageURL.USER_RETRIEVAL]: {
    layout: 'anonymous',
  },
  [PageURL.SECURE_ACCOUNT]: {
    layout: 'anonymous',
  },
  [PageURL.USER_KIND]: {
    layout: 'anonymous',
  },
  [PageURL.EMAIL_VERIFICATION]: {
    layout: 'anonymous',
  },
  [PageURL.SUB_USER_APPROVAL]: {
    layout: 'anonymous',
  },
  [PageURL.BANK_ACCOUNT]: {
    layout: 'dashboard',
  },
  [PageURL.TRANSFERS_PAYMENTS]: {
    layout: 'dashboard',
  },
  [PageURL.ALERT_SETTINGS]: {
    layout: 'dashboard',
  },
  [PageURL.MESSAGE_CENTER]: {
    layout: 'dashboard',
  },
  [PageURL.CONTACTS_AND_SETTINGS]: {
    layout: 'dashboard',
  },
  [PageURL.CARD_CONTROL]: {
    layout: 'dashboard',
  },
  [PageURL.FAMILY_AND_FRIENDS]: {
    layout: 'dashboard',
  },
  [PageURL.BUSINESS_BANKING]: {
    layout: 'dashboard',
  },
  [PageURL.ESTATEMENTS]: {
    layout: 'dashboard',
  },
  [PageURL.OPEN_NEW_SHARES]: {
    layout: 'dashboard',
  },
  [PageURL.TRANSFER_ACTIVITY]: {
    layout: 'dashboard',
  },
  [PageURL.CREDIT_SCORE]: {
    layout: 'dashboard',
  },
};

/**
 * Error to report when selected page initialization have been failed due to
 * not found page layout or page wizard settings.
 */
export const getNotFoundError = () => new UserError({
  critical: true,
  message: t('page-not-found.message|Page not found error message',
    'The page you are looking for does not exist, have been removed, name changed or is temporary unavailable.'),
  title: t('page-not-found.title|Page not found error title',
    'Page not found'),
});

/**
 * Extract page path from URL.
 * @param path URL path
 * @returns '/' or '/page-path'
 */
export const extractPagePath = (path: string): string => `/${path.split('/').filter((s) => !!s)[0] || ''}`;

/**
 * Extract page id from page path.
 * @param pagePath '/page-path' url.
 * @returns 'page-path' as page id.
 */
export const getPageIdFromPage = (pagePath: string):string => (pagePath.replace('/', ''));

/**
  * Checks by URL if a page relates to anonymous layout
  * @param url page URL
  */
export const isAnonymousPage = (url: PageURL) => (
  Pages[url] && Pages[url].layout === 'anonymous'
);

/**
 * Get page layout from page path.
 * @param pagePath '/page-path' url.
 * @returns page settings.
 */
export const getPageLayout = (pagePath: string): ApplicationLayout => (
  // Anonynous layout used for enrollment/login/retrieval pages
  isAnonymousPage(pagePath as PageURL) ? 'anonymous' : 'dashboard'
);

/**
 * Get page settings from page path.
 * @param pagePath '/page-path' url.
 * @returns page settings.
 */
export const getPageSettings = (pagePath: string): PageSettings => (
  {
    layout: getPageLayout(pagePath),
    pageId: getPageIdFromPage(pagePath),
  }
);
