import React, { useState, useEffect, ComponentType } from 'react';
import { useFormContext } from 'react-hook-form';
import { InputAttributes, PatternFormat, PatternFormatProps } from 'react-number-format';

import { Input } from '@ast/magma/components/input';

import { CFFormField } from '@app/common/configurable-wizards/fields/common/CFFormField';
import { getFieldErrorMessage } from '@app/common/configurable-wizards/utils';
import { useCommonValidationMessages } from '@app/common/valiation/commonValidationMessages';

export interface PhoneInputProps extends Omit<PatternFormatProps, 'defaultValue' | 'format'> {
  readonly name: string;
  readonly defaultValue?: string | null;
  readonly options?: any
  readonly containerClassName?: string;
  readonly fieldClassName?: string;
  readonly format?: string;
  readonly label?: string;
}

export const PhoneInput: React.FC<PhoneInputProps> = ({
  name,
  options,
  defaultValue = '',
  containerClassName,
  fieldClassName,
  format = '',
  ...props
}) => {
  const {
    register,
    formState: { errors },
    setValue,
  } = useFormContext();
  const [inputValue, setInputValue] = useState(defaultValue || '');
  const commonValidationMessages = useCommonValidationMessages();
  const error = getFieldErrorMessage(name, errors, commonValidationMessages);
  const { onChange, ...registerOptions } = register(name, options);

  useEffect(() => {
    setValue(name, inputValue);
  }, [inputValue]);

  return (
    <CFFormField
      error={error}
      className={containerClassName}
    >
      <PatternFormat
        mask=""
        format={format}
        type="tel"
        className={fieldClassName}
        customInput={Input as ComponentType<InputAttributes> | undefined}
        aria-invalid={!!error}
        value={inputValue}
        onChange={(e: any) => {
          setInputValue(e.target.value);
          onChange(e);
        }}
        {...props}
        {...registerOptions}
      />
    </CFFormField>
  );
};
