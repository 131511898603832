/* eslint-disable i18next/no-literal-string */
import { ReactElement } from 'react';
import {
  DefaultNamespace,
  initReactI18next, KeyPrefix, Namespace,
  useTranslation as originalUseTranslation,
  UseTranslationOptions,
  UseTranslationResponse,
} from 'react-i18next';

import i18n, { TFunction } from 'i18next';
import join from 'url-join';

import { DEFAULT_FALLBACK_LANGUAGE_CULTURE } from '@app/core/contexts/localizationContext/constants';
import {
  getCurrentLanguageCultureFromStorage,
  getLanguageFromCulture,
} from '@app/core/contexts/localizationContext/LocalizationContext';
import { parseHtml } from '@app/core/stringResources/parseHtml';

import { getCustomAuthHeaders } from './authentication/customAuthHeaders';

import { appSettings } from './appSettings';

/**
 * Text customization service initialized promise
 */

const currentCulture = getCurrentLanguageCultureFromStorage();
const currentLanguage = getLanguageFromCulture(currentCulture || DEFAULT_FALLBACK_LANGUAGE_CULTURE);

export const initializeI18n = new Promise<void>((resolve) => {
  i18n
    .use({
      type: 'backend',
      read: (language: string, namespace: string, callback: any) => {
        const url = join(
          appSettings().apiEndpoint,
          'api/textcustomization',
        );
        const customAuthHeaders = getCustomAuthHeaders();
        const headers = {
          ...customAuthHeaders,
          'Content-Type': 'application/json',
          'x-ast-lang-namespace': namespace,
        };

        fetch(url, {
          method: 'GET',
          headers,
          credentials: 'include',
          mode: 'cors',
        })
          .then((response) => response.json())
          .then((data) => {
            callback(null, data);
          })
          .catch((error) => {
            callback(error, null);
          });
      },
    })
    // pass the i18n instance to react-i18next.
    .use(initReactI18next)
    // init i18next
    // for all options read: https://www.i18next.com/overview/configuration-options
    .init({
      keySeparator: false, // to use dots in strings
      nsSeparator: ':',
      lng: currentLanguage,
      fallbackLng: currentLanguage,
      defaultNS: 'app',
      ns: ['app'],
      debug: false,
      interpolation: {
        escapeValue: false, // not needed for react as it escapes by default
      },
      react: {
        transSupportBasicHtmlNodes: true,
        transKeepBasicHtmlNodesFor: ['strong', 'b', 'em', 'i', 'div', 's'],
        useSuspense: false,
      },
    });

  if (i18n.isInitialized) {
    resolve();
  } else {
    i18n.on('initialized', () => resolve());
  }
});

const parseHtmlIfString = (
  translatedText: any,
): string | ReactElement<any, any> | Array<string | ReactElement<any, any>> => {
  const text = parseHtml(translatedText);

  if (Array.isArray(text)) {
    if (text.length === 1) {
      return text[0];
    }
    return text;
  }

  return text;
};

export function useTranslation<
  N extends Namespace = DefaultNamespace,
  TKPrefix extends KeyPrefix<N> = undefined,
>(
  ns?: N | Readonly<N>,
  options?: UseTranslationOptions<TKPrefix>,
): UseTranslationResponse<N, TKPrefix> {
  const { t: originalT, i18n: originalI18n, ready } = originalUseTranslation(ns, options);
  const t: TFunction = (key: any, defaultMessage?: string, _options?: any): any => {
    const translatedText = originalT(key, defaultMessage, _options);
    return parseHtmlIfString(translatedText);
  };

  return { t, i18n: originalI18n, ready } as UseTranslationResponse<N, TKPrefix>;
}

export function getFixedT(
  lng: string | readonly string[],
  ns?: string | readonly string[],
  keyPrefix?: string
): TFunction;
export function getFixedT(
  lng: null,
  ns: string | readonly string[] | null,
  keyPrefix?: string
): TFunction;
export function getFixedT(
  lng: string | readonly string[] | null,
  ns?: string | readonly string[] | null,
  keyPrefix?: string,
): TFunction {
  if (lng === null) {
    // @ts-ignore
    const originalFixedT = i18n.getFixedT(lng, ns, keyPrefix);
    return (lng, key: any, options?: any): any => {
      const translatedText = originalFixedT(lng, key, options);
      return parseHtmlIfString(translatedText);
    };
  }
  const originalFixedT = i18n.getFixedT(lng, ns as string | readonly string[] | undefined, keyPrefix);
  return (_lng: string, _ns: any, _keyPrefix?: any): any => {
    const translatedText = originalFixedT(_lng, _ns, _keyPrefix);
    return parseHtmlIfString(translatedText);
  };
}

const originalT: TFunction = i18n.t.bind(i18n);
const tWithHtmlParsing: TFunction = (
  key: any,
  defaultMessage?: string,
  options?: any,
): any => {
  const translatedText = originalT(key, defaultMessage, options);
  return parseHtmlIfString(translatedText);
};

export { tWithHtmlParsing as t };
export default i18n;
