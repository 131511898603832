import React from 'react';

import classNames from 'classnames';

import CheckCircle from '@ast/magma/components/icon/icons/CheckCircle';
import TimesCircle from '@ast/magma/components/icon/icons/TimesCircle';
import Warning from '@ast/magma/components/icon/icons/Warning';

import { DialogStatus, DialogStatusProps, StatusLevel } from '@ast/magma/components/dialog';

import { uniqueId } from '@ast/magma/inner/utils/uniqueid';

import { isFunction } from '@app/common/utils';
import { isHTMLElement } from '@app/common/utils/dom';
import { useDocumentTitle } from '@app/common/utils/hooks/useDocumentTitle';
import { endWithDot } from '@app/common/utils/string/string';
import { getFirstFocusableElement } from '@app/common/utils/tabbable';

import style from './StatusWizardStep.pcss';

export interface StatusWizardStepProps {
  readonly level: DialogStatusProps['level'];
  readonly icon?: DialogStatusProps['icon'];
  readonly buttons?: () => React.ReactNode;
  readonly title: string;
  readonly contentClassName?: string;
}

/**
 * Status step of wizard flow.
 * Appeared as card with title, message, and action buttons
 */
export const StatusWizardStep: React.FC<StatusWizardStepProps> = ({
  level,
  icon,
  title,
  contentClassName,
  children,
  buttons,
}) => {
  const titleId = uniqueId('wizardStatusTitle');

  useDocumentTitle(title, false, true);

  const setAccessibleFocus = (elm: HTMLElement) => {
    // 1. find first focusable element
    const focusableElm = getFirstFocusableElement(elm);

    if (isHTMLElement(focusableElm)) {
      // 2. prepare the element before focusing
      // We need to associate the first focusable element with some additional information
      // to make clear what is going on for people with disabilities.
      // So when the user will be focused on the first focusable element he will hear all required information.
      const currentValue = focusableElm.getAttribute('aria-describedby');
      focusableElm.setAttribute('aria-describedby', `${titleId} ${currentValue}`);

      // 3. foucus on the element
      focusableElm.focus();
    }
  };

  const getDefaultIcon = (statusLevel: StatusLevel) => {
    switch (statusLevel) {
      case 'success': return <CheckCircle data-stable-name={`StatusStep-${statusLevel}`} />;
      case 'error': return <TimesCircle data-stable-name={`StatusStep-${statusLevel}`} />;
      case 'warning': return <Warning data-stable-name={`StatusStep-${statusLevel}`} />;
      case 'info': return <Warning data-stable-name={`StatusStep-${statusLevel}`} />;
      default: return <></>;
    }
  };

  return (
    <div
      data-stable-name="WizardDialogStatus"
      className={classNames(style.container, contentClassName)}
      ref={(element) => {
        if (isHTMLElement(element)) {
          setAccessibleFocus(element);
        }
      }}
    >
      {level !== 'support' && (
      <DialogStatus
        icon={icon || getDefaultIcon(level)}
        level={level}
        className={style.title}
      />
      )}

      {/* FIXME: There should be no `aria-label` on labelled elements; */}
      {title && (
        <h5 className={style.wizardTitle} id={titleId} aria-label={endWithDot(title)}>
          {title}
        </h5>
      )}

      {children && (
        <div className={style.body}>
          {children}
        </div>
      )}

      {isFunction(buttons) && (
        <div>
          {buttons()}
        </div>
      )}
    </div>
  );
};
