/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.
import * as Types from '../../../../queryTyping';

import { gql } from '@apollo/client';
export type AllFields_AgreementFieldInfo_Fragment = (
  { __typename?: 'AgreementFieldInfo' }
  & AgreementFieldFragment
);

export type AllFields_BooleanFieldInfo_Fragment = (
  { __typename?: 'BooleanFieldInfo' }
  & BooleanFieldFragment
);

export type AllFields_ChoiceCollectionFieldInfoBase_Fragment = { __typename?: 'ChoiceCollectionFieldInfoBase' };

export type AllFields_ConstrainedTextFieldInfo_Fragment = (
  { __typename?: 'ConstrainedTextFieldInfo' }
  & ConstrainedTextFieldFragment
);

export type AllFields_CurrencyFieldInfo_Fragment = (
  { __typename?: 'CurrencyFieldInfo' }
  & CurrencyFieldFragment
);

export type AllFields_DateFieldInfo_Fragment = (
  { __typename?: 'DateFieldInfo' }
  & DateFieldFragment
);

export type AllFields_DecimalFieldInfo_Fragment = { __typename?: 'DecimalFieldInfo' };

export type AllFields_EmailFieldInfo_Fragment = (
  { __typename?: 'EmailFieldInfo' }
  & EmailFieldFragment
);

export type AllFields_ErrorMessageFieldInfo_Fragment = (
  { __typename?: 'ErrorMessageFieldInfo' }
  & ErrorMessageFieldFragment
);

export type AllFields_ExternalLinkFieldInfo_Fragment = (
  { __typename?: 'ExternalLinkFieldInfo' }
  & ExternalLinkFieldFragment
);

export type AllFields_FieldInfoGroup_Fragment = (
  { __typename?: 'FieldInfoGroup' }
  & FieldInfoGroupFieldFragment
);

export type AllFields_FuzzyValueFieldInfo_Fragment = (
  { __typename?: 'FuzzyValueFieldInfo' }
  & FuzzyValueFieldFragment
);

export type AllFields_HiddenFieldInfo_Fragment = (
  { __typename?: 'HiddenFieldInfo' }
  & HiddenFieldFragment
);

export type AllFields_HtmlTextFieldInfo_Fragment = (
  { __typename?: 'HtmlTextFieldInfo' }
  & HtmlTextFieldFragment
);

export type AllFields_IntegerFieldInfo_Fragment = (
  { __typename?: 'IntegerFieldInfo' }
  & IntegerFieldFragment
);

export type AllFields_LabelFieldInfo_Fragment = (
  { __typename?: 'LabelFieldInfo' }
  & LabelFieldFragment
);

export type AllFields_MaskedTextFieldInfo_Fragment = (
  { __typename?: 'MaskedTextFieldInfo' }
  & MaskedTextFieldFragment
);

export type AllFields_MultipleChoiceFieldInfo_Fragment = (
  { __typename?: 'MultipleChoiceFieldInfo' }
  & MultipleChoiceFieldFragment
);

export type AllFields_NotificationFieldInfo_Fragment = (
  { __typename?: 'NotificationFieldInfo' }
  & NotificationFieldFragment
);

export type AllFields_PasswordFieldInfo_Fragment = (
  { __typename?: 'PasswordFieldInfo' }
  & PasswordFieldFragment
);

export type AllFields_QuestionPoolsFieldInfoGroup_Fragment = (
  { __typename?: 'QuestionPoolsFieldInfoGroup' }
  & QuestionPoolsFieldGroupFragment
);

export type AllFields_SelectableGroupFieldInfo_Fragment = (
  { __typename?: 'SelectableGroupFieldInfo' }
  & SelectableGroupFieldFragment
);

export type AllFields_SingleChoiceFieldInfo_Fragment = (
  { __typename?: 'SingleChoiceFieldInfo' }
  & SingleChoiceFieldFragment
);

export type AllFields_SsnFieldInfo_Fragment = (
  { __typename?: 'SsnFieldInfo' }
  & SsnFieldFragment
);

export type AllFields_TextFieldInfo_Fragment = (
  { __typename?: 'TextFieldInfo' }
  & TextFieldFragment
);

export type AllFields_TimeFieldInfo_Fragment = (
  { __typename?: 'TimeFieldInfo' }
  & TimeFieldFragment
);

export type AllFields_UsernameFieldInfo_Fragment = (
  { __typename?: 'UsernameFieldInfo' }
  & UsernameFieldFragment
);

export type AllFields_ZipCodeFieldInfo_Fragment = (
  { __typename?: 'ZipCodeFieldInfo' }
  & ZipFieldFragment
);

export type AllFieldsFragment = AllFields_AgreementFieldInfo_Fragment | AllFields_BooleanFieldInfo_Fragment | AllFields_ChoiceCollectionFieldInfoBase_Fragment | AllFields_ConstrainedTextFieldInfo_Fragment | AllFields_CurrencyFieldInfo_Fragment | AllFields_DateFieldInfo_Fragment | AllFields_DecimalFieldInfo_Fragment | AllFields_EmailFieldInfo_Fragment | AllFields_ErrorMessageFieldInfo_Fragment | AllFields_ExternalLinkFieldInfo_Fragment | AllFields_FieldInfoGroup_Fragment | AllFields_FuzzyValueFieldInfo_Fragment | AllFields_HiddenFieldInfo_Fragment | AllFields_HtmlTextFieldInfo_Fragment | AllFields_IntegerFieldInfo_Fragment | AllFields_LabelFieldInfo_Fragment | AllFields_MaskedTextFieldInfo_Fragment | AllFields_MultipleChoiceFieldInfo_Fragment | AllFields_NotificationFieldInfo_Fragment | AllFields_PasswordFieldInfo_Fragment | AllFields_QuestionPoolsFieldInfoGroup_Fragment | AllFields_SelectableGroupFieldInfo_Fragment | AllFields_SingleChoiceFieldInfo_Fragment | AllFields_SsnFieldInfo_Fragment | AllFields_TextFieldInfo_Fragment | AllFields_TimeFieldInfo_Fragment | AllFields_UsernameFieldInfo_Fragment | AllFields_ZipCodeFieldInfo_Fragment;

export type FieldInfo_AgreementFieldInfo_Fragment = (
  { __typename?: 'AgreementFieldInfo' }
  & Pick<Types.AgreementFieldInfo, 'fieldBehaviorMode' | 'placeholder'>
);

export type FieldInfo_BooleanFieldInfo_Fragment = (
  { __typename?: 'BooleanFieldInfo' }
  & Pick<Types.BooleanFieldInfo, 'fieldBehaviorMode' | 'placeholder'>
);

export type FieldInfo_ChoiceCollectionFieldInfoBase_Fragment = (
  { __typename?: 'ChoiceCollectionFieldInfoBase' }
  & Pick<Types.ChoiceCollectionFieldInfoBase, 'fieldBehaviorMode' | 'placeholder'>
);

export type FieldInfo_ConstrainedTextFieldInfo_Fragment = (
  { __typename?: 'ConstrainedTextFieldInfo' }
  & Pick<Types.ConstrainedTextFieldInfo, 'fieldBehaviorMode' | 'placeholder'>
);

export type FieldInfo_CurrencyFieldInfo_Fragment = (
  { __typename?: 'CurrencyFieldInfo' }
  & Pick<Types.CurrencyFieldInfo, 'fieldBehaviorMode' | 'placeholder'>
);

export type FieldInfo_DateFieldInfo_Fragment = (
  { __typename?: 'DateFieldInfo' }
  & Pick<Types.DateFieldInfo, 'fieldBehaviorMode' | 'placeholder'>
);

export type FieldInfo_DecimalFieldInfo_Fragment = (
  { __typename?: 'DecimalFieldInfo' }
  & Pick<Types.DecimalFieldInfo, 'fieldBehaviorMode' | 'placeholder'>
);

export type FieldInfo_EmailFieldInfo_Fragment = (
  { __typename?: 'EmailFieldInfo' }
  & Pick<Types.EmailFieldInfo, 'fieldBehaviorMode' | 'placeholder'>
);

export type FieldInfo_ErrorMessageFieldInfo_Fragment = (
  { __typename?: 'ErrorMessageFieldInfo' }
  & Pick<Types.ErrorMessageFieldInfo, 'fieldBehaviorMode' | 'placeholder'>
);

export type FieldInfo_FuzzyValueFieldInfo_Fragment = (
  { __typename?: 'FuzzyValueFieldInfo' }
  & Pick<Types.FuzzyValueFieldInfo, 'fieldBehaviorMode' | 'placeholder'>
);

export type FieldInfo_HtmlTextFieldInfo_Fragment = (
  { __typename?: 'HtmlTextFieldInfo' }
  & Pick<Types.HtmlTextFieldInfo, 'fieldBehaviorMode' | 'placeholder'>
);

export type FieldInfo_IntegerFieldInfo_Fragment = (
  { __typename?: 'IntegerFieldInfo' }
  & Pick<Types.IntegerFieldInfo, 'fieldBehaviorMode' | 'placeholder'>
);

export type FieldInfo_MaskedTextFieldInfo_Fragment = (
  { __typename?: 'MaskedTextFieldInfo' }
  & Pick<Types.MaskedTextFieldInfo, 'fieldBehaviorMode' | 'placeholder'>
);

export type FieldInfo_MultipleChoiceFieldInfo_Fragment = (
  { __typename?: 'MultipleChoiceFieldInfo' }
  & Pick<Types.MultipleChoiceFieldInfo, 'fieldBehaviorMode' | 'placeholder'>
);

export type FieldInfo_PasswordFieldInfo_Fragment = (
  { __typename?: 'PasswordFieldInfo' }
  & Pick<Types.PasswordFieldInfo, 'fieldBehaviorMode' | 'placeholder'>
);

export type FieldInfo_QuestionPoolsFieldInfoGroup_Fragment = (
  { __typename?: 'QuestionPoolsFieldInfoGroup' }
  & Pick<Types.QuestionPoolsFieldInfoGroup, 'fieldBehaviorMode' | 'placeholder'>
);

export type FieldInfo_SingleChoiceFieldInfo_Fragment = (
  { __typename?: 'SingleChoiceFieldInfo' }
  & Pick<Types.SingleChoiceFieldInfo, 'fieldBehaviorMode' | 'placeholder'>
);

export type FieldInfo_SsnFieldInfo_Fragment = (
  { __typename?: 'SsnFieldInfo' }
  & Pick<Types.SsnFieldInfo, 'fieldBehaviorMode' | 'placeholder'>
);

export type FieldInfo_TextFieldInfo_Fragment = (
  { __typename?: 'TextFieldInfo' }
  & Pick<Types.TextFieldInfo, 'fieldBehaviorMode' | 'placeholder'>
);

export type FieldInfo_TimeFieldInfo_Fragment = (
  { __typename?: 'TimeFieldInfo' }
  & Pick<Types.TimeFieldInfo, 'fieldBehaviorMode' | 'placeholder'>
);

export type FieldInfo_UsernameFieldInfo_Fragment = (
  { __typename?: 'UsernameFieldInfo' }
  & Pick<Types.UsernameFieldInfo, 'fieldBehaviorMode' | 'placeholder'>
);

export type FieldInfo_ZipCodeFieldInfo_Fragment = (
  { __typename?: 'ZipCodeFieldInfo' }
  & Pick<Types.ZipCodeFieldInfo, 'fieldBehaviorMode' | 'placeholder'>
);

export type FieldInfoFragment = FieldInfo_AgreementFieldInfo_Fragment | FieldInfo_BooleanFieldInfo_Fragment | FieldInfo_ChoiceCollectionFieldInfoBase_Fragment | FieldInfo_ConstrainedTextFieldInfo_Fragment | FieldInfo_CurrencyFieldInfo_Fragment | FieldInfo_DateFieldInfo_Fragment | FieldInfo_DecimalFieldInfo_Fragment | FieldInfo_EmailFieldInfo_Fragment | FieldInfo_ErrorMessageFieldInfo_Fragment | FieldInfo_FuzzyValueFieldInfo_Fragment | FieldInfo_HtmlTextFieldInfo_Fragment | FieldInfo_IntegerFieldInfo_Fragment | FieldInfo_MaskedTextFieldInfo_Fragment | FieldInfo_MultipleChoiceFieldInfo_Fragment | FieldInfo_PasswordFieldInfo_Fragment | FieldInfo_QuestionPoolsFieldInfoGroup_Fragment | FieldInfo_SingleChoiceFieldInfo_Fragment | FieldInfo_SsnFieldInfo_Fragment | FieldInfo_TextFieldInfo_Fragment | FieldInfo_TimeFieldInfo_Fragment | FieldInfo_UsernameFieldInfo_Fragment | FieldInfo_ZipCodeFieldInfo_Fragment;

export type CompoundField_AgreementFieldInfo_Fragment = (
  { __typename?: 'AgreementFieldInfo' }
  & Pick<Types.AgreementFieldInfo, 'description' | 'id' | 'isEnabled' | 'isSubmittable' | 'label' | 'name' | 'type'>
);

export type CompoundField_BooleanFieldInfo_Fragment = (
  { __typename?: 'BooleanFieldInfo' }
  & Pick<Types.BooleanFieldInfo, 'description' | 'id' | 'isEnabled' | 'isSubmittable' | 'label' | 'name' | 'type'>
);

export type CompoundField_ChoiceCollectionFieldInfoBase_Fragment = (
  { __typename?: 'ChoiceCollectionFieldInfoBase' }
  & Pick<Types.ChoiceCollectionFieldInfoBase, 'description' | 'id' | 'isEnabled' | 'isSubmittable' | 'label' | 'name' | 'type'>
);

export type CompoundField_ConstrainedTextFieldInfo_Fragment = (
  { __typename?: 'ConstrainedTextFieldInfo' }
  & Pick<Types.ConstrainedTextFieldInfo, 'description' | 'id' | 'isEnabled' | 'isSubmittable' | 'label' | 'name' | 'type'>
);

export type CompoundField_CurrencyFieldInfo_Fragment = (
  { __typename?: 'CurrencyFieldInfo' }
  & Pick<Types.CurrencyFieldInfo, 'description' | 'id' | 'isEnabled' | 'isSubmittable' | 'label' | 'name' | 'type'>
);

export type CompoundField_DateFieldInfo_Fragment = (
  { __typename?: 'DateFieldInfo' }
  & Pick<Types.DateFieldInfo, 'description' | 'id' | 'isEnabled' | 'isSubmittable' | 'label' | 'name' | 'type'>
);

export type CompoundField_DecimalFieldInfo_Fragment = (
  { __typename?: 'DecimalFieldInfo' }
  & Pick<Types.DecimalFieldInfo, 'description' | 'id' | 'isEnabled' | 'isSubmittable' | 'label' | 'name' | 'type'>
);

export type CompoundField_EmailFieldInfo_Fragment = (
  { __typename?: 'EmailFieldInfo' }
  & Pick<Types.EmailFieldInfo, 'description' | 'id' | 'isEnabled' | 'isSubmittable' | 'label' | 'name' | 'type'>
);

export type CompoundField_ErrorMessageFieldInfo_Fragment = (
  { __typename?: 'ErrorMessageFieldInfo' }
  & Pick<Types.ErrorMessageFieldInfo, 'description' | 'id' | 'isEnabled' | 'isSubmittable' | 'label' | 'name' | 'type'>
);

export type CompoundField_ExternalLinkFieldInfo_Fragment = (
  { __typename?: 'ExternalLinkFieldInfo' }
  & Pick<Types.ExternalLinkFieldInfo, 'description' | 'id' | 'isEnabled' | 'isSubmittable' | 'label' | 'name' | 'type'>
);

export type CompoundField_FieldInfoGroup_Fragment = (
  { __typename?: 'FieldInfoGroup' }
  & Pick<Types.FieldInfoGroup, 'description' | 'id' | 'isEnabled' | 'isSubmittable' | 'label' | 'name' | 'type'>
);

export type CompoundField_FuzzyValueFieldInfo_Fragment = (
  { __typename?: 'FuzzyValueFieldInfo' }
  & Pick<Types.FuzzyValueFieldInfo, 'description' | 'id' | 'isEnabled' | 'isSubmittable' | 'label' | 'name' | 'type'>
);

export type CompoundField_HiddenFieldInfo_Fragment = (
  { __typename?: 'HiddenFieldInfo' }
  & Pick<Types.HiddenFieldInfo, 'description' | 'id' | 'isEnabled' | 'isSubmittable' | 'label' | 'name' | 'type'>
);

export type CompoundField_HtmlTextFieldInfo_Fragment = (
  { __typename?: 'HtmlTextFieldInfo' }
  & Pick<Types.HtmlTextFieldInfo, 'description' | 'id' | 'isEnabled' | 'isSubmittable' | 'label' | 'name' | 'type'>
);

export type CompoundField_IntegerFieldInfo_Fragment = (
  { __typename?: 'IntegerFieldInfo' }
  & Pick<Types.IntegerFieldInfo, 'description' | 'id' | 'isEnabled' | 'isSubmittable' | 'label' | 'name' | 'type'>
);

export type CompoundField_LabelFieldInfo_Fragment = (
  { __typename?: 'LabelFieldInfo' }
  & Pick<Types.LabelFieldInfo, 'description' | 'id' | 'isEnabled' | 'isSubmittable' | 'label' | 'name' | 'type'>
);

export type CompoundField_MaskedTextFieldInfo_Fragment = (
  { __typename?: 'MaskedTextFieldInfo' }
  & Pick<Types.MaskedTextFieldInfo, 'description' | 'id' | 'isEnabled' | 'isSubmittable' | 'label' | 'name' | 'type'>
);

export type CompoundField_MultipleChoiceFieldInfo_Fragment = (
  { __typename?: 'MultipleChoiceFieldInfo' }
  & Pick<Types.MultipleChoiceFieldInfo, 'description' | 'id' | 'isEnabled' | 'isSubmittable' | 'label' | 'name' | 'type'>
);

export type CompoundField_NotificationFieldInfo_Fragment = (
  { __typename?: 'NotificationFieldInfo' }
  & Pick<Types.NotificationFieldInfo, 'description' | 'id' | 'isEnabled' | 'isSubmittable' | 'label' | 'name' | 'type'>
);

export type CompoundField_PasswordFieldInfo_Fragment = (
  { __typename?: 'PasswordFieldInfo' }
  & Pick<Types.PasswordFieldInfo, 'description' | 'id' | 'isEnabled' | 'isSubmittable' | 'label' | 'name' | 'type'>
);

export type CompoundField_QuestionPoolsFieldInfoGroup_Fragment = (
  { __typename?: 'QuestionPoolsFieldInfoGroup' }
  & Pick<Types.QuestionPoolsFieldInfoGroup, 'description' | 'id' | 'isEnabled' | 'isSubmittable' | 'label' | 'name' | 'type'>
);

export type CompoundField_SelectableGroupFieldInfo_Fragment = (
  { __typename?: 'SelectableGroupFieldInfo' }
  & Pick<Types.SelectableGroupFieldInfo, 'description' | 'id' | 'isEnabled' | 'isSubmittable' | 'label' | 'name' | 'type'>
);

export type CompoundField_SingleChoiceFieldInfo_Fragment = (
  { __typename?: 'SingleChoiceFieldInfo' }
  & Pick<Types.SingleChoiceFieldInfo, 'description' | 'id' | 'isEnabled' | 'isSubmittable' | 'label' | 'name' | 'type'>
);

export type CompoundField_SsnFieldInfo_Fragment = (
  { __typename?: 'SsnFieldInfo' }
  & Pick<Types.SsnFieldInfo, 'description' | 'id' | 'isEnabled' | 'isSubmittable' | 'label' | 'name' | 'type'>
);

export type CompoundField_TextFieldInfo_Fragment = (
  { __typename?: 'TextFieldInfo' }
  & Pick<Types.TextFieldInfo, 'description' | 'id' | 'isEnabled' | 'isSubmittable' | 'label' | 'name' | 'type'>
);

export type CompoundField_TimeFieldInfo_Fragment = (
  { __typename?: 'TimeFieldInfo' }
  & Pick<Types.TimeFieldInfo, 'description' | 'id' | 'isEnabled' | 'isSubmittable' | 'label' | 'name' | 'type'>
);

export type CompoundField_UsernameFieldInfo_Fragment = (
  { __typename?: 'UsernameFieldInfo' }
  & Pick<Types.UsernameFieldInfo, 'description' | 'id' | 'isEnabled' | 'isSubmittable' | 'label' | 'name' | 'type'>
);

export type CompoundField_ZipCodeFieldInfo_Fragment = (
  { __typename?: 'ZipCodeFieldInfo' }
  & Pick<Types.ZipCodeFieldInfo, 'description' | 'id' | 'isEnabled' | 'isSubmittable' | 'label' | 'name' | 'type'>
);

export type CompoundFieldFragment = CompoundField_AgreementFieldInfo_Fragment | CompoundField_BooleanFieldInfo_Fragment | CompoundField_ChoiceCollectionFieldInfoBase_Fragment | CompoundField_ConstrainedTextFieldInfo_Fragment | CompoundField_CurrencyFieldInfo_Fragment | CompoundField_DateFieldInfo_Fragment | CompoundField_DecimalFieldInfo_Fragment | CompoundField_EmailFieldInfo_Fragment | CompoundField_ErrorMessageFieldInfo_Fragment | CompoundField_ExternalLinkFieldInfo_Fragment | CompoundField_FieldInfoGroup_Fragment | CompoundField_FuzzyValueFieldInfo_Fragment | CompoundField_HiddenFieldInfo_Fragment | CompoundField_HtmlTextFieldInfo_Fragment | CompoundField_IntegerFieldInfo_Fragment | CompoundField_LabelFieldInfo_Fragment | CompoundField_MaskedTextFieldInfo_Fragment | CompoundField_MultipleChoiceFieldInfo_Fragment | CompoundField_NotificationFieldInfo_Fragment | CompoundField_PasswordFieldInfo_Fragment | CompoundField_QuestionPoolsFieldInfoGroup_Fragment | CompoundField_SelectableGroupFieldInfo_Fragment | CompoundField_SingleChoiceFieldInfo_Fragment | CompoundField_SsnFieldInfo_Fragment | CompoundField_TextFieldInfo_Fragment | CompoundField_TimeFieldInfo_Fragment | CompoundField_UsernameFieldInfo_Fragment | CompoundField_ZipCodeFieldInfo_Fragment;

export type TextFieldFragment = (
  { __typename?: 'TextFieldInfo' }
  & Pick<Types.TextFieldInfo, 'minLength' | 'maxLength'>
  & { sValue: Types.TextFieldInfo['value'] }
  & CompoundField_TextFieldInfo_Fragment
  & FieldInfo_TextFieldInfo_Fragment
);

export type ConstrainedTextFieldFragment = (
  { __typename?: 'ConstrainedTextFieldInfo' }
  & Pick<Types.ConstrainedTextFieldInfo, 'minLength' | 'maxLength' | 'validationRegExp' | 'validationMessage'>
  & { sValue: Types.ConstrainedTextFieldInfo['value'] }
  & CompoundField_ConstrainedTextFieldInfo_Fragment
  & FieldInfo_ConstrainedTextFieldInfo_Fragment
);

export type ZipFieldFragment = (
  { __typename?: 'ZipCodeFieldInfo' }
  & Pick<Types.ZipCodeFieldInfo, 'length' | 'maskFormat'>
  & { sValue: Types.ZipCodeFieldInfo['value'] }
  & CompoundField_ZipCodeFieldInfo_Fragment
  & FieldInfo_ZipCodeFieldInfo_Fragment
);

export type HtmlTextFieldFragment = (
  { __typename?: 'HtmlTextFieldInfo' }
  & Pick<Types.HtmlTextFieldInfo, 'htmlTextValue'>
  & CompoundField_HtmlTextFieldInfo_Fragment
  & FieldInfo_HtmlTextFieldInfo_Fragment
);

export type PasswordFieldFragment = (
  { __typename?: 'PasswordFieldInfo' }
  & Pick<Types.PasswordFieldInfo, 'minLength' | 'maxLength'>
  & { sValue: Types.PasswordFieldInfo['value'] }
  & { passwordPolicy: Types.Maybe<(
    { __typename?: 'PasswordStrengthPolicy' }
    & Pick<Types.PasswordStrengthPolicy, 'anyCharacterMustBeAlphabetic' | 'firstCharacterMustBeAlphabetic' | 'isMaximumCharacterLengthEnabled' | 'isMinimumCharacterLengthEnabled' | 'lowerCaseRequirement' | 'maximumCharacterLength' | 'minimumCharacterLength' | 'mustNotContainBirthdate' | 'mustNotContainMemberNumber' | 'mustNotContainSsn' | 'mustNotContainUsername' | 'numberRequirement' | 'passwordStrengthMeterEnabled' | 'specialCharacterRequirement' | 'upperCaseRequirement'>
  )> }
  & CompoundField_PasswordFieldInfo_Fragment
  & FieldInfo_PasswordFieldInfo_Fragment
);

export type UsernameFieldFragment = (
  { __typename?: 'UsernameFieldInfo' }
  & Pick<Types.UsernameFieldInfo, 'minLength' | 'maxLength'>
  & { sValue: Types.UsernameFieldInfo['value'] }
  & { userNamePolicy: Types.Maybe<(
    { __typename?: 'UsernamePolicy' }
    & Pick<Types.UsernamePolicy, 'anyCharacterMustBeAlphabetic' | 'firstCharacterMustBeAlphabetic' | 'isMaximumCharacterLengthEnabled' | 'isMinimumCharacterLengthEnabled' | 'lowerCaseRequirement' | 'maximumCharacterLength' | 'minimumCharacterLength' | 'mustNotContainBirthdate' | 'mustNotContainMemberNumber' | 'mustNotContainSsn' | 'mustNotContainUsername' | 'mustNotBeContainedWithinUsername' | 'numberRequirement' | 'specialCharacterRequirement' | 'upperCaseRequirement'>
  )> }
  & CompoundField_UsernameFieldInfo_Fragment
  & FieldInfo_UsernameFieldInfo_Fragment
);

export type MaskedTextFieldFragment = (
  { __typename?: 'MaskedTextFieldInfo' }
  & Pick<Types.MaskedTextFieldInfo, 'minLength' | 'maxLength' | 'maskFormat'>
  & { sValue: Types.MaskedTextFieldInfo['value'] }
  & CompoundField_MaskedTextFieldInfo_Fragment
  & FieldInfo_MaskedTextFieldInfo_Fragment
);

export type SelectableGroupFieldFragment = (
  { __typename?: 'SelectableGroupFieldInfo' }
  & Pick<Types.SelectableGroupFieldInfo, 'selectedFieldGroupId'>
  & { fieldGroups: Array<Types.Maybe<(
    { __typename?: 'FieldInfoGroup' }
    & FieldInfoGroupSubFieldFragment
  )>> }
  & CompoundField_SelectableGroupFieldInfo_Fragment
);

export type SelectableGroupSubFieldFragment = (
  { __typename?: 'SelectableGroupFieldInfo' }
  & Pick<Types.SelectableGroupFieldInfo, 'selectedFieldGroupId'>
  & { fieldGroups: Array<Types.Maybe<(
    { __typename?: 'FieldInfoGroup' }
    & FieldInfoGroupSubSubFieldFragment
  )>> }
  & CompoundField_SelectableGroupFieldInfo_Fragment
);

export type QuestionPoolsFieldGroupFragment = (
  { __typename?: 'QuestionPoolsFieldInfoGroup' }
  & Pick<Types.QuestionPoolsFieldInfoGroup, 'answerMaxLength' | 'answerMinLength'>
  & { questionPools: Array<(
    { __typename?: 'QuestionPool' }
    & { question: (
      { __typename?: 'SingleChoiceFieldInfo' }
      & SingleChoiceFieldFragment
    ), answer: (
      { __typename?: 'EmailFieldInfo' }
      & EmailFieldFragment
    ) | (
      { __typename?: 'ConstrainedTextFieldInfo' }
      & ConstrainedTextFieldFragment
    ) | (
      { __typename?: 'PasswordFieldInfo' }
      & PasswordFieldFragment
    ) | (
      { __typename?: 'SsnFieldInfo' }
      & SsnFieldFragment
    ) | (
      { __typename?: 'UsernameFieldInfo' }
      & UsernameFieldFragment
    ) | (
      { __typename?: 'MaskedTextFieldInfo' }
      & MaskedTextFieldFragment
    ) | (
      { __typename?: 'TextFieldInfo' }
      & TextFieldFragment
    ) | (
      { __typename?: 'ZipCodeFieldInfo' }
      & ZipFieldFragment
    ) | (
      { __typename?: 'HtmlTextFieldInfo' }
      & HtmlTextFieldFragment
    ) | (
      { __typename?: 'SingleChoiceFieldInfo' }
      & SingleChoiceFieldFragment
    ) | (
      { __typename?: 'MultipleChoiceFieldInfo' }
      & MultipleChoiceFieldFragment
    ) | { __typename?: 'ChoiceCollectionFieldInfoBase' } | (
      { __typename?: 'FuzzyValueFieldInfo' }
      & FuzzyValueFieldFragment
    ) | (
      { __typename?: 'CurrencyFieldInfo' }
      & CurrencyFieldFragment
    ) | (
      { __typename?: 'AgreementFieldInfo' }
      & AgreementFieldFragment
    ) | (
      { __typename?: 'BooleanFieldInfo' }
      & BooleanFieldFragment
    ) | (
      { __typename?: 'DateFieldInfo' }
      & DateFieldFragment
    ) | { __typename?: 'DecimalFieldInfo' } | (
      { __typename?: 'IntegerFieldInfo' }
      & IntegerFieldFragment
    ) | (
      { __typename?: 'TimeFieldInfo' }
      & TimeFieldFragment
    ) | (
      { __typename?: 'ErrorMessageFieldInfo' }
      & ErrorMessageFieldFragment
    ) | { __typename?: 'QuestionPoolsFieldInfoGroup' } | { __typename?: 'FieldInfoGroup' } | (
      { __typename?: 'LabelFieldInfo' }
      & LabelFieldFragment
    ) | (
      { __typename?: 'HiddenFieldInfo' }
      & HiddenFieldFragment
    ) | (
      { __typename?: 'NotificationFieldInfo' }
      & NotificationFieldFragment
    ) | (
      { __typename?: 'ExternalLinkFieldInfo' }
      & ExternalLinkFieldFragment
    ) | { __typename?: 'SelectableGroupFieldInfo' } }
  )> }
  & CompoundField_QuestionPoolsFieldInfoGroup_Fragment
  & FieldInfo_QuestionPoolsFieldInfoGroup_Fragment
);

export type FieldInfoGroupFieldFragment = (
  { __typename?: 'FieldInfoGroup' }
  & { subFields: Array<Types.Maybe<(
    { __typename?: 'EmailFieldInfo' }
    & EmailFieldFragment
  ) | (
    { __typename?: 'ConstrainedTextFieldInfo' }
    & ConstrainedTextFieldFragment
  ) | (
    { __typename?: 'PasswordFieldInfo' }
    & PasswordFieldFragment
  ) | (
    { __typename?: 'SsnFieldInfo' }
    & SsnFieldFragment
  ) | (
    { __typename?: 'UsernameFieldInfo' }
    & UsernameFieldFragment
  ) | (
    { __typename?: 'MaskedTextFieldInfo' }
    & MaskedTextFieldFragment
  ) | (
    { __typename?: 'TextFieldInfo' }
    & TextFieldFragment
  ) | (
    { __typename?: 'ZipCodeFieldInfo' }
    & ZipFieldFragment
  ) | (
    { __typename?: 'HtmlTextFieldInfo' }
    & HtmlTextFieldFragment
  ) | (
    { __typename?: 'SingleChoiceFieldInfo' }
    & SingleChoiceFieldFragment
  ) | (
    { __typename?: 'MultipleChoiceFieldInfo' }
    & MultipleChoiceFieldFragment
  ) | { __typename?: 'ChoiceCollectionFieldInfoBase' } | (
    { __typename?: 'FuzzyValueFieldInfo' }
    & FuzzyValueFieldFragment
  ) | (
    { __typename?: 'CurrencyFieldInfo' }
    & CurrencyFieldFragment
  ) | (
    { __typename?: 'AgreementFieldInfo' }
    & AgreementFieldFragment
  ) | (
    { __typename?: 'BooleanFieldInfo' }
    & BooleanFieldFragment
  ) | (
    { __typename?: 'DateFieldInfo' }
    & DateFieldFragment
  ) | { __typename?: 'DecimalFieldInfo' } | (
    { __typename?: 'IntegerFieldInfo' }
    & IntegerFieldFragment
  ) | (
    { __typename?: 'TimeFieldInfo' }
    & TimeFieldFragment
  ) | (
    { __typename?: 'ErrorMessageFieldInfo' }
    & ErrorMessageFieldFragment
  ) | (
    { __typename?: 'QuestionPoolsFieldInfoGroup' }
    & QuestionPoolsFieldGroupFragment
  ) | (
    { __typename?: 'FieldInfoGroup' }
    & FieldInfoGroupSubFieldFragment
  ) | (
    { __typename?: 'LabelFieldInfo' }
    & LabelFieldFragment
  ) | (
    { __typename?: 'HiddenFieldInfo' }
    & HiddenFieldFragment
  ) | (
    { __typename?: 'NotificationFieldInfo' }
    & NotificationFieldFragment
  ) | (
    { __typename?: 'ExternalLinkFieldInfo' }
    & ExternalLinkFieldFragment
  ) | (
    { __typename?: 'SelectableGroupFieldInfo' }
    & SelectableGroupFieldFragment
  )>> }
  & CompoundField_FieldInfoGroup_Fragment
);

export type FieldInfoGroupSubFieldFragment = (
  { __typename?: 'FieldInfoGroup' }
  & { subFields: Array<Types.Maybe<(
    { __typename?: 'EmailFieldInfo' }
    & EmailFieldFragment
  ) | (
    { __typename?: 'ConstrainedTextFieldInfo' }
    & ConstrainedTextFieldFragment
  ) | (
    { __typename?: 'PasswordFieldInfo' }
    & PasswordFieldFragment
  ) | (
    { __typename?: 'SsnFieldInfo' }
    & SsnFieldFragment
  ) | (
    { __typename?: 'UsernameFieldInfo' }
    & UsernameFieldFragment
  ) | (
    { __typename?: 'MaskedTextFieldInfo' }
    & MaskedTextFieldFragment
  ) | (
    { __typename?: 'TextFieldInfo' }
    & TextFieldFragment
  ) | (
    { __typename?: 'ZipCodeFieldInfo' }
    & ZipFieldFragment
  ) | (
    { __typename?: 'HtmlTextFieldInfo' }
    & HtmlTextFieldFragment
  ) | (
    { __typename?: 'SingleChoiceFieldInfo' }
    & SingleChoiceFieldFragment
  ) | (
    { __typename?: 'MultipleChoiceFieldInfo' }
    & MultipleChoiceFieldFragment
  ) | { __typename?: 'ChoiceCollectionFieldInfoBase' } | (
    { __typename?: 'FuzzyValueFieldInfo' }
    & FuzzyValueFieldFragment
  ) | (
    { __typename?: 'CurrencyFieldInfo' }
    & CurrencyFieldFragment
  ) | (
    { __typename?: 'AgreementFieldInfo' }
    & AgreementFieldFragment
  ) | (
    { __typename?: 'BooleanFieldInfo' }
    & BooleanFieldFragment
  ) | (
    { __typename?: 'DateFieldInfo' }
    & DateFieldFragment
  ) | { __typename?: 'DecimalFieldInfo' } | (
    { __typename?: 'IntegerFieldInfo' }
    & IntegerFieldFragment
  ) | (
    { __typename?: 'TimeFieldInfo' }
    & TimeFieldFragment
  ) | (
    { __typename?: 'ErrorMessageFieldInfo' }
    & ErrorMessageFieldFragment
  ) | (
    { __typename?: 'QuestionPoolsFieldInfoGroup' }
    & QuestionPoolsFieldGroupFragment
  ) | { __typename?: 'FieldInfoGroup' } | (
    { __typename?: 'LabelFieldInfo' }
    & LabelFieldFragment
  ) | (
    { __typename?: 'HiddenFieldInfo' }
    & HiddenFieldFragment
  ) | (
    { __typename?: 'NotificationFieldInfo' }
    & NotificationFieldFragment
  ) | (
    { __typename?: 'ExternalLinkFieldInfo' }
    & ExternalLinkFieldFragment
  ) | (
    { __typename?: 'SelectableGroupFieldInfo' }
    & SelectableGroupSubFieldFragment
  )>> }
  & CompoundField_FieldInfoGroup_Fragment
);

export type FieldInfoGroupSubSubFieldFragment = (
  { __typename?: 'FieldInfoGroup' }
  & { subFields: Array<Types.Maybe<(
    { __typename?: 'EmailFieldInfo' }
    & EmailFieldFragment
  ) | (
    { __typename?: 'ConstrainedTextFieldInfo' }
    & ConstrainedTextFieldFragment
  ) | (
    { __typename?: 'PasswordFieldInfo' }
    & PasswordFieldFragment
  ) | (
    { __typename?: 'SsnFieldInfo' }
    & SsnFieldFragment
  ) | (
    { __typename?: 'UsernameFieldInfo' }
    & UsernameFieldFragment
  ) | (
    { __typename?: 'MaskedTextFieldInfo' }
    & MaskedTextFieldFragment
  ) | (
    { __typename?: 'TextFieldInfo' }
    & TextFieldFragment
  ) | (
    { __typename?: 'ZipCodeFieldInfo' }
    & ZipFieldFragment
  ) | (
    { __typename?: 'HtmlTextFieldInfo' }
    & HtmlTextFieldFragment
  ) | (
    { __typename?: 'SingleChoiceFieldInfo' }
    & SingleChoiceFieldFragment
  ) | (
    { __typename?: 'MultipleChoiceFieldInfo' }
    & MultipleChoiceFieldFragment
  ) | { __typename?: 'ChoiceCollectionFieldInfoBase' } | (
    { __typename?: 'FuzzyValueFieldInfo' }
    & FuzzyValueFieldFragment
  ) | (
    { __typename?: 'CurrencyFieldInfo' }
    & CurrencyFieldFragment
  ) | (
    { __typename?: 'AgreementFieldInfo' }
    & AgreementFieldFragment
  ) | (
    { __typename?: 'BooleanFieldInfo' }
    & BooleanFieldFragment
  ) | (
    { __typename?: 'DateFieldInfo' }
    & DateFieldFragment
  ) | { __typename?: 'DecimalFieldInfo' } | (
    { __typename?: 'IntegerFieldInfo' }
    & IntegerFieldFragment
  ) | (
    { __typename?: 'TimeFieldInfo' }
    & TimeFieldFragment
  ) | (
    { __typename?: 'ErrorMessageFieldInfo' }
    & ErrorMessageFieldFragment
  ) | (
    { __typename?: 'QuestionPoolsFieldInfoGroup' }
    & QuestionPoolsFieldGroupFragment
  ) | { __typename?: 'FieldInfoGroup' } | (
    { __typename?: 'LabelFieldInfo' }
    & LabelFieldFragment
  ) | (
    { __typename?: 'HiddenFieldInfo' }
    & HiddenFieldFragment
  ) | (
    { __typename?: 'NotificationFieldInfo' }
    & NotificationFieldFragment
  ) | (
    { __typename?: 'ExternalLinkFieldInfo' }
    & ExternalLinkFieldFragment
  ) | { __typename?: 'SelectableGroupFieldInfo' }>> }
  & CompoundField_FieldInfoGroup_Fragment
);

export type LabelFieldFragment = (
  { __typename?: 'LabelFieldInfo' }
  & CompoundField_LabelFieldInfo_Fragment
);

export type BooleanFieldFragment = (
  { __typename?: 'BooleanFieldInfo' }
  & { bValue: Types.BooleanFieldInfo['value'] }
  & CompoundField_BooleanFieldInfo_Fragment
  & FieldInfo_BooleanFieldInfo_Fragment
);

export type IntegerFieldFragment = (
  { __typename?: 'IntegerFieldInfo' }
  & { iMinValue: Types.IntegerFieldInfo['minValue'], iMaxValue: Types.IntegerFieldInfo['maxValue'], iValue: Types.IntegerFieldInfo['value'] }
  & CompoundField_IntegerFieldInfo_Fragment
  & FieldInfo_IntegerFieldInfo_Fragment
);

export type CurrencyFieldFragment = (
  { __typename?: 'CurrencyFieldInfo' }
  & { cMinValue: Types.CurrencyFieldInfo['minValue'], cMaxValue: Types.CurrencyFieldInfo['maxValue'], cValue: Types.CurrencyFieldInfo['value'] }
  & CompoundField_CurrencyFieldInfo_Fragment
  & FieldInfo_CurrencyFieldInfo_Fragment
);

export type TimeFieldFragment = (
  { __typename?: 'TimeFieldInfo' }
  & { tMinValue: Types.TimeFieldInfo['minValue'], tMaxValue: Types.TimeFieldInfo['maxValue'], tValue: Types.TimeFieldInfo['value'] }
  & CompoundField_TimeFieldInfo_Fragment
  & FieldInfo_TimeFieldInfo_Fragment
);

export type SsnFieldFragment = (
  { __typename?: 'SsnFieldInfo' }
  & Pick<Types.SsnFieldInfo, 'dataPattern' | 'dataMask' | 'validationPattern' | 'minLength' | 'maxLength'>
  & { sValue: Types.SsnFieldInfo['value'] }
  & CompoundField_SsnFieldInfo_Fragment
  & FieldInfo_SsnFieldInfo_Fragment
);

export type ErrorMessageFieldFragment = (
  { __typename?: 'ErrorMessageFieldInfo' }
  & Pick<Types.ErrorMessageFieldInfo, 'errorMessageValue'>
  & CompoundField_ErrorMessageFieldInfo_Fragment
  & FieldInfo_ErrorMessageFieldInfo_Fragment
);

export type EmailFieldFragment = (
  { __typename?: 'EmailFieldInfo' }
  & Pick<Types.EmailFieldInfo, 'minLength' | 'maxLength' | 'validationRegExp' | 'isConfirmationRequired'>
  & { sValue: Types.EmailFieldInfo['value'] }
  & CompoundField_EmailFieldInfo_Fragment
  & FieldInfo_EmailFieldInfo_Fragment
);

export type HiddenFieldFragment = (
  { __typename?: 'HiddenFieldInfo' }
  & { sValue: Types.HiddenFieldInfo['value'] }
  & CompoundField_HiddenFieldInfo_Fragment
);

export type NotificationFieldFragment = (
  { __typename?: 'NotificationFieldInfo' }
  & Pick<Types.NotificationFieldInfo, 'notificationLevel'>
  & CompoundField_NotificationFieldInfo_Fragment
);

export type SingleChoiceFieldFragment = (
  { __typename?: 'SingleChoiceFieldInfo' }
  & Pick<Types.SingleChoiceFieldInfo, 'selectedValue' | 'defaultChoiceText'>
  & { choiceList: Types.Maybe<Array<Types.Maybe<(
    { __typename?: 'Choice' }
    & ChoiceFragment
  )>>> }
  & CompoundField_SingleChoiceFieldInfo_Fragment
  & FieldInfo_SingleChoiceFieldInfo_Fragment
);

export type MultipleChoiceFieldFragment = (
  { __typename?: 'MultipleChoiceFieldInfo' }
  & { choiceList: Types.Maybe<Array<Types.Maybe<(
    { __typename?: 'Choice' }
    & ChoiceFragment
  )>>>, selectedValues: Types.Maybe<Array<Types.Maybe<(
    { __typename?: 'MultipleChoiceSelection' }
    & Pick<Types.MultipleChoiceSelection, 'indexId' | 'value'>
  )>>> }
  & CompoundField_MultipleChoiceFieldInfo_Fragment
  & FieldInfo_MultipleChoiceFieldInfo_Fragment
);

export type ExternalLinkFieldFragment = (
  { __typename?: 'ExternalLinkFieldInfo' }
  & Pick<Types.ExternalLinkFieldInfo, 'wizardCode'>
  & CompoundField_ExternalLinkFieldInfo_Fragment
);

export type ChoiceFragment = (
  { __typename?: 'Choice' }
  & Pick<Types.Choice, 'dataMask' | 'dataPattern' | 'enabled' | 'id' | 'isHidden' | 'maxLength' | 'minLength' | 'text' | 'validationPattern'>
);

export type FuzzyValueFieldFragment = (
  { __typename?: 'FuzzyValueFieldInfo' }
  & Pick<Types.FuzzyValueFieldInfo, 'minValue' | 'maxValue' | 'acceptableDifferenceValue' | 'differenceType'>
  & { nValue: Types.FuzzyValueFieldInfo['value'] }
  & CompoundField_FuzzyValueFieldInfo_Fragment
  & FieldInfo_FuzzyValueFieldInfo_Fragment
);

export type AgreementFieldFragment = (
  { __typename?: 'AgreementFieldInfo' }
  & Pick<Types.AgreementFieldInfo, 'agreementText' | 'agreementTitle'>
  & { bValue: Types.AgreementFieldInfo['value'] }
  & CompoundField_AgreementFieldInfo_Fragment
  & FieldInfo_AgreementFieldInfo_Fragment
);

export type DateFieldFragment = (
  { __typename?: 'DateFieldInfo' }
  & Pick<Types.DateFieldInfo, 'value'>
  & { dMinValue: Types.DateFieldInfo['minValue'], dMaxValue: Types.DateFieldInfo['maxValue'] }
  & CompoundField_DateFieldInfo_Fragment
  & FieldInfo_DateFieldInfo_Fragment
);

export const CompoundFieldFragmentDoc = gql`
    fragment CompoundField on UIElementInfo {
  description
  id
  isEnabled
  isSubmittable
  label
  name
  type
}
    `;
export const FieldInfoFragmentDoc = gql`
    fragment FieldInfo on FieldInfoBase {
  fieldBehaviorMode
  placeholder
}
    `;
export const TextFieldFragmentDoc = gql`
    fragment TextField on TextFieldInfo {
  ...CompoundField
  ...FieldInfo
  minLength
  maxLength
  sValue: value
}
    ${CompoundFieldFragmentDoc}
${FieldInfoFragmentDoc}`;
export const PasswordFieldFragmentDoc = gql`
    fragment PasswordField on PasswordFieldInfo {
  ...CompoundField
  ...FieldInfo
  minLength
  maxLength
  passwordPolicy {
    anyCharacterMustBeAlphabetic
    firstCharacterMustBeAlphabetic
    isMaximumCharacterLengthEnabled
    isMinimumCharacterLengthEnabled
    lowerCaseRequirement
    maximumCharacterLength
    minimumCharacterLength
    mustNotContainBirthdate
    mustNotContainMemberNumber
    mustNotContainSsn
    mustNotContainUsername
    numberRequirement
    passwordStrengthMeterEnabled
    specialCharacterRequirement
    upperCaseRequirement
  }
  sValue: value
}
    ${CompoundFieldFragmentDoc}
${FieldInfoFragmentDoc}`;
export const UsernameFieldFragmentDoc = gql`
    fragment UsernameField on UsernameFieldInfo {
  ...CompoundField
  ...FieldInfo
  minLength
  maxLength
  userNamePolicy {
    anyCharacterMustBeAlphabetic
    firstCharacterMustBeAlphabetic
    isMaximumCharacterLengthEnabled
    isMinimumCharacterLengthEnabled
    lowerCaseRequirement
    maximumCharacterLength
    minimumCharacterLength
    mustNotContainBirthdate
    mustNotContainMemberNumber
    mustNotContainSsn
    mustNotContainUsername
    mustNotBeContainedWithinUsername
    numberRequirement
    specialCharacterRequirement
    upperCaseRequirement
  }
  sValue: value
}
    ${CompoundFieldFragmentDoc}
${FieldInfoFragmentDoc}`;
export const MaskedTextFieldFragmentDoc = gql`
    fragment MaskedTextField on MaskedTextFieldInfo {
  ...CompoundField
  ...FieldInfo
  minLength
  maxLength
  maskFormat
  sValue: value
}
    ${CompoundFieldFragmentDoc}
${FieldInfoFragmentDoc}`;
export const LabelFieldFragmentDoc = gql`
    fragment LabelField on LabelFieldInfo {
  ...CompoundField
}
    ${CompoundFieldFragmentDoc}`;
export const BooleanFieldFragmentDoc = gql`
    fragment BooleanField on BooleanFieldInfo {
  ...CompoundField
  ...FieldInfo
  bValue: value
}
    ${CompoundFieldFragmentDoc}
${FieldInfoFragmentDoc}`;
export const IntegerFieldFragmentDoc = gql`
    fragment IntegerField on IntegerFieldInfo {
  ...CompoundField
  ...FieldInfo
  iMinValue: minValue
  iMaxValue: maxValue
  iValue: value
}
    ${CompoundFieldFragmentDoc}
${FieldInfoFragmentDoc}`;
export const CurrencyFieldFragmentDoc = gql`
    fragment CurrencyField on CurrencyFieldInfo {
  ...CompoundField
  ...FieldInfo
  cMinValue: minValue
  cMaxValue: maxValue
  cValue: value
}
    ${CompoundFieldFragmentDoc}
${FieldInfoFragmentDoc}`;
export const TimeFieldFragmentDoc = gql`
    fragment TimeField on TimeFieldInfo {
  ...CompoundField
  ...FieldInfo
  tMinValue: minValue
  tMaxValue: maxValue
  tValue: value
}
    ${CompoundFieldFragmentDoc}
${FieldInfoFragmentDoc}`;
export const SsnFieldFragmentDoc = gql`
    fragment SsnField on SsnFieldInfo {
  ...CompoundField
  ...FieldInfo
  dataPattern
  dataMask
  validationPattern
  minLength
  maxLength
  sValue: value
}
    ${CompoundFieldFragmentDoc}
${FieldInfoFragmentDoc}`;
export const ErrorMessageFieldFragmentDoc = gql`
    fragment ErrorMessageField on ErrorMessageFieldInfo {
  ...CompoundField
  ...FieldInfo
  errorMessageValue
}
    ${CompoundFieldFragmentDoc}
${FieldInfoFragmentDoc}`;
export const EmailFieldFragmentDoc = gql`
    fragment EmailField on EmailFieldInfo {
  ...CompoundField
  ...FieldInfo
  minLength
  maxLength
  validationRegExp
  isConfirmationRequired
  sValue: value
}
    ${CompoundFieldFragmentDoc}
${FieldInfoFragmentDoc}`;
export const HiddenFieldFragmentDoc = gql`
    fragment HiddenField on HiddenFieldInfo {
  ...CompoundField
  sValue: value
}
    ${CompoundFieldFragmentDoc}`;
export const NotificationFieldFragmentDoc = gql`
    fragment NotificationField on NotificationFieldInfo {
  ...CompoundField
  notificationLevel
}
    ${CompoundFieldFragmentDoc}`;
export const ChoiceFragmentDoc = gql`
    fragment Choice on Choice {
  dataMask
  dataPattern
  enabled
  id
  isHidden
  maxLength
  minLength
  text
  validationPattern
}
    `;
export const SingleChoiceFieldFragmentDoc = gql`
    fragment SingleChoiceField on SingleChoiceFieldInfo {
  ...CompoundField
  ...FieldInfo
  choiceList {
    ...Choice
  }
  selectedValue
  defaultChoiceText
}
    ${CompoundFieldFragmentDoc}
${FieldInfoFragmentDoc}
${ChoiceFragmentDoc}`;
export const MultipleChoiceFieldFragmentDoc = gql`
    fragment MultipleChoiceField on MultipleChoiceFieldInfo {
  ...CompoundField
  ...FieldInfo
  choiceList {
    ...Choice
  }
  selectedValues {
    indexId
    value
  }
}
    ${CompoundFieldFragmentDoc}
${FieldInfoFragmentDoc}
${ChoiceFragmentDoc}`;
export const ExternalLinkFieldFragmentDoc = gql`
    fragment ExternalLinkField on ExternalLinkFieldInfo {
  ...CompoundField
  wizardCode
}
    ${CompoundFieldFragmentDoc}`;
export const ConstrainedTextFieldFragmentDoc = gql`
    fragment ConstrainedTextField on ConstrainedTextFieldInfo {
  ...CompoundField
  ...FieldInfo
  minLength
  maxLength
  validationRegExp
  validationMessage
  sValue: value
}
    ${CompoundFieldFragmentDoc}
${FieldInfoFragmentDoc}`;
export const ZipFieldFragmentDoc = gql`
    fragment ZipField on ZipCodeFieldInfo {
  ...CompoundField
  ...FieldInfo
  length
  maskFormat
  sValue: value
}
    ${CompoundFieldFragmentDoc}
${FieldInfoFragmentDoc}`;
export const HtmlTextFieldFragmentDoc = gql`
    fragment HtmlTextField on HtmlTextFieldInfo {
  ...CompoundField
  ...FieldInfo
  htmlTextValue
}
    ${CompoundFieldFragmentDoc}
${FieldInfoFragmentDoc}`;
export const FuzzyValueFieldFragmentDoc = gql`
    fragment FuzzyValueField on FuzzyValueFieldInfo {
  ...CompoundField
  ...FieldInfo
  nValue: value
  minValue
  maxValue
  acceptableDifferenceValue
  differenceType
}
    ${CompoundFieldFragmentDoc}
${FieldInfoFragmentDoc}`;
export const AgreementFieldFragmentDoc = gql`
    fragment AgreementField on AgreementFieldInfo {
  ...CompoundField
  ...FieldInfo
  bValue: value
  agreementText
  agreementTitle
}
    ${CompoundFieldFragmentDoc}
${FieldInfoFragmentDoc}`;
export const DateFieldFragmentDoc = gql`
    fragment DateField on DateFieldInfo {
  ...CompoundField
  ...FieldInfo
  value
  dMinValue: minValue
  dMaxValue: maxValue
}
    ${CompoundFieldFragmentDoc}
${FieldInfoFragmentDoc}`;
export const QuestionPoolsFieldGroupFragmentDoc = gql`
    fragment QuestionPoolsFieldGroup on QuestionPoolsFieldInfoGroup {
  ...CompoundField
  ...FieldInfo
  answerMaxLength
  answerMinLength
  questionPools {
    question {
      ...SingleChoiceField
    }
    answer {
      ...TextField
      ...PasswordField
      ...UsernameField
      ...MaskedTextField
      ...LabelField
      ...BooleanField
      ...IntegerField
      ...CurrencyField
      ...TimeField
      ...SsnField
      ...ConstrainedTextField
      ...ZipField
      ...HtmlTextField
      ...ErrorMessageField
      ...EmailField
      ...HiddenField
      ...NotificationField
      ...SingleChoiceField
      ...MultipleChoiceField
      ...ExternalLinkField
      ...FuzzyValueField
      ...AgreementField
      ...DateField
    }
  }
}
    ${CompoundFieldFragmentDoc}
${FieldInfoFragmentDoc}
${SingleChoiceFieldFragmentDoc}
${TextFieldFragmentDoc}
${PasswordFieldFragmentDoc}
${UsernameFieldFragmentDoc}
${MaskedTextFieldFragmentDoc}
${LabelFieldFragmentDoc}
${BooleanFieldFragmentDoc}
${IntegerFieldFragmentDoc}
${CurrencyFieldFragmentDoc}
${TimeFieldFragmentDoc}
${SsnFieldFragmentDoc}
${ConstrainedTextFieldFragmentDoc}
${ZipFieldFragmentDoc}
${HtmlTextFieldFragmentDoc}
${ErrorMessageFieldFragmentDoc}
${EmailFieldFragmentDoc}
${HiddenFieldFragmentDoc}
${NotificationFieldFragmentDoc}
${MultipleChoiceFieldFragmentDoc}
${ExternalLinkFieldFragmentDoc}
${FuzzyValueFieldFragmentDoc}
${AgreementFieldFragmentDoc}
${DateFieldFragmentDoc}`;
export const FieldInfoGroupSubSubFieldFragmentDoc = gql`
    fragment FieldInfoGroupSubSubField on FieldInfoGroup {
  ...CompoundField
  subFields {
    ...TextField
    ...PasswordField
    ...UsernameField
    ...MaskedTextField
    ...LabelField
    ...BooleanField
    ...IntegerField
    ...CurrencyField
    ...TimeField
    ...SsnField
    ...ErrorMessageField
    ...EmailField
    ...HiddenField
    ...NotificationField
    ...SingleChoiceField
    ...MultipleChoiceField
    ...ExternalLinkField
    ...ConstrainedTextField
    ...ZipField
    ...HtmlTextField
    ...FuzzyValueField
    ...AgreementField
    ...DateField
    ...QuestionPoolsFieldGroup
  }
}
    ${CompoundFieldFragmentDoc}
${TextFieldFragmentDoc}
${PasswordFieldFragmentDoc}
${UsernameFieldFragmentDoc}
${MaskedTextFieldFragmentDoc}
${LabelFieldFragmentDoc}
${BooleanFieldFragmentDoc}
${IntegerFieldFragmentDoc}
${CurrencyFieldFragmentDoc}
${TimeFieldFragmentDoc}
${SsnFieldFragmentDoc}
${ErrorMessageFieldFragmentDoc}
${EmailFieldFragmentDoc}
${HiddenFieldFragmentDoc}
${NotificationFieldFragmentDoc}
${SingleChoiceFieldFragmentDoc}
${MultipleChoiceFieldFragmentDoc}
${ExternalLinkFieldFragmentDoc}
${ConstrainedTextFieldFragmentDoc}
${ZipFieldFragmentDoc}
${HtmlTextFieldFragmentDoc}
${FuzzyValueFieldFragmentDoc}
${AgreementFieldFragmentDoc}
${DateFieldFragmentDoc}
${QuestionPoolsFieldGroupFragmentDoc}`;
export const SelectableGroupSubFieldFragmentDoc = gql`
    fragment SelectableGroupSubField on SelectableGroupFieldInfo {
  ...CompoundField
  selectedFieldGroupId
  fieldGroups {
    ...FieldInfoGroupSubSubField
  }
}
    ${CompoundFieldFragmentDoc}
${FieldInfoGroupSubSubFieldFragmentDoc}`;
export const FieldInfoGroupSubFieldFragmentDoc = gql`
    fragment FieldInfoGroupSubField on FieldInfoGroup {
  ...CompoundField
  subFields {
    ...SelectableGroupSubField
    ...TextField
    ...PasswordField
    ...UsernameField
    ...MaskedTextField
    ...LabelField
    ...BooleanField
    ...IntegerField
    ...CurrencyField
    ...TimeField
    ...SsnField
    ...ErrorMessageField
    ...EmailField
    ...HiddenField
    ...NotificationField
    ...SingleChoiceField
    ...MultipleChoiceField
    ...ExternalLinkField
    ...ConstrainedTextField
    ...ZipField
    ...HtmlTextField
    ...FuzzyValueField
    ...AgreementField
    ...DateField
    ...QuestionPoolsFieldGroup
  }
}
    ${CompoundFieldFragmentDoc}
${SelectableGroupSubFieldFragmentDoc}
${TextFieldFragmentDoc}
${PasswordFieldFragmentDoc}
${UsernameFieldFragmentDoc}
${MaskedTextFieldFragmentDoc}
${LabelFieldFragmentDoc}
${BooleanFieldFragmentDoc}
${IntegerFieldFragmentDoc}
${CurrencyFieldFragmentDoc}
${TimeFieldFragmentDoc}
${SsnFieldFragmentDoc}
${ErrorMessageFieldFragmentDoc}
${EmailFieldFragmentDoc}
${HiddenFieldFragmentDoc}
${NotificationFieldFragmentDoc}
${SingleChoiceFieldFragmentDoc}
${MultipleChoiceFieldFragmentDoc}
${ExternalLinkFieldFragmentDoc}
${ConstrainedTextFieldFragmentDoc}
${ZipFieldFragmentDoc}
${HtmlTextFieldFragmentDoc}
${FuzzyValueFieldFragmentDoc}
${AgreementFieldFragmentDoc}
${DateFieldFragmentDoc}
${QuestionPoolsFieldGroupFragmentDoc}`;
export const SelectableGroupFieldFragmentDoc = gql`
    fragment SelectableGroupField on SelectableGroupFieldInfo {
  ...CompoundField
  selectedFieldGroupId
  fieldGroups {
    ...FieldInfoGroupSubField
  }
}
    ${CompoundFieldFragmentDoc}
${FieldInfoGroupSubFieldFragmentDoc}`;
export const FieldInfoGroupFieldFragmentDoc = gql`
    fragment FieldInfoGroupField on FieldInfoGroup {
  ...CompoundField
  subFields {
    ...TextField
    ...PasswordField
    ...UsernameField
    ...MaskedTextField
    ...FieldInfoGroupSubField
    ...SelectableGroupField
    ...LabelField
    ...BooleanField
    ...IntegerField
    ...CurrencyField
    ...TimeField
    ...SsnField
    ...ConstrainedTextField
    ...ZipField
    ...HtmlTextField
    ...ErrorMessageField
    ...EmailField
    ...HiddenField
    ...NotificationField
    ...SingleChoiceField
    ...MultipleChoiceField
    ...ExternalLinkField
    ...FuzzyValueField
    ...AgreementField
    ...DateField
    ...QuestionPoolsFieldGroup
  }
}
    ${CompoundFieldFragmentDoc}
${TextFieldFragmentDoc}
${PasswordFieldFragmentDoc}
${UsernameFieldFragmentDoc}
${MaskedTextFieldFragmentDoc}
${FieldInfoGroupSubFieldFragmentDoc}
${SelectableGroupFieldFragmentDoc}
${LabelFieldFragmentDoc}
${BooleanFieldFragmentDoc}
${IntegerFieldFragmentDoc}
${CurrencyFieldFragmentDoc}
${TimeFieldFragmentDoc}
${SsnFieldFragmentDoc}
${ConstrainedTextFieldFragmentDoc}
${ZipFieldFragmentDoc}
${HtmlTextFieldFragmentDoc}
${ErrorMessageFieldFragmentDoc}
${EmailFieldFragmentDoc}
${HiddenFieldFragmentDoc}
${NotificationFieldFragmentDoc}
${SingleChoiceFieldFragmentDoc}
${MultipleChoiceFieldFragmentDoc}
${ExternalLinkFieldFragmentDoc}
${FuzzyValueFieldFragmentDoc}
${AgreementFieldFragmentDoc}
${DateFieldFragmentDoc}
${QuestionPoolsFieldGroupFragmentDoc}`;
export const AllFieldsFragmentDoc = gql`
    fragment AllFields on UIElementInfo {
  ...TextField
  ...PasswordField
  ...UsernameField
  ...MaskedTextField
  ...SelectableGroupField
  ...FieldInfoGroupField
  ...LabelField
  ...BooleanField
  ...IntegerField
  ...CurrencyField
  ...TimeField
  ...SsnField
  ...ConstrainedTextField
  ...ZipField
  ...HtmlTextField
  ...ErrorMessageField
  ...EmailField
  ...HiddenField
  ...NotificationField
  ...SingleChoiceField
  ...MultipleChoiceField
  ...ExternalLinkField
  ...FuzzyValueField
  ...AgreementField
  ...DateField
  ...QuestionPoolsFieldGroup
}
    ${TextFieldFragmentDoc}
${PasswordFieldFragmentDoc}
${UsernameFieldFragmentDoc}
${MaskedTextFieldFragmentDoc}
${SelectableGroupFieldFragmentDoc}
${FieldInfoGroupFieldFragmentDoc}
${LabelFieldFragmentDoc}
${BooleanFieldFragmentDoc}
${IntegerFieldFragmentDoc}
${CurrencyFieldFragmentDoc}
${TimeFieldFragmentDoc}
${SsnFieldFragmentDoc}
${ConstrainedTextFieldFragmentDoc}
${ZipFieldFragmentDoc}
${HtmlTextFieldFragmentDoc}
${ErrorMessageFieldFragmentDoc}
${EmailFieldFragmentDoc}
${HiddenFieldFragmentDoc}
${NotificationFieldFragmentDoc}
${SingleChoiceFieldFragmentDoc}
${MultipleChoiceFieldFragmentDoc}
${ExternalLinkFieldFragmentDoc}
${FuzzyValueFieldFragmentDoc}
${AgreementFieldFragmentDoc}
${DateFieldFragmentDoc}
${QuestionPoolsFieldGroupFragmentDoc}`;