import React from 'react';
import { Trans } from 'react-i18next';

import { TextLink } from '@ast/magma/components/textlink';

import { useFISettingsContext } from '@app/core/contexts/fiSettings/FISettingsContext';

import { FieldBehaviorMode } from '@app/queryTyping';

import { EmailInput } from '@app/common/configurable-wizards/fields/email/EmailInput';
import { EmailFieldFragment } from '@app/common/configurable-wizards/queries/queryTyping/all-fields';
import { FieldComponent, FieldLocator } from '@app/common/configurable-wizards/types';
import { combineNames, isEmailField } from '@app/common/configurable-wizards/utils';

import { useUserLoginTranslation } from '@app/widgets/user-login/hooks/useUserLoginTranslation';

import { emailChangedEventName } from '../constants';
import { EmailChangedNotifier } from '../EmailChangedNotifier';

import styles from './EmailVerificationField.pcss';

export const EmailVerificationField: FieldComponent<EmailFieldFragment> = ({
  parent,
  field,
}) => {
  const fiSettings = useFISettingsContext();
  const supportUrl = fiSettings?.getIfConfigured('customerHelpCenterUrl');

  const emailFieldName = combineNames(parent, field.id);
  const isEmailEditable = field.fieldBehaviorMode !== FieldBehaviorMode.READONLY;
  const isEmailEmpty = !field.sValue || field.sValue.length === 0;

  const { t } = useUserLoginTranslation();

  const verificationDescriptionForEditableEmail = t('email-verification-field.verification-description.editable-email|Email validation step description for editable email',
    'To protect your account, we require that all new users verify their email address. Please check your inbox for a verification email, and verify your email address by clicking the link in that message.');

  const verificationDescriptionForEmptyEmail = t('email-verification-field.verification-description.empty-email|Email validation step description for empty email',
    'To protect your account, we require that all new users verify their email address. It looks like we don\'t have an email address on file for you. Please enter your email address below, then request a verification email.');

  const resendTitleForEditableEmail = t('email-verification-field.resend-title.editable-email|Email verification field title for editable email',
    'Wrong address? Didn\'t get the email?');

  const resendTitleForNotEditableEmail = t('email-verification-field.resend-title.not-editable-email|Email verification field title for not editable email',
    'Didn\'t get the email?');

  const resendDescriptionForEditableEmail = t('email-verification-field.resend-description.editable-email|Email verification field resend option description for editable email',
    'If the email address listed below isn\'t correct or you no longer have access to it, please update it and request another verification email. If the address is correct but you didn\'t receive the verification email, you can request another. It may take up to 10 minutes to arrive. Be sure to check your spam/junk folder.');

  const resendDescriptionForNotEditableEmail = t('email-verification-field.resend-description.not-editable-email|Email verification field resend option description for not editable email',
    'If you didn\'t receive the verification email, you can request another. It may take up to 10 minutes to arrive. Be sure to check your spam/junk folder.');

  const resendDescriptionForEmptyEmail = t('email-verification-field.resend-description.empty-email|Email verification field resend option description for empty email',
    'Check your inbox for the verification email. It may take up to 10 minutes to arrive. Be sure to check your spam/junk folder. If you don\'t receive it, you can request another.');

  const updateEmailLabelForEmptyEmail = t('email-verification-field.update-label.empty-email|Email verification field change email for empty email instructions',
    'After you receive the email, verify your email address by clicking the link in that message.');

  const verificationDescription = isEmailEmpty
    ? verificationDescriptionForEmptyEmail
    : verificationDescriptionForEditableEmail;

  const getResendTitle = () => {
    if (isEmailEditable) {
      return isEmailEmpty ? '' : resendTitleForEditableEmail;
    }

    return resendTitleForNotEditableEmail;
  };

  const resendTitle = getResendTitle();

  const getResendDescription = () => {
    if (isEmailEditable) {
      return isEmailEmpty ? resendDescriptionForEmptyEmail : resendDescriptionForEditableEmail;
    }

    return resendDescriptionForNotEditableEmail;
  };

  const resendDescription = getResendDescription();

  const changeHandler = (value: string) => {
    EmailChangedNotifier.getInstance().notify(emailChangedEventName, value);
  };

  const supportHref = supportUrl ?? '';

  const updateNonEditableEmailLabel = (
    <Trans i18nKey="email-verification-field.update-label.not-editable-email-contact-link|
      Email verification field change email for read only email instructions with contact link configured"
    >
      Wrong address?
      {' '}
      <TextLink
        href={supportHref}
        rel="noopener noreferrer nofollow"
        target="_blank"
      >
        Contact us
      </TextLink>
      {' '}
      to change your email.
    </Trans>
  );

  const updateNonEditableEmailLabelNoLink = t('email-verification-field.update-label.not-editable-email-no-contact-link|Email verification field change email for read only email instructions with no contact link configured',
    'Wrong address? Contact us to change your email.');

  const getUpdateEmailLabel = () => {
    if (isEmailEditable) {
      return isEmailEmpty ? updateEmailLabelForEmptyEmail : '';
    }

    return supportHref ? updateNonEditableEmailLabel : updateNonEditableEmailLabelNoLink;
  };

  return (
    <div>
      <p data-stable-name="verificationDescriptionId" className={styles.verificationDescription}>
        {verificationDescription}
      </p>
      {resendTitle && (<p className={styles.title}>{resendTitle}</p>)}
      <p className={styles.description}>{resendDescription}</p>
      <p data-stable-name="UpdateEmailLabelId">
        {getUpdateEmailLabel()}
      </p>

      <EmailInput
        name={emailFieldName}
        field={field}
        onChangeAction={changeHandler}
      />
    </div>
  );
};

export const EmailVerificationFieldLocator: FieldLocator = ({ field }) => (
  isEmailField(field)
    ? EmailVerificationField
    : undefined
);
