import React, {
  useCallback, useMemo, useRef,
} from 'react';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from '@app/core/i18n';

import { ListItem } from '@ast/magma/components/list';

import { FieldBehaviorMode } from '@app/queryTyping';

import {
  FormControl,
} from '@app/common/components/controls/FormControl';
import {
  formControlRequiredRule,
} from '@app/common/components/controls/formControlValidation';
import {
  IconTitleSubtitleControl,
} from '@app/common/components/controls/IconTitleSubtitleControl/IconTitleSubtitleControl';
import { combineNames, getFieldErrorMessage } from '@app/common/configurable-wizards/utils';
import { useCommonValidationMessages } from '@app/common/valiation/commonValidationMessages';

import styles from './IconTitleSubtitleControlSelector.pcss';

export interface SelectorOption {
  readonly id: string,
  readonly title?: string,
  readonly subtitle?: string,
  readonly icon?: JSX.Element,
  readonly priority: number,
}

export interface IconTitleSubtitleControlSelectorProps {
  readonly id: string,
  readonly name: string,
  readonly fieldBehaviorMode: FieldBehaviorMode,
  readonly selectorOptions: SelectorOption[],
  readonly titleClassName?: string,
}

export const IconTitleSubtitleControlSelectorComponent: React.FC<IconTitleSubtitleControlSelectorProps> = React.memo(({
  id,
  name,
  fieldBehaviorMode,
  selectorOptions,
  titleClassName,
}) => {
  const {
    formState: { errors },
    setValue,
    watch,
  } = useFormContext();

  const { t } = useTranslation();

  const value = watch(name);

  const required = fieldBehaviorMode === FieldBehaviorMode.REQUIRED;
  const validationRules = useMemo(() => [
    ...(required ? [formControlRequiredRule(t(
      'icon-title-subtitle-selector.validation.required|Validation message',
      'Select one of the options',
    ))] : []),
  ], [required]);

  const commonValidationMessages = useCommonValidationMessages();
  const getErrorMessage = useCallback(
    () => getFieldErrorMessage(name, errors, commonValidationMessages),
    [name, errors],
  );

  // This workaround is used due to wizards fields are temporary unable
  // to submit current wizard step in any other way.
  //
  // Basically, this fields submits current wizard step by submitting its form
  // using hidden submit button.
  const submitButtonRef = useRef<any>(null);
  const triggerSubmit = () => {
    submitButtonRef?.current?.click();
  };

  return (
    <>
      <FormControl
        name={name}
        validations={validationRules}
        getErrorMessage={getErrorMessage}
        className={styles.field}
      >
        <>
          { selectorOptions.sort((a, b) => a.priority - b.priority).map((selectorOption) => (
            <ListItem
              key={selectorOption.id}
              aria-selected={selectorOption.id === value}
              data-stable-name={combineNames(id, selectorOption.id)}
              tag="span"
              onClick={() => {
                setValue(name, selectorOption.id);
                triggerSubmit();
              }}
              onFocus={() => {
                setValue(name, selectorOption.id);
              }}
              onKeyDown={(e) => {
                if (e.key === 'Enter') {
                  triggerSubmit();
                }
              }}
              className={styles.listItem}
            >
              <IconTitleSubtitleControl
                icon={selectorOption.icon}
                title={selectorOption.title}
                subtitle={selectorOption.subtitle}
                titleClassName={titleClassName}
              />
            </ListItem>
          ))}
        </>
      </FormControl>
      {/*
        This workaround is used due to wizards fields are temporary unable
        to submit current wizard step in any other way.
      */}
      <input
        style={{ display: 'none' }}
        type="submit"
        aria-hidden="true"
        tabIndex={-1}
        ref={submitButtonRef}
      />
    </>
  );
});
