export enum GQLFieldNames {
  BANK_ACCOUNT_BALANCE_CHANGES = 'bankAccountBalanceChanges',
  BANK_ACCOUNT_SUMMARY = 'bankAccountSummary',
  CONFIRMED_EXTERNAL_ACCOUNTS = 'confirmedExternalAccounts',
  PENDING_EXTERNAL_ACCOUNTS = 'pendingExternalAccounts',
  E_STATEMENTS_MEMBERS = 'eStatementsMembers',
  E_STATEMENTS_SETTINGS = 'eStatementsSettings',
  OPEN_NEW_SHARES_MEMBERS = 'openNewSharesMembers',
  OPEN_NEW_SHARES_FUNDING = 'openNewSharesFundingSources',
  TRANSACTIONS_HISTORY = 'transactionsHistory',
  USER_BANK_ACCOUNTS = 'userBankAccounts',
  BANK_ACCOUNT_TRANSACTIONS_GRAPH = 'transactionsGraph',
  BANK_ACCOUNT_BALANCE_GRAPH = 'bankAccountBalancesGraph',
  OVERDRAFT_PROTECTION_SETTINGS = 'overdraftProtectionSettings',
  BANK_ACCOUNT_DETAILS = 'bankAccountDetails',
  DISPLAY_OPTIONS = 'accountsDisplayOptions',
  CARDS = 'cards',
  YEAR_END_INFO_MEMBER_ACCOUNTS = 'yearEndInfoMemberAccounts',
  TRANSFER_ACTIVITIES = 'getTransferActivities',
  CREDIT_SCORE_MEMBERS = 'creditScoreMemberAccounts',
  CREDIT_SCORE_SETTINGS = 'creditScoreSettings',
  CREDIT_SCORE = 'creditScore',
}
