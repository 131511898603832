import React, {
  useEffect, useRef,
} from 'react';

import classNames from 'classnames';

import { useBreakpoint } from '@ast/magma/breakpoints';

import { useCompromisedCredentialCheckAfterLogin } from '@app/features/compromised-credentials/useCompromisedCredentialCheckAfterLogin';

import { AutoLogoutCountdownAlert } from '@app/common/components/AutoLogoutCountdown/AutoLogoutCountdownAlert';
import {
  AutoLogoutCountdownDocumentTitle,
} from '@app/common/components/AutoLogoutCountdown/AutoLogoutCountdownDocumentTitle';
import { ErrorCard } from '@app/common/components/ErrorCard/ErrorCard';
import { CommonErrorDialogFooter } from '@app/common/components/ErrorDialog/CommonFooter/CommonFooter';
import { LocalizationSelectorWrapper } from '@app/common/components/LocalizationSelector/LocalizationSelectorWrapper';
import { MaintenanceModCard } from '@app/common/components/MaintenanceModeCard/MaintenanceModCard';
import { OverlaySpinner } from '@app/common/components/overlayspinner';
import { ErrorTypes } from '@app/common/errors/errorProcessors/constants';
import { GenericErrorBoundary } from '@app/common/errors/GenericErrorBoundary/GenericErrorBoundary';
import { checkIfMaintenanceModeError } from '@app/common/errors/genericErrorHandler';
import { UserError } from '@app/common/errors/UserError';
import { isUndefined } from '@app/common/utils';
import { secToMin } from '@app/common/utils/dateTime';

import { AUTH_TOKEN_UPDATE_EVENT } from '@app/core/authentication/authenticationToken';
import { isEmbeddedMode } from '@app/core/authentication/embeddedMode/embeddedMode';
import { useIsLoggedIn } from '@app/core/authentication/login/isLoggedIn';
import { useAutoLogout } from '@app/core/authentication/logout/autoLogout/useAutoLogout';
import { useAutoLogoutCountdown } from '@app/core/authentication/logout/autoLogout/useAutoLogoutCountdown';
import { isAutoLogoutApplicable } from '@app/core/authentication/logout/autoLogout/utils';
import { useForceLogoutSubscription } from '@app/core/authentication/logout/forceLogout/useForceLogoutSubscription';
import { logout } from '@app/core/authentication/logout/logout';
import { NonAuthHeader } from '@app/core/components/Header/NonAuthHeader';
import { useSessionSettingsContext } from '@app/core/contexts/sessionSettings/SessionSettingsContext';
import { renderEmbeddedResources } from '@app/core/emdeddedResources/embeddedResources';
import { useGetEmbeddedResourcesQuery } from '@app/core/emdeddedResources/queries/queryTyping/getEmbeddedResources';
import { useUserSessionHeartbeat } from '@app/core/heartbeat/useUserSessionHeartbeat';
import { useTranslation } from '@app/core/i18n';

import { UserLoginSessionStorage } from '@app/widgets/user-login/LoginWizard/UserLoginSessionStorage';

import { Footer } from '../Footer';
import { Header } from '../Header';

import { Main } from '../Main/Main';
import { Page } from '../Page/Page';

import styles from './Application.pcss';
import { useNavigateURLEvent } from './useNavigateURLEvent';
import { useProccessPagePath } from './usePagePath';

/**
 * Error dialog fallback method with
 * error dialog containing error message and
 * common footer with support URL.
 * @param error user facing error.
 */
export const errorDialogFallback = (error: UserError) => {
  const isMaintenanceMode = checkIfMaintenanceModeError(error);

  if (isMaintenanceMode) {
    return (<MaintenanceModCard />);
  }

  return (
    <ErrorCard
      error={error}
      footer={<CommonErrorDialogFooter isInternalError={error.type === ErrorTypes.InternalServerError} />}
    />
  );
};

/**
 * Application component which:
 * 1. Calculate page id according to URL (first segment).
 * 2. Get page layout html by id from server.
 * 3. Insert page layout html in dom.
 * 4. Render page component according to id.
 * @returns application component.
 */
export const Application = () => {
  const { t } = useTranslation('application');

  /**
   * Process current browser location to get application layout and page ids.
   */
  const { layoutId, pageId, toRedirect } = useProccessPagePath();
  const mainRef = useRef<HTMLElement>(null);
  const isMobile = useBreakpoint().device === 'mobile';
  const isLoggedIn = useIsLoggedIn();

  /**
   * Subscribe to navigate URL event from window to
   * redirect react router to a provided url without page reload.
   * To be used by WebView.
   */
  useNavigateURLEvent();

  const userSessionExpirationInSecs = useSessionSettingsContext()?.userSessionExpirationInSecs;
  const secsToLogout = useAutoLogoutCountdown(userSessionExpirationInSecs);
  const timeToLogout = useAutoLogout(userSessionExpirationInSecs);

  useUserSessionHeartbeat(userSessionExpirationInSecs);

  useForceLogoutSubscription();

  useEffect(() => {
    if (timeToLogout) {
      const userLoginSessionStorage = UserLoginSessionStorage.getInstance();

      // TODO: also, keep some public user id to return him to the last visited page durign next signin (MPH-44052)
      userLoginSessionStorage.setAutoLogoutRedirection({
        // keep the last page user visited
        lastPage: window.location.href,
      });

      logout();
    }
  }, [timeToLogout]);

  const {
    data: resData, loading: resLoading, error: resError, refetch: resRefetch,
  } = useGetEmbeddedResourcesQuery();
  useEffect(() => {
    if (!resLoading && !resError) {
      // eslint-disable-next-line i18next/no-literal-string
      renderEmbeddedResources(resData!, 'Head', document.querySelector('#head')!);
      // eslint-disable-next-line i18next/no-literal-string
      renderEmbeddedResources(resData!, 'Tail', document.querySelector('#tail')!);
    }
  }, [resData, resLoading, resError]);

  useEffect(() => {
    window.addEventListener(AUTH_TOKEN_UPDATE_EVENT, () => resRefetch());
    return () => window.removeEventListener(AUTH_TOKEN_UPDATE_EVENT, () => resRefetch());
  }, [resRefetch]);

  useCompromisedCredentialCheckAfterLogin(isLoggedIn);

  // useSetTraceSpan(pageId, tracing);

  if (toRedirect) {
    setTimeout(() => {
      window.location.href = toRedirect;
    }, 2000);
    return (
      <p data-stable-name="ApplicationRedirectLabel" className={styles.redirect}>
        {t('application.redirect.label|Redirect label', 'One moment. Page will be redirected to {{ toRedirect }}', { toRedirect })}
      </p>
    );
  }

  return (
    <div
      className={classNames(
        styles.container,
        layoutId === 'dashboard' && styles.dashboard,
      )}
    >
      {/* Auto logout section */}
      {isAutoLogoutApplicable() && secsToLogout !== null && (
        <>
          <AutoLogoutCountdownAlert
            secsToLogout={secsToLogout}
            userSessionTimeInMin={
              !isUndefined(userSessionExpirationInSecs)
                ? secToMin(userSessionExpirationInSecs)
                : undefined
            }
          />
          <AutoLogoutCountdownDocumentTitle secsToLogout={secsToLogout} />
        </>
      )}

      {!isEmbeddedMode() && !isLoggedIn && (
        <NonAuthHeader />
      )}

      {!isMobile && isLoggedIn && !isEmbeddedMode() && (
        <Header />
      )}

      <Main
        layoutId={layoutId}
        ref={mainRef}
      >
        {pageId && (
          <GenericErrorBoundary fallback={errorDialogFallback}>
            <React.Suspense fallback={<OverlaySpinner visible />}>
              <Page />
            </React.Suspense>
          </GenericErrorBoundary>
        )}
      </Main>
      { isEmbeddedMode() && <LocalizationSelectorWrapper isNotVisible /> }
      {!isEmbeddedMode() && <Footer />}
    </div>
  );
};
