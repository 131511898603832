import React from 'react';

import Key from '@ast/magma/components/icon/icons/Key';

import { Button } from '@ast/magma/components/button';

import { FlexContainer } from '@app/common/components/FlexContainer/FlexContainer';

import { useUserLoginTranslation } from '@app/widgets/user-login/hooks/useUserLoginTranslation';

import styles from './Buttons.pcss';

export const Buttons = ({
  onSubmitPasskey,
  isPasskeyEnabled = false,
}: {
  onSubmitPasskey: () => void,
  // eslint-disable-next-line react/require-default-props
  isPasskeyEnabled?: boolean,
}) => {
  const { t } = useUserLoginTranslation();

  return (
    <FlexContainer gap="small" direction="column">
      <Button
        type="submit"
        className={styles.signInButton}
        look="primary"
        predefinedSize="medium"
        data-stable-name="SignInButton"
      >
        {t('login.steps.username-password.buttons.signin.text|Sign in button text', 'Sign in')}
      </Button>
      {isPasskeyEnabled && (
      <Button
        className={styles.signInButton}
        look="outlined"
        predefinedSize="medium"
        data-stable-name="AuthButtonPasskey"
        icon={<Key />}
        onClick={
          onSubmitPasskey
        }
      >
        {t('login.steps.username-password.buttons.auth-passkey.text|Auth button passkey', 'Sign in with a passkey')}
      </Button>
      )}
    </FlexContainer>
  );
};
