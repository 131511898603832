import React from 'react';
import { Trans } from 'react-i18next';

import { TextLink } from '@ast/magma/components/textlink';

import { useFISettingsContext } from '@app/core/contexts/fiSettings/FISettingsContext';

import {
  FieldComponent,
  FieldContext, FieldLocator, isLabelField,
} from '@app/common/configurable-wizards';
import { LabelAsPlainTextField } from '@app/common/configurable-wizards/fields/label/LabelAsPlainText';
import { LabelFieldFragment } from '@app/common/configurable-wizards/queries/queryTyping/all-fields';

import { useUserLoginTranslation } from '@app/widgets/user-login/hooks/useUserLoginTranslation';

export const PrimaryEmailAbsentFieldComponent: FieldComponent<LabelFieldFragment> = ({
  parent,
  field,
  locator,
}) => {
  const fiSettings = useFISettingsContext();
  const supportUrl = fiSettings?.getIfConfigured('customerHelpCenterUrl');

  const { t } = useUserLoginTranslation();

  const supportHref = supportUrl ?? '';

  const contactUsLabel = (
    <Trans i18nKey="email-absent-field.contact-us-label.text-contact-link|
    Contact us label text on email verification step when email is absent with contact link configured"
    >
      Please
      {' '}
      <TextLink
        href={supportHref}
        rel="noopener noreferrer nofollow"
        target="_blank"
      >
        contact us
      </TextLink>
      {' '}
      for help, or visit the nearest branch to update your email address in our records.
    </Trans>
  );

  const contactUsLabelNoLink = t('email-absent-field.contact-us-label.text-no-contact-link|Contact us label text on email verification step when email is absent with no contact link configured',
    'Please contact us for help, or visit the nearest branch to update your email address in our records.');

  const getContactUsLabel = () => (supportHref ? contactUsLabel : contactUsLabelNoLink);

  return (
    <div>
      <LabelAsPlainTextField
        field={field}
        parent={parent}
        locator={locator}
      />

      <p data-stable-name="ContactUsLabel">
        {getContactUsLabel()}
      </p>
    </div>
  );
};

export const isPrimaryEmailAbsentFieldLabel = ({ field }: FieldContext) => (
  // eslint-disable-next-line i18next/no-literal-string
  isLabelField(field) && field.id === 'PrimaryEmailAbsent'
);

export const PrimaryEmailAbsentFieldLocator: FieldLocator = (context) => (
  isPrimaryEmailAbsentFieldLabel(context)
    ? PrimaryEmailAbsentFieldComponent
    : undefined
);
