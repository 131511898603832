/* eslint-disable i18next/no-literal-string */

import { createUploadLink } from 'apollo-upload-client';
import { SubscriptionClient } from 'subscriptions-transport-ws';
import join from 'url-join';

import {
  ApolloClient, ApolloError,
  ApolloLink,
  from,
  InMemoryCache,
  split,
} from '@apollo/client';
import { onError } from '@apollo/client/link/error';
import { WebSocketLink } from '@apollo/client/link/ws';
import { getMainDefinition } from '@apollo/client/utilities';

import { checkIfMaintenanceModeError } from '@app/common/errors/genericErrorHandler';

import { logger } from '@app/core/logger/setupLogger';
import { parseAmazonTraceId, X_AMAZON_TRACE_ID_KEY } from '@app/core/opentelemetry/setupTracing';
import possibleTypes from '@app/core/types/possibleTypes.json';

import { getCustomAuthHeaders } from './authentication/customAuthHeaders';
import { reCaptchaApolloLink } from './components/ReCaptcha/reCaptchaApolloLink';
import { triggeredEventApolloLink } from './components/TriggeredEvents/triggeredEventApolloLink';

// Temporary disable persistCache
// import { persistCache } from './apolloPersistenceCache';
import { appSettings } from './appSettings';

const subscriptionClient = new SubscriptionClient(
  join(appSettings().apiEndpoint.replace('https://', 'wss://'), '/api/graphql'),
  {
    reconnect: true,
    connectionParams: () => ({
      ...getCustomAuthHeaders(),
      credentials: 'include',
    }),
  },
);

const wsLink = new WebSocketLink(subscriptionClient);

const httpLink = createUploadLink({
  uri: join(appSettings().apiEndpoint, '/api/graphql'),
  fetchOptions: {
    mode: 'cors',
    credentials: appSettings().credentials,
  },
});

// The split function takes three parameters:
//
// * A function that's called for each operation to execute
// * The Link to use for an operation if the function returns a "truthy" value
// * The Link to use for an operation if the function returns a "falsy" value

const splitLink = split(
  ({ query }) => {
    const definition = getMainDefinition(query);
    return (
      definition.kind === 'OperationDefinition'
      && definition.operation === 'subscription'
    );
  },
  wsLink,
  httpLink,
);

const authMiddlewareLink = new ApolloLink((operation, forward) => {
  // add the authorization to the headers
  const customHeaders = getCustomAuthHeaders();
  if (customHeaders) {
    operation.setContext(({ headers = {} }) => ({
      headers: {
        ...headers,
        ...customHeaders,
      },
    }));
  }
  return forward(operation);
});

const loggerLink = onError((data) => {
  const {
    graphQLErrors,
  } = data;

  const err = JSON.stringify(graphQLErrors) ?? 'Unknown err message';
  const amazonTraceIdValue = data.operation.getContext().headers[X_AMAZON_TRACE_ID_KEY];

  if (amazonTraceIdValue) {
    const amazonTraceContext = parseAmazonTraceId(
      amazonTraceIdValue,
    );
    logger.error(err, {
      corrTraceId: amazonTraceContext.traceId,
      corrSpanId: amazonTraceContext.spanId,
      errorException: data.operation.operationName,
    });
  }
});

const maintenanceModeBoundaryLink = onError((data) => {
  const {
    graphQLErrors,
  } = data;

  if (graphQLErrors && window.location.pathname !== '/') {
    const apolloError = new ApolloError({
      graphQLErrors,
    });

    if (checkIfMaintenanceModeError(apolloError)) {
      window.location.assign('/');
    }
  }
});

export const reconnectWebSocket = (): void => {
  if (subscriptionClient) {
    subscriptionClient.close(false, false);
  }
};

const cache = new InMemoryCache({
  possibleTypes,
  typePolicies: {
    BankAccountDisplayOptions: {
      keyFields: ['bankAccountId'],
    },
    ConsumerBankAccountsDisplayOptions: {
      keyFields: ['consumerId'],
    },
    SecurityAlert: {
      keyFields: ['formId'],
    },
    AccountAlert: {
      keyFields: ['id', 'alertType'],
    },
    AccountAlertTypeGroup: {
      keyFields: ['formId'],
    },
    Card: {
      keyFields: ['key'],
      fields: {
        features: { merge: false },
      },
    },
    Travel: {
      keyFields: ['key'],
    },
    SubuserInfo: {
      keyFields: ['key'],
    },
    OptInSetting: {
      keyFields: ['memberNumber'],
    },
    MemberAccount: {
      keyFields: ['number'],
    },
    InternalAccountView: {
      // Prevent cache objects apart from list and prevent use ref for them
      keyFields: false,
    },
    UserTimeZone: {
      // Keep UserTimeZone in cache as singleton
      keyFields: [],
    },
  },
});

// persistCache(cache);

export const client = new ApolloClient({
  link: from([
    loggerLink,
    triggeredEventApolloLink,
    reCaptchaApolloLink,
    authMiddlewareLink,
    maintenanceModeBoundaryLink,
    splitLink]),
  cache,
  connectToDevTools: true,
});
