import React from 'react';

import { Tab, Tabs } from '@ast/magma/components/tabs';

import { isFunction } from '@app/common/utils';

import { useUserLoginTranslation } from '@app/widgets/user-login/hooks/useUserLoginTranslation';

import { LoginMode } from './types';

export interface LoginModeSwitcherProps {
  readonly defaultMode: LoginMode;
  readonly onLoginModeChange?: (mode: LoginMode) => void;
}

export const indexesToModes: Map<number, keyof typeof LoginMode> = new Map([
  [0, LoginMode.password],
  [1, LoginMode.biometric],
]);

export const modesToIndexes: Map<keyof typeof LoginMode, number> = new Map([
  [LoginMode.password, 0],
  [LoginMode.biometric, 1],
]);

export const LoginModeSwitcher: React.FC<LoginModeSwitcherProps> = ({
  defaultMode,
  onLoginModeChange,
}) => {
  const { t } = useUserLoginTranslation();

  return (
    <Tabs
      current={modesToIndexes.get(defaultMode)}
      onChange={(idx) => {
        if (idx !== null && isFunction(onLoginModeChange)) {
          onLoginModeChange(indexesToModes.get(idx) as LoginMode);
        }
      }}
    >
      <Tab label={t(
        'login.steps.username-password.login-mode-segment-control.password-label|Represent the option for the classic login mode with username-password',
        'Password',
      )}
      />
      <Tab label={t(
        'login.steps.username-password.login-mode-segment-control.biometric-label|Represent the option for the biometric login mode',
        'Biometric',
      )}
      />
    </Tabs>
  );
};
