import React, {
  Ref, useEffect, useState,
} from 'react';
import { useTranslation } from '@app/core/i18n';
import { useHistory, useLocation } from 'react-router-dom';

import classNames from 'classnames';

import { Spinner } from '@ast/magma/components/spinner/spinner';

import useForwardedRef from '@ast/magma/utils/useForwardedRef';

import { LocalizationSelectorWrapper } from '@app/common/components/LocalizationSelector/LocalizationSelectorWrapper';
import { useGetMenuQuery } from '@app/common/types/queries/queryTyping/getNavigationMenu';
import { LinkMenuItem, useMainMenuQuery } from '@app/common/types/useMainMenuQuery';
import { isAbsoluteURL } from '@app/common/utils/url';

import { logout } from '@app/core/authentication/logout/logout';
import { MenuItemEligibility } from '@app/core/components/MenuNavigation/MenuItemEligibility';
import { useCurrentUserInfoContext } from '@app/core/contexts/currentUserInfo/CurrentUserInfoContext';
import { SelectedMainMenuItemContext } from '@app/core/contexts/selectedMainMenuItem/SelectedMainMenuItemContext';

import { MobileMenu } from './mobilemenu/mobilemenu';
import { QuickLaunchItem } from './quicklaunchitem/quicklaunchitem';

import styles from './quicklaunch.pcss';

export const QuickLaunch = React.forwardRef((props, ref: Ref<HTMLDivElement>) => {
  const menuId = 'dashboard-mobile-menu';

  const { t } = useTranslation('footer');
  const location = useLocation();
  const history = useHistory();
  const [menuOpened, setMenuOpened] = useState(false);
  const currentUserInfo = useCurrentUserInfoContext();

  const { loading, error, data } = useGetMenuQuery({
    variables: {
      ids: menuId,
    },
  });

  const {
    allMenuItems,
  } = useMainMenuQuery();

  const containerRef = useForwardedRef(ref);

  const updateQuickLaunchSize = () => {
    if (containerRef.current && window.visualViewport) {
      // eslint-disable-next-line i18next/no-literal-string
      containerRef.current.style.height = (menuOpened) ? `${window.visualViewport.height}px` : 'initial';
    }
  };

  // NOTE: Fix of MPH-37904. To update menu size while user drag address bar into screen
  // we need to listen visualViewport resize. See: https://developers.google.com/web/updates/2016/12/url-bar-resizing
  // Example: https://bokand.github.io/demo/urlbarsize.html
  // Fix only work for Chrome browsers
  useEffect(() => {
    const viewportResizeHandler = () => {
      updateQuickLaunchSize();
    };
    window.visualViewport?.addEventListener('resize', viewportResizeHandler);
    // eslint-disable-next-line consistent-return
    return () => { window.visualViewport?.removeEventListener('resize', viewportResizeHandler); };
  });

  useEffect(() => {
    updateQuickLaunchSize();
  }, [menuOpened]);

  if (loading) return <Spinner size="lg" />;

  if (error) throw error;

  const quickLaunchData:LinkMenuItem[] | null = (loading
    || !data?.menu
    || data?.menu.length === 0
    || !data?.menu[0]?.menuItems)
    ? null
    : data?.menu[0]?.menuItems as any;
  const hasQuickLaunchData = quickLaunchData && (quickLaunchData.length > 0);

  const handleItemSelect = (item?:LinkMenuItem) => {
    setMenuOpened(false);
    if (item != null && item.url != null) {
      // MPH-56007: QuickLaunch item should take into external urls
      // eslint-disable-next-line @typescript-eslint/no-unused-expressions
      isAbsoluteURL(item.url) ? window.open(item.url, '_blank', 'noreferrer') : history.push(item.url);
    }
  };

  const handleSignOut = () => {
    logout();
  };

  return (
    <div
      ref={containerRef}
      className={classNames(styles.container, menuOpened && styles.fill)}
      data-stable-name="QuickLaunchContainer"
    >
      <LocalizationSelectorWrapper
        isNotVisible
        preferredCulture={currentUserInfo?.preferredCulture}
      />

      { menuOpened && (
      <SelectedMainMenuItemContext>
        <MobileMenu onClose={handleItemSelect} onSignOut={handleSignOut} />
      </SelectedMainMenuItemContext>
      )}
      <nav className={styles.quicklaunch}>
        <div className={classNames(styles.itemsContainer, !hasQuickLaunchData && styles.noItems)}>
          {
              (hasQuickLaunchData
               && quickLaunchData!.slice(0, 4).map((item) => (
                 <QuickLaunchItem
                   menuId={menuId}
                   selected={location.pathname === item.url}
                   key={item.linkName}
                   data={item}
                   onSelect={handleItemSelect}
                 />
               ))
              )
            }
          <QuickLaunchItem
            menuId={menuId}
            selected={false}
            data={{
              id: '',
              icon: menuOpened ? 'Times' : 'DotsAlt',
              linkName: menuOpened
                ? t('quick-launch.close-menu-button|Close menu button label', 'Close')
                : t('quick-launch.menu-button-button|Menu button label', 'Menu'),
            }}
            onSelect={() => setMenuOpened(!menuOpened)}
          />
        </div>
        <div style={{ display: 'none' }}>
          {
            allMenuItems
              .filter((item) => item.isEligibilityCheckRequired)
              .map((item) => (
                <MenuItemEligibility key={item.id} menuItem={item}>
                  <span data-stable-name="MenuItemEligibilityItem" style={{ display: 'none' }} />
                </MenuItemEligibility>
              ))
          }
        </div>
      </nav>
    </div>
  );
});
