import React, { useEffect, useState } from 'react';
import { useTranslation } from '@app/core/i18n';
import { Alert } from '@ast/magma/components/alert';

import { Portal } from '@app/common/components/Portal';
import { isMultipleOf, isUndefined } from '@app/common/utils';
import { usePrevious } from '@app/common/utils/hooks/usePrevious/usePrevious';
import styles from './AutoLogoutCountdownAlert.pcss';

interface AutoLogoutCountdownAlertProps {
  readonly secsToLogout: number;
  readonly userSessionTimeInMin?: number | undefined;
}

export const AutoLogoutCountdownAlert: React.FC<AutoLogoutCountdownAlertProps> = ({
  secsToLogout,
  userSessionTimeInMin,
}) => {
  const { t } = useTranslation();
  const contdownMessage = t(
    'user-auto-logout.notification.countdown-text|Represents the auto-logout countdown message that will be shown when the user is away',
    'For your security, you\'ll be logged out in {{ secsToLogout }} seconds due to inactivity',
    { secsToLogout },
  );
  const loggingOutMessage = t(
    'user-auto-logout.notification.countdown-over-text|Represents the auto-logout message right after the countdown was finished and before redirecting to the login page',
    'We\'re automatically logging you out after because of inactivity',
  );

  const loggingOutMessageWithSessionTime = t(
    'user-auto-logout.notification.countdown-over-text-with-session-time|Represents the auto-logout message right after the countdown was finished and before redirecting to the login page',
    'We\'re automatically logging you out after {{userSessionTimeInMin}} minutes of inactivity',
    { userSessionTimeInMin },
  );

  const prevSecsToLogout = usePrevious(secsToLogout);

  // Returns true if it's a countdown starting
  const isCountdownJustStarting = () => (
    (isUndefined(prevSecsToLogout) || prevSecsToLogout === 0) && secsToLogout !== 0
  );

  // Returns 'countdown' or 'logging out in progress' message
  const getCurrentMessage = () => (
    // eslint-disable-next-line no-nested-ternary
    secsToLogout > 0
      ? contdownMessage
      : userSessionTimeInMin ? loggingOutMessageWithSessionTime : loggingOutMessage
  );

  const [webAccessabilityMessage, setWebAccessabilityMessage] = useState<string>(getCurrentMessage());

  useEffect(() => {
    // On countdown starting, or once by 10 seconds, - update the accessibility message.
    // As a result, we will have the following sequence: 59, 50, 40..
    if (isCountdownJustStarting() || isMultipleOf(secsToLogout, 10)) {
      setWebAccessabilityMessage(getCurrentMessage());
    }
  }, [secsToLogout]);

  return (
    <Portal>
      <div className={styles.alert}>
        <Alert
          level="warning"
          // [MPH-44641]. More elegant solution but it is not supported for iOS 15 Safari/VoiceOver.
          // aria-label={webAccessabilityMessage}
          data-stable-name="AutoLogoutCountdownAlert"
          aria-atomic="false"
        >
          <span aria-hidden="true" data-stable-name="AutoLogoutCountdownAlertMessage">
            {getCurrentMessage()}
          </span>
          {/* [MPH-44641]. "Phantom" element that contains audible text, used for screen readers */}
          <span
            style={{
              position: 'absolute',
              left: '999rem',
            }}
            data-stable-name="AutoLogoutCountdownAlertAudibleMessage"
          >
            {webAccessabilityMessage}
          </span>
        </Alert>
      </div>
    </Portal>
  );
};
