/* eslint-disable i18next/no-literal-string */

import { ApolloError } from '@apollo/client';

import { VerifyAuthenticationParams } from '@app/queryTyping';

import { WizardStepStateDispatchAction, setWizardStepAlertMessage } from '@app/common/configurable-wizards';

import { client } from '@app/core/apolloClient';

import {
  convertGetOptions,
  convertGetCredential,
} from '@app/widgets/contacts-and-settings/fields/ProfileAndSecurity/Passkeys/webauthn-helpers';
import { useUserLoginTranslation } from '@app/widgets/user-login/hooks/useUserLoginTranslation';

import {
  GeneratePasskeyAuthOptionsDocument, GeneratePasskeyAuthOptionsQuery, GeneratePasskeyAuthOptionsQueryVariables,
} from './queries/queryTyping/generate-passkey-auth-options';
import { useVerifyPasskeyAuthenticationMutation } from './queries/queryTyping/verify-passkey-authentication';

const buildVerificationVariables = (publicKeyCredential: Credential) : {
  variables: { verifyAuthenticationParams: VerifyAuthenticationParams } } => ({
  variables: {
    verifyAuthenticationParams: convertGetCredential(publicKeyCredential),
  },
});

export const usePasskeyLogin = ({ onApolloError, dispatch }: {
  onApolloError: (error: ApolloError) => void;
  dispatch: (value: WizardStepStateDispatchAction) => void
}) => {
  const { t } = useUserLoginTranslation();

  // Verifying the authentication passkey
  const [verifyAuthentication] = useVerifyPasskeyAuthenticationMutation({
    onError: (error) => {
      onApolloError(error);
      throw error;
    },
    onCompleted: (data) => {
      if (!data.verifyAuthentication.success) {
        throw new Error(data.verifyAuthentication.message);
      }
    },
  });

  const startAuthentication = async () => {
    // eslint-disable-next-line max-len
    const { data: passkeyOptions, error } = await client.query<GeneratePasskeyAuthOptionsQuery, GeneratePasskeyAuthOptionsQueryVariables>({
      query: GeneratePasskeyAuthOptionsDocument,
      fetchPolicy: 'no-cache',
    });
    if (error) {
      onApolloError(error);
      throw error;
    }

    if (passkeyOptions) {
      try {
        const publicKey = convertGetOptions(passkeyOptions.generateAuthOptions);

        const publicKeyCredential = await navigator.credentials.get({
          publicKey: publicKey as PublicKeyCredentialRequestOptions,
        });

        if (publicKeyCredential) {
          const verificationVariables = buildVerificationVariables(publicKeyCredential);
          await verifyAuthentication(verificationVariables);
        }
      } catch (err) {
        if (!(err instanceof ApolloError)) {
          dispatch(setWizardStepAlertMessage({
            content: t('login.passkey.error.message|Login passkey error message', 'An error occurred while trying to authenticate with a passkey.'),
            type: 'error',
          }));
        }

        throw err;
      }
    }
  };

  return {
    startAuthentication,
  };
};
