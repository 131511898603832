import React from 'react';

import classNames from 'classnames';

import { useBreakpoint } from '@ast/magma/breakpoints';

import { Card } from '@ast/magma/components/card';

import styles from './WizardStepCard.pcss';
import { WizardStepOverlaySpinner } from './WizardStepOverlaySpinner';

/**
 * The size of the card.
 *
 * 'small' - is used for narrow content. (Applied on desktop only)
 * 'large' - is used for wide content. (Applied on desktop only)
 * 'full' - is fluid and takes all available space.
 */
export type WizardStepSizes = 'small' | 'large' | 'full';

export interface WizardStepCardProps {
  size: WizardStepSizes,
  loading: boolean,
  className?: string,
}

const sizeStyles: Record<WizardStepSizes, Array<string>> = {
  small: [styles.smallCard],
  large: [styles.largeCard],
  full: [styles.fullCard],
};

const useSizeStyles = (size: WizardStepSizes) => {
  const isMobile = useBreakpoint().device === 'mobile';

  // on mobile we also always use full size
  if (isMobile || size === 'full') {
    return sizeStyles.full;
  }

  return sizeStyles[size];
};

/**
 * The wrapper that defines common Card component and Spinner during loading
 */
export const WizardStepCard: React.FC<WizardStepCardProps> = ({
  children,
  loading,
  size,
  className,
}) => {
  const sizeClasses = useSizeStyles(size);

  return (
    <Card
      className={classNames(
        styles.cardWrapper,
        ...sizeClasses,
        className,
      )}
      hasBorder={false}
      data-stable-name="ConfigurableWizardStepCard"
    >
      <section
        className={styles.content}
        aria-labelledby="wizardStepContainer"
        tabIndex={-1}
      >
        <WizardStepOverlaySpinner loading={loading} />
        <div id="wizardStepContainer">
          {children}
        </div>
      </section>
    </Card>
  );
};

WizardStepCard.defaultProps = {
  size: 'small',
  loading: true,
};
