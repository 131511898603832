import React from 'react';

import { WidgetType } from '@app/core/widgets/react/types';
import { widgetsMap } from '@app/core/widgets/react/widgets';

import { ZoneName } from '../constants';

import { WidgetWrapper } from '../components/WidgetWrapper';

/**
 * Renders a widget based on its type.
 */
export function renderWidget(id: string, widget: WidgetType, zoneName: ZoneName): JSX.Element | null {
  const [, Component] = [...widgetsMap].find(([checker]) => checker(widget)) || [];

  if (Component) {
    return (
      <WidgetWrapper key={id}>
        <Component widget={widget} zoneName={zoneName} />
      </WidgetWrapper>
    );
  }

  // eslint-disable-next-line no-underscore-dangle, no-console
  console.error(`Unknown widget type for widget "${widget?.__typename}".`);

  return null;
}
