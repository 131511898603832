/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.
import * as Types from '../../../../../queryTyping';

import { WizardNextStepFragment } from '../../../../../common/configurable-wizards/queries/queryTyping/wizard-result-next-step';
import { gql } from '@apollo/client';
import { WizardNextStepFragmentDoc } from '../../../../../common/configurable-wizards/queries/queryTyping/wizard-result-next-step';
import * as Apollo from '@apollo/client';
const defaultOptions =  {}
export type PostLoginStepMutationVariables = Types.Exact<{
  stepParams: Types.SubmitWizardStepParams;
}>;


export type PostLoginStepMutation = (
  { __typename?: 'Mutation' }
  & { loginStep: Types.Maybe<(
    { __typename?: 'NextStep' }
    & WizardNextStepFragment
  ) | (
    { __typename: 'AuthResult' }
    & Pick<Types.AuthResult, 'userAuthToken'>
  )> }
);


export const PostLoginStepDocument = gql`
    mutation PostLoginStep($stepParams: SubmitWizardStepParams!) {
  loginStep(submitWizardStepParams: $stepParams) {
    ...WizardNextStep
    ... on AuthResult {
      __typename
      userAuthToken
    }
  }
}
    ${WizardNextStepFragmentDoc}`;
export type PostLoginStepMutationFn = Apollo.MutationFunction<PostLoginStepMutation, PostLoginStepMutationVariables>;

/**
 * __usePostLoginStepMutation__
 *
 * To run a mutation, you first call `usePostLoginStepMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `usePostLoginStepMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [postLoginStepMutation, { data, loading, error }] = usePostLoginStepMutation({
 *   variables: {
 *      stepParams: // value for 'stepParams'
 *   },
 * });
 */
export function usePostLoginStepMutation(baseOptions?: Apollo.MutationHookOptions<PostLoginStepMutation, PostLoginStepMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<PostLoginStepMutation, PostLoginStepMutationVariables>(PostLoginStepDocument, options);
      }
export type PostLoginStepMutationHookResult = ReturnType<typeof usePostLoginStepMutation>;
export type PostLoginStepMutationResult = Apollo.MutationResult<PostLoginStepMutation>;
export type PostLoginStepMutationOptions = Apollo.BaseMutationOptions<PostLoginStepMutation, PostLoginStepMutationVariables>;