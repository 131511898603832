import { Tracing } from '@ast/opentelemetry';

import { appSettings } from '@app/core/appSettings';
import { removeCorrSessionId } from '@app/core/authentication/customAuthHeaders/corrSessionId';

export {
  useSetTraceSpan, X_AMAZON_TRACE_ID_KEY, context, Span, trace,
} from '@ast/opentelemetry';

const otlpCollectorEnabled = Boolean(appSettings().otlpCollectorEnabled === 'true') && appSettings().otlpCollectorUrl;
const otlpSamplingRatio = localStorage.getItem('OTLP-SAMPLING-RATIO') ? Number(localStorage.getItem('OTLP-SAMPLING-RATIO')) : 0.07;
export const getTracingOptions = () => ({
  serviceName: 'ofm-fe',
  isDebugEnabled: false,
  samplingRatio: otlpSamplingRatio,
  exporterUrl: otlpCollectorEnabled ? appSettings().otlpCollectorUrl : '',
  exportMetricsIntervalMillis: 60000,
  metricConcurrencyLimit: 10,
  resourceAttrs: {
    'fi.name': appSettings().loggerFiId,
    'deployment.environment': appSettings().loggerExecEnvironment,
  },
});

export const tracing = new Tracing(getTracingOptions());
export const parseAmazonTraceId = (str: string) => ({
  traceId: str.slice(7, 40).replace(/-/, ''),
  spanId: str.slice(48, 64),
});

removeCorrSessionId();
