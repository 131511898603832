export interface AppSettings {
  /**
   * Path to frontback api URL.
   */
  readonly apiEndpoint: string;
  /**
   * API security key.
   */
  readonly apiKey: string;
  /**
   * Including credentials in requests
   *
   * https://github.com/apollographql/apollo-client/blob/main/docs/source/networking/basic-http-networking.md
   *
   * include - Always send user credentials (cookies, basic http auth, etc.), even for cross-origin requests.
   * same-origin - Send user credentials (cookies, basic http auth, etc.) if the server's URL is on the same
   *               origin as the requesting client. This is the default value.
   * omit - Never send or receive credentials.
   */
  readonly credentials: string;
  /**
   * Logger options
   * @see https://cf.mfmnow.com/display/ARCH/Logging+Standard+Specification
   */
  readonly apiLoggerEndpoint: string;
  readonly isApiLoggerEnabled: string;
  readonly loggerFiId: string,
  readonly loggerExecEnvironment: string,
  readonly loggerFiEnvironment: string,

  /**
   * OpenTelemetry
   */

  // @TODO: Remove apm vars
  readonly apmApiUrl: string;
  readonly isApmApiEnabled: string;

  readonly otlpCollectorEnabled: string,
  readonly otlpCollectorUrl: string,

  /**
   * Client version based on ORPHEUS_VERSION_BUILD env vars
   */
  readonly clientVersion: string;
}

export const appSettings = (): AppSettings => (
  JSON.parse(document.getElementById('app-settings')!.textContent!.trim())
);
