import fetchIntercept from 'fetch-intercept';

import { appSettings } from '@app/core/appSettings';
import { isEmbeddedMode } from '@app/core/authentication/embeddedMode/embeddedMode';
import {
  extractInitParametersFromLocalStorage,
} from '@app/core/authentication/parametrizedLogin/sessionParametersStorage';
import { DEFAULT_FALLBACK_LANGUAGE_CULTURE } from '@app/core/contexts/localizationContext/constants';
import {
  getCurrentLanguageCultureFromStorage,
} from '@app/core/contexts/localizationContext/LocalizationContext';

import { getAuthenticationToken } from '../authenticationToken';

import { getClientDeviceUID } from './clientDeviceUID';
import { getDSSessionId } from './dsSessionId';

type CustomAuthHeaders = {
  readonly authorization?:string,
  // eslint-disable-next-line i18next/no-literal-string
  readonly 'x-client-device-id':string,
  // eslint-disable-next-line i18next/no-literal-string
  readonly 'x-session-id':string,
  // eslint-disable-next-line i18next/no-literal-string
  readonly 'x-api-key':string,
  // eslint-disable-next-line i18next/no-literal-string
  readonly 'x-mfm-hosted'?: string,
  // eslint-disable-next-line i18next/no-literal-string
  readonly 'x-amzn-trace-id'?: string,
  // eslint-disable-next-line i18next/no-literal-string
  readonly 'traceparent'?: string;
  // eslint-disable-next-line i18next/no-literal-string
  readonly 'x-ast-installationid'?: string,
  // eslint-disable-next-line i18next/no-literal-string
  readonly 'x-ast-culture-id'?: string,
};
/**
 * Return custom authentication headers.
 * @returns object with 'x-client-device-id' and 'x-session-id' headers.
 */
export const getCustomAuthHeaders = (): CustomAuthHeaders => {
  const paramSettings = extractInitParametersFromLocalStorage();

  let uidHeaders: CustomAuthHeaders = {
    // eslint-disable-next-line i18next/no-literal-string
    'x-client-device-id': getClientDeviceUID(),
    // eslint-disable-next-line i18next/no-literal-string
    'x-session-id': getDSSessionId(),
    // eslint-disable-next-line i18next/no-literal-string
    'x-api-key': appSettings().apiKey,
    // eslint-disable-next-line i18next/no-literal-string
    'x-ast-culture-id': getCurrentLanguageCultureFromStorage() ?? DEFAULT_FALLBACK_LANGUAGE_CULTURE,
  };

  const authToken = getAuthenticationToken();

  if (isEmbeddedMode()) {
    uidHeaders = {
      ...uidHeaders,
      // eslint-disable-next-line i18next/no-literal-string
      'x-mfm-hosted': 'true',
      // eslint-disable-next-line i18next/no-literal-string
      'x-ast-installationid': paramSettings.cduid,
    };
  }
  // Add auth token to headers if exist
  return (authToken) ? { ...uidHeaders, authorization: `Token ${authToken}` } : uidHeaders;
};

/**
 * Add custom auth headers to each fetch request.
 * @returns callback to unregister registered custom headers.
 */
export const requestInterceptorCallback = (url: string, config: any) => {
  const withDefaults = { ...config };
  if (typeof url === 'string') {
    const incomingUrl = new URL(url).origin;
    if (incomingUrl === appSettings().apiEndpoint) {
      withDefaults.headers = { ...getCustomAuthHeaders(), ...withDefaults.headers || {} };
    }
  }
  return [url, withDefaults];
};

export const registerFetchAuthHeaders = () => fetchIntercept.register({
  request(url: any, config) {
    return requestInterceptorCallback(url, config);
  },
  response(response) {
    return response;
  },
});
