import { generateRandomNumber } from '@app/common/utils/randomNumber';

import { joinStoragePath } from '../storage/browser/utils';

export const DUPLICATED_TAB_FLAG = joinStoragePath('windowTabId');

export const disallowDuplicatedTab = () => {
  const tabId = generateRandomNumber();
  const eventHandler = (event: StorageEvent) => {
    if (event.key === DUPLICATED_TAB_FLAG) {
      if (event.newValue == null) {
        localStorage.setItem(DUPLICATED_TAB_FLAG, tabId);
      } else if (event.newValue !== tabId) {
        // eslint-disable-next-line i18next/no-literal-string
        window.location.assign('/multi-tab-warning.html');
      }
    }
  };

  localStorage.setItem(DUPLICATED_TAB_FLAG, tabId);

  window.addEventListener('storage', eventHandler, false);

  return () => window.removeEventListener('storage', eventHandler, false);
};
