// eslint-disable-next-line max-len
// todo: move content rendering to consumer, adding a render prop and removing beforeFieldsContent, afterFieldsContent, containerComponent, etc.
// eslint-disable-next-line max-len
// todo: move from initiliazation outside of the component to have an ablitity use form methods in wizard step components

import React, {
  FC,
  useEffect,
  useMemo,
  useRef,
} from 'react';
import { useForm } from 'react-hook-form';

import { Alert } from '@ast/magma/components/alert';

import { moveFocus, moveNextGenerator } from '@ast/magma/utils/focus';

import { isEmptyArray, isEmptyObject, isFunction } from '../utils';

import { FlexContainer } from '../components/FlexContainer/FlexContainer';
import { FormProviderWrapper } from '../components/FormProviderWrapper/FormProviderWrapper';
import { FieldServerErrors } from '../types/validation';
import { getFirstInvalidFieldName } from '../utils/form/getFirstFieldError';
import { useDefaultValues } from './hooks/useDefaultValues';

import {
  createLocator,
} from './multilocator';
import {
  Field,
  FieldDataObject,
  WizardStepProps,
} from './types';
import {
  serializeResult,
} from './utils';
import { useWizardStepStateContext } from './WizardStateStateContext';
import styles from './WizardStep.pcss';

/**
 * Generic wizard step component.
 * It concentrates on fields validation, focusing, and form-related actions.
 */
export const WizardStep: FC<WizardStepProps> = ({
  wizard,
  buttons,
  onSubmit,
  onBack,
  onCancel,
  locators,
  onTerminationStep,
  contentContainer,
  fieldsContainer,
  buttonsContainer,
  beforeFieldsContent,
  afterFieldsContent,
  defaultFieldValues,
}) => {
  const {
    id,
    fields,
    fieldErrors,
    alertMessage,
    fieldsUiProps,
  } = useWizardStepStateContext();

  const stepId = id;
  const alertRef = useRef<HTMLDivElement>(null);

  const defaultValues = useDefaultValues({
    wizard, stepId, fields, defaultFieldValues,
  });

  const form = useForm({
    defaultValues,
    criteriaMode: 'firstError',
  });

  // Present to user the first field with error (validation or server-side)
  // by scrolling and setting focus into the field with fieldID.
  const focusField = (fieldID: string) => {
    const invalidFields = document.querySelectorAll(`[name="${fieldID}"]`);
    if (invalidFields.length) {
      const lastInvalidField = invalidFields[invalidFields.length - 1];

      // scroll to show the error
      if (isFunction(lastInvalidField.scrollIntoView)) {
        lastInvalidField.scrollIntoView({ block: 'center' });
      }

      // focus invalid field
      const nextInvalidField = moveNextGenerator(lastInvalidField, true);
      moveFocus(nextInvalidField);
    }
  };

  // handle server-side errors
  const setFieldServerErrors = (errors?: FieldServerErrors | null) => {
    if (!errors || isEmptyArray(errors)) {
      return;
    }

    // set field server errors
    errors.forEach((error) => {
      form.setError(
        error.fieldId,
        { message: error.message, type: 'server' },
        { shouldFocus: true },
      );
    });

    // set focus on first invalid field
    focusField(errors[0].fieldId);
  };

  // update defaults values when fields list was changed
  useEffect(() => {
    form.reset(defaultValues);
  }, [form.reset, fields, defaultValues]);

  // set field errors and focus on it
  useEffect(() => {
    setFieldServerErrors(fieldErrors);
  }, [fieldErrors, form.control?.fieldsRef?.current]);

  // scroll to the alert message
  useEffect(() => {
    if (alertRef?.current) {
      alertRef?.current.scrollIntoView?.({ behavior: 'smooth' });
    }
  }, [alertMessage]);

  // handle submit button
  const submitHandler = form.handleSubmit(
    (data: FieldDataObject) => {
      // TODO: implement a centralized way to disable user interaction with fields during submitting the form
      if (document.activeElement) {
        const activeElement = document.activeElement as HTMLElement;
        activeElement.blur();
      }

      onSubmit({
        setFieldErrors: setFieldServerErrors,
        setTerminationStepAction: onTerminationStep,
        data: serializeResult(data),
      });
    },
    () => {
      // trying to focus first invalid input after client validation

      // map with errors from state ..
      const invalidFieldIds = form.formState.errors;
      if (!invalidFieldIds || isEmptyObject(invalidFieldIds)) {
        return;
      }

      // set focus on first invalid input
      const firstInvalidFieldName = getFirstInvalidFieldName(invalidFieldIds);
      if (firstInvalidFieldName) {
        focusField(firstInvalidFieldName);
      }
    },
  );

  const locator = useMemo(
    () => createLocator(wizard, stepId || '', locators),
    [wizard, stepId, locators],
  );

  const ContentContainer = contentContainer || FlexContainer;
  const FieldsContainer = fieldsContainer || FlexContainer;
  const ButtonsContainer = buttonsContainer || FlexContainer;

  return (
    <FormProviderWrapper
      formProviderProps={form}
      formProps={{
        noValidate: true,
        onSubmit: submitHandler,
      }}
    >
      <ContentContainer direction="column" className={styles.container}>
        {beforeFieldsContent && beforeFieldsContent({ formState: form.formState })}

        {alertMessage && (
          <Alert
            ref={alertRef}
            // eslint-disable-next-line i18next/no-literal-string
            level={alertMessage.type || 'info'}
            title={alertMessage.title || ''}
            data-stable-name="WizardAlertMessage"
          >
            {alertMessage.content}
          </Alert>
        )}

        <FieldsContainer direction="column" data-stable-name="FieldsContainer">
          {fields.map((f: Field, i) => {
            const Component = locator(f);
            const uiProps = fieldsUiProps?.get(Component);

            return (
              <Component
                key={f.id || i}
                parent=""
                field={f}
                locator={locator}
                fieldUiProps={uiProps}
              />
            );
          })}
        </FieldsContainer>

        {afterFieldsContent && afterFieldsContent({ formState: form.formState })}

        {buttons && (
          <ButtonsContainer direction="column" data-stable-name="ButtonsContainer">
            {buttons({
              form,
              submit: submitHandler,
              back: onBack,
              cancel: onCancel,
            })}
          </ButtonsContainer>
        )}
      </ContentContainer>
    </FormProviderWrapper>
  );
};
