/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.
import * as Types from '../../../../queryTyping';

import { AllFields_AgreementFieldInfo_Fragment, AllFields_BooleanFieldInfo_Fragment, AllFields_ChoiceCollectionFieldInfoBase_Fragment, AllFields_ConstrainedTextFieldInfo_Fragment, AllFields_CurrencyFieldInfo_Fragment, AllFields_DateFieldInfo_Fragment, AllFields_DecimalFieldInfo_Fragment, AllFields_EmailFieldInfo_Fragment, AllFields_ErrorMessageFieldInfo_Fragment, AllFields_ExternalLinkFieldInfo_Fragment, AllFields_FieldInfoGroup_Fragment, AllFields_FuzzyValueFieldInfo_Fragment, AllFields_HiddenFieldInfo_Fragment, AllFields_HtmlTextFieldInfo_Fragment, AllFields_IntegerFieldInfo_Fragment, AllFields_LabelFieldInfo_Fragment, AllFields_MaskedTextFieldInfo_Fragment, AllFields_MultipleChoiceFieldInfo_Fragment, AllFields_NotificationFieldInfo_Fragment, AllFields_PasswordFieldInfo_Fragment, AllFields_QuestionPoolsFieldInfoGroup_Fragment, AllFields_SelectableGroupFieldInfo_Fragment, AllFields_SingleChoiceFieldInfo_Fragment, AllFields_SsnFieldInfo_Fragment, AllFields_TextFieldInfo_Fragment, AllFields_TimeFieldInfo_Fragment, AllFields_UsernameFieldInfo_Fragment, AllFields_ZipCodeFieldInfo_Fragment } from './all-fields';
import { gql } from '@apollo/client';
import { AllFieldsFragmentDoc } from './all-fields';
export type CurrentWizardStepFragment = (
  { __typename?: 'CurrentWizardStep' }
  & Pick<Types.CurrentWizardStep, 'id' | 'name' | 'description' | 'finalStep' | 'allowBackRedirect' | 'isTermination'>
  & { fields: Array<Types.Maybe<(
    { __typename?: 'EmailFieldInfo' }
    & AllFields_EmailFieldInfo_Fragment
  ) | (
    { __typename?: 'ConstrainedTextFieldInfo' }
    & AllFields_ConstrainedTextFieldInfo_Fragment
  ) | (
    { __typename?: 'PasswordFieldInfo' }
    & AllFields_PasswordFieldInfo_Fragment
  ) | (
    { __typename?: 'SsnFieldInfo' }
    & AllFields_SsnFieldInfo_Fragment
  ) | (
    { __typename?: 'UsernameFieldInfo' }
    & AllFields_UsernameFieldInfo_Fragment
  ) | (
    { __typename?: 'MaskedTextFieldInfo' }
    & AllFields_MaskedTextFieldInfo_Fragment
  ) | (
    { __typename?: 'TextFieldInfo' }
    & AllFields_TextFieldInfo_Fragment
  ) | (
    { __typename?: 'ZipCodeFieldInfo' }
    & AllFields_ZipCodeFieldInfo_Fragment
  ) | (
    { __typename?: 'HtmlTextFieldInfo' }
    & AllFields_HtmlTextFieldInfo_Fragment
  ) | (
    { __typename?: 'SingleChoiceFieldInfo' }
    & AllFields_SingleChoiceFieldInfo_Fragment
  ) | (
    { __typename?: 'MultipleChoiceFieldInfo' }
    & AllFields_MultipleChoiceFieldInfo_Fragment
  ) | (
    { __typename?: 'ChoiceCollectionFieldInfoBase' }
    & AllFields_ChoiceCollectionFieldInfoBase_Fragment
  ) | (
    { __typename?: 'FuzzyValueFieldInfo' }
    & AllFields_FuzzyValueFieldInfo_Fragment
  ) | (
    { __typename?: 'CurrencyFieldInfo' }
    & AllFields_CurrencyFieldInfo_Fragment
  ) | (
    { __typename?: 'AgreementFieldInfo' }
    & AllFields_AgreementFieldInfo_Fragment
  ) | (
    { __typename?: 'BooleanFieldInfo' }
    & AllFields_BooleanFieldInfo_Fragment
  ) | (
    { __typename?: 'DateFieldInfo' }
    & AllFields_DateFieldInfo_Fragment
  ) | (
    { __typename?: 'DecimalFieldInfo' }
    & AllFields_DecimalFieldInfo_Fragment
  ) | (
    { __typename?: 'IntegerFieldInfo' }
    & AllFields_IntegerFieldInfo_Fragment
  ) | (
    { __typename?: 'TimeFieldInfo' }
    & AllFields_TimeFieldInfo_Fragment
  ) | (
    { __typename?: 'ErrorMessageFieldInfo' }
    & AllFields_ErrorMessageFieldInfo_Fragment
  ) | (
    { __typename?: 'QuestionPoolsFieldInfoGroup' }
    & AllFields_QuestionPoolsFieldInfoGroup_Fragment
  ) | (
    { __typename?: 'FieldInfoGroup' }
    & AllFields_FieldInfoGroup_Fragment
  ) | (
    { __typename?: 'LabelFieldInfo' }
    & AllFields_LabelFieldInfo_Fragment
  ) | (
    { __typename?: 'HiddenFieldInfo' }
    & AllFields_HiddenFieldInfo_Fragment
  ) | (
    { __typename?: 'NotificationFieldInfo' }
    & AllFields_NotificationFieldInfo_Fragment
  ) | (
    { __typename?: 'ExternalLinkFieldInfo' }
    & AllFields_ExternalLinkFieldInfo_Fragment
  ) | (
    { __typename?: 'SelectableGroupFieldInfo' }
    & AllFields_SelectableGroupFieldInfo_Fragment
  )>> }
);

export const CurrentWizardStepFragmentDoc = gql`
    fragment CurrentWizardStep on CurrentWizardStep {
  id
  name
  description
  finalStep
  allowBackRedirect
  isTermination
  fields {
    ...AllFields
  }
}
    ${AllFieldsFragmentDoc}`;