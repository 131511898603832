import React from 'react';

import { CustomAppWidget } from '@app/queryTyping';

import { hasOwnPropertyOfType, isString } from '@app/common/utils';

import { WidgetRenderer, WidgetType } from './types';

/**
 * Custom App widget typeguard.
 * @param widget orchard widget.
 * @returns true if widget is a Custom App widget.
 */
export function isCustomAppWidget(
  widget: WidgetType,
): widget is Pick<CustomAppWidget, 'url' | 'width' | 'height'> {
  return (
  // eslint-disable-next-line no-underscore-dangle
    widget.__typename === 'CustomAppWidget'
      // eslint-disable-next-line i18next/no-literal-string
      && hasOwnPropertyOfType(widget, 'url', isString)
      // eslint-disable-next-line i18next/no-literal-string
      && hasOwnPropertyOfType(widget, 'width', isString)
      // eslint-disable-next-line i18next/no-literal-string
      && hasOwnPropertyOfType(widget, 'height', isString)
  );
}

const WidgetComponent = React.lazy(() => import(
  // webpackChunkName: "custom-app"
  '@app/widgets/custom-app'
));

export const CustomAppWidgetRenderer: WidgetRenderer<CustomAppWidget> = ({ widget }) => (
  <div>
    <WidgetComponent data={{
      width: widget.width!,
      height: widget.height!,
      url: widget.url!,
    }}
    />
  </div>
);
