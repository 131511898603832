/* eslint-disable no-underscore-dangle */
import { LocalStorageDocument } from '@app/core/storage/browser/LocalStorageDocument';
import { Migration } from '@app/core/versionedSerializer/versionedSerializer';

import { LoginMode } from './LoginWizard/steps/UsernamePassword/types';

const CURRENT_VERSION = 1;
const LOGIN_SETTINGS_NODE = 'login-settings';

/**
 * Inner serialization interface for data saved by LoginLocalSettings.
 */
interface ILoginLocalSettings {
  prefferableLoginMode: LoginMode;
}

/**
 * Singletone class for managing login settings stored in local storage.
 */
export class LoginLocalSettings extends LocalStorageDocument<ILoginLocalSettings> {
  /**
   * Singleton methods
   */
  private static inner?: LoginLocalSettings;

  /**
   * Constructor must describe latest data version and provide migrations from previous versions of saved data.
   * @param node string node key to storage data in local storage.
   * @param version latest version.
   * @param migrations migrations from previous versions to latest.
   */
  private constructor(node: string, version: number, migrations: Migration[] = []) {
    super(node, version, migrations);

    if (this.prefferableLoginMode == null) {
      this.prefferableLoginMode = LoginMode.password;
    }
  }

  public static get instance(): LoginLocalSettings {
    if (!this.inner) {
      this.inner = new LoginLocalSettings(LOGIN_SETTINGS_NODE, CURRENT_VERSION);
    }

    return this.inner;
  }

  /**
   * Public interface methods
   */

  private _prefferableLoginMode!: LoginMode;

  public get prefferableLoginMode() {
    return this._prefferableLoginMode;
  }

  public set prefferableLoginMode(value: LoginMode) {
    this._prefferableLoginMode = value;
    // Persist new value on change.
    this.save();
  }

  /**
   * Abstract methods implementation
   */

  /**
   * Save public data properties into serializable data stracture.
   * @returns data structure which can be saved in local storage.
   */
  protected serialize(): ILoginLocalSettings {
    return {
      prefferableLoginMode: this.prefferableLoginMode,
    };
  }

  /**
   * Init public data properties with saved data.
   * @param deserialized saved data.
   */
  protected deserialize(deserialized: ILoginLocalSettings) {
    this.prefferableLoginMode = deserialized.prefferableLoginMode;
  }
}
