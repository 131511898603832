import React from 'react';

import { Widget } from '@app/queryTyping';

import { getAbsoluteURL } from '@app/common/utils/url';

import { PureWidget, WidgetRenderer, WidgetType } from '../types';

import styles from './styles.pcss';

/**
 * Html widget typeguard.
 * @param widget orchard widget.
 * @returns true if widget is html widget.
 */
export function isHtmlWidget(widget: WidgetType): widget is PureWidget<Widget> {
  return widget.__typename === 'Widget' && widget.html !== undefined;
}

/**
   * Workaround solution to replace relative source paths for images to absolute paths.
   * @param html source widget html
   */
export const replaceRelativeMedia = (html:string):string => (html.replace(
  /(\s(?:href|src)\s?=\s?["'])(\/media\/[^'"#]+)(["'])/gmi,
  (match, prefix, relativeURL, suffix) => (
    [prefix, getAbsoluteURL(relativeURL), suffix].join('')
  ),
));

export const HtmlWidgetRenderer: WidgetRenderer<Widget> = (
  { widget },
) => (
  <div
    className={styles.htmlWidget}
    dangerouslySetInnerHTML={{ __html: replaceRelativeMedia(widget?.html!) }}
  />
);
