import React, {
  useEffect, useRef, useState,
} from 'react';

import { TFunction } from 'i18next';

import Biometric from '@ast/magma/components/icon/icons/Biometric';
import CheckCircle from '@ast/magma/components/icon/icons/CheckCircle';
import TimesCircle from '@ast/magma/components/icon/icons/TimesCircle';

import { Button } from '@ast/magma/components/button';

import { BiometricsScanResultType } from '@app/queryTyping';

import {
  StatusWizardStep,
  StatusWizardStepProps,
} from '@app/common/components/configurable-wizards/ResultWizardStep/StatusWizardStep';
import {
  ConnectedWizardStep, WizardStep,
} from '@app/common/configurable-wizards';
import { isFunction, isUndefined } from '@app/common/utils';

import { useUserLoginTranslation } from '@app/widgets/user-login/hooks/useUserLoginTranslation';

import {
  useBiometricAuthenticationNotificationSubscription,
} from './queries/queryTyping/biometricAuthenticationNotification';

import style from './QuickLoginStateWizardStep.pcss';

enum QuickLoginState {
  Pending = 'Pending',
  Failed = 'Failed',
  Succeded = 'Succeded',
}

function getStatusAndTexts(quickLoginState: QuickLoginState, t: TFunction): {
  title: string;
  description: string;
  button?: {
    title: string;
  }
  status: StatusWizardStepProps['level'],
  icon: StatusWizardStepProps['icon'],
} {
  switch (quickLoginState) {
    case QuickLoginState.Succeded:
      return {
        title: t('login.steps.quick-login.success.title|Successful biometric login title', 'Biometric authentication successful'),
        description: t('login.steps.quick-login.success.description|Successful biometric login description', 'Successful login'),
        icon: <CheckCircle />,
        status: 'success',
      };
    case QuickLoginState.Failed:
      return {
        title: t('login.steps.quick-login.fail.title|Failed biometric login title', 'Biometric authentication failed'),
        description: t('login.steps.quick-login.fail.description|Failed biometric login description',
          'We were unable to authenticate your biometric credentials. Please try again or contact your financial institution for further assistance.'),
        icon: <TimesCircle />,
        status: 'error',
        button: {
          title: t('login.steps.quick-login.fail.button.title|Cancel login & return to login button text', 'Return to login'),
        },
      };
    case QuickLoginState.Pending:
    default:
      return {
        title: t('login.steps.quick-login.pending.title|Pending biometric login title', 'Biometric authentication pending'),
        description: t('login.steps.quick-login.pending.description|Pending biometric login description',
          'Waiting for biometric authentication on client’s mobile device. Please check your registered mobile device(s) to continue.'),
        icon: <Biometric />,
        status: 'info',
        button: {
          title: t('login.steps.quick-login.pending.button.title|Cancel login button text', 'Cancel'),
        },
      };
  }
}

export const QuickLoginStateWizardStep: React.FC<ConnectedWizardStep> = ({ locators, wizard, ...restParams }) => {
  const { t } = useUserLoginTranslation();

  const [quickLoginState, setQuickLoginState] = useState<QuickLoginState>(QuickLoginState.Pending);
  const submitRef = useRef<() => void>();
  const {
    title,
    description,
    button,
    status,
    icon,
  } = getStatusAndTexts(quickLoginState, t);

  const {
    data,
    error,
    loading: biometricAuthInProgress,
  } = useBiometricAuthenticationNotificationSubscription();

  // update the login state, when biometric auth is finished
  useEffect(() => {
    if (!biometricAuthInProgress) {
      const result = data?.biometricAuthenticationNotification?.biometricAuthenticationResult;
      if (error || result !== BiometricsScanResultType.SUCCEEDED) {
        setQuickLoginState(QuickLoginState.Failed);
      } else {
        setQuickLoginState(QuickLoginState.Succeded);
      }
    }
  }, [biometricAuthInProgress, error]);

  // when the login state is succeded, submit the form to go to the next step
  useEffect(() => {
    if (quickLoginState === QuickLoginState.Succeded) {
      if (isFunction(submitRef.current)) submitRef.current();
    }
  }, [quickLoginState]);

  function handleButtonClick(cancel?: () => void) {
    switch (quickLoginState) {
      case QuickLoginState.Pending:
      case QuickLoginState.Failed:
        if (isFunction(cancel)) cancel();
        break;
      default:
        // eslint-disable-next-line i18next/no-literal-string
        throw new Error('Unexpected quick login state');
    }
  }

  function renderAfterFieldsContent() {
    return (
      <div role="alert">
        <StatusWizardStep
          level={status}
          title={title}
          icon={icon}
        >
          {description}
        </StatusWizardStep>
      </div>
    );
  }

  return (
    <WizardStep
      locators={locators}
      wizard={wizard}
      {...restParams}
      buttons={({ submit, cancel }) => {
        // keep the submit function to be able to submit the form later
        submitRef.current = submit;

        if (isUndefined(button)) { return null; }

        return (
          <Button
            look="outlined"
            className={style.button}
            onClick={() => handleButtonClick(cancel)}
          >
            {button.title}
          </Button>
        );
      }}
      afterFieldsContent={() => renderAfterFieldsContent()}
    />
  );
};
